import { RollbackOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Row,
  Space,
  // message,
  Modal,
  Tooltip,
  Typography,
  Card,
  Tabs,
  Select,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ContentLayout from '../../components/ContentLayout';
// import Heading from '../../components/common/Heading';
import SiteContent from '../../components/SiteContent';
import Spacer from '../../components/common/Spacer';
import { DataGrid } from '../../components/common/datagrid/DataGrid2';
import styled from 'styled-components';
import moment from 'moment';
import ExportDialog from './ExportAuditDialog';
import ProductDetailDialog from '../ListProducts/ProductDetailDialog';

// import { getOperationStatusDesc } from '../DetailProduct/BasicAttrEditor'
import {
  getNotonchannelExceptionProducts,
  getNosaleschannelflagExceptionProducts,
  getInventoryUploadProductsSuccess,
  getInventoryUploadProductsFail,
  getAllNotonchannelExceptionProducts,
  getAllNosaleschannelflagExceptionProducts,
  getAllInventoryUploadProductsSuccess,
  getAllInventoryUploadProductsFail,
  postGenerateAuditInventory,
  getAuditInventoryWarehouses,
} from '../../services/products';
// import { CHANNEL_AUDIT_DEFAULT_WAREHOUSE } from '../../constants/config';

const { Text, Title } = Typography;
const NTabs = styled(Tabs)`
  .ant-tabs-content {
    height: calc(100% - 60px);
  }
`;

const errorEnum: { [key: number]: string } = {
  0: 'None',
  1: 'Not in Channel',
  2: 'No Sales Channel Flag',
  3: 'Sync Error',
};

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const PageContent: React.FC<{
  selectedChannel: ChannelAccount;
  onCallback: () => void;
}> = ({ selectedChannel, onCallback }) => {
  // const localWarehouseKey = `${CHANNEL_AUDIT_DEFAULT_WAREHOUSE}_${selectedChannel.ChannelNum}_${selectedChannel.ChannelAccountNum}`;
  // const localTempSelectedWarehouse = localStorage.getItem(localWarehouseKey);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(50);
  const [selected, setSelected] = React.useState<any>({});
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [filteredData1, setFilteredData1] = useState<any[]>([]);
  const [isFetching1, setIsFetching1] = useState(false);
  const [pageSkip1, setPageSkip1] = useState(0);
  const [pageTop1, setPageTop1] = useState(50);
  const [selected1, setSelected1] = React.useState<any>({});
  const [totalCount1, setTotalCount1] = useState(0);
  const [selectedRows1, setSelectedRows1] = useState<any[]>([]);

  const [filteredData2, setFilteredData2] = useState<any[]>([]);
  const [isFetching2, setIsFetching2] = useState(false);
  const [pageSkip2, setPageSkip2] = useState(0);
  const [pageTop2, setPageTop2] = useState(50);
  const [selected2, setSelected2] = React.useState<any>({});
  const [totalCount2, setTotalCount2] = useState(0);
  const [selectedRows2, setSelectedRows2] = useState<any[]>([]);

  const [filteredData3, setFilteredData3] = useState<any[]>([]);
  const [isFetching3, setIsFetching3] = useState(false);
  const [pageSkip3, setPageSkip3] = useState(0);
  const [pageTop3, setPageTop3] = useState(50);
  const [selected3, setSelected3] = React.useState<any>({});
  const [totalCount3, setTotalCount3] = useState(0);
  const [selectedRows3, setSelectedRows3] = useState<any[]>([]);

  const [exportDialogVisible, setExportDialogVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('3');
  // const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [generating, setGenerating] = useState(false);
  const [warehouseList, setWarehouseList] = useState<any[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>('');
  const [selectedWarehouse1, setSelectedWarehouse1] = useState<string>('');

  const openProductDetailDialog = (product: StringKAnyVPair) => {
    setDetailVisible(true);
    setCurrentProduct({ ...product, ProductId: product.productId });
  };

  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
  };

  const columns = [
    {
      name: 'VariationParentSKU',
      header: 'Style Code',
      defaultFlex: 1,
      minWidth: 120,
      render({ data }: { data: any }) {
        if (data && typeof data === 'object') {
          return data.styleCode || data.VariationParentSKU;
        }

        return '';
      },
    },
    {
      name: 'subStyleCode',
      header: 'Sub-Style Code',
      defaultFlex: 1,
      minWidth: 180,
      render({ data }: { data: any }) {
        if (data && typeof data === 'object') {
          return data.subStyleCode || '';
        }

        return '';
      },
    },
    {
      name: 'sku',
      header: 'SKU',
      defaultFlex: 1,
      minWidth: 200,
      // sort: (a: any, b: any) => false,
      render({ data }: { data: any }) {
        return data.productNum ? (
          <Button type="link" onClick={() => openProductDetailDialog(data)}>
            {data.sku}
          </Button>
        ) : (
          <span>{data.sku}</span>
        );
      },
    },
    {
      name: 'title',
      header: 'Title',
      defaultFlex: 1,
      minWidth: 150,
      sort: (a: any, b: any) => false,
    },
    {
      name: 'generateTime',
      header: 'Report Time',
      dateFormat: 'YYYY-MM-DD',
      defaultFlex: 1,
      minWidth: 180,
      render: ({ value }: { value: string }) => moment(value).format('MM/DD/YYYY - hh:mm A'),
    },
    {
      name: 'localGenerateTime',
      header: 'Local Report Time',
      visible: false,
    },
  ];

  const columns1 = [
    {
      name: 'VariationParentSKU',
      header: 'Style Code',
      defaultFlex: 1,
      minWidth: 120,
      render({ data }: { data: any }) {
        if (data && typeof data === 'object') {
          return data.styleCode || data.VariationParentSKU;
        }

        return '';
      },
    },
    {
      name: 'subStyleCode',
      header: 'Sub-Style Code',
      defaultFlex: 1,
      minWidth: 180,
      render({ data }: { data: any }) {
        if (data && typeof data === 'object') {
          return data.subStyleCode || '';
        }

        return '';
      },
    },
    {
      name: 'sku',
      header: 'SKU',
      defaultFlex: 1,
      minWidth: 200,
      // sort: (a: any, b: any) => false,
      render({ data }: { data: any }) {
        return (
          <Button type="link" onClick={() => openProductDetailDialog(data)}>
            {data.sku}
          </Button>
        );
      },
    },
    {
      name: 'lastUploadQty',
      header: 'Last Upload Qty',
      defaultFlex: 1,
      minWidth: 150,
    },
    {
      name: 'lastUploadDate',
      header: 'Last Upload Time',
      dateFormat: 'YYYY-MM-DD',
      defaultFlex: 1,
      minWidth: 180,
      render: ({ value }: { value: string }) =>
        value ? moment(value).format('MM/DD/YYYY - hh:mm A') : '',
    },
    {
      name: 'localLastUploadDate',
      header: 'Local Last Upload Time',
      visible: false,
    },
    {
      name: 'inStock',
      header: 'Report In Stock',
      defaultFlex: 1,
      minWidth: 150,
    },
    {
      name: 'openSoQty',
      header: 'Report Open SO',
      defaultFlex: 1,
      minWidth: 150,
    },
    {
      name: 'ats',
      header: 'Report ATS',
      defaultFlex: 1,
      minWidth: 150,
    },
    {
      name: 'channelAckType',
      header: 'Channel Ack Type',
      defaultFlex: 1,
      minWidth: 150,
      render: ({ value }: { value: number }) =>
        !value ? '' : value === 1 ? 'Batch' : 'Individual ',
    },
    {
      name: 'channelAckDate',
      header: 'Channel Ack Time',
      dateFormat: 'YYYY-MM-DD',
      defaultFlex: 1,
      minWidth: 180,
      render: ({ value }: { value: string }) =>
        value ? moment(value).format('MM/DD/YYYY - hh:mm A') : '',
    },
    {
      name: 'localChannelAckDate',
      header: 'Local Channel Ack Time',
      visible: false,
    },
    {
      name: 'warehouseCode',
      header: 'Warehouse',
      defaultFlex: 1,
      minWidth: 150,
    },
    {
      name: 'channelWarehouseCode',
      header: 'Channel Warehouse',
      defaultFlex: 1,
      minWidth: 150,
      defaultVisible: false,
    },
    {
      name: 'generateTime',
      header: 'Report Time',
      dateFormat: 'YYYY-MM-DD',
      defaultFlex: 1,
      minWidth: 180,
      render: ({ value }: { value: string }) => moment(value).format('MM/DD/YYYY - hh:mm A'),
    },
    {
      name: 'localGenerateTime',
      header: 'Local Report Time',
      visible: false,
    },
  ];

  const columns2 = [
    {
      name: 'reason',
      header: 'Failed Reason',
      defaultFlex: 1,
      minWidth: 180,
      render({ data }: { data: any }) {
        if (data && typeof data === 'object') {
          return data.reason >= 0 ? errorEnum[data.reason] : '';
        }
        return '';
      },
    },
    ...columns1,
  ];

  const getNoinChannel = useCallback(
    async (top: number, skip: number) => {
      setSelected({});
      setSelectedRows([]);
      try {
        setIsFetching(true);
        const res = await getNotonchannelExceptionProducts(
          selectedChannel.ChannelNum,
          selectedChannel.ChannelAccountNum,
          {
            $top: top,
            $skip: skip,
            $sortBy: '',
          },
        );
        setIsFetching(false);
        if (res) {
          setTotalCount(res.total);
          setFilteredData(
            res.data.map((i: any) => {
              return {
                ...i,
                generateTime: res.time,
                localGenerateTime: moment(res.time).format('MM/DD/YYYY - hh:mm A'),
              };
            }),
          );
          setPageSkip(skip);
          setPageTop(top);
        }
      } catch (error) {
        setIsFetching(false);
      }
    },
    [selectedChannel],
  );

  const getNoSalesChannelFlag = useCallback(
    async (top: number, skip: number) => {
      setSelected1({});
      setSelectedRows1([]);
      try {
        setIsFetching1(true);
        const res = await getNosaleschannelflagExceptionProducts(
          selectedChannel.ChannelNum,
          selectedChannel.ChannelAccountNum,
          {
            $top: top,
            $skip: skip,
            $sortBy: '',
          },
        );
        setIsFetching1(false);
        if (res) {
          setTotalCount1(res.total);
          setFilteredData1(
            res.data.map((i: any) => {
              return {
                ...i,
                generateTime: res.time,
                localGenerateTime: moment(res.time).format('MM/DD/YYYY - hh:mm A'),
              };
            }),
          );
          setPageSkip1(skip);
          setPageTop1(top);
        }
      } catch (error) {
        setIsFetching1(false);
      }
    },
    [selectedChannel],
  );

  const getInventoryUploadSuccess = useCallback(
    async (top: number, skip: number, warehouse: string) => {
      setSelected2({});
      setSelectedRows2([]);
      try {
        setIsFetching2(true);
        const res = await getInventoryUploadProductsSuccess(
          selectedChannel.ChannelNum,
          selectedChannel.ChannelAccountNum,
          {
            $top: top,
            $skip: skip,
            $sortBy: '',
            warehouse: warehouse,
          },
        );
        setIsFetching2(false);
        if (res) {
          setTotalCount2(res.total);
          setFilteredData2(
            res.data.map((i: any) => {
              return {
                ...i,
                generateTime: res.time,
                localGenerateTime: moment(res.time).format('MM/DD/YYYY - hh:mm A'),
                localChannelAckDate: i.channelAckDate
                  ? moment(i.channelAckDate).format('MM/DD/YYYY - hh:mm A')
                  : '',
                localLastUploadDate: i.lastUploadDate
                  ? moment(i.lastUploadDate).format('MM/DD/YYYY - hh:mm A')
                  : '',
              };
            }),
          );
          setPageSkip2(skip);
          setPageTop2(top);
          // localStorage.setItem(localWarehouseKey, warehouse);
        }
      } catch (error) {
        setIsFetching2(false);
      }
    },
    [selectedChannel],
  );

  const getInventoryUploadFail = useCallback(
    async (top: number, skip: number, warehouse: string) => {
      setSelected3({});
      setSelectedRows3([]);
      try {
        setIsFetching3(true);
        const res = await getInventoryUploadProductsFail(
          selectedChannel.ChannelNum,
          selectedChannel.ChannelAccountNum,
          {
            $top: top,
            $skip: skip,
            $sortBy: '',
            warehouse: warehouse,
          },
        );
        setIsFetching3(false);
        if (res) {
          setTotalCount3(res.total);
          setFilteredData3(
            res.data.map((i: any) => {
              return {
                ...i,
                generateTime: res.time,
                localGenerateTime: moment(res.time).format('MM/DD/YYYY - hh:mm A'),
                localChannelAckDate: i.channelAckDate
                  ? moment(i.channelAckDate).format('MM/DD/YYYY - hh:mm A')
                  : '',
                localLastUploadDate: i.lastUploadDate
                  ? moment(i.lastUploadDate).format('MM/DD/YYYY - hh:mm A')
                  : '',
              };
            }),
          );
          setPageSkip3(skip);
          setPageTop3(top);
          // localStorage.setItem(localWarehouseKey, warehouse);
        }
      } catch (error) {
        setIsFetching3(false);
      }
    },
    [selectedChannel],
  );

  const generateInventory = useCallback(async () => {
    try {
      setGenerating(true);
      const res = await postGenerateAuditInventory(
        selectedChannel.ChannelNum,
        selectedChannel.ChannelAccountNum,
      );
      setGenerating(false);
      if (res && res.data) {
        Modal.success({
          content:
            'The data is being generated, please wait a few minutes before re-fetching the table data',
        });
      }
    } catch (error) {
      setGenerating(false);
    }
  }, [selectedChannel]);

  const getWarehouses = useCallback(async () => {
    try {
      const res = await getAuditInventoryWarehouses(
        selectedChannel.ChannelNum,
        selectedChannel.ChannelAccountNum,
      );
      if (res) {
        setWarehouseList(res.data);
      }
    } catch (error) {}
  }, [selectedChannel]);

  const dataSource = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredData,
      count: totalCount,
    };
  };

  const dataSource1 = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredData1,
      count: totalCount1,
    };
  };

  const dataSource2 = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredData2,
      count: totalCount2,
    };
  };

  const dataSource3 = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredData3,
      count: totalCount3,
    };
  };

  const onSelectionChange = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected(selected);
    if (selected === true && !unselected) {
      setSelectedRows(data as any);
    } else if (!unselected) {
      setSelectedRows(Object.values(selected as any));
    } else {
      setSelectedRows((prev) => prev.filter((item) => item.ProductId !== (data as any).id));
    }
  }, []);

  const onSelectionChange1 = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected1(selected);
    if (selected === true && !unselected) {
      setSelectedRows1(data as any);
    } else if (!unselected) {
      setSelectedRows1(Object.values(selected as any));
    } else {
      setSelectedRows1((prev) => prev.filter((item) => item.ProductId !== (data as any).id));
    }
  }, []);

  const onSelectionChange2 = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected2(selected);
    if (selected === true && !unselected) {
      setSelectedRows2(data as any);
    } else if (!unselected) {
      setSelectedRows2(Object.values(selected as any));
    } else {
      setSelectedRows2((prev) => prev.filter((item) => item.ProductId !== (data as any).id));
    }
  }, []);

  const onSelectionChange3 = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected3(selected);
    if (selected === true && !unselected) {
      setSelectedRows3(data as any);
    } else if (!unselected) {
      setSelectedRows3(Object.values(selected as any));
    } else {
      setSelectedRows3((prev) => prev.filter((item) => item.ProductId !== (data as any).id));
    }
  }, []);

  /* eslint-disable */
  useEffect(() => {
    getNoinChannel(50, 0);
    getNoSalesChannelFlag(50, 0);
    getWarehouses();
    getInventoryUploadSuccess(50, 0, '');
    getInventoryUploadFail(50, 0, '');
  }, [
    getNoSalesChannelFlag,
    getNoinChannel,
    getInventoryUploadSuccess,
    getWarehouses,
    getInventoryUploadFail,
  ]);
  /* eslint-disable */

  return (
    <ContentLayout>
      {/* <Heading title={`Sell Listing -> ${selectedChannel.ChannelName}`} /> */}
      <Row justify="start" align="middle">
        <Space size="small">
          <Tooltip placement="leftTop" title="back">
            <Button type="link" icon={<RollbackOutlined />} onClick={() => onCallback()} />
          </Tooltip>
          <Text
            style={{
              fontFamily: 'Lato,sans-serif',
              fontSize: 28,
              fontWeight: 900,
              color: '#2d3f5d',
            }}
          >{`${selectedChannel.ChannelName} -> ${selectedChannel.ChannelAccountName}`}</Text>
        </Space>
      </Row>
      <Spacer />
      <SiteContent style={{ padding: 0 }} flexGrow transparent>
        <Card
          size="small"
          style={{ width: '100%', height: '100%' }}
          bodyStyle={{ height: '100%' }}
          title={<Title level={3}>Audit</Title>}
        >
          <NTabs
            style={{ height: 'calc(100% - 32px)' }}
            onChange={(activeKey) => setActiveTab(activeKey)}
            tabBarExtraContent={
              <Space>
                {activeTab === '3' && (
                  <Space>
                    <Text>Warehouse:</Text>
                    <Select
                      allowClear
                      style={{ width: 150 }}
                      disabled={isFetching2}
                      defaultValue={undefined}
                      onChange={(value: string) => {
                        setSelectedWarehouse(value);
                        getInventoryUploadSuccess(pageTop2, 0, value);
                      }}
                    >
                      {warehouseList.map((item: string) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                )}
                {activeTab === '4' && (
                  <Space>
                    <Text>Warehouse:</Text>
                    <Select
                      allowClear
                      style={{ width: 150 }}
                      disabled={isFetching3}
                      defaultValue={undefined}
                      onChange={(value: string) => {
                        setSelectedWarehouse1(value);
                        getInventoryUploadFail(pageTop3, 0, value);
                      }}
                    >
                      {warehouseList.map((item: string) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                )}
                {(activeTab === '3' || activeTab === '4') && (
                  <Button
                    loading={generating}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Do you want to generate new report?',
                        icon: <ExclamationCircleOutlined />,
                        // content: 'we automatically generate data once a day at a fixed time',
                        okText: 'No',
                        cancelText: 'Yes',
                        okType: 'default',
                        cancelButtonProps: { type: 'primary' },
                        onOk() {},
                        onCancel() {
                          generateInventory();
                        },
                      });
                    }}
                  >
                    Generate New Report
                  </Button>
                )}
                <Button onClick={() => setExportDialogVisible(true)}>
                  Export
                  <DownloadOutlined />
                </Button>
                {/* {activeTab !== '3' && (
                  <Button onClick={() => setModifyModalVisible(true)}>
                    Modify Sales Channel Setting
                  </Button>
                )} */}
              </Space>
            }
          >
            <NTabs.TabPane
              tab={totalCount2 ? `Successful Sync (${totalCount2})` : 'Successful Sync'}
              key="3"
              style={{ height: '100%' }}
            >
              <DataGrid
                style={{ height: '100%' }}
                idProperty="sku"
                rowHeight={35}
                columns={columns1}
                dataSource={dataSource2}
                enableFiltering={false}
                limit={pageTop2}
                loading={isFetching2}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                checkboxOnlyRowSelect
                onLimitChange={(limit) => {
                  getInventoryUploadSuccess(limit, pageSkip2, selectedWarehouse);
                }}
                onSelectionChange={onSelectionChange2}
                onSkipChange={(skip) => {
                  getInventoryUploadSuccess(pageTop2, skip, selectedWarehouse);
                }}
                rowExpandHeight={300}
                skip={pageSkip2}
                sortable={false}
                checkboxColumn
                selected={selected2}
              />
            </NTabs.TabPane>
            <NTabs.TabPane
              tab={totalCount3 ? `Failed Sync (${totalCount3})` : 'Failed Sync'}
              key="4"
              style={{ height: '100%' }}
            >
              <DataGrid
                style={{ height: '100%' }}
                idProperty="sku"
                rowHeight={35}
                columns={columns2}
                dataSource={dataSource3}
                enableFiltering={false}
                limit={pageTop3}
                loading={isFetching3}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                checkboxOnlyRowSelect
                onLimitChange={(limit) => {
                  getInventoryUploadSuccess(limit, pageSkip3, selectedWarehouse1);
                }}
                onSelectionChange={onSelectionChange3}
                onSkipChange={(skip) => {
                  getInventoryUploadFail(pageTop3, skip, selectedWarehouse1);
                }}
                rowExpandHeight={300}
                skip={pageSkip3}
                sortable={false}
                checkboxColumn
                selected={selected3}
              />
            </NTabs.TabPane>
            <NTabs.TabPane
              tab={totalCount ? `Not in Channel (${totalCount})` : 'Not in Channel'}
              key="1"
              style={{ height: '100%' }}
            >
              <DataGrid
                style={{ height: '100%' }}
                idProperty="sku"
                rowHeight={35}
                columns={columns}
                dataSource={dataSource}
                enableFiltering={false}
                limit={pageTop}
                loading={isFetching}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                checkboxOnlyRowSelect
                onLimitChange={(limit) => {
                  getNoinChannel(limit, pageSkip);
                }}
                onSelectionChange={onSelectionChange}
                onSkipChange={(skip) => {
                  getNoinChannel(pageTop, skip);
                }}
                rowExpandHeight={300}
                skip={pageSkip}
                sortable={false}
                checkboxColumn
                selected={selected}
              />
            </NTabs.TabPane>
            <NTabs.TabPane
              tab={totalCount1 ? `No Sales Channel Flag (${totalCount1})` : 'No Sales Channel Flag'}
              key="2"
              style={{ height: '100%' }}
            >
              <DataGrid
                style={{ height: '100%' }}
                idProperty="sku"
                rowHeight={35}
                columns={columns}
                dataSource={dataSource1}
                enableFiltering={false}
                limit={pageTop1}
                loading={isFetching1}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                checkboxOnlyRowSelect
                onLimitChange={(limit) => {
                  getNoSalesChannelFlag(limit, pageSkip1);
                }}
                onSelectionChange={onSelectionChange1}
                onSkipChange={(skip) => {
                  getNoSalesChannelFlag(pageTop1, skip);
                }}
                rowExpandHeight={300}
                skip={pageSkip1}
                sortable={false}
                checkboxColumn
                selected={selected1}
              />
            </NTabs.TabPane>
          </NTabs>
        </Card>
      </SiteContent>
      {exportDialogVisible && (
        <ExportDialog
          account={selectedChannel}
          columns={activeTab === '3' ? columns1 : activeTab === '4' ? columns2 : columns}
          allData={
            activeTab === '1'
              ? filteredData
              : activeTab === '2'
              ? filteredData1
              : activeTab === '3'
              ? filteredData2
              : filteredData3
          }
          selectedRows={
            activeTab === '1'
              ? selectedRows
              : activeTab === '2'
              ? selectedRows1
              : activeTab === '3'
              ? selectedRows2
              : selectedRows3
          }
          fileName={
            activeTab === '1'
              ? `${selectedChannel.ChannelAccountName}_Audit_NotInChannel`
              : activeTab === '2'
              ? `${selectedChannel.ChannelAccountName}_Audit_NoSalesChannelFlag`
              : activeTab === '3'
              ? `${selectedChannel.ChannelAccountName}_Audit_Successful_Sync`
              : `${selectedChannel.ChannelAccountName}_Audit_Failed_Sync`
          }
          filterArr={[]}
          typeName={
            activeTab === '1'
              ? 'Not in Channel'
              : activeTab === '2'
              ? 'No Sales Channel Flag'
              : activeTab === '3'
              ? 'Successful Sync'
              : 'Failed Sync'
          }
          downloadUrl={
            activeTab === '1'
              ? getAllNotonchannelExceptionProducts
              : activeTab === '2'
              ? getAllNosaleschannelflagExceptionProducts
              : activeTab === '3'
              ? getAllInventoryUploadProductsSuccess
              : getAllInventoryUploadProductsFail
          }
          onClose={() => {
            setExportDialogVisible(false);
          }}
          formatColumns={{
            channelAckType: { 1: 'Batch', 2: 'Individual' },
            reason: errorEnum,
          }}
        />
      )}
      {/* {modifyModalVisible && (
        <Modal
          visible
          title="Modify Sales Channel Setting"
          onCancel={() => setModifyModalVisible(false)}
          width={660}
          footer={[
            <Button type="primary" key="back" onClick={() => setModifyModalVisible(false)}>
              Close
            </Button>,
          ]}
        >
          {activeTab === '1' && (
            <Row justify="center">
              <Space direction="vertical">
                {selectedRows.length === 0 && <Text type="danger">Please select rows first.</Text>}
                <Space>
                  <Button disabled={selectedRows.length === 0}>Disable Feed Inventory</Button>
                  <Button disabled={selectedRows.length === 0}>Remove Sales Channel Flag</Button>
                </Space>
              </Space>
            </Row>
          )}

          {activeTab === '2' && (
            <Row justify="center">
              <Space direction="vertical">
                {selectedRows1.length === 0 && <Text type="danger">Please select rows first.</Text>}
                <Space>
                  <Button disabled={selectedRows1.length === 0}>Add Feed Inventory</Button>
                  <Button disabled={selectedRows1.length === 0}>Add Sync Catalog</Button>
                  <Button disabled={selectedRows1.length === 0}>
                    Add Feed Inventory & Sync Catalog
                  </Button>
                </Space>
              </Space>
            </Row>
          )}
        </Modal>
      )} */}
      {detailVisible && (
        <ProductDetailDialog
          onClose={closeProductDetailDialog}
          product={currentProduct}
          visible={detailVisible}
        />
      )}
    </ContentLayout>
  );
};

export default PageContent;
