import {
  RetweetOutlined,
  UserOutlined,
  CarryOutOutlined,
  // BulbOutlined,
  ShoppingCartOutlined,
  LineChartOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React from 'react';
// import Export from '../../assets/icons/export';
import DashboardSVG from '../../assets/icons/dashboardMenu';
import CubeSVG from '../../assets/icons/cube';
// import Import from '../../assets/icons/import';
// import Profile from '../../assets/icons/profile';
import Settings from '../../assets/icons/settings';
import Shopping from '../../assets/icons/shoppingIcon';
import CustomService from '../../assets/icons/textSms';
import Warehouse from '../../assets/icons/warehouse';
import linkERP from '../../util/linkERP';
import AccountsPayableSVG from '../../assets/icons/accountsPayable';
import AccountsReceivableSVG from '../../assets/icons/accountsReceivable';

const iconColor = 'currentColor';

export type IMenuItem = {
  key: string;
  name?: string;
  route?: string;
  /**
   * If the entry should be a link to an external page
   */
  link?: string;
  icon?: any;
  children?: IMenuItem[];
  permissionNum?: number;
  /**
   * If true, children will be rendered within a `MenuItem.ItemGroup`,
   * otherwise it'll use a `Menu.SubMenu`
   */
  group?: boolean;
  onClick?: () => void;
  visible?: boolean;
};

// use to hide based on env
// const isDevEnv = () => {
//   const devEnvs = ['development', 'local'];

//   return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
// };

const MenuItems: IMenuItem[] = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    route: '/',
    icon: <DashboardSVG pathColor={iconColor} />,
  },
  {
    key: 'products-list',
    name: 'Product Information',
    route: '/products-list',
    icon: <CubeSVG />,
  },
  {
    key: 'sell',
    name: 'Sell',
    route: '/sell',
    icon: <Shopping pathColor={iconColor} />,
    children: [
      {
        key: 'sell-channel',
        name: 'Channel Listing',
        route: '/sell/channel/home',
      },
      {
        key: 'sell-settings-content-resources',
        name: 'Content Resources',
        route: '/sell/settings-content-resources',
      },
      {
        key: 'sell-images',
        name: 'Image Master List',
        route: '/sell/images',
      },
      {
        key: 'sell-product-optimization-center',
        name: 'Product Optimization',
        route: '/sell/product-optimization-center',
      },
      {
        key: 'sell-operation-sets',
        name: 'Operation Sets',
        route: '/sell/operation-sets',
      },
    ],
  },
  {
    key: 'fulfill',
    name: 'Fulfill',
    // onClick: () => {
    //   linkERP.openSellDashboard();
    // },
    // icon: <Fan height={24} pathColor={iconColor} />,
    icon: <CarryOutOutlined />,
    children: [
      {
        key: 'fulfill-sales-order',
        name: 'Sales Order',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
      {
        key: 'fulfill-shipment',
        name: 'Shipment',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
      {
        key: 'fulfill-pick-ticket',
        name: 'Pick Ticket',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
      {
        key: 'fulfill-inventory',
        name: 'Inventory',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
    ],
  },
  {
    key: 'buy',
    name: 'Buy',
    // onClick: () => {
    //   linkERP.openBuyDashboard();
    // },
    icon: <ShoppingCartOutlined />,
    children: [
      {
        key: 'buy-po',
        name: 'Purchase Order',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
      {
        key: 'buy-dropship',
        name: 'Dropship',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
      {
        key: 'buy-vendor',
        name: 'Vendor',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
      {
        key: 'buy-manufacturing',
        name: 'Manufacturing',
        onClick: () => {
          linkERP.openBuyDashboard();
        },
      },
    ],
  },
  {
    key: 'customerService',
    name: 'Customer Service',
    // route: '/customer-service',
    icon: <CustomService pathColor={iconColor} />,
    children: [
      {
        key: 'customerService-customer',
        name: 'Customer',
        // route: '/customerService/customer',
        onClick: () => {
          linkERP.openCustomerList();
        },
      },
      {
        key: 'customerService-track-order',
        name: 'Track Order',
        route: '/customerService/track-order',
      },
    ],
  },
  {
    key: 'return',
    name: 'Return',
    onClick: () => {
      linkERP.openInvoiceCustomerReturnList();
    },
    icon: <RetweetOutlined />,
  },
  {
    key: 'warehouse',
    name: 'Warehouse',
    link: process.env.WMS_LINK,
    icon: <Warehouse pathColor={iconColor} />,
  },
  {
    key: 'account-receivalbe',
    name: 'Accounts Receivable',
    link: process.env.WMS_LINK,
    icon: <AccountsReceivableSVG />,
  },
  {
    key: 'accounts-payable',
    name: 'Accounts Payable',
    link: process.env.WMS_LINK,
    icon: <AccountsPayableSVG />,
  },
  {
    key: 'business',
    name: 'Business Intelligence',
    icon: <LineChartOutlined />,
    children: [
      {
        key: 'business-sales',
        name: 'Sales Analysis',
        onClick: () => {
          linkERP.openInquiryProductSalesLog();
        },
      },
      {
        key: 'business-financial',
        name: 'Financial Analysis',
      },
      {
        key: 'business-inventory',
        name: 'Inventory Analysis',
      },
      {
        key: 'business-channel',
        name: 'Channel Audit',
      },
    ],
  },
  {
    key: 'file',
    name: 'Import / Export',
    icon: <UploadOutlined />,
    children: [
      {
        key: 'file-import',
        name: 'Channel Import',
        route: '/file-import',
        // icon: <Import pathColor={iconColor} />,
      },
      {
        key: 'file-export',
        name: 'Channel Export',
        route: '/file-export',
        // icon: <Export pathColor={iconColor} />,
      },
    ],
  },
  {
    //  An object with only a key and with divider included in key will be inserted as a divider
    key: 'divider-1',
  },
  {
    key: 'settings',
    name: 'Settings',
    icon: <Settings pathColor={iconColor} />,
    children: [
      {
        key: 'settings-channels-integrations',
        name: 'Channel Integrations',
        route: '/settings-channels-integrations',
      },
      {
        key: 'settings-channels-control-flags',
        name: 'Channel Control Flags',
        route: '/settings-channels-control-flags',
      },
      {
        key: 'settings-dashboard',
        name: 'Dashboard',
        route: '/settings-dashboard',
      },
    ],
  },
  {
    key: 'administration',
    name: 'Administration',
    icon: <UserOutlined />,
    children: [
      {
        key: 'administration-user-management',
        name: 'Users',
        route: '/administration-user-management',
        // icon: <Profile pathColor={iconColor} />,
        permissionNum: 10004,
      },
      {
        key: 'administration-roles',
        name: 'Roles',
        route: '/administration-roles',
        // icon: <BulbOutlined />,
        permissionNum: 10004,
      },
    ],
  },
  {
    //  An object with only a key and with spacer included in key will be inserted as a spacer
    key: 'spacer-1',
  },
];

export default MenuItems;
