import {
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  SettingOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Button, Col, Row, Dropdown, Space, Menu, Modal, message, Typography, Input } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid } from '../../components/common/datagrid/DataGrid';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { fetchCopywritingelements, deleteCopywritingElement, fetchContentSourceType } from '../../services/copywriting';
// import { enumNameToLabel } from '../../util/strings';
import { Actions, Dispatcher, initialState, PageContext, reducer } from './context';
import EditDialog from './AddEditDialog';
import MappingDialog from './MappingDialog';
import ManageGroupDialog from './ManageGroupDialog';
import { ProductDetailDialogBodyWrapper } from '../../components/common/styledComponents';
import ImportDialog from './ImportDialog'

const { Text } = Typography;

const ElementDataTypeEnum: { [key: string]: string; } = {
  '1': 'string',
  '2': 'Integer',
  '3': 'Decimal',
  '4': 'DataTime',
  '5': 'ImageURL',
  '6': 'Price',
  '7': 'ImageUrlList',
  '8': 'VideoURL',
  '9': 'Virtual',
  '10': 'Metafield',
  '11': 'PageUrl'
};

const baseCSVColumns: CSVColumns = [
  {
    key: 'copywritingElementNum',
    header: 'CopywritingElementNum',
  },
  { key: 'elementName', header: 'Name' },
  { key: 'groupName', header: 'Group' },
  { key: 'elementDataType', header: 'Data Type' },
  {
    key: 'tag',
    header: 'Tag',
  },
  { key: 'displaySeq', header: 'Sequence' },
  // { key: 'defaultValue', header: 'Default Value' },
];

interface PageContentProps {
  reload: () => void;
  groupList: any[];
}
const PageContent: React.FC<PageContentProps> = (props) => {
  const { groupList = [] } = props;
  const [state, dispatch] = useContext(PageContext);
  const [localData, setLocalData] = useState<Entities.CopywritingElement[]>([]);
  const [dataSource, setDataSource] = useState<Entities.CopywritingElement[]>([]);
  const deleteValue = React.useRef<string>('');

  const searchFields = useMemo(
    (): Array<SearchField<Entities.CopywritingElement> | string> => [
      {
        fieldName: 'elementName',
      },
    ],
    [],
  );

  useEffect(() => {
    if (localData.length > 0 && groupList.length > 0) {
      const temp = localData.map(item => {
        const targetGroupIndex = groupList.findIndex(group => group.enumValue === item.contentSourceGroup);
        return {
          ...item,
          groupName: targetGroupIndex > -1 ? groupList[targetGroupIndex].enumName : '',
        }
      })
      setDataSource(temp);
    }
  }, [localData, groupList])

  const delCopywriting = useCallback(
    async (data: any) => {
      try {
        const res = await deleteCopywritingElement(data.copywritingElementNum);
        if (res.isSuccess) {
          message.success('Deleted successfully');
          props.reload();
        } else {
          message.error(res.message || res.Message);
        }
      } catch (error) {
        if (
          (error as any).response.data.message === 'Exist Attribute Mapping.' ||
          (error as any).response.data.message === 'CopywritingElement used in product.'
        ) {
          Modal.confirm({
            title: (error as any).response.data.message,
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                <Row>
                  <Text>
                    If you are sure to delete, please enter <Text mark>{data.elementName}</Text> to
                    confirm the deletion
                  </Text>
                </Row>
                <Row style={{ marginTop: 8 }}>
                  <Input onChange={(e) => (deleteValue.current = e.target.value)} />
                </Row>
              </>
            ),
            onOk: async () => {
              return new Promise((resolve, reject) => {
                if (deleteValue.current !== `${data.elementName}`) {
                  message.info('Please check input value');
                  reject();
                } else {
                  deleteCopywritingElement(data.copywritingElementNum, data.elementName).then(
                    (res1) => {
                      if (res1.isSuccess) {
                        resolve(1);
                        message.success('Deleted successfully');
                        props.reload();
                      } else {
                        reject();
                      }
                    },
                  );
                }
              });
            },
          });
        } else {
          message.error(
            (error as any).response.data.message || (error as any).response.data.Message,
          );
        }
      }
    },
    [props, deleteValue],
  );

  const columns: TypeColumn[] = [
    {
      name: 'elementName',
      header: 'Name',
      defaultFlex: 2,
      render({ value, data }: { value: string; data: any; }) {
        return (
          <Button
            type="link"
            onClick={() =>
              dispatch && dispatch({ type: Actions.SET_MODAL_FORM_VISIBLE, params: data })
            }
          >
            {value}
          </Button>
        );
      },
    },
    {
      name: 'groupName',
      header: 'Group',
      defaultFlex: 1,
    },
    {
      name: 'elementDataType',
      header: 'Data Type',
      defaultFlex: 1,
      render({ value }) {
        const enumValue = value ? ElementDataTypeEnum[`${value}`] : '';
        return <span>{enumValue}</span>;
      },
    },
    {
      name: 'tag',
      header: 'Tag',
      defaultFlex: 1,
      // render({ value }) {
      //   return <ChannelName value={value} />;
      // },
    },
    {
      name: 'displaySeq',
      header: 'Sequence',
      defaultFlex: 1,
    },
    // {
    //   name: 'defaultValue',
    //   header: 'Default Value',
    //   defaultFlex: 1,
    // },
    {
      name: 'optionList',
      header: 'Option List',
      defaultFlex: 1, 
    },
    {
      name: '',
      header: '',
      defaultFlex: 3,
      minWidth: 230,
      maxWidth: 230,
      sortable: false,
      editable: false,
      render({ data, rowIndex, rowId, columnId, columnIndex }: any) {
        return (
          <Row justify="end">
            <Col>
              <Button
                size="small"
                onClick={() => {
                  dispatch && dispatch({ type: Actions.SET_MAPPING_VISIBLE, params: data });
                }}
              >
                Manage Mapping
              </Button>
            </Col>
            <Col>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu
                    id="grid_more"
                    onClick={(e) => {
                      if (e.key === '1') {
                        Modal.confirm({
                          title: `Do you want to delete this Content Resources`,
                          icon: <ExclamationCircleOutlined />,
                          content: `name: ${data?.elementName}`,
                          onOk: () => {
                            delCopywriting(data);
                          },
                          onCancel() { },
                        });
                      }
                    }}
                  >
                    <Menu.Item
                      key="1"
                      id="menu1"
                      icon={<DeleteOutlined style={{ color: '#c13939' }} />}
                    >
                      Delete
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button size="small">
                  <Space>
                    More
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },
  ];

  if (!state || !dispatch) {
    return null;
  }

  return (
    <>
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <SearchBar<any>
            reference="copywritingElementNum"
            data={state.data}
            onResult={setLocalData}
            fields={searchFields}
            disabled={state.searchDisabled}
          />
        </Col>
        <Col>
          <CSVLink
            filename="content-resources-setting.csv"
            data={dataSource}
            disabled={state.searchDisabled}
            columns={baseCSVColumns}
          />
        </Col>
      </Row>
      <Spacer height={14} />
      <DataGrid<Entities.ProductAttribute>
        style={{ minHeight: 500 }}
        columns={columns}
        dataSource={dataSource}
        loading={state.loading}
        onEdit={(r) => {
          dispatch({ type: Actions.EDIT_ENTITY, params: r.AttributeNum });
        }}
        inlineEdit={false}
        pagination
      />
    </>
  );
};

const useFetchData = (shouldFetch: boolean, dispatch: Dispatcher) => {
  useEffect(() => {
    if (!shouldFetch) {
      return;
    }

    dispatch({ type: Actions.FETCH_DATA });
    const requests = async () => {
      const res = await fetchCopywritingelements();
      if (res.isSuccess) {
        dispatch({ type: Actions.DATA_FETCHED, params: { data: res.data } });
      } else {
        message.error(res.message || res.Message);
      }
    };

    requests().catch(() => {
      dispatch({ type: Actions.SET_LOADING, params: false });
    });
  }, [shouldFetch, dispatch]);
};

const Page: React.FC = () => {
  const history = useHistory();
  const [groupList, setGroupList] = useState<any[]>([]);
  const context = useReducer(reducer, initialState());
  const [
    { fetchData, current, modalFormVisible, manageGroupVisible, mappingDialogVisible, dialogIsFullscreen, importDialogVisible },
    dispatch,
  ] = context;

  useFetchData(fetchData, dispatch);

  const reloadData = useCallback(() => {
    dispatch({ type: Actions.FETCH_DATA });

    const requests = async () => {
      await getGroupList();
      const res = await fetchCopywritingelements();
      if (res.isSuccess) {
        dispatch({ type: Actions.DATA_FETCHED, params: { data: res.data } });
      } else {
        message.error(res.message || res.Message);
      }
    };
    requests().catch(() => {
      dispatch({ type: Actions.SET_LOADING, params: false });
    });
  }, [dispatch]);

  const getGroupList = async () => {
    try {
      const { data = [], isSuccess, message: resMsg = '' } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        message.error(resMsg || 'No results found');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getGroupList();
  }, [])

  const dialogWidth = React.useCallback(() => {
    return dialogIsFullscreen
      ? window.innerWidth
      : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
  }, [dialogIsFullscreen]);

  return (
    <PageContext.Provider value={context}>
      <ContentLayout>
        <Heading
          title="Content Resources"
          actions={
            <Space>
              <Button
                type="primary"
                id="add_button"
                onClick={() => dispatch({ type: Actions.SET_MODAL_FORM_VISIBLE, params: undefined })}
              >
                <PlusOutlined />
                Add
              </Button>
              <Button
                type="primary"
                id="add_button"
                onClick={() => dispatch({ type: Actions.SET_IMPORT_DIALOG_VISIBLE, params: true })}
              >
                <CloudUploadOutlined />
                Import
              </Button>
              <Button
                id="manageGroup_button"
                onClick={() => dispatch({ type: Actions.SET_MANAGE_GROUP_VISIBLE })}
              >
                <SettingOutlined />
                Manage Group
              </Button>
            </Space>
          }
        />
        <Spacer />
        <SiteContent flexGrow>
          <PageContent reload={() => reloadData()} groupList={groupList} />
        </SiteContent>
      </ContentLayout>
      {modalFormVisible && (
        <EditDialog
          visible
          onSuccess={() => {
            dispatch({ type: Actions.CLOSE_MODAL_FORM });
            reloadData();
            // dispatch({ type: Actions.FETCH_DATA });
          }}
          onClose={() => {
            dispatch({ type: Actions.CLOSE_MODAL_FORM });
          }}
          formValues={current}
        />
      )}
      {
        manageGroupVisible && (
          <ManageGroupDialog
            visible
            onSuccess={reloadData}
            onClose={() => {
              dispatch({ type: Actions.CLOSE_MANAGE_GROUP });
            }}
            formValues={current}
          />
        )
      }
      {mappingDialogVisible && (
        <Modal
          bodyStyle={{ backgroundColor: '#F0F2F5', padding: 12 }}
          centered
          className="fullscreen-modal"
          wrapClassName='ant-spin-flex'
          closable={false}
          footer={
            dialogIsFullscreen ? (
              <Row justify="end">
                <Button
                  onClick={() => dispatch && dispatch({ type: Actions.CLOSE_MAPPING_DIALOG })}
                >
                  Cancel
                </Button>
              </Row>
            ) : null
          }
          onCancel={() => dispatch && dispatch({ type: Actions.CLOSE_MAPPING_DIALOG })}
          style={{ paddingBottom: 0 }}
          //title={currentProduct.SKU}
          title={
            <Row align="middle" justify="space-between">
              <span>{`Content Resources Mapping`}</span>
              <Space>
                <Button
                  onClick={() =>
                    dispatch &&
                    dispatch({
                      type: Actions.SET_DIALOG_IS_FULL_SCREEN,
                      params: !dialogIsFullscreen,
                    })
                  }
                >
                  {dialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  {dialogIsFullscreen ? 'Exit' : 'Enter'} Fullscreen
                </Button>
                <Button
                  onClick={() =>
                    dispatch && dispatch({ type: Actions.CLOSE_MAPPING_DIALOG, params: false })
                  }
                >
                  <CloseOutlined />
                  Close
                </Button>
              </Space>
            </Row>
          }
          visible={mappingDialogVisible}
          width={dialogWidth()}
        >
          <ProductDetailDialogBodyWrapper className={dialogIsFullscreen ? 'fullscreen-mode' : ''}>
            <MappingDialog
              current={current}
              handleCancel={() => dispatch && dispatch({ type: Actions.CLOSE_MAPPING_DIALOG })}
            />
          </ProductDetailDialogBodyWrapper>
        </Modal>
      )}
      {
        importDialogVisible && 
        <ImportDialog
          onSuccess={()=>{
            dispatch && dispatch({ type: Actions.SET_IMPORT_DIALOG_VISIBLE, params: false })
            Modal.confirm({
              title: 'Your file has been uploaded',
              okText: 'Close',
              cancelText: 'Check now',
              content:
                'We are currently processing your file, you can check the job status in the Import Center.',
              onOk() {
              },
              onCancel() {
                history.push('/file-import');
              },
            });
          }}
          onClose={()=>dispatch && dispatch({ type: Actions.SET_IMPORT_DIALOG_VISIBLE, params: false })}
          visible
        />
      }
    </PageContext.Provider>
  );
};

export default Page;
