import React from 'react';
import {
  Button,
  Carousel,
  Image,
  Row,
  notification,
} from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import theme from '../../assets/styles/theme.js';
import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  HTTP_STATUS_CREATED,
  HTTP_STATUS_OK,
  LOADING_ICON_SIZE1,
} from '../../constants/config';
import Products from '../../services/products';
import { Actions } from './context';
import EditImageDialog from './EditImageDialog';
import { getProductType } from './helper';

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #D9D9D9; 
  border-radius: 4px;

  :hover .img-area-left-toolbar,
  :hover .img-area-right-toolbar,
  :hover .img-area-top-toolbar {
    display: flex;
  }

  & .ant-carousel,
  & .slick-list,
  & .slick-slider,
  & .slick-track,
  & .slick-slide > div {
    height: 100%;
  }

  & .img-area {
    background-color: unset;
    border-radius: ${theme['@border-radius-base']}; 
    height: 100%;
  }

  & .img-area-left-toolbar,
  & .img-area-right-toolbar,
  & .img-area-top-toolbar {
    display: none;
    position: absolute;
    top: 0;
  }

  & .img-area-left-toolbar,
  & .img-area-right-toolbar {
    align-items: center;
    height: 100%;
  }

  & .img-area-left-toolbar {
    left: 0;
  }

  & .img-area-right-toolbar {
    right: 0;
  }

  & .img-area-top-toolbar {
    align-items: center;
    height: auto;
    justify-content: end;
    width: 100%;
  }

  & .img-area-left-toolbar .ant-btn,
  & .img-area-right-toolbar .ant-btn,
  & .img-area-top-toolbar .ant-btn {
    background-color: rgba(255, 255, 255, .5);
    padding: 5px 8px;
  }

  & .img-area-left-toolbar .ant-btn,
  & .img-area-right-toolbar .ant-btn {
    border-radius: 16px;
  }

  & .img-name {
    /*color: #FFF;*/
    font-weight: 550;
    margin: 8px;
  }

  & .ant-image {
    align-items: center;
    display: flex;
    height: 100%;
  }

  & .ant-image-img {
    object-fit: cover;
  }
`;

type Props = {
  dispatch?: Function;
  productId: string;
  state: StringKAnyVPair;
  style?: StringKAnyVPair;
};

const BasicImageCarousel = (props: Props) => {
  const { useState } = React;
  const [basicImgList, setBasicImgList] = useState<StringKAnyVPair[]>([]);
  const [currentImg, setCurrentImg] = useState<StringKAnyVPair>({});
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mouseIsOnDot, setMouseIsOnDot] = useState(false);
  const carouselRef = React.useRef<any>(null);

  const closeEditImageDialog = () => {
    setEditImageDialogVisible(false);
  };

  const editImageDialogTitle = () => {
    const { state } = props;
    const sku = state.product?.ProductBasic?.SKU;
    const ts: string[] = [];
    const typ = getProductType(state.productType);

    if (typ) ts.push(typ);

    if (sku) ts.push(sku);

    ts.push(`${currentImg.imageAttributeName}`);

    return ts.join(' ');
  };

  const loadProductImages = async (productId: string) => {
    setIsLoadingImages(true);

    try {
      const list = await Products.getProductImages(productId);

      if (Array.isArray(list)) {
        const attrDict: StringKAnyVPair = {};
        const basic: StringKAnyVPair[] = [];
        const channelList: string[] = [];
        // console.log('l->', list);
        list.forEach(e => {
          if (e.channelName) {
            if (!attrDict[e.channelName]) {
              attrDict[e.channelName] = [];
              channelList.push(e.channelName);
            }

            attrDict[e.channelName].push(e);
          } else {
            e.imageAttributeName = (e.imageAttributeName || '').replace('Image', '');
            basic.push(e)
          }
        });

        setBasicImgList(basic);
        //console.log('basic --->', basic);
        if (basic.length > 0) {
          setCurrentImg(basic[0]);
        }
        /*setAttrImgList(channelList.map(e => ({
          channel: e,
          images: attrDict[e] || [],
        })));*/
      } else {
        notification.error({
          message: 'Product images is not an array',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } catch(e) {
      notification.error({
        message: `Fetch product images error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoadingImages(false);
    }
  };

  const nextSlick = () => {
    carouselRef?.current.next();
  };

  const onMouseOverCarousel = (evt: any) => {
    const tagName = evt.target.tagName.toUpperCase();

    if (tagName === 'BUTTON') {
      setMouseIsOnDot(true);
    } else {
      setMouseIsOnDot(false);
    }
  };

  const onOverwriteChange = (chk: boolean) => {
    if (typeof props.dispatch === 'function') {
      props.dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: chk });
    }
  };

  const onSlickChange = (idx: number) => {
    setCurrentImg(basicImgList[idx] || {});
  };

  const openEditImageDialog = () => {
    setEditImageDialogVisible(true);
  };

  const openImageWindow = (src: string, name?: string) => {
    console.log('-->', currentImg);
    const img = `<img src="${src}" />`;
    const winFeatures = "location=no,height=570,width=520,scrollbars=yes,status=no,popup=no";
    //const popup = window.open(currentImg.imageURL, 'Image');
    const popup = window.open('', '_blank', winFeatures);

    if (popup) {
      popup.document.write(img); 

      setTimeout(() => {
        popup.document.title = name || 'Untitled';
      }, 100);
    }
  };

  const prevSlick = () => {
    carouselRef?.current.prev();
  };

  const saveImage = async (imgUrl: string) => {
    //const mainImage = attrDict.MainImage;

    //console.log('cc->', currentImg);
    if (currentImg.imageAttributeNum) {
      setIsLoading(true);
      const res = await saveImages([{
        imageAttributeNum: currentImg.imageAttributeNum,
        imageURL: imgUrl,
      }]);
      setIsLoading(false);

      if (res) {
        //mainImage.value = imgUrl;
        currentImg.imageURL = imgUrl;
        //setAttrDict({...attrDict});
        setBasicImgList([...basicImgList]);
        notification.success({
          message: `Edit ${currentImg.basicAttributeName} successfully`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }

      return res;
    } else {
      notification.error({
        message: 'Image is lost',
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const saveImages = async (imgs: StringKAnyVPair[]) => {
    try {
      if (props.productId) {
        const overwrite = props.state.overwriteChildren ? 1 : 0;
        const res = await Products.editProductImages(props.productId, imgs, {params: {isApplyChild: overwrite,}}) as StringKAnyVPair;

        if (res && typeof res === 'object') {
          //console.log('r->', res);
          return [HTTP_STATUS_CREATED, HTTP_STATUS_OK].indexOf(res.status) > -1;
        } else {
          notification.error({
            message: `Save image failed: ${res}`,
            duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
          });
        }
      }
    } catch(e) {
      notification.error({
        message: `Save image error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  React.useEffect(() => {
    if (!inited) {
      loadProductImages(props.productId);
      setInited(true);
    }
  }, [inited, props]);

  return (<>
    <CarouselWrapper
      onMouseOver={onMouseOverCarousel}
      style={{backgroundColor: mouseIsOnDot ? 'rgba(0, 0, 0, .5)' : '', ...props.style}}
    >
      {isLoadingImages ?
        <Loading /> :
        <Carousel
          afterChange={onSlickChange}
          ref={carouselRef}
        >
          {basicImgList.map(img => (
            <Row align="middle" className="img-area" justify="center" key={img.imageAttributeNum}>
              <Image
                alt={img.imageAttributeName}
                src={img.imageURL || ImagePlaceholder}
              />
            </Row>
          ))}
        </Carousel>
      }
      {!isLoadingImages && basicImgList.length > 0 && (<>
        <div className="img-area-left-toolbar">
          <Button onClick={prevSlick}>
            <LeftOutlined />
          </Button>
        </div>
        <div className="img-area-right-toolbar">
          <Button onClick={nextSlick}>
            <RightOutlined />
          </Button>
        </div>
        <div className="img-area-top-toolbar">
          {/*<span className="img-name">{currentImg.imageAttributeName}</span>*/}
          <Button onClick={() => openImageWindow(currentImg.imageURL, currentImg.imageAttributeName)}>
            <EyeOutlined />
          </Button>
          <Button onClick={openEditImageDialog}>
            <EditOutlined />
          </Button>
        </div>
      </>)}
    </CarouselWrapper>
    {editImageDialogVisible && (
      <EditImageDialog
        imageUrl={currentImg.imageURL || ''}
        onClose={closeEditImageDialog}
        onOverwriteChange={onOverwriteChange}
        onSave={saveImage}
        overwrite={props.state.overwriteChildren}
        overwriteEnable={props.state.productType !== 1}
        //title={`Edit ${currentImg.imageAttributeName}`}
        title={editImageDialogTitle()}
        visible={editImageDialogVisible}
      />
    )}
    {(isLoading /*|| isLoadingProductData*/) && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default BasicImageCarousel;
