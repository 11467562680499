import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { FormLabel } from '../../components/common/styledComponents';
import MaskOverlay from '../../components/common/MaskOverlay';
/*import {
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_LIST,
} from '../../constants/config';*/
import LabelsSelector from './LabelsSelector';
import BrandSelector from './BrandSelector';
import ChannelControlFlagSelector from './ChannelControlFlagSelector';
import SortSelector from './SortSelector';
import { isCodeListType } from './';
import NewCondition, { ConditionItem } from './NewCondition';

type Props = {
  channelFlagVersion: number;
  clearChannelFlags: Function;
  clearLabels: Function;
  clearSearchBrand: Function;
  clearSearchTitle: Function;
  handleSearch: Function;
  hidePanel: Function;
  labelVersion: number;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  searchBrandVersion: number;
  searchCodeType: number;
  searchMultiCode: string;
  searchTitleVersion: number;
  setBrand: Function;
  setLabels: Function;
  setMultiCode: Function;
  setSalesChannel: Function;
  setSortType: Function;
  setStatus: Function;
  setTitle: Function;
  showPanel: Function;
  status: number[];
  statusOptions: StringKAnyVPair[];
  x: number;
  y: number;
  visible: boolean;
  width: number;
  ExtraFilter?: (JSX.Element | string)[];
  clearExtraFilter?: Function;
  needAddNewCondition?: boolean;
  setConditionList?: (list: ConditionItem[]) => void;
  salesChannelVisible?: boolean;
};

const PanelWrapper = styled.div`
  background-color: #FFF;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  position: absolute;

  & textarea.ant-input {
    height: 120px;
  }

  & .ant-divider {
    margin: 8px;
  }

  & .ant-select {
    width: 100%;
  }

  & .bottom-toolbar {
    margin: 16px 8px 0;
  }

  & .filter-body {
    margin: 12px;
  }

  & .filter-form-cell {
    padding: 8px;
    width: 50%;
  }

  & .form-text-input {
    width: 100%;
  }

  & .textarea-wrapper {
    padding: 8px;
  }
`;

const SearchFilterPanel = (props: Props) => {
  const { salesChannelVisible = true } = props;
  //const { useState } = React;
  //const [searchBrand, setSearchBrand] = useState('');
  //const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  //const [searchLabels, setSearchLabels] = useState<any[]>([]);
  //const [searchSorter, setSearchSorter] = useState<string>();
  //const [searchSorter, setSearchSorter] = useState('SKU ASC');
  //const [searchStatus, setSearchStatus] = useState<number[]>([0]);
  //const [searchTitle, setSearchTitle] = useState('');
  const [conditionList, setConditionList] = useState<ConditionItem[]>([]);

  const clearFilter = () => {
    props.clearChannelFlags();
    props.clearLabels();
    props.clearSearchBrand();
    props.clearSearchTitle();
    props.setMultiCode('');
    props.setStatus([]);
    setConditionList([]);
    if (typeof props.clearExtraFilter === 'function') props.clearExtraFilter();
  };

  const handleSearch = () => {
    setTimeout(() => {
      props.handleSearch()
    }, 0);
    props.hidePanel();
  };

  const onPanelMouseDown = () => {
    props.showPanel();
  };

  // eslint-disable-next-line
  const onSearchBrandChange = (brands: string[]) => {
    //console.log('v', brands);
    props.setBrand(brands.join(','));
  };

  // eslint-disable-next-line
  const onSearchBrandChange2 = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchBrand(value);
      props.setBrand(value);
    }, 0);
  };

  const onSearchCodeListChange = (evt: any) => {
    const value = evt.target.value as string;
    const str = value.replace(/\t/g, '\n');

    //setSearchMultiCode(str);
    props.setMultiCode(str);
  };

  const onSearchCodeListKeyDown = (evt: any) => {
    if (evt.keyCode === 9) {
      if (props.searchMultiCode.trim()) {
        props.setMultiCode(props.searchMultiCode.trim() + '\n');
      }

      evt.preventDefault();
    }

    evt.stopPropagation();
  };

  const onSearchStatusChange = (st: number[]) => {
    //setSearchStatus(st);
    props.setStatus(st);
  };

  const onSearchTitleChange = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchTitle(value);
      props.setTitle(value);
    }, 0);
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    //setSearchCCFs(values);
    props.setSalesChannel(values);
  };

  const onSelectLabels = (values: any[]) => {
    //setSearchLabels(values);
    props.setLabels(values);
  };

  const onSelectSortType = (value: any) => {
    //setSearchSorter(value);
    props.setSortType(value);
  };

  const addNewCondition = () => {
    setConditionList([...conditionList, {
      attribute: undefined,
      searchType: 1,     // 1:= 2:like 3:> 4:< 5 <>
      value: ''
    }])
  }

  const deleteCondition = (index: number) => {
    const temp: ConditionItem[] = [];
    conditionList.forEach((item, idx) => {
      if (idx !== index) temp.push(item);
    })
    setConditionList(temp);
  }

  const updateConditionList = (index: number, key: string, value: any) => {
    const target = conditionList[index];
    // @ts-ignore
    target[key] = value;
    const temp: ConditionItem[] = [];
    conditionList.forEach((item, idx) => {
      if (idx === index) temp.push(target);
      else temp.push(item);
    })
    setConditionList(temp);
  }

  useEffect(() => {
    if (typeof props.setConditionList === 'function') {
      props.setConditionList(conditionList);
    }
    // eslint-disable-next-line
  }, [conditionList])

  return (<>
    <PanelWrapper
      onMouseDown={onPanelMouseDown}
      onMouseEnter={props.onMouseEnter as any}
      onMouseLeave={props.onMouseLeave as any}
      style={{ left: props.x, top: props.y, width: props.width, display: props.visible ? '' : 'none', zIndex: 1 }}
    // style={{ left: props.x, top: props.y, width: props.width, zIndex: 1 }}
    >
      <Col className="filter-body">
        {isCodeListType(props.searchCodeType) && (
          <div className="textarea-wrapper">
            <Input.TextArea
              allowClear
              onChange={onSearchCodeListChange}
              onKeyDown={onSearchCodeListKeyDown}
              value={props.searchMultiCode}
            />
          </div>
        )}
        <div style={{ maxHeight: 350, overflowY: 'auto', overflowX: 'hidden' }}>
          <Row justify="space-between">
            <div className="filter-form-cell">
              <FormLabel>Title</FormLabel>
              <Input
                allowClear
                className="form-text-input"
                key={`title-${props.searchTitleVersion}`}
                onChange={onSearchTitleChange}
              //value={searchTitle}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Status</FormLabel>
              <Select
                mode="multiple"
                onChange={onSearchStatusChange}
                options={props.statusOptions as any[]}
                value={props.status}
              />
            </div>
            {salesChannelVisible && (
              <div className="filter-form-cell">
                <FormLabel>Sales Channel</FormLabel>
                <ChannelControlFlagSelector
                  channelFlagVersion={props.channelFlagVersion}
                  onChange={onSelectChannelControlFlags}
                />
              </div>
            )}
            <div className="filter-form-cell">
              <FormLabel>Brand</FormLabel>
              {/*<Input
              allowClear
              className="form-text-input"
              key={`brand-${props.searchBrandVersion}`}
              onChange={onSearchBrandChange2}
            />*/}
              <BrandSelector
                key={`brand-${props.searchBrandVersion}`}
                version={props.searchBrandVersion}
                onBrandChange={onSearchBrandChange}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Labels</FormLabel>
              <LabelsSelector
                labelVersion={props.labelVersion}
                onChange={onSelectLabels}
              />
            </div>
            {
              (props.ExtraFilter || []).map((item, index) => item === '-' ? <Divider /> : (
                <div className="filter-form-cell" key={`extra-item-${index}`}>
                  {item}
                </div>
              ))
            }
            <div className="filter-form-cell">
              <FormLabel>Sort By</FormLabel>
              <SortSelector
                onChange={onSelectSortType}
              />
            </div>
          </Row>
          {
            conditionList.length > 0 && (
              <>
                <Divider children="Advanced" orientation="left" />
                <NewCondition
                  conditionList={conditionList}
                  updateConditionList={updateConditionList}
                  deleteCondition={deleteCondition}
                />
              </>
            )
          }
        </div>
        <Divider />
        <Row className="bottom-toolbar" justify="end">
          {/*<Button onClick={() => props.hidePanel()}>
            <CloseOutlined />
            Close
          </Button>*/}
          <Space>
            {
              props.needAddNewCondition && (
                <Button
                  onClick={addNewCondition}
                  type="text"
                >
                  <PlusOutlined />
                  Add New Condition
                </Button>
              )
            }
            <Button
              onClick={clearFilter}
              type="text"
            >
              Clear Filter
            </Button>
            <Button
              onClick={handleSearch}
              type="primary"
            >
              <SearchOutlined />
              Search
            </Button>
          </Space>
        </Row>
      </Col>
    </PanelWrapper>
    <MaskOverlay
      clearOnClick
      height={`calc(100vh - ${props.y}px)`}
      onClick={props.hidePanel}
      x={0}
      y={props.y}
      visible={props.visible}
    />
  </>);
};

export default SearchFilterPanel;
