import { ClearOutlined, CloudDownloadOutlined, CloudUploadOutlined, SearchOutlined } from '@ant-design/icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  Menu,
  MenuProps,
  message,
  Modal,
  ModalProps,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { MenuInfo } from 'rc-menu/es/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react'; // eslint-disable-line
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
// import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
// import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import AccountsMenu from '../components/common/channelIntegrations/AccountsMenu';
import Heading from '../components/common/Heading';
import ProductSearchAddRemove from '../components/common/ProductSearchAddRemove';
import Spacer from '../components/common/Spacer';
import { FormLabel, ModalTitle } from '../components/common/styledComponents';
// import TimedCallback from '../components/common/TimedCallback';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { Permissions, ViewLevel } from '../constants/enums/permissions';
import { ChannelAccountEntry, ChannelEntry } from '../redux/api/channelIntegration';
import {
  ExportChannelAccountFileParams,
  GetExportFilesParams,
  useExportChannelAccountFileMutation,
  useExportProductsByTypeMutation,
  useGetExportFilesQuery,
} from '../redux/api/exports';
import { downloadCustomerExportedFile } from '../services/products';
import { ExportReportType, ExportStatus, IntegrationScheduleSettingsSection } from '../types/enums';
import { formatBytes } from '../util';
import { enumToObjects } from '../util/enums';
import { useFileDownload } from '../util/hooks';
import { enumNameToLabel } from '../util/strings';
import { useGetChannelAccountProductsQuery } from '../redux/api/products';
import { hasHigherPermission } from '../util/permissions';
import { convertTimeByUtc, convertToUtcTime } from '../util/utcTime';
import moment from 'moment';
import ArrowTurnUp from '../assets/icons/arrowTurnUp';

const { Text } = Typography;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: #0070d2;

  &:hover {
    text-decoration: underline;
  }
`;

const SearchFormWrapper = styled.div`
  & .search-form-bottom-btn-row {
    margin-top: 12px;
  }

  & .search-form-label {
    margin-right: 8px;
  }

  & .search-user-input {
    width: calc(100% - 100px);
  }

  & .search-submit-date-picker {
    width: calc(100% - 180px);
  }
`;

const FileNameLabel: React.FC<{ data: Entities.ExportFile }> = ({ data }) => {
  const label = data.FileName;
  const file = useCallback(() => downloadCustomerExportedFile(data.FileName), [data]);
  const fileName = data.FileName;
  const [download, loading] = useFileDownload(file, fileName);

  if ([ExportStatus.COMPLETE, ExportStatus.COMPLETED_WITH_ERROR].indexOf(data.ExportStatus) < 0) {
    return <span>{label}</span>;
  }

  return (
    <Spin spinning={loading}>
      <LinkButton onClick={download}>{label}</LinkButton>
    </Spin>
  );
};

const tableColumns = [
  {
    header: 'Report type',
    name: 'ExportReportType',
    defaultFlex: 2,
    render: ({ value }: { value: number }) => enumNameToLabel(ExportReportType[value]),
  },
  {
    header: 'Reason',
    name: 'Reason',
  },
  {
    header: 'Submitted Time',
    name: 'RequestTime',
    defaultFlex: 3,
    // render: ({ value }: { value: string }) => formatDate(value, 'dateTime'),
    render: ({ value }: { value: string }) => convertTimeByUtc(value, '', '', 'MM/DD/YYYY - hh:mm A'),
    // filterEditor: DateFilter,
    dateFormat: 'YYYY-MM-DD',
  },
  {
    header: 'File Name',
    name: 'FileName',
    defaultFlex: 3,
    render({ data }: { data: Entities.ExportFile }) {
      return <FileNameLabel data={data} />;
    },
  },
  {
    header: 'File size',
    name: 'FileSize',
    render: ({ value }: { value: number }) => formatBytes(value),
  },
  {
    header: 'User',
    name: 'RequestEmail',
    // filterDelay: 500,
  },
  {
    header: 'Status',
    name: 'ExportStatus',
    render: ({ value }: { value: number }) => enumNameToLabel(ExportStatus[value]),
    // filterEditor: SelectFilter,
    // filterEditorProps: {
    //   placeholder: 'Any',
    //   dataSource: enumToObjects({ choices: ExportStatus }),
    // },
  },
];

// const initialFilterValue = [
//   { name: 'RequestTime', operator: 'inrange', type: 'date', value: '' },
//   { name: 'ExportStatus', operator: 'eq', type: 'select', value: '' },
//   { name: 'RequestEmail', operator: 'contains', type: 'string', value: '' },
// ];

const initialDataState = { count: 0, data: [] };

const ProductSelectionText = styled.p`
  margin-bottom: 0;
  line-height: 2rem;
  padding-left: 1rem;
`;

enum ExportMenuSection {
  PRODUCTS,
  INVENTORY,
  ORDERS,
  SHIPPING,
  CUSTOM_TEMPLATE,
  INVOICES,
  REFUNDS,
}

const exportMenuSectionMapping = new Map<ExportMenuSection, IntegrationScheduleSettingsSection>([
  [ExportMenuSection.PRODUCTS, IntegrationScheduleSettingsSection.PRODUCT_SETTINGS],
  [ExportMenuSection.INVENTORY, IntegrationScheduleSettingsSection.INVENTORY_SETTINGS],
  [ExportMenuSection.CUSTOM_TEMPLATE, IntegrationScheduleSettingsSection.CUSTOM_TEMPLATE_SETTINGS],
]);

interface ExportModalProps extends ModalProps {
  form: FormInstance<ExportChannelAccountFileParams>;
  onFinish: (values: ExportChannelAccountFileParams) => void;
  section: ExportMenuSection | null;
  channel: ChannelEntry | null;
}

const ExportModal: React.FC<ExportModalProps> = ({
  section,
  channel,
  form,
  onFinish,
  ...modalProps
}) => {
  const [selectedProducts, setSelectedProducts] = useState<Entities.ProductProfile[] | boolean>([]);
  const [productsModalVisible, setProductsModalVisible] = useState(false);
  const [listStyleMasterProducts, setListStyleMasterProducts] = useState(false);

  const onSelectedProductsChanged = useCallback(
    (selected: Entities.ProductProfile[], available: Entities.ProductProfile[]) => {
      if (selected.length === available.length) {
        setSelectedProducts(true);
      } else {
        setSelectedProducts(selected);
      }
    },
    [],
  );

  const modalTitle = useMemo(() => {
    if (channel === null || section === null) {
      return '';
    }
    setSelectedProducts([]);
    const sectionLabel = enumNameToLabel(ExportMenuSection[section]);
    return `Export ${channel.channelName} ${sectionLabel}`;
  }, [channel, section]);

  useEffect(() => {
    if (typeof selectedProducts === 'boolean') {
      form.setFieldsValue({ centralProductNumList: [0], isForAllSku: true });
    } else {
      form.setFieldsValue({
        centralProductNumList: selectedProducts.map((p) => p.CentralProductNum),
        isForAllSku: false,
      });
    }
  }, [selectedProducts, form, productsModalVisible]);

  useEffect(() => {
    if (section === 1 || section === 4) {
      form.setFieldsValue({ isForAllSku: true });
    }
  }, [section, form]);

  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
      width="60%"
      title={<ModalTitle>{modalTitle}</ModalTitle>}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        {/* Hidden form fields */}
        <Form.Item name="channelNum" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="channelAccountNum" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="fileOperation" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="channelMappingType" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="channelAcctJsonFileId" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="isFileOperation" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="operationSetNum" hidden noStyle>
          <Input disabled />
        </Form.Item>
        <Form.Item name="isForAllSku" hidden noStyle />
        {/* End hidden fields */}
        {section === ExportMenuSection.PRODUCTS && (
          <Form.Item
            label="Products"
            name="centralProductNumList"
            initialValue={[0]}
            rules={[{ required: true, message: 'You must select at least 1 product' }]}
          >
            <Row>
              <Col span={13}>
                <ProductSelectionText>
                  {typeof selectedProducts === 'boolean' ? 'All' : selectedProducts.length}
                  &nbsp;Product
                  {typeof selectedProducts === 'boolean' || selectedProducts.length !== 1
                    ? 's'
                    : null}
                  &nbsp;selected for Export
                </ProductSelectionText>
              </Col>
              <Col span={11}>
                <Button
                  onClick={() => {
                    setProductsModalVisible(true);
                    setListStyleMasterProducts(false);
                  }}
                  style={{ margin: '0 10px' }}
                >
                  Select Single Products
                </Button>
                Or
                <Button
                  onClick={() => {
                    setProductsModalVisible(true);
                    setListStyleMasterProducts(true);
                  }}
                  style={{ margin: '0 10px' }}
                >
                  Select Style Master Products
                </Button>
              </Col>
            </Row>
            <Modal
              title="Search Products and Select"
              visible={productsModalVisible}
              onCancel={() => setProductsModalVisible(false)}
              footer={null}
              width="90%"
            >
              <ProductSearchAddRemove
                onChangeSelected={onSelectedProductsChanged}
                tableStyle={{ height: '800px' }}
                channelAccountNum={form.getFieldValue('channelAccountNum')}
                channelNum={form.getFieldValue('channelNum')}
                query={useGetChannelAccountProductsQuery}
                styleMaster={listStyleMasterProducts}
              />
            </Modal>
          </Form.Item>
        )}
        <Form.Item label="Export Reason" name="reason">
          <Input />
        </Form.Item>
        <Space align="center">
          <Button type="primary" htmlType="submit">
            <CloudUploadOutlined />
            Submit
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

interface ExportButtonOverlayProps extends MenuProps {
  exportProductsByType: Function;
}

const ExportButtonOverlay: React.FC<ExportButtonOverlayProps> = ({
  exportProductsByType,
  ...props
}) => (
  <Menu
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    selectable={false}
    triggerSubMenuAction="click"
  >
    {enumToObjects({ choices: ExportMenuSection }).map((entry) => {
      const sectionKey = exportMenuSectionMapping.get(Number(entry.key));

      if (!sectionKey) {
        return null;
      }
      return (
        <AccountsMenu key={entry.value} sectionKey={sectionKey} title={entry.label}>
          {entry.label === 'Products' && (
            <Menu.Item
              onClick={() => {
                exportProductsByType();
              }}
            >
              Commerce Central
            </Menu.Item>
          )}
        </AccountsMenu>
      );
    })}
  </Menu>
);

const ExportButton: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<ExportMenuSection | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<ChannelEntry | null>(null);
  const [form] = Form.useForm<ExportChannelAccountFileParams>();
  const [queueFileExport, { status: operationStatus }] = useExportChannelAccountFileMutation();
  const [exportProductTypeFiles, { status: operationStatus2 }] = useExportProductsByTypeMutation();
  const [visible, setVisible] = useState(false);

  const onSelected = useCallback(
    (info: MenuInfo) => {
      const [accountJSON, channelJSON, section] = info.keyPath;
      if (accountJSON.charAt(0) !== '{') {
        return;
      }
      const account: ChannelAccountEntry = JSON.parse(accountJSON);
      const channel: ChannelEntry = JSON.parse(channelJSON);
      form.setFieldsValue({
        channelNum: channel.channelNum,
        channelAccountNum: account.channelAccountNum,
        mappingType: account.mappingType,
        fileOperation: 20004,
        isForAllSku: false,
        channelAcctJsonFileId: account.productMappingNum.toString(),
        isFileOperation: false,
        operationSetNum: 0,
      });

      setSelectedSection(Number(section));
      setSelectedChannel(channel);
      setVisible(true);
    },
    [form],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
    form.resetFields();
    setSelectedSection(null);
    setSelectedChannel(null);
  }, [form]);

  const exportProductsByType = useCallback(() => {
    exportProductTypeFiles({});
  }, [exportProductTypeFiles]);

  const submit = useCallback(
    (values: ExportChannelAccountFileParams) => {
      queueFileExport(values);
      setVisible(false);
      form.resetFields();
    },
    [form, queueFileExport],
  );

  // Checks the API call status and shows an error message if it fails
  useEffect(() => {
    if (operationStatus !== 'rejected' || operationStatus2 !== 'rejected') {
      return;
    }

    message.error('There was an error queueing your export, please try again later').then();
  }, [operationStatus, operationStatus2]);

  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={
          <ExportButtonOverlay onClick={onSelected} exportProductsByType={exportProductsByType} />
        }
      >
        <Button
          type="primary"
          loading={operationStatus === 'pending' || operationStatus2 === 'pending'}
        >
          <CloudDownloadOutlined />
          Export
        </Button>
      </Dropdown>
      <ExportModal
        form={form}
        visible={visible}
        onFinish={submit}
        onCancel={onCancel}
        section={selectedSection}
        channel={selectedChannel}
      />
    </>
  );
};

const Page: React.FC = () => {
  // const [filterValue, setFilterValue] = useState<TypeFilterValue>(initialFilterValue);
  const [filterParams, setFilterParams] = useState<GetExportFilesParams>({
    $top: 10,
    $skip: 0,
    $count: true,
    requestedDateFrom: convertToUtcTime(moment().add(-7, 'days').startOf('day').format()),
    requestedDateTo: convertToUtcTime(moment().endOf('day').format())
  });
  const profiles = useSelector((s: any) => s.profiles);
  const [init, setInit] = useState<boolean>(false);
  const [exporter, setExporter] = useState<string>();
  const [exportStatus, setExportStatus] = useState<number>();
  const [submittedDate, setSubmittedDate] = useState<any>([
    moment().add(-7, 'days').startOf('day'),
    moment().endOf('day'),
  ]);

  const onSkipLimitChange = useCallback(
    (values: api.Pagination) => {
      setFilterParams({
        ...filterParams,
        ...values,
      });
    },
    [filterParams],
  );

  const onSubmitDateChange = (dates: any) => {
    setSubmittedDate(dates);

    if (dates) {
      console.log(convertToUtcTime(dates[0].startOf('day').format()));
      console.log(convertToUtcTime(dates[1].endOf('day').format()));
    }
  };

  const onSearchExportedFiles = () => {
    setInit(true);
    const { $top, $skip } = filterParams;
    const params = {
      $count: true,
      $top,
      $skip,
      requestedDateFrom: submittedDate ?
        convertToUtcTime(submittedDate[0].startOf('day').format()) : undefined,
      requestedDateTo: submittedDate ?
        convertToUtcTime(submittedDate[1].endOf('day').format()) : undefined,
      requester: exporter,
      exportStatus: exportStatus,
      timeStamp: new Date().getTime()
    };
    setFilterParams(params);
  };

  // const onFilterValueChange = useCallback(
  //   (value: TypeFilterValue) => {
  //     // Update local copy of the value
  //     setFilterValue(value);

  //     const requestedTime = value?.find((f) => f.name === 'RequestTime');

  //     // Update filter params with respective values
  //     const newFilterParams: GetExportFilesParams = {
  //       ...filterParams,
  //       requester: value?.find((f) => f.name === 'RequestEmail')?.value,
  //       exportStatus: value?.find((f) => f.name === 'ExportStatus')?.value,
  //       requestedDateFrom: requestedTime?.value?.start,
  //       requestedDateTo: requestedTime?.value?.end,
  //     };

  //     setFilterParams(newFilterParams);
  //   },
  //   [filterParams],
  // );

  const { data: response = initialDataState, isFetching, /*refetch*/ } = useGetExportFilesQuery(
    filterParams
  );
  // const wrappedResponse = useCallback(async () => response, [response]);
  const wrappedResponse = useCallback(async () => {
    if (init) return response;
    else return initialDataState;
  }, [init, response])

  const clearSearchForm = () => {
    setExportStatus(undefined);
    setExporter(undefined);
    setSubmittedDate([
      moment().add(-7, 'days').startOf('day'),
      moment().endOf('day'),
    ]);
  };

  const SearchTips = (params: { left: number }) => {
    const { left } = params;
    return (
      <div
        style={{ position: 'absolute', left, top: 35, width: 620, backgroundColor: 'transparent', textAlign: 'center' }}
      >
        <div style={{ width: 35, height: 35, padding: 5, marginLeft: 330 }}>
          <ArrowTurnUp />
        </div>
        <Row>
          <Text style={{ fontSize: 24, color: '#5C677D' }}>
            Input your search criteria and click "Search" button
          </Text>
        </Row>
      </div>
    )
  }

  return (
    <ContentLayout>
      <Heading
        title="Export Center"
        actions={
          hasHigherPermission(profiles.permissions, Permissions.MANAGE_EXPORT, ViewLevel.VIEW) ? (
            <ExportButton />
          ) : null
        }
      />
      <Spacer />
      <SiteContent flexGrow={init}>
        {/* <TimedCallback onCountDownFinished={refetch} /> */}
        <SearchFormWrapper>
          <Row>
            <Col span={10}>
              <Row align="middle">
                <FormLabel className="search-form-label">Submitted Date</FormLabel>
                <DatePicker.RangePicker
                  className="search-submit-date-picker"
                  onChange={onSubmitDateChange}
                  value={submittedDate}
                  allowClear={false}
                />
              </Row>
            </Col>
            <Col span={7}>
              <Row align="middle">
                <FormLabel className="search-form-label">User</FormLabel>
                <Input
                  allowClear
                  className="search-user-input"
                  onChange={e => setExporter(e.target.value)}
                  value={exporter}
                />
              </Row>
            </Col>
            <Col span={7}>
              <Row align="middle">
                <FormLabel className="search-form-label">Status</FormLabel>
                <Select
                  allowClear
                  className="search-user-input"
                  onChange={setExportStatus}
                  value={exportStatus}
                  options={enumToObjects({ choices: ExportStatus })}
                />
              </Row>
            </Col>
          </Row>
          <Row className="search-form-bottom-btn-row" justify="center">
            <Space>
              <Button
                onClick={onSearchExportedFiles}
                disabled={isFetching}
                type="primary"
                style={{ position: 'relative', width: 95 }}
              >
                <SearchOutlined />
                Search
                {!init && <SearchTips left={-295} />}
              </Button>
              <Button
                onClick={clearSearchForm}
                disabled={isFetching}
              >
                <ClearOutlined />
                Clear
              </Button>
            </Space>
          </Row>
        </SearchFormWrapper>
        {
          init && (
            <ReactDataGrid
              idProperty="$uid"
              style={{ minHeight: '50vh', marginTop: 15 }}
              columns={tableColumns}
              dataSource={wrappedResponse}
              loading={isFetching}
              pagination="remote"
              defaultLimit={10}
              skip={filterParams.$skip}
              limit={filterParams.$top}
              onSkipChange={($skip: number) => onSkipLimitChange({ $skip })}
              onLimitChange={($top: number) => onSkipLimitChange({ $top })}
              // // filterValue={filterValue}
              // onFilterValueChange={onFilterValueChange}
              // enableFiltering
              showColumnMenuLockOptions={false}
            />
          )
        }
      </SiteContent>
    </ContentLayout>
  );
};

export default Page;
