import styled from 'styled-components';
import {Layout, Menu} from 'antd';

export const LogoContainer = styled.div`
  // margin-bottom: 24px;
  margin: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;


export const LogoHeader = styled.div`
  // height: 55px;
  // margin: 24px 20px 5px 20px;
  display: flex;
  align-items: center;
`;

export const UserContainer = styled.div`
  display: flex;
`;

export const UserInfoContainer = styled.div`
  margin-left: 12px;
`;

export const RegularText = styled.h2`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin-left: 10px;
  margin-bottom: 0;
`;

export const PrimaryText = styled.h2`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: @primary-text;
  margin: 0;
  margin-left: 10px;
`;

export const LogoText = styled(PrimaryText)`
  margin-top: 14px;
  color: white;
`;

export const LabelName = styled.label`
  width: 69px;
  height: 22px;
  font-family: Lato;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
`;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

export const MenuItemGroup = styled(Menu.ItemGroup)`
.ant-menu-item-group-title:before {
  display: block;
  content: "";
  height: 1px;
  width: 100%;
  margin-bottom: 8px;
  background-color: white;
}
`;

export const Divider = styled.div`
  border-bottom-color: #d8dde6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 10px 24px 10px 24px;
`;

export const SiderContainer = styled(Layout.Sider)`
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: #353A40;
  overflow-y: auto;
`;
