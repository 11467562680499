import React from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  notification,
} from 'antd';
import {
  CopyOutlined,
  LinkOutlined,
  SearchOutlined,
  //PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';

import ImagePlaceholder from '../../../assets/images/preview_image.jpeg';
import theme from '../../../assets/styles/theme';
import ContentLayout from '../../../components/ContentLayout';
import Heading from '../../../components/common/Heading';
import SpaceCell from '../../../components/common/SpaceCell';
//import Loading from '../../../components/common/Loading';
import { StyleInputWrapper } from '../../../components/common/styledComponents';
//import ScreenMask from '../../../components/common/ScreenMask';
import SiteContent from '../../../components/SiteContent';
import Spacer from '../../../components/common/Spacer';
import { DataGrid } from '../../../components/common/datagrid/DataGrid2';
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from '../../../components/common/datagrid/Filter';
import {
  DATAGRID_CELL_ICON_WIDTH1,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  CODE_OPTION_COLOR_CONTAINS,
  CODE_OPTION_COLOR_EQUALS,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_CONTAINS,
  CODE_OPTION_SKU_EQUALS,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_CONTAINS,
  CODE_OPTION_STYLE_EQUALS,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_EQUALS,
  CODE_OPTION_UPC_LIST,
  GROUP_OPTION_COLOR,
  GROUP_OPTION_NO_GROUP,
  GROUP_OPTION_PRODUCT,
  GROUP_OPTION_STYLE,
  OPERATION_CONTAIN,
  OPERATION_EQUALS,
  OPERATION_LIST,
} from '../../../constants/config';
import { isRequestError } from '../../../services/http';
import { fetchControlTowerListExtraColumnData } from '../../../services/controlTower';
import /*Products,*/ {
  fetchSimpleProductGroup,
  fetchSimpleProductList,
  fetchSimpleProductPartialData,
} from '../../../services/products';
import { checkStringType, getProfileSettingValue, /*isDevEnv*/ } from '../../../util';
import { EnumType } from '../../../util/enums';

import SetValueCell from '../../Channel/setValueCell';
import {
  getOperationStatusDesc,
  getOperationStatusOptions,
} from '../../DetailProduct/BasicAttrEditor';
import ChannelControlFlagSelector from '../../ListProducts/ChannelControlFlagSelector';
import GroupDetail from '../../ListProducts/GroupDetail';
import LabelsSelector from '../../ListProducts/LabelsSelector';
import SearchFilterPanel from '../../ListProducts/SearchFilterPanel';
import { ConditionItem } from '../../ListProducts/NewCondition';
import { isCodeListType } from '../../ListProducts';
import ProductDetailDialog from './ProductDetailDialog';
import { ImageContainer, SearchCol } from './styles';

type SKULinkProps = {
  product: Entities.ProductProfile;
  target?: string;
};

export const TOWER_ITEM_MARGIN = 6;

const CancelToken = axios.CancelToken;
let cancel: any = undefined;

const SKULink = (props: SKULinkProps) => {
  const { product, target = '_self' } = props;
  const pids = product.ProductId.split('/');
  const productId = pids[pids.length - 1] || '-';

  return (
    <Link target={target} to={`/product-detail/${productId}`}>
      <LinkOutlined />
      &nbsp;
      {product.SKU}
    </Link>
  );
};

export const columns = [
  {
    name: 'mediaURL',
    header: 'Image',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 120,
    onRender(cell: any) {
      //console.log('c->', cell);
    },
    render({ value, data }: { value: string, data: Entities.ProductProfile }) {
      const src = value || ImagePlaceholder;
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: 'VariationParentSKU',
    header: 'Style Code',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        return data.styleCode || data.VariationParentSKU;
      }

      return '';
    },
  },
  {
    //name: 'ProductId',
    name: 'subStyleCode',
    header: 'Sub-Style Code',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 160,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        //return data.colorPatternCode || '';
        return data.subStyleCode || '';
      }

      return '';
    },
  },
  {
    name: 'SKU',
    header: 'SKU',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 180,
    // sort: (a: any, b: any) => false,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  /*{ name: 'ProductTitle', header: 'Title', defaultFlex: 2, minWidth: 200, },*/
  {
    name: 'ProductStatus',
    header: 'Status',
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;

      return getOperationStatusDesc(data.ProductStatus, true);
    },
  },
  /*{
    name: 'Brand',
    header: 'Brand',
    defaultFlex: 1,
    minWidth: 150,
  },
  { name: 'Type', header: 'Type', defaultFlex: 1, minWidth: 100, },
  {
    name: 'ChannelControlFlags',
    header: 'Sales Channel',
    defaultFlex: 1,
    minWidth: 190,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // note can not use Array.isArray to identify the type of channelControlFlags here
        if (data.channelContorlFlags && typeof data.channelContorlFlags === 'object' && data.channelContorlFlags.length > 0) {
          //return Array.isArray(data.channelControlFlags) ? data.channelControlFlags.join(', ') : (data.ChannelControlFlags || '');
          return data.channelContorlFlags.join(', ');
        }
      }

      return '';
    },
  },
  {
    name: 'Labels',
    header: 'Labels',
    defaultFlex: 1,
    minWidth: 130,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        return Array.isArray(data.labels) ? data.labels.join(', ') : (data.Labels || '');
      }

      return '';
    },
  },
  { name: 'upc', header: 'UPC', defaultFlex: 1, minWidth: 150, },*/
];

const ControlTower = () => {
  const { Option } = Select;
  const { useCallback, useMemo, useState } = React;
  const DEFAULT_GRID_LIMIT = 20;
  const [ccfList, /*setCcfList*/] = useState<StringKAnyVPair[]>([]);
  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [conditionList, setConditionList] = useState<ConditionItem[]>([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>({});
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [filteredData, setFilteredData] = useState<Entities.ProductProfile[]>([]);
  const [inited, setInited] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [labelList, /*setLabelList*/] = useState<StringKAnyVPair[]>([]);
  const [labelVersion, setLabelVersion] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  const [productListStyle, setProductListStyle] = useState<string>();
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchCFs, /*setSearchCFs*/] = useState<any[]>([]);
  const [searchCode, setSearchCode] = useState('');
  const [searchCodeType, setSearchCodeType] = useState(CODE_OPTION_STYLE_CONTAINS);
  const [searchGroup, setSearchGroup] = useState(GROUP_OPTION_PRODUCT);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  const [searchMore, /*setSearchMore*/] = useState(false);
  const [searchMultiCode, setSearchMultiCode] = useState('');
  const [searchSorter, setSearchSorter] = useState('SKU ASC');
  const [searchStatus, setSearchStatus] = useState<number[]>([0]);
  const [searchTitle, setSearchTitle] = useState('');
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: Entities.ProductProfile } | boolean>({});
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const codeInputRef = React.useRef<any>(null);
  const codeListInputRef = React.useRef<any>(null);
  const filterHiddenTimer = React.useRef<any>(0);
  const filterState = React.useRef<any>(null);

  const searchMoreStyle = useMemo(() => {
    return {
      display: searchMore ? '' : 'none',
    };
  }, [searchMore]);

  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  const clearSearchBrand = () => {
    setSearchBrand('');
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle('');
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
  };

  const dataSource = async (): Promise<{ data: any[], count: number; }> => {
    return {
      data: filteredData,
      count: totalCount,
    };
  };

  const fetchProductExtData = async (products: StringKAnyVPair[]) => {
    try {
      if (cancel !== undefined) {
        cancel();
      }
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });

      /*const params = {
        products: ids,
        //groups: searchGroups,
        //tags: searchTags,
      };*/

      // setExtLoading(true);
      const data = await fetchControlTowerListExtraColumnData(
        ids,
        //params,
        new CancelToken(function executor(c) {
          cancel = c;
        })
      );
      console.log('data ->', data);
      if (data.length > 0) {
        setTableColumns([
          ...gridColumns(searchTreeMode),
          ...data[0].mappedPreviewers.map((i: any, index: number) => {
            return {
              name: `ext${index}`,
              header: i.previewDisplayName,
              defaultFlex: 1,
              minWidth: 150,
              render: (value: any) => {
                const { data, /*rowIndex*/ } = value;
                return (
                  <SetValueCell
                    value={data[`ext${index}`]}
                    valueType={checkStringType(data[`ext${index}`])}
                    //editable={true}
                    editable={false}
                    onButtonClick={() => {
                      /*const attr = getDataAttrById(i.attributeNum);
                      const opts = getAttrOptions(attr);

                      setCurrentValue({
                        data: data[`ext${index}`],
                        options: opts,
                        previewValue: data[`ext${index}`],
                        previewDisplayName: i.previewDisplayName,
                        previewID: i.previewID,
                        productId: data.ProductId,
                        name: `ext${index}`,
                        attributeNum: i.attributeNum,
                        rowIndex: rowIndex,
                        editable: true,
                        sku: data.SKU,
                      });

                      if (attr) {
                        if (opts.length > 0) {
                          setEditSelectorDialogVisible(true);
                        } else {
                          switch (attr.elementDataType) {
                            case ATTRIBUTE_DATA_TYPE_IMAGEURL:
                              setEditImageDialogVisible(true);
                              break;

                            default:
                              setValueModalVisible(true);
                          }
                        }
                      } else {
                        setValueModalVisible(true);
                      }*/
                    }}
                  />
                );
              },
            };
          }),
        ]);
        data.map((item: any) => {
          if (pDict[item.productId]) {
            item.mappedPreviewers.map((i: any, index: number) => {
              pDict[item.productId][`ext${index}`] = i.previewValue;
              return true;
            });
          }
          return true;
        });
        // setFilteredData(Object.values(pDict));
      }
      // setExtLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("request canceled..");
      }
    }
  };

  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    if (!productListStyle) {
      await getProductListStyle();
    }
    const { skip, top } = options;

    setIsFetching(true)

    try {
      const { ProductList, ProductTotalCount } = await fetchSimpleProductList(
        typeof skip === 'number' && skip >= 0 ? skip : pageSkip,
        typeof top === 'number' && top >= 0 ? top : pageTop,
        getSearchOptions(),
      );

      if (Array.isArray(ProductList)) {
        if (isSearchGroupMode(searchCodeType)) {
          ProductList.forEach(e => {
            if (['Product', 'Bundle'].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }

        setFilteredData(ProductList);
        setTotalCount(ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        setSearchTreeMode(isSearchGroupMode(searchCodeType));

        if (ProductList.length > 0) {
          setTimeout(async () => {
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
          }, 0);
          await fetchProductExtData(ProductList);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      //message.error(`Fetch products error: ${e}`);
      notification.error({
        message: `Fetch products error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
      console.log('Fetch products error:', e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    // setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map(e => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData(ids);

      if (data && Array.isArray(data)) {
        //console.log('d->', data.headers, products);
        data.forEach(e => {
          if (pDict[e.productId]) {
            const obj = { ...e };

            delete obj.productId;
            // pDict[e.productId] = obj;
            // console.log('e->', obj);
            for (let k in obj) {
              pDict[e.productId][k] = obj[k];
            }
          }
        });
        //console.log('p-->', products);
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      if (!isRequestError(e)) {
        notification.error({
          message: `Fetch partial error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
      // console.error('Fetch partial error:', e, e.isAxiosError);
    } finally {
      // setIsFetching(false);
    }
  };

  const filterValue = [
    { name: 'SKU', operator: 'contains', type: GRID_FILTER13, value: '' },
    { name: 'ProductTitle', operator: 'contains', type: GRID_FILTER11, value: '' },
    { name: 'UPC', operator: 'eq', type: GRID_FILTER12, value: '' },
    { name: 'QtyTotal', operator: 'gte', type: GRID_FILTER21, value: null },
  ];

  const getAttributeFilters = () => {
    let ret: {
      filterNum: string;
      op: 1 | 2 | 3 | 4 | 5;   // 1:= 2:like 3:> 4:< 5 <>
      filterValue: string;
      filterType: 1, // 目前固定传1
      filterFlag: 0, // 目前固定传0
    }[] = [];

    console.log('conditionList = ', conditionList);

    if (conditionList.length > 0) {
      conditionList.forEach(item => {
        ret.push({
          filterNum: item.attribute.AttributeNum,
          op: item.searchType,
          filterValue: item.value,
          filterType: 1,
          filterFlag: 0
        })
      })
    }
    return ret;
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode.split('\n')
          .map(e => e.trim())
          .filter(e => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(),
          collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    ret.push({
      filterName: 'ProductStatus',
      collectionFilterValues: searchStatus,
      op: 2,
    });

    return ret;
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        //return 'VariationParentSKU';
        return 'ColorPatternCode';

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return 'SKU';

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return 'StyleCode';

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return 'UPC';
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };

  const getProductListStyle = async () => {
    const value = await getProfileSettingValue('ProductListStyle', '0');
    setProductListStyle(value);
  };

  const getSearchGrouper = useCallback(() => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = '';
    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = 'ColorPatternCode';
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = 'Product';
        break;

      case GROUP_OPTION_STYLE:
        groupName = 'StyleCode';
        break;
      default:
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
    // eslint-disable-next-line
  }, [searchGroup, searchCodeType]);

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [];
    /*let searchCodeValue = searchCodeType === CODE_OPTION_SKU_LIST 
      ? searchCode.split('\n').map((item) => item.trim()).filter(item => item)
      : searchCode;*/
    if (searchCodeType !== CODE_OPTION_SKU_LIST && searchCodeType !== CODE_OPTION_COLOR_LIST && searchCodeType !== CODE_OPTION_STYLE_LIST && searchCodeType !== CODE_OPTION_UPC_LIST) {
      filters.push({
        'filterName': getFilterNameBySearchCodeType(),
        //'filterValue': searchCodeValue,
        'filterValue': searchCode,
        'op': getOperateCodeBySearchCodeType(),
      });
    }

    if (searchTitle) {
      filters.push({
        'filterName': 'ProductTitle',
        'filterValue': searchTitle,
        'op': OPERATION_CONTAIN,
      });
    }

    if (searchBrand) {
      filters.push({
        'filterName': 'Brand',
        'filterValue': searchBrand,
        'op': OPERATION_CONTAIN,
      });
    }

    if (searchCCFs.length > 0) {
      filters.push({
        'filterName': 'ChannelControlFlag',
        'filterValue': searchCCFs.join('|'),
        'op': OPERATION_EQUALS,
      });
    }

    if (searchCFs.length > 0) {
      filters.push({
        'filterName': 'Classification',
        'filterValue': searchCFs.join('|'),
        'op': OPERATION_EQUALS,
      });
    }

    if (searchLabels.length > 0) {
      filters.push({
        'filterName': 'Labels',
        'filterValue': searchLabels.join('|'),
        'op': OPERATION_EQUALS,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      //queryGrouper: getCollectionFilters().length > 0 ? null : getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
      queryAttributeFilters: getAttributeFilters()
    };
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(' ');

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  // eslint-disable-next-line
  const gridColumns = (isGroupMode = false) => {
    const colDef: any[] = [];

    for (let i = 0; i < columns.length; i++) {
      if (productListStyle === '2') {
        if (['Style Code', 'Sub-Style Code'].includes(columns[i].header)) {
          continue;
        }
      }
      if (columns[i].header === 'Image') {
        columns[i].render = (p: any) => {
          const { value, data } = p;
          const src = value || ImagePlaceholder;
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);

          return (
            <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
              <Image width={28} height={28} preview={!!value} src={src} />
            </ImageContainer>
          );
        };
        colDef.push(columns[i]);
        continue;
      } else if (columns[i].header === 'SKU') {
        columns[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split('/');
          const productId = pids[pids.length - 1] || '-';

          /*return (<Button type="link" onClick={() => openProductDetailDialog(`${productId}`, data)}>
            {data.SKU}
          </Button>);*/
          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyToClipboard
                    text={data.SKU || ''}
                    onCopy={() => notification.success({ message: `"${data.SKU}" has been copied to clipboard`, duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION, })}
                  >
                    <CopyOutlined
                      style={{ color: theme['@info-color'] }}
                    />
                  </CopyToClipboard>
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => openProductDetailDialog(`${productId}`, data)}
              text={data.SKU}
              textIsButton
            />
          );
        }
        colDef.push(columns[i]);
        continue;
      }
      colDef.push(columns[i]);
    }

    return colDef;
  };

  const gridFilterTypes = Object.assign({
    styleCodeFilter: {
      type: 'string',
      emptyValue: '',
      operators: [
        { name: 'SKU Contains', fn: () => true },
        { name: 'SKU Equals', fn: () => true },
      ],
    },
  }, filterTypes);

  const groupSubStyle = (productList: any) => {
    const substyles: any[] = [];
    const products: any[] = [];
    if (Array.isArray(productList)) {
      // group substyle and children together
      productList.forEach((item) => {
        if (item.ProductType === 1) {
          products.push(item);
        } else {
          substyles.push(item);
        }
      });
      if (products.length === substyles.length) return productList;
      products.forEach((item) => {
        const {
          subStyleCode,
        } = item;
        const parent = substyles.find((item) => item.SKU === subStyleCode);
        if (parent) {
          parent.nodes = Array.isArray(parent.nodes) ? [...parent.nodes, item] : [item];
        } else {
          substyles.push(item);
        }
      });
      return substyles;
    }
    return productList;
  };

  // eslint-disable-next-line
  const handleSearchProducts = () => {
    if (conditionList.filter(line => !line.attribute || !line.value).length > 0) {
      Modal.error({
        title: 'Please complete or delete the advanced filter!',
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk: () => {
          onSearchbarFocus();
        }
      });
      return;
    }
    setPageSkip(0);
    fetchProductList({ skip: 0 });
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  const isColorCode = (code: number) => {
    return [
      CODE_OPTION_COLOR_CONTAINS,
      CODE_OPTION_COLOR_EQUALS,
      CODE_OPTION_COLOR_LIST,
    ].indexOf(code) > -1;
  };

  const isCommonGroupOption = (code: number) => {
    return isColorCode(code) || isStyleCode(code);
  };

  const isSearchGroupMode = (code: number) => {
    // return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
    return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_PRODUCT;
  };

  const isStyleCode = (code: number) => {
    return [
      CODE_OPTION_STYLE_CONTAINS,
      CODE_OPTION_STYLE_EQUALS,
      CODE_OPTION_STYLE_LIST,
    ].indexOf(code) > -1;
  };

  const loadNextLevelProducts = async (data: any) => {
    const { node } = data;
    let ret: any = null;

    // if (node.nodes) return;
    //setIsFetching(true);
    try {
      let { ProductList } = await fetchSimpleProductGroup(node.ProductId);

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        if (ProductList.length > 0) {
          await fetchProductParticalData(ProductList);
          //await fetchProductParticalData(ProductList);
          ProductList = groupSubStyle(ProductList);
          node.nodes = ProductList;
          updateGridRow(node);
          //updateGridRow(node);
        }

        ret = ProductList;
      }
    } catch (e) {
      //message.error(`Fetch children nodes error: ${e}`);
      notification.error({
        message: `Fetch children nodes error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
      console.log('Fetch children nodes error:', e);
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const onEditTowerItem = () => {
    handleSearchProducts();
  };

  const onFilterValueChange = (val: any) => {
    console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    fetchProductList({ top: limit });
    setPageTop(limit);
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      //console.log('enter', filterPanelVisible);
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };

  const onSearchBrandChange = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => setSearchBrand(value), 0);
  };

  const onSearchCodeChange = (evt: any) => {
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  const onSearchTitleChange = (evt: any) => {
    //setSearchTitle(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchTitle(value), 0);
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    setSearchCCFs(values);
  };

  const onSelectLabels = (values: any[]) => {
    setSearchLabels(values);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      /*case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;*/
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (value === CODE_OPTION_SKU_LIST || value === CODE_OPTION_COLOR_LIST || value === CODE_OPTION_STYLE_LIST || value === CODE_OPTION_UPC_LIST) {
      setTimeout(() => {
        setSearchMultiCode('');
        codeListInputRef?.current?.focus();
        codeInputRef.current.setValue('');
        setSearchCode('');
      }, 0);
    } else {
      setTimeout(() => {
        codeInputRef?.current?.select();
        codeInputRef?.current?.focus();
      }, 0);
    }

    //showSearchFilterPanel();
  };

  const onSearchbarFocus = () => {
    const ctn = getCodeInputWrapperDom();

    if (ctn) {
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      setFilterPanelOutline({ x: rect.x, y: rect.y + rect.height, width: rect.width });

      // if (!filterPanelVisible) {
      showSearchFilterPanel();
      // }
    }
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelectedRows(selected);
  }, []);

  const onSkipChange = (skip: number) => {
    fetchProductList({ skip });
    setPageSkip(skip);
  };

  const onSortChange = async (info: any) => {
  };

  const openProductDetailDialog = (productId: string, product: StringKAnyVPair) => {
    setDetailVisible(true);
    setCurrentProduct(product);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    return <GroupDetail row={data} />;
  };

  const searchCodeTypeSelector = () => (
    <Select
      defaultValue={searchCodeType}
      //onBlur={onSearchbarBlur}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230, }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Code Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Code Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style Code List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Sub-Style Code Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>Sub-Style Code Contains</Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Sub-Style Code List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );

  const showSearchConditionTags = () => {
    const ccfLabels: string[] = ccfList
      .filter(c => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map(c => c.CHNLCtrlFlag);
    const labels: string[] = labelList.filter(l => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map(l => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter(e => searchStatus.indexOf(e.code) > -1)
      .map(e => e.description);

    const attrSearchTypeEnum: EnumType = { 1: 'equals', 2: 'contains', 5: `doesn't contain` };

    return (
      <Space>
        {searchTitle.trim() && (
          <Tag
            closable
            onClose={clearSearchTitle}
          >
            <span className="field-label">Title</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag
            closable
            onClose={() => setSearchStatus([])}
          >
            <span className="field-label">Stauts</span>
            {statusLabels.join(', ')}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(', ')}
          </Tag>
        )}
        {searchBrand.trim() && (
          <Tag
            closable
            onClose={clearSearchBrand}
          >
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Labels</span>
            {labels.join(', ')}
          </Tag>
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
        {(conditionList.filter(item => item.attribute && item.value)).map(i => {
          return (
            <Tag>
              <span className="field-label">{`${i.attribute.AttributeName} ${attrSearchTypeEnum[i.searchType]}`}</span>
              {i.value}
            </Tag>
          )
        })}
      </Space>
    );
  };

  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: (new Date()).getTime(),
    };
    setFilterPanelVisible(true);
  };

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }

    if (found) {
      setFilteredData([...filteredData]);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
      setTableColumns(gridColumns(searchTreeMode));
      handleSearchProducts();
    }
  }, [
    gridColumns,
    handleSearchProducts,
    inited,
    searchTreeMode,
  ]);

  return (<>
    <ContentLayout>
      <Heading
        title="Control Tower"
        actions={
          <Space>
          </Space>
        }
      />
      <Spacer />
      <SiteContent flexGrow noPadding transparent>
        <Row className="content-section" justify="space-between">
          {/*<SearchCol xs={24} lg={12}>*/}
          <SearchCol>
            {/*<SearchBar
              data={data.ProductList}
              fields={searchFields}
              reference="SKU"
              onResult={setFilteredData}
            />*/}
            <Space direction="vertical" style={{ width: '100%' }}>
              <Row className="search-element-area">
                <Row>
                  {
                    productListStyle !== '2' && (
                      <Row align="middle" className="display-category-field">
                        <span className="field-label" style={{ paddingRight: 8 }}>Display</span>
                        <Radio.Group
                          onChange={onGroupModeChange}
                          //style={{ width: 430 }}
                          value={searchGroup}
                        >
                          <Radio
                            // disabled={!isStyleCode(searchCodeType)}
                            value={GROUP_OPTION_STYLE}
                          >
                            Style Master
                          </Radio>
                          {
                            productListStyle !== '1' && (
                              <Radio
                                // disabled={!isCommonGroupOption(searchCodeType)}
                                value={GROUP_OPTION_COLOR}
                              >
                                Sub-Style
                              </Radio>
                            )
                          }
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_PRODUCT}
                          >
                            Product
                          </Radio>
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_NO_GROUP}
                          >
                            All
                          </Radio>
                        </Radio.Group>
                      </Row>
                    )
                  }
                  {isCodeListType(searchCodeType) && (
                    <StyleInputWrapper>
                      <Input
                        addonAfter={addonSearchButton()}
                        addonBefore={searchCodeTypeSelector()}
                        allowClear
                        className={filterPanelVisible ? 'code-selector-active' : ''}
                        //disabled
                        //onBlur={onSearchbarBlur}
                        onMouseDown={onSearchbarFocus}
                        onChange={onSearchCodeChange}
                        onMouseEnter={onMouseEnterFilter}
                        onMouseLeave={onMouseLeaveFilter}
                        readOnly
                        ref={codeInputRef}
                        value={searchMultiCode.replace(/\n/g, ', ')}
                      />
                    </StyleInputWrapper>
                  )}
                  {!isCodeListType(searchCodeType) && (
                    <StyleInputWrapper>
                      <Input
                        addonAfter={addonSearchButton()}
                        addonBefore={searchCodeTypeSelector()}
                        allowClear
                        className={filterPanelVisible ? 'code-selector-active' : ''}
                        //onBlur={onSearchbarBlur}
                        onMouseDown={onSearchbarFocus}
                        onChange={onSearchCodeChange}
                        onMouseEnter={onMouseEnterFilter}
                        onMouseLeave={onMouseLeaveFilter}
                        ref={codeInputRef}
                      />
                    </StyleInputWrapper>
                  )}
                </Row>
                {totalCount > 0 && <div className="action-btn-wrap">{/*<CSVLink
                  filename="products.csv"
                  data={csvSource(filteredData)}
                  columns={csvColumns}
                  type="default"
                />*/}</div>}
              </Row>
              {/*(searchCodeType === CODE_OPTION_SKU_LIST || searchCodeType === CODE_OPTION_COLOR_LIST || searchCodeType === CODE_OPTION_STYLE_LIST || searchCodeType === CODE_OPTION_UPC_LIST) && (
                <Input.TextArea
                  allowClear
                  onChange={onSearchCodeListChange}
                  onKeyDown={onSearchCodeListKeyDown}
                  ref={codeListInputRef}
                  style={{ width: 380, height: 120 }}
                  value={searchMultiCode}
                />
              )*/}
              <Space style={searchMoreStyle}>
                <div className="title-wrapper">
                  <span className="field-label">Title:</span>
                  <Input
                    allowClear
                    onChange={onSearchTitleChange}
                    style={{ width: 218 }}
                  />
                </div>
                <div className="channel-ctrl-flag-wrapper">
                  <span className="field-label">Sales Channel:</span>
                  <ChannelControlFlagSelector
                    onChange={onSelectChannelControlFlags}
                    style={{ width: 226 }}
                  />
                </div>
                <div className="title-wrapper">
                  <span className="field-label">Brand:</span>
                  <Input
                    allowClear
                    className="brand-input"
                    onChange={onSearchBrandChange}
                  />
                </div>
              </Space>
              <Space style={searchMoreStyle}>
                <div className="labels-wrapper">
                  <span className="field-label">Labels</span>
                  <LabelsSelector
                    onChange={onSelectLabels}
                    style={{ width: 285 }}
                  />
                </div>
              </Space>
              <Row align="middle" className="search-btn-row" justify="space-between" style={{ marginTop: searchMore ? 0 : -6, }}>
                {searchBegun && showSearchConditionTags()}
              </Row>
            </Space>
          </SearchCol>
        </Row>
        <Spacer height={14} />
        <div className="content-section" style={{ height: 'calc(100% - 115px)' }}>
          {tableColumns.length > 0 && (
          <DataGrid
            idProperty="ProductId"
            rowHeight={35}
            //columns={gridColumns(searchTreeMode)}
            columns={tableColumns}
            dataSource={dataSource}
            defaultFilterValue={filterValue}
            defaultLimit={DEFAULT_GRID_LIMIT}
            //disableLoadingIcon={filterPanelVisible}
            enableFiltering={false}
            filterTypes={gridFilterTypes}
            limit={pageTop}
            loadNode={loadNextLevelProducts}
            //loading={isFetching || filterPanelVisible}
            loading={isFetching}
            onFilterValueChange={onFilterValueChange}
            onLimitChange={onLimitChange}
            onSelectionChange={onSelectionChange}
            onSkipChange={onSkipChange}
            onSortInfoChange={onSortChange}
            pageSizes={[20, 30, 50, 100, 200]}
            pagination
            //renderRowContextMenu={renderRowContextMenu}
            renderRowDetails={renderRowDetails}
            rowExpandColumn={searchGroupMode}
            rowExpandHeight={300}
            selected={selectedRows}
            skip={pageSkip}
            sortable={false}
            style={{ height: '100%' }}
            treeColumn={searchTreeMode ? 'mediaURL' : undefined}
          />
          )}
        </div>
      </SiteContent>
    </ContentLayout>
    <SearchFilterPanel
      channelFlagVersion={channelFlagVersion}
      clearChannelFlags={clearChannelFlags}
      clearLabels={clearLabels}
      clearSearchBrand={clearSearchBrand}
      clearSearchTitle={clearSearchTitle}
      handleSearch={handleSearchProducts}
      hidePanel={hideSearchFilterPanel}
      labelVersion={labelVersion}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      searchBrandVersion={searchBrandVersion}
      searchCodeType={searchCodeType}
      searchMultiCode={searchMultiCode}
      searchTitleVersion={searchTitleVersion}
      setBrand={setSearchBrand}
      setLabels={setSearchLabels}
      setMultiCode={setSearchMultiCode}
      setSalesChannel={setSearchCCFs}
      setSortType={setSearchSorter}
      setStatus={setSearchStatus}
      setTitle={setSearchTitle}
      showPanel={showSearchFilterPanel}
      status={searchStatus}
      statusOptions={getOperationStatusOptions().map(e => ({ label: e.description, value: e.code }))}
      x={filterPanelOutline.x || 0}
      y={filterPanelOutline.y || 0}
      visible={filterPanelVisible}
      width={filterPanelOutline.width || 0}
      setConditionList={(list: ConditionItem[]) => setConditionList([...list])}
      needAddNewCondition={true}
    />
    {detailVisible && (
      <ProductDetailDialog
        onClose={closeProductDetailDialog}
        onSave={onEditTowerItem}
        product={currentProduct}
        visible={detailVisible}
      />
    )}
  </>);
};

export default ControlTower;
