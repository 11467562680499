import React from 'react';
import { Input, Select, Typography } from 'antd';
import LoadingIcon from '../../../components/common/LoadingIcon';
import message from '../../../components/common/message';
import { fetchCategoryChildrenById } from '../../../services/channels';
import Products from '../../../services/products';
import {
  loadChannelControlFlags,
  loadClassifications,
  loadProductLabels,
} from '../helper';
import { EditorWrapper } from '../styledComponents';
import { ATTR_CATEGORY_OPERATION } from './';
import TwoColSelectorEditor from './TwoColSelectorEditor';

type Props = {
  attr?: StringKAnyVPair;
  category?: number;
  className?: string;
  editOnly?: boolean;
  label?: string;
  mode?: 'multiple' | 'tags';
  name?: string;
  onSelect?: Function;
  options?: StringKAnyVPair[];
  productId?: string;
  readOnly?: boolean;
  refreshFormState?: Function;
  refreshOptions?: Function;
  state?: StringKAnyVPair;
  styleCode?: string;
  type: string;
  value: any;
};

const CommonSelectEditor = (props: Props) => {
  const { useState } = React;
  const [col2Value, setCol2Value] = useState<any>('');
  // eslint-disable-next-line
  const [currentValue, setCurrentValue] = useState<any>(props.value);
  // eslint-disable-next-line
  const [lastValue, setLastValue] = useState<any>(props.value);
  const [inited, setInited] = useState(false);
  // eslint-disable-next-line
  const [isDataReady, setIsDataReady] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<StringKAnyVPair[]>([]);
  const [twoColSelectorEditorVisible, setTwoColSelectorEditorVisible] = useState(false);

  const attrOpeIcon = () => {
    return (<>
      {isLoading && (
        <LoadingIcon />
      )}
      {!props.readOnly && !isLoading && (
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          onClick={toggleEditMode}
        >
          {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
          {isEditable ? '💾' : '🖊️'}
        </span>
      )}
    </>);
  };

  // eslint-disable-next-line
  const checkChannelFlagsInitialValue = (vals: string[], opts: StringKAnyVPair[]) => {
    console.log('chk flags', vals, opts);
    const all = opts.map(e => e.ProductCHNLCtrlFlagId); 
    const existVals = vals.filter(e => all.indexOf(e) > -1);

    if (/*existVals.length > 0 &&*/ existVals.length !== all.length) {
      setCurrentValue(existVals);
      setLastValue(existVals);
      console.log(vals, '->', existVals);
    }
  };

  const closeTwoColSelectorDialog = () => {
    setTwoColSelectorEditorVisible(false);
  };

  const columnOneClassName = () => {
    let cls = ['selector-column-one'];

    switch (props.type) {
      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'divisionCode':
      case 'groupCode':
      case 'lengthCode':
      case 'materialsContent':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
      case 'widthCode':
        cls = ['selector-column-two'];
        break;

      /*case 'NRFSizeCode':
        cls = ['selector-column-three'];
        break;*/
    }

    /*if (isLoading) {
      cls.push('space-32')
    }*/

    return cls.join(' ');
  };

  const columnCellThree = () => {
    /*switch (props.type) {
      case 'NRFSizeCode':
        return (
          <span className="selector-adj-cell selector-column-three cell-disabled">
            <Typography.Text ellipsis={{tooltip: currentValue}}>
            </Typography.Text>
          </span>
        );
    }*/

    return null;
  };

  const columnCellTwo = () => {
    switch (props.type) {
      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'divisionCode':
      case 'groupCode':
      case 'lengthCode':
      case 'materialsContent':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
      case 'widthCode':
        return (
          <span className={columnTwoClassName()}>
            <Typography.Text ellipsis={{tooltip: col2Value}}>
              {col2Value}
            </Typography.Text>
          </span>
        );
    }

    return null;
  };

  const columnTwoClassName = () => {
    let cls = ['selector-adj-cell'];

    switch (props.type) {
      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'divisionCode':
      case 'groupCode':
      case 'lengthCode':
      case 'materialsContent':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
      case 'widthCode':
        cls.push('selector-column-two');
        cls.push('cell-disabled');
        break;

      /*case 'NRFSizeCode':
        cls.push('selector-column-three');
        cls.push('cell-disabled');
        break;*/
    }

    return cls.join(' ');
  };

  const commonSelectorClassName = () => {
    const cls = [
      props.editOnly ? 'common-sel-editor' : 'common-sel-box',
    ];

    if (!isEditable && !props.editOnly && !isLoading) {
      if (props.readOnly) {
        cls.push('visual-ctn0');
      } else {
        cls.push('visual-ctn');
      }
    }

    return cls.join(' ');
  };

  // eslint-disable-next-line
  const isLastValueNull = () => {
    return lastValue === undefined || lastValue === null;
  };

  const isValueChanged = () => {
    if (currentValue && lastValue) {
      return JSON.stringify(currentValue) !== JSON.stringify(lastValue);
    }

    return currentValue !== lastValue;
  };

  const listValues = (curList: string[]) => {
    const ret: StringKAnyVPair[] = [];

    curList.forEach((e: string) => {
      ret.push({
        ElementId: e,
        //ProductEditType: lastValue.indexOf(e) > -1 ? 3 : 0,
        editType: Array.isArray(lastValue) && lastValue.indexOf(e) > -1 ? 3 : 0,
      });
    });

    if (Array.isArray(lastValue)) {
      lastValue.forEach((e: string) => {
        if (curList.indexOf(e) < 0) {
          ret.push({
            ElementId: e,
            //ProductEditType: 2,
            editType: 2,
          });
        }
      });
    }

    return ret;
  };

  const loadChannelFlags = async () => {
    const cs = await loadChannelControlFlags();

    setIsDataReady(true);
    // console.log('cs', cs);
    if (Array.isArray(cs)) {
      /*if (Array.isArray(props.value)) {
        checkChannelFlagsInitialValue(props.value, cs);
      }*/

      setOptions(cs);
    }
  };

  const loadLabels = async () => {
    const labels = await loadProductLabels();

    setIsDataReady(true);

    if (Array.isArray(labels)) {
      setOptions(labels);
    }
  };

  const loadClassificationList = async () => {
    const cls = await loadClassifications();

    setIsDataReady(true);

    if (Array.isArray(cls)) {
      setOptions(cls);
    }
  };

  const loadProductClassifications = async () => {
    const nodes = await fetchCategoryChildrenById('0');

    setIsDataReady(true);

    if (Array.isArray(nodes)) {
      nodes.sort(sortNodeTitle);
      setOptions(nodes);
    }
  };

  // eslint-disable-next-line
  const loadSelectOptions = async () => {
    setIsLoading(true);

    try {
      switch (props.type) {
        case 'classification':
          await loadClassificationList();
          break;

        case 'NRFColorCode':
        case 'NRFSizeCode':
        case 'ageGroup':
        case 'brand':
        case 'careInstructions':
        case 'categoryCode':
        case 'classCode':
        case 'colorPatternCode':
        case 'custPackType':
        case 'defaultWarehouse':
        case 'countryOfOrigin':
        case 'divisionCode':
        case 'gender':
        case 'groupCode':
        case 'lengthCode':
        case 'materialsContent':
        case 'manufacturer':
        case 'model':
        case 'operationStatus':
        case 'subclassCode':
        case 'subgroupCode':
        case 'sizeCode':
        case 'sizeType':
        case 'widthCode':
          await loadStyleVariationCode();
          break;

        case 'label':
          await loadLabels();
          break;

        case 'channel':
          await loadChannelFlags();
          break;

        case 'productClassifications':
          //await loadChannelFlags();
          await loadProductClassifications();
          console.log('prd cls', typeof props.value);
          break;
      }
    } catch(e) {
      message.error(`Load option list error: ${e}`);
    } finally {
      setIsLoading(false);
      setInitialCol2Value();
    }
  };

  const loadStyleVariationCode = async () => {
    if (Array.isArray(props.options)) {
      //console.log(' -> ', props.options);
      setIsDataReady(true);
      setOptions(props.options);
    }
  };

  const makeChannelFlagOptions = () => {
    return options.map(e => (
      <Select.Option
        key={e.ProductCHNLCtrlFlagId}
        value={e.ProductCHNLCtrlFlagId}
      >
        {e.CHNLCtrlFlagDesc || e.CHNLCtrlFlag || e.ProductCHNLCtrlFlagId}
      </Select.Option>
    ));
  };

  const makeCommonCodeDescriptionOptions = () => {
    return options.map(e => (
      <Select.Option
        key={e.code}
        value={e.code}
      >
        {e.description}
      </Select.Option>
    ));
  };

  const makeCommonStyleVariationOptions = () => {
    return options.map(e => (
      <Select.Option
        key={e.code}
        value={e.code}
      >
        {e.code}
      </Select.Option>
    ));
  };

  const makeClassificationOptions = () => {
    return options.map(e => (
      <Select.Option
        key={e.ClassificationId}
        value={e.ClassificationId}
      >
        {e.ClassificationName}
      </Select.Option>
    ));
  };

  const makeLabelOptions = () => {
    return options.map(e => (
      <Select.Option
        key={e.ProductLabelId}
        value={e.ProductLabelId}
      >
        {e.ProductLabelName}
      </Select.Option>
    ));
  };

  const makeProductClassificationOptions = () => {
    return options.map(e => (
      <Select.Option
        key={e.rowNum}
        value={e.rowNum}
      >
        {e.categoryName}
      </Select.Option>
    ));
  };

  const makeSelectOptions = () => {
    switch (props.type) {
      case 'channel':
        return makeChannelFlagOptions();

      case 'classification':
        return makeClassificationOptions();

      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'ageGroup':
      case 'brand':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'countryOfOrigin':
      case 'divisionCode':
      case 'gender':
      case 'groupCode':
      case 'lengthCode':
      case 'materialsContent':
      case 'manufacturer':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
      case 'widthCode':
        return makeCommonStyleVariationOptions();

      case 'label':
        return makeLabelOptions();

      case 'operationStatus':
        return makeCommonCodeDescriptionOptions();

      case 'productClassifications':
        return makeProductClassificationOptions();
    };
  };

  const onSelectionBlur = () => {
    if (isEditable && isValueChanged()) {
      saveValues();
    } else if (props.editOnly) {
      if (props.attr) {
        props.attr.editValue = currentValue;
        props.attr.valueChanged = true;

        if (typeof props.refreshFormState === 'function') {
          props.refreshFormState();
        }
      } else {
        if (typeof props.onSelect === 'function') {
          if (currentValue !== lastValue) {
            props.onSelect(currentValue, props.name, true);
          }
        }
      }
    }
  };

  const onSelectionChange = (values: any) => {
    //console.log('v->', values);
    setCurrentValue(values);

    switch(props.type) {
      //case 'ageGroup':
      //case 'brand':
      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'divisionCode':
      case 'groupCode':
      case 'lengthCode':
      case 'materialsContent':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
      case 'widthCode':
        setCommonCol2Value(values);
        break;

      case 'label': // ???
        if (typeof props.onSelect === 'function') {
          const dl = listValues(values);

          props.onSelect(dl, 'LabelIdList');
        }
        break;

      case 'productClassifications':
        if (typeof props.onSelect === 'function') {
          console.log('v->', values);
          props.onSelect(values);
        }

        break;
    }
  };

  const openTwoColSelectorDialog = () => {
    setTwoColSelectorEditorVisible(true);
  };

  const saveValues = async (value = currentValue) => {
    const attrLabel = props.attr ? props.attr.basicAttributeName : (props.label || props.name || '');

    //console.log('at->', props.attr);
    setIsLoading(true);

    try {
       const basic: StringKAnyVPair = {
        /*sku: props.styleCode,
        styleCode: props.styleCode,*/
      };
      const data: StringKAnyVPair = {
        //ApplyToAllRelevantSubStyleAndSKU: true,
        ApplyToAllRelevantSubStyleAndSKU: props.state?.overwriteChildren || false,
        styleCode: props.styleCode,
        productBasic: basic,
      };

      if (props.name) basic[props.name] = value;

      if (props.attr && props.attr.basicAttributeNum > 999) {
        const { basicAttributeId } = props.attr;

        //console.log('ccc->', currentValue, lastValue);
        data.productBasicInfoAttributeList = [{
          AttributeId: basicAttributeId,
          //Value: currentValue,
          Value: value,
          editType: isLastValueNull() ? 0 : 1,
          //editType: 0,
          //editType: 1,
        }];
        data.productBasic = undefined;
      }

      if (props.category) {
        if (props.category === ATTR_CATEGORY_OPERATION) {
          data.productOperation = basic;
          data.productBasic = undefined;
        }
      }

      setSpecialSaveData(data);

      //console.log('pdata ->', data, props.attr);
      if (props.productId) {
        console.log('pdata ->', data, props.attr);
        const res = await Products.editSimpleStyleMaster(props.productId, data);
        console.log('res ->', res);
        setCurrentValue(value)
        setLastValue(value);
        setIsEditable(false);
        trySetCommonCol2Value(value);
        message.success(`Saved '${attrLabel}' successfully`);
      } else {
        message.info(`Not enough conditions to save '${attrLabel}'`);
      }
    } catch(e) {
      message.error(`Saved '${attrLabel}' failed: ${e}`);
    } finally {
      setIsLoading(false);
    }
    /*setTimeout(() => {
      message.success(`Saved ${attrName || ''} successfully`);
      setIsEditable(false);
      setIsLoading(false);
    }, 1000);*/
  };

  const setCommonCol2Value = (previous: any, ops = options) => {
    //console.log('p ->', previous);
    if (previous) {
      //const sels = options.filter(e => e.code === previous);
      const sels = ops.filter(e => e.code === previous);

      if (sels.length > 0) {
        setCol2Value(sels[0].description);
      } else {
        setCol2Value('');
      }
    } else {
      setCol2Value('');
    }
  };

  const setInitialCol2Value = () => {
    if (!isLastValueNull() && Array.isArray(props.options)) {
      setCommonCol2Value(props.value, props.options);
    }
  };

  const setSpecialSaveData = (data: StringKAnyVPair) => {
    const listValues = () => {
      const ret: StringKAnyVPair[] = [];

      currentValue.forEach((e: string) => {
        ret.push({
          ElementId: e,
          //ProductEditType: lastValue.indexOf(e) > -1 ? 3 : 0,
          editType: Array.isArray(lastValue) && lastValue.indexOf(e) > -1 ? 3 : 0,
        });
      });

      if (Array.isArray(lastValue)) {
        lastValue.forEach((e: string) => {
          if (currentValue.indexOf(e) < 0) {
            ret.push({
              ElementId: e,
              //ProductEditType: 2,
              editType: 2,
            });
          }
        });
      }

      return ret;
    };
    let foundIdList = false;

    switch(props.type) {
      case 'channel':
        data.ChannelControlFlagIdList = listValues();
        foundIdList = true;
        break;

      case 'label':
        data.LabelIdList = listValues();
        foundIdList = true;
        break;
    }

    if (foundIdList) {
      data.productBasic = undefined;
      data.productBasicInfoAttributeList = undefined;
    }
  };

  const sortNodeTitle = (
    a: StringKAnyVPair,
    b: StringKAnyVPair,
  ) => {
    const ta = a.categoryName.toUpperCase();
    const tb = b.categoryName.toUpperCase();

    if (ta > tb) return 1;
    else if (ta < tb) return -1;

    return 0;
  };

  const toggleEditMode = () => {
    switch (props.type) {
      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'divisionCode':
      case 'groupCode':
      case 'materialsContent':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
        openTwoColSelectorDialog();
        return;
    }

    if (isEditable) {
      // use json to compare
      if (currentValue === lastValue) {
        setIsEditable(false);
      } else {
        if (isValueChanged()) {
          saveValues();
        }
      }
    } else {
      setIsEditable(true);

      if (!isDataReady) {
        loadSelectOptions();
      }
    }
  };

  const trySetCommonCol2Value = (val: any, ops = options) => {
    switch (props.type) {
      case 'NRFColorCode':
      case 'NRFSizeCode':
      case 'careInstructions':
      case 'categoryCode':
      case 'classCode':
      case 'colorPatternCode':
      case 'custPackType':
      case 'defaultWarehouse':
      case 'divisionCode':
      case 'groupCode':
      case 'materialsContent':
      case 'model':
      case 'subclassCode':
      case 'subgroupCode':
      case 'sizeCode':
      case 'sizeType':
        setCommonCol2Value(val, ops);
        break;
    }
  };

  React.useEffect(() => {
    if (!inited) {
      loadSelectOptions();
      setInited(true);
    }
  }, [inited, loadSelectOptions]);

  return (<>
    <EditorWrapper className={props.className || ''}>
      <Input.Group compact>
        <span className={commonSelectorClassName()}>
          <span className="selector-row">
            <Select
              allowClear
              className={columnOneClassName()}
              disabled={props.readOnly || !(isEditable || props.editOnly) || !isDataReady}
              onBlur={onSelectionBlur}
              onChange={onSelectionChange}
              mode={props.mode}
              value={currentValue}
              filterOption={(input: string, option: any) => {
                  return ((option.children || option.label || option.value) as string).toLowerCase().startsWith(input.toLowerCase());
              }}
              showSearch
            >
              {makeSelectOptions()}
            </Select>
            {columnCellTwo()}
            {columnCellThree()}
          </span>
        </span>
        {!props.editOnly && attrOpeIcon()}
      </Input.Group>
    </EditorWrapper>
    {twoColSelectorEditorVisible && (
      <TwoColSelectorEditor
        attr={props.attr}
        refreshOptions={props.refreshOptions}
        onClose={closeTwoColSelectorDialog}
        onSave={saveValues}
        openEditor={openTwoColSelectorDialog}
        options={props.options}
        title={props.label}
        value={currentValue}
        visible={twoColSelectorEditorVisible}
      />
    )}
  </>);
};

export default CommonSelectEditor;
