import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import ResetApp from './screens/ResetApp';
import App from './App';
import store, { persistor } from './redux/store';
import authentication, { config } from './auth';

authentication.initialize(config);
setUseWhatChange({ active: process.env.NODE_ENV === 'local' });

const RESET_PATH = '/reset';

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

const renderResetApp = () => {
  ReactDOM.render(<Provider store={store}>
      <ResetApp />
    </Provider>,
    document.getElementById('root'),
  );
};

if (process.env.NODE_ENV === 'testing') {
  renderApp();
} if (RESET_PATH === window.location.pathname) {
  renderResetApp();
} else {
  authentication.run(renderApp);
}
