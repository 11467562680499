import { Col, Row, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowRight from '../../assets/icons/rightArrow';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { fetchCentralEnabled } from '../../services/channels';
import { groupByKey } from '../../util/strings';
import { ChannelIntegrationRoutes } from './index';

const ChannelWrapper = styled.div`
  margin-left: 40px;
  padding: 30px 0px;
  border-bottom: 1px solid #E4E4E4;

  &:last-child  {
    border-bottom: none;
  }
`

const ChannelTitle = styled.h4`
  font-size: 20px;
  margin: 0px 0px 30px 0px;
  color: #2d3f5d;
`

const ChannelCard = styled(Col)`
  position: relative;
  padding: 10px;
  height: 112px;
  width: 170px;
  background-color: #F0F2F5;
  border-radius: 4px;
  transition: background-color 0.2s;

  .name-line {
    color: #2d3f5d;
    font-size: 17px;
    font-weight: 500;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    position: absolute;
    bottom: 4px;
    right: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: #F7D6B8;
  }
`

const ChannelIntegrationSelector: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState<{ [key: string]: Array<Entities.ChannelsIntegration> }>({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchCentralEnabled();
        const newChannels = groupByKey(data, 'category');
        setChannels(newChannels);
      } finally {
        setLoading(false);
      }
    };
    fetchData()
      .catch();
  }, []);

  const ChannelRow = (props: { title: string, channels: Array<Entities.ChannelsIntegration> }) => {
    const { title = '', channels = [] } = props;
    return (
      <ChannelWrapper>
        <ChannelTitle>{title}</ChannelTitle>
        <Row gutter={[20, 20]}>
          {channels.map(channel => <ChannelCol channel={channel} />)}
        </Row>
      </ChannelWrapper>
    )
  }

  const ChannelCol = (props: { channel: Entities.ChannelsIntegration }) => {
    const { channel } = props;
    const { channelName = '', channelNum, category = '' } = channel;
    const url = useMemo(() => {
      const isThirdParty = category === 'ThirdPartyPlatform';

      return generatePath(
        ChannelIntegrationRoutes.add,
        {
          channelNum: isThirdParty ? 0 : channelNum,
          platformNum: isThirdParty ? channelNum : 0,
        },
      );
    }, [channelNum, category]);

    const namelineArr: string[] = [];
    channelName.split(' ').forEach((str, index) => {
      if (index <= 2) namelineArr.push(str);
      if (index > 2) namelineArr[2] = '...';
    })

    return (
      <Col key={`row-${channelNum}`}>
        <Link to={url}>
          <ChannelCard>
            {namelineArr.map(line => (
              <div className="name-line">{line}</div>
            ))}

            <div className="arrow"><ArrowRight /></div>
          </ChannelCard>
        </Link>
      </Col>
    )
  }


  return (
    <ContentLayout>
      <Heading title="Channel Integrations" />
      <Spacer />
      <Spin spinning={loading}>
        <SiteContent style={{ padding: '24px' }}>
          {/* <Row gutter={24} justify="space-between">
            <Channels channels={channels} />
          </Row> */}
          {(Object.keys(channels) || []).map((channelName, index) => (
            <ChannelRow
              key={`$channel-${index}`}
              title={channelName}
              channels={channels[channelName]}
            />
          ))}
        </SiteContent>
      </Spin>
    </ContentLayout>
  );
};

export default ChannelIntegrationSelector;
