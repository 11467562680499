import React from 'react';
import { Space, Switch } from 'antd';

type Props = {
  onChange?: Function;
  overwrite: boolean;
};

const OverwriteOption = (props: Props) => {
  const { overwrite } = props;

  const onChange = (chk: boolean) => {
    if (typeof props.onChange === 'function') {
      props.onChange(chk);
    }
  };

  return (<Space>
    <span style={{marginRight: 4}}>Overwrite children's value</span>
    <Switch
      checkedChildren="On"
      //defaultChecked={overwrite}
      onChange={onChange}
      unCheckedChildren="Off"
      checked={overwrite}
    />
  </Space>);
};

export default OverwriteOption;
