import React from "react";

const Icon = ({ width = "16", height = "17", fill = "#D9D9D9", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M4 14.789a2.667 2.667 0 1 1 1.355-4.964l4.348-4.348a2.663 2.663 0 1 1 .942.943l-4.347 4.347A2.667 2.667 0 0 1 4 14.79Zm0-4a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666Zm8-8a1.333 1.333 0 1 0 1.333 1.393v.267-.327c0-.736-.597-1.333-1.333-1.333Z"
        fill={fill}
      />
    </svg>
  );
};
export default React.memo(Icon);
