import React from "react";

const Icon = ({ width = "16", height = "17", fill = "#D9D9D9", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M12.667 14.122H3.333A1.333 1.333 0 0 1 2 12.789V3.455c0-.736.597-1.333 1.333-1.333h9.334c.736 0 1.333.597 1.333 1.333v9.334c0 .736-.597 1.333-1.333 1.333ZM3.333 3.455v9.334h9.334V3.455H3.333Zm8.667 8H4L6 8.79l.667.666 2-2.666L12 11.455Zm-6.333-4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill={fill}
      />
    </svg>
  );
};
export default React.memo(Icon);
