import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';


function Counter () {
    let [count, setCount] = useState(60 * 5);
    let [duration, setDuration] = useState(moment.duration({
        'minutes': 5,
        'seconds': 0
    }));
    const [min, setMin] = useState(duration.minutes());
    const [sec, setSec] = useState(duration.seconds());

    const useInterval = (callback: any, delay: any) => {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            function tick() {
                // @ts-ignore
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };
    useInterval(() => {
        if(count <= 0) {
            window.location.reload()
        } else {
            setCount(count - 1);
            setDuration(moment.duration(duration.asMilliseconds() - 1000, 'milliseconds'))
            setMin(duration.minutes());
            setSec(duration.seconds())
        }
    }, 1000);


    return (
        <>{`${min}:${sec}`}</>
    )

}

export default Counter;