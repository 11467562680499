import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  Space,
  Spin,
  Statistic,
  Card,
  Select,
} from 'antd';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import SiteContent from '../../components/SiteContent';
import Spacer from '../../components/common/Spacer';
import { ListOrdersParams } from '../../redux/api/orders';
import { getColorByIndex } from '../../util/colors';
import { Chart } from 'react-google-charts';
import { fetchChannelListWithReportCode, fetchERPWeeklySOReports, getDashboardSettingType } from '../../services/channels';
import { isNumber } from 'lodash';

const FormWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`;

const ChartGroup = styled(Row)`
  margin-bottom: 2rem;
`;

const NoData = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #546279;
`;

interface FiltersForm extends ListOrdersParams {
  orderDate?: Moment;
}

interface FiltersProps extends Omit<FormProps, 'onFinish'> {
  onFinish?: (data: ListOrdersParams) => void;
  setFilterDate: Function;
  setFilterChannel: Function;
  channelOpt: any[];
  dashboardSettings: any;
  report: any[];
}

const Filters: React.FC<FiltersProps> = ({ onFinish, channelOpt, dashboardSettings, report, setFilterDate, setFilterChannel, ...formProps }) => {
  const [form] = Form.useForm<FiltersForm>();

  if (dashboardSettings.settingType === 3) form.setFieldsValue({ channelNum: dashboardSettings.channelValue });

  const onOpenChange = (open: any) => {
    if (open) {
      // setDates([]);
      form.setFieldsValue({ orderDate: undefined });
    }
  };

  const onFormFinished = useCallback(
    (values: FiltersForm) => {
      const payload = {
        ...values,
      };

      setFilterDate(payload.orderDate);
      setFilterChannel(payload.channelNum);

      // Transform moment dates to string and remove `orderDate` attribute
      if (values.orderDate) {
        payload.orderDate = values.orderDate;
      }
      // Emit values
      onFinish?.(payload);
    },
     // eslint-disable-next-line
    [onFinish],
  );

  useEffect(() => {
    if (report.length > 0) {
      form.setFieldsValue({ orderDate: moment(report[0].OrderDate) })
    }
  }, [report, form])

  return (
    <FormWrapper>
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formProps}
        form={form}
        layout="inline"
        onFinish={onFormFinished}
      >
        <Form.Item label="Date">
          <Space>
            <Form.Item
              name="orderDate"
              initialValue={moment().add('day', -1)}
              rules={[{ required: true, message: 'You must select date' }]}
              noStyle
            >
              <DatePicker
                onOpenChange={onOpenChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Channel" name="channelNum">
          <Select
            disabled={dashboardSettings.settingType === 3}
            mode="multiple"
            options={channelOpt}
            placeholder="Select Channel"
            style={{ minWidth: '200px', maxWidth: '600px' }}
            allowClear
            dropdownMatchSelectWidth={false}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">
            Apply
            {/* <PlusOutlined /> */}
          </Button>
        </Form.Item>
        {/* Hidden pagination fields */}
        <Form.Item name="$top" initialValue={20000} hidden noStyle>
          <Input disabled />
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

/**
 * Custom hook that takes the API data and transform it to fit the requirements for each chart.
 * @param data
 */
const useTransformData = (data: any[], filter: any, dashboardSettings: any, channels: any[]): [any, any, number, boolean] => {
  const [processing, setProcessing] = useState(false);
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [transformedData1, setTransformedData1] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  const { orderDate, channelNum = [] } = filter;
  const reportDate = moment(orderDate).format('YYYY-MM-DD');

  const processData = useCallback((mounted: boolean, source: any[]) => {
    if (!source || !mounted) {
      return;
    }

    setProcessing(true);

    try {
      const value: any[] = [['Channels', 'Orders']];
      const value1: any[] = [['Channels', 'Orders']];
      const target = data.find(item => item.OrderDate === reportDate);

      const filteredCol = ['LineNumber', 'OrderDate', 'TotalQty'];
      if (dashboardSettings.settingType === 2) filteredCol.push('DropShip');

      const includedCol = channels.filter((channel: any) => channelNum.includes(channel.channelAccountNum)).map(channel => channel.channelAccountName);

      if (target) {
        let { TotalQty } = target;
        if (dashboardSettings.settingType === 2) TotalQty -= target.DropShip;

        // eslint-disable-next-line
        for (const key in target) {
          // eslint-disable-next-line
          if (filteredCol.includes(key)) continue;
          if (includedCol.length > 0 && !includedCol.includes(key)) {
            TotalQty -= target[key];
            // eslint-disable-next-line
            continue;
          }
          const amount = ((target[key] / TotalQty) * 100).toFixed(1);
          value.push([
            `${key} - ${amount}%`,
            target[key],
          ]);
          value1.push([
            key,
            target[key],
          ]);
        }
        setTransformedData(value.sort((a, b) => {
          if (!isNumber(b[1])) return 0;
          else return b[1] - a[1];
        }));
        setTransformedData1(value1.sort((a, b) => {
          if (!isNumber(b[1])) return 0;
          else return b[1] - a[1];
        }));
        setCount(TotalQty);
      }

    } catch (error) {
      console.log('error = ', error);
    }

    setProcessing(false);
  },
    // eslint-disable-next-line 
    [filter, data, dashboardSettings, channels],
  );

  useEffect(() => {
    let mounted = true;
    const destroy = () => {
      mounted = false;
    };

    if (data) {
      processData(mounted, data);
    }

    return destroy;
  }, [data, processData, filter, dashboardSettings]);

  return [transformedData, transformedData1, count, processing];
};

type SummaryChartProps = {
  setFilterDate: Function;
  setFilterChannel: Function;
}

const SummaryChart = (props: SummaryChartProps) => {
  const [filters, setFilters] = useState<any>({
    orderDate: moment().add('day', -1),
    $top: 20000,
  });
  // const { data, isFetching } = useListOrdersQuery(filters);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [report, setReport] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [channelOpt, setChannelOpt] = useState<any[]>([]);
  const [dashboardSettings, setDashboardSettings] = useState<any>({ settingType: 1, channelValue: [] });
  const [transformedData, transformedData1, count, processing] = useTransformData(report, filters, dashboardSettings, channels);

  const getERPWeeklyReport = async () => {
    setIsFetching(true);
    try {
      const res = await fetchERPWeeklySOReports();
      const columns: any[] = [];
      if (Array.isArray(res)) {
        res.forEach((itm, index) => {

          // For chart, use the data from latest day, just in case the report didn’t run succefully
          if (index === 0) setFilters({ ...filters, orderDate: moment(itm.OrderDate) });

          for (const key in itm) {
            if (key === 'LineNumber') continue;
            const idx = columns.findIndex(col => col.header === key);
            // columns doest exist
            if (idx === -1) {
              columns.push({
                name: key,
                header: key,
                sortable: true,
                minWidth: 100,
              })
            }
          }
        })
        const dateIdx = columns.findIndex(col => col.header === 'OrderDate');
        const dateCol = {
          ...columns[dateIdx],
          header: 'Date',
          minWidth: 100,
          render: (row: { data: any; }) => {
            const { data } = row;
            const today = moment(data.OrderDate);
            const week = today.format('dddd').substring(0, 3);
            return `${today.format('YYYY/MM/DD')} ${week}`
          }
        }
        columns.splice(dateIdx, 1);
        columns.unshift(dateCol);
        setReport(res);
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setIsFetching(false);
    }
  };

  const getSettings = async () => {
    setIsFetching(true);
    try {
      const res = await getDashboardSettingType();
      const { settingValue = '' } = res;
      if (settingValue) {
        const { SettingType: settingType = 1, ChannelDropShipItems: channelDropShipItems = [] } = JSON.parse(settingValue);
        const channelValue = channelDropShipItems.map((item: any) => item.ChannelAccountNum);

        setDashboardSettings({ settingType, channelValue });
        setFilters({ ...filters, channelNum: channelValue });
        getERPWeeklyReport();
      } else {
        getERPWeeklyReport();
      }
    } catch (error) {
      console.log('error = ', error);
    }
  };

  const getChannelList = async () => {
    // eslint-disable-next-line
    try {
      const channels = await fetchChannelListWithReportCode();
      if (Array.isArray(channels)) {
        const temp = channels.map(channel => {
          const { channelAccountName, channelAccountNum } = channel;
          return { label: channelAccountName, value: channelAccountNum };
        });
        setChannels(channels);
        setChannelOpt(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettings();
    getChannelList();
    getERPWeeklyReport();
    // eslint-disable-next-line 
  }, [])

  return (
    <>
      {channelOpt && (
        <Filters
          report={report}
          onFinish={setFilters}
          channelOpt={channelOpt}
          dashboardSettings={dashboardSettings}
          setFilterDate={props.setFilterDate}
          setFilterChannel={props.setFilterChannel}
        />
      )}
      <Spacer />
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={8}>
          <Card size="small" style={{ borderTopColor: getColorByIndex(7, 2), borderTopWidth: 3 }}>
            <Statistic
              title="Total Orders"
              value={count}
              precision={0}
              valueStyle={{ color: getColorByIndex(7, 2) }}
            />
          </Card>
        </Col>
      </Row>
      <Spacer />
      <SiteContent flexGrow>
        <Spin spinning={isFetching || processing}>
          <ChartGroup justify="space-between" gutter={24}>
            <Col span={10}>
              {transformedData.length > 1 ? (
                <Chart
                  chartType="PieChart"
                  data={transformedData}
                  options={{
                    title: 'Orders by Channel',
                    titleTextStyle: { fontSize: 14 },
                    is3D: true,
                    legend: { position: 'left' },
                  }}
                  width="100%"
                  height="500px"
                />
              ) : (
                <NoData visible>No information available</NoData>
              )}
            </Col>
            <Col span={14}>
              {transformedData.length > 1 ? (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="500px"
                  data={transformedData1}
                  options={{
                    title: 'Orders and Shipments by Channel',
                    hAxis: {
                      title: 'Count',
                      minValue: 0,
                    },
                    vAxis: {
                      title: 'Channels',
                    },
                    bars: 'horizontal',
                    axes: {
                      y: {
                        0: { side: 'right' },
                      },
                    },
                    // legend: { position: "right" },
                  }}
                />
              ) : (
                <NoData visible>No information available</NoData>
              )}
            </Col>
          </ChartGroup>
        </Spin>
      </SiteContent>
    </>
  );
};

export default SummaryChart;
