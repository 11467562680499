import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  //Col,
  Input,
  Modal,
  Row,
  Steps,
  notification,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ContentLayout from '../../components/ContentLayout';
import Heading from '../../components/common/Heading';
import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
import SiteContent from '../../components/SiteContent';
import Spacer from '../../components/common/Spacer';
import { FormLabel } from '../../components/common/styledComponents';
import message from '../../components/common/message';
import { InfoButton } from '../../components/common/styledComponents';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, LOADING_ICON_SIZE1 } from '../../constants/config';
import Products from '../../services/products';
import { randomString } from '../../util';
import AddSettingSelector from './AddSettingSelector';
import ClassSelector from './ClassSelector';
import StyleCodeGrid from './StyleCodeGrid';
import StyleCodeSelector, { StyleCodeDict } from './StyleCodeSelector';

const EditorWrapper = styled.div`
  & .ant-select-selection-item .code-desc {
    /*display: none;*/
  }

  & .editor-ctn {
    position: relative;
    height: calc(100vh - 238px);
    margin-top: 16px;
  }

  & .step-wrapper {
    height: 100%;
  }

  & .style-info-form {
    max-width: 800px;
    width: 65%;
  }

  & .style-info-form .InovuaReactDataGrid {
    height: 100%;
  }

  & .style-info-form.code-selector {
    max-width: 900px;
    width: 75%;
  }

  & .style-info-form.grid-ctn {
    height: calc(100% - 32px);
    max-width: 1000px;
    width: 80%;
  }

  & .style-code-form-row,
  & .style-info-form-row {
    display: flex;
  }

  & .style-info-form-row .class-selector,
    .style-info-form-row .form-input-grid {
    width: calc(100% - 166px);
  }

  & .style-info-form-row .form-input-grid {
    max-width: calc(100% - 176px);
  }

  & .style-code-form-row.middle,
  & .style-info-form-row.middle {
    align-items: center;
  }

  & .style-code-form-row .ant-input,
    .style-code-form-row .ant-select,
    .style-code-form-row .ctn-selector,
    .style-code-form-row .style-format {
    color: #76972F;
    width: calc(100% - 170px);
  }

  & .style-code-form-row .ant-select-selection-item-content,
  & .style-code-form-row .ant-select-selection-item {
    text-align: left;
  };

  & .style-code-form-row .style-format {
    font-weight: 500;
    text-align: left;
  }

  .style-code-form-row .form-label,
  .style-info-form-row .form-label {
    display: inline-block;
    margin-right: 8px;
    text-align: right;
    width: 166px;
  }

  .style-code-form-row .form-label {
    width: 160px;
  }

  & .style-step-form-wrapper {
    height: calc(100% - 32px);
  }
`;

const SuccessfulText = styled.span`
  font-size: 16px;
  font-weight: 550;
`;

const SuccessfulTitle = styled.span`
  color: #76972F;
  font-size: 1.3em;
`;

enum AttrType {
  StyleCode = 'styleCode',
};

const StyleMasterEditor = () => {
  const { useState } = React;
  const { TextArea } = Input;
  const { Step } = Steps;
  const { type: itemType } = useParams<{type: string}>();
  const [codeSelectDone, setCodeSelectDone] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [createResult, setCreateResult] = useState<StringKAnyVPair>({});
  const [endDialogVisible, setEndDialogVisible] = useState(false);
  const [existProducts, setExistProducts] = useState<StringKAnyVPair[]>([]);
  const [existSubStyleCodes, setExistSubStyleCodes] = useState<StringKAnyVPair[]>([]);
  const [generateBtnLocked, setGenerateBtnLocked] = useState(false);
  const [inited, setInited] = useState(false);
  const [inputProductCode, setInputProductCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isStyleCodeCheckExist, setIsStyleCodeCheckExist] = useState<boolean>();
  const [lastCheckedStyleCode, setLastCheckedStyleCode] = useState('');
  const [styleClass, setStyleClass] = useState<string>();
  const [classInfo, setClassInfo] = useState<any>();
  const [styleCode, setStyleCode] = useState('');
  const [styleCodeDesc, setStyleCodeDesc] = useState('');
  const [styleCodeDict, setStyleCodeDict] = useState<StyleCodeDict>({
    colorPatternList: [],
    colorPatternDescList: [],
    lengthCodeList: [],
    lengthDescList: [],
    sizeCodeList: [],
    sizeDescList: [],
    widthCodeList: [],
    widthDescList: [],
  });
  const [styleData, setStyleData] = useState<StringKAnyVPair[]>([]);
  const [styleOpt, setStyleOpt] = useState<StringKAnyVPair>();
  const [styleSettingOptions, setStyleSettingOptions] = useState<StringKAnyVPair[]>([]);
  const [subStyleCode, setSubStyleCode] = useState<string>();
  const history = useHistory();

  const addNewStyle = () => {
    setCurrentStep(0);
    closeEndDialog();
    clearStyleState();
    /*setCreateResult({});
    setCodeSelectDone(false);
    setGenerateBtnLocked(false);
    setStyleCode('');
    setStyleCodeDesc('');
    setStyleCodeDict({
      colorPatternList: [],
      colorPatternDescList: [],
      lengthCodeList: [],
      lengthDescList: [],
      sizeCodeList: [],
      sizeDescList: [],
      widthCodeList: [],
      widthDescList: [],
    });
    setStyleData([]);*/
  };

  const clearStyleState = () => {
    setCreateResult({});
    setCodeSelectDone(false);
    setExistProducts([]);
    setExistSubStyleCodes([]);
    setGenerateBtnLocked(false);
    setInputProductCode('');
    setLastCheckedStyleCode('');
    setStyleCode('');
    setStyleCodeDesc('');
    setStyleCodeDict({
      colorPatternList: [],
      colorPatternDescList: [],
      lengthCodeList: [],
      lengthDescList: [],
      sizeCodeList: [],
      sizeDescList: [],
      widthCodeList: [],
      widthDescList: [],
    });
    setStyleData([]);
    setSubStyleCode('');
  };

  const checkStyleCode = async (code: string) => {
    setIsLoading(true);

    try {
      const {
        addSettingNum = 0,
        classCode,
        productName,
        styleCode: rawStyleCode,
        subStyleCodes,
      } = await Products.getStyleInfoByCode(code);

      if (/*classCode !== undefined &&*/ rawStyleCode) {
        // maybe need to filter substyle codes by class name
        setIsStyleCodeCheckExist(true);

        if (['product', 'sub'].indexOf(itemType) > -1) {
          setStyleClass(classCode);
          setStyleCode(rawStyleCode);
          setStyleCodeDesc(productName || '');
          console.log(subStyleCodes);
          setExistSubStyleCodes(Array.isArray(subStyleCodes) ? subStyleCodes : []);

          if (itemType === 'sub') {
            const ass = styleSettingOptions.filter(e => e.SettingNum === addSettingNum);

            console.log('asn->', addSettingNum);
            if (ass.length > 0) {
              setStyleOpt(ass[0]);
            }
          }
        } else {
          notification.error({
            message: `Style code '${code}' already exists`,
            duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
          });
        }
      } else {
        setIsStyleCodeCheckExist(false);
      }
    } catch(e) {
      //maybe it should be undefined in future //setIsStyleCodeCheckExist(undefined);
      console.log(`Get style code error: ${e}`);
      setIsStyleCodeCheckExist(false);
      setStyleClass(undefined);
      //setStyleCode('');
      if (!isStyle()) {
        notification.error({
          message: `Style code '${code}' does not exist`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const closeEndDialog = () => {
    setIsLoading(false);
    setEndDialogVisible(false);
  };

  const createStyleItem = async (data: StringKAnyVPair) => {
    switch (itemType) {
      case 'style':
        return await Products.createStyleMaster2(data);

      case 'sub':
        return await Products.createSubStyle(styleCode, data);

      case 'product':
        return await Products.createSimpleProduct(subStyleCode || '', data);
    }
  };

  const deleteStyleDataRow = (row: StringKAnyVPair) => {
    // console.log('r->', row);
    for (let i = styleData.length - 1; i >= 0; i--) {
      if (styleData[i].sku === row.sku) {
        styleData.splice(i, 1);
        break;
      }
    }
    setStyleData([...styleData]);
  };

  const editStyleContent = () => {
    if (createResult) {
      // console.log('cc', createResult.productId);
      history.push(`/product-detail/${createResult.productId}`);
    }
  };

  const fetchProductSetting = async () => {
    let ret: any = null;

    setIsLoading(true);

    try {
      const settings = await Products.fetchProductCreationSetting();

      //console.log('is settings', settings);
      if (Array.isArray(settings)) {
        const actives = settings.filter(e => e.IsDefault);
        //const actives = settings.filter(e => e.SettingNum === 2);

        //console.log('act ', actives);
        setStyleSettingOptions(settings);

        if (Array.isArray(actives)) {
          ret = actives[0];
          setStyleOpt(ret);
        }
        //console.log('act -->', actives);
      }
    } catch(e) {
      notification.error({
        message: `Fetch product creation setting error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }

    return ret;
  };

  const forwardEditGrid = () => {
    if (isSubStyleCodeStep()) {
      const { colorPatternList } = styleCodeDict;
      let existCPs: string[] = [];

      if (colorPatternList.length > 0 && existSubStyleCodes.length > 0) {
        console.log('sub step', styleOpt?.GenerateRuleValue, colorPatternList);
        colorPatternList.forEach(code => {
          let cpCode = code;

          if (styleOpt && styleOpt.GenerateRuleValue === 1) {
            cpCode = trimCodeLiteral(cpCode);
          }

          const fs = existSubStyleCodes.filter(f => f.colorPatternCode === cpCode);

          if (fs.length > 0) existCPs.push(code);
        });
      }

      if (existCPs.length > 0) {
        //console.log('ee', existCPs);
        Modal.error({
          title: 'Color/Pattern code exists',
          icon: <ExclamationCircleOutlined />,
          //content: `'${existCPs.join(', ')}' already existed`,
          content: `Sub-Style Code already exists: '${existCPs.join(', ')}'`,
          centered: true,
        });

        return;
      }
    }

    if (isStyleCodeStep()) {
      if (styleOpt && Array.isArray(styleOpt.ProductPatternElements)) {
        //console.log('els', styleOpt.ProductPatternElements, styleCodeDict);
        const dl = makeStyleData();

        if (dl) {
          const edl = dl.map(e => e.sku);
          const existPs: string[] = [];
          //console.log('dl -->', dl, edl, existProducts);
          existProducts.forEach((p: StringKAnyVPair) => { if (edl.indexOf(p.sku) > -1) existPs.push(p.sku); });

          //console.log('ee', existPs, edl);
          if (existPs.length > 0) {
            Modal.error({
              title: 'Product code exists',
              icon: <ExclamationCircleOutlined />,
              content: `Product Code already exists: '${existPs.join(', ')}'`,
              centered: true,
            });

            return;
          }
        }
      }
    }

    if (isNoCodeMode()) {
      setStyleData([
        { sku: inputProductCode, subStyle: subStyleCode, /*uniqueId: randomString(),*/ },
      ]);
    } else {
      makeStyleData();
    }

    nextStep();
  };

  const generateStyleMaster = async () => {
    setIsLoading(true);
    setGenerateBtnLocked(true);

    try {
      const { GenerateSubStyleCode } = styleOpt || {};
      const styleDict: StringKAnyVPair = {};
      styleData.forEach(e => {
        const k = e.subStyle || '-';

        if (!styleDict[k]) {
          styleDict[k] = {
            SubStyleCode: GenerateSubStyleCode === 1 ? e.subStyle : undefined,
            ProductList: [],
          };
        }

        styleDict[k].ProductList.push({
          SKU: e.sku,
          ERPCode: {
            ColorPatternCode: e.ColorPatternCode,
            ColorPatternCodeId: e.ColorPatternCode === e.ColorPatternCodeId ? undefined : e.ColorPatternCodeId,
            SizeCode: e.SizeCode,
            SizeCodeId: e.SizeCodeId,
            LengthCode: e.LengthCode,
            LengthCodeId: e.LengthCodeId,
            WidthCode: e.WidthCode,
            WidthCodeId: e.WidthCodeId,
          },
        });
      });
      const styleList: StringKAnyVPair[] = [];
      for (const k in styleDict) {
        styleList.push(styleDict[k]);
      }
      console.log('sd-->', styleData, styleList);
      const data = {
        AddSetting: styleOpt,
        ProductType: getStyleTypeNum(),
        StyleCode: styleCode,
        ClassCode: styleClass || '',
        ItemPatternArr: styleOpt ? styleOpt.ProductPatternElements : [],
        ProductBasicInfoAttributeList: [
          {
            AttributeId: '301-126',
            Value: styleCodeDesc.trim(),
          },
        ],
        ProductPattern: styleOpt ? styleOpt.ProductPattern : null,
        SubStyleCodePattern: styleOpt ? styleOpt.SubStyleCodePattern : null,
        StyleMasterItemList: styleList,
      };
      //console.log('d->', data, styleData);
      //const res = await Products.createStyleMaster2(data);
      const res = await createStyleItem(data);

      if (res && typeof res === 'object') {
        // console.log('res->', res);
        setCreateResult(res);
      }

      openEndDialog();
    } catch(e) {
      message.error(`Create Style Master error: ${e}`);
      setGenerateBtnLocked(false);
      // openEndDialog();
    } finally {
      setIsLoading(false);
    }
  };

  const getStyleTypeNum = () => {
    let ret = 0;

    switch (itemType) {
      case 'style':
        ret = 2;
        break;

      case 'sub':
        ret = 3;
        break;

      case 'product':
        ret = 1;
        break;
    }

    return ret;
  };

  const getStyleTypeTitle = () => {
    let ret = '';

    switch (itemType) {
      case 'style':
        ret = 'Style Master';
        break;

      case 'sub':
        ret = 'Sub Style';
        break;

      case 'product':
        ret = 'Product';
        break;
    }

    return ret;
  };

  const isFirstStepReady = () => {
    let styleCodeOk = false;

    if (typeof isStyleCodeCheckExist === 'boolean') {
      styleCodeOk = itemType === 'style' ? !isStyleCodeCheckExist : isStyleCodeCheckExist;
    }

    styleCodeOk = !!styleCode.trim() && styleCodeOk;

    if (!styleCodeOk && styleOpt) {
      styleCodeOk = styleOpt.GenerateStyleCode === 0 && styleOpt.GenerateSubStyleCode === 0;
    }

    return styleCodeOk;
  };

  const isNeedStyleCode = () => {
    return styleOpt && styleOpt.GenerateStyleCode > 0;
  };

  const isNeedSubStyleCode = () => {
    return !!(styleOpt && styleOpt.GenerateSubStyleCode);
  };

  const isNoCodeMode = () => {
    return !!(styleOpt && styleOpt.ProductPatternValue === 0);
  };

  const isOnlyInputCodeMode = () => {
    //return !!(styleOpt && styleOpt.GenerateStyleCode === 0 && styleOpt.GenerateSubStyleCode === 0);
    return !!(styleOpt && ((styleOpt.GenerateStyleCode === 0 && styleOpt.GenerateSubStyleCode === 0)
      || styleOpt.ProductPatternValue === 0)
    );
  };

  const isProduct = () => {
    return itemType === 'product';
  };

  const isProductPatternValue = () => {
    return !!(styleOpt && styleOpt.ProductPatternValue === 1);
  };
  const isProductOnlyNoPattern = () => {
    return (styleOpt && styleOpt.SettingNum === 3);
  };

  const isStyle = () => {
    return itemType === 'style';
  };

  const isStyleCodesReady = (
    patternType: string,
    codeDict = styleCodeDict,
  ) => {
    let ret = false;

    if (styleOpt) {
      const dict: StringKAnyVPair = {
        'StyleCode': [ styleCode ],
        'ColorPatternCode': codeDict.colorPatternList,
        'SizeCode': codeDict.sizeCodeList,
        'LengthCode' : codeDict.lengthCodeList,
        'WidtCode': codeDict.widthCodeList,
      };
      const pes = styleOpt[patternType];

      if (Array.isArray(pes)) {
        const pks = [ ...pes ];

        if (isProduct() && patternType === 'ProductPatternElements') {

          const sps = styleOpt['SubStyleCodePatternElements'];

          if (Array.isArray(sps)) {
            for (let i = pks.length; i > 0; i--) {
              if (sps.indexOf(pks[i - 1]) > -1) {
                pks.splice(i - 1, 1);
              }
            }
            //console.log('pks', pes, pks, sps, styleOpt);
          }
          console.log('sps', sps);
        }
        console.log('pks', pks, ' :> ', dict);
        //ret = styleOpt.ProductPatternElements.length > 0
        ret = pks.length > 0 && pks.every((k: string) => Array.isArray(dict[k]) && dict[k].length > 0);
      }
    }

    //console.log('ret->', ret);
    return ret;
  };

  const isStyleCodeStep = () => {
    let ret = isNeedStyleCode() && (isNeedSubStyleCode() ?
        currentStep === 2 :
        currentStep === 1);

    ret = ret || (currentStep === 1 && !!styleOpt && styleOpt.GenerateStyleCode === 0 && styleOpt.GenerateSubStyleCode === 0);

    return ret;
  };

  const isStyleGridEditStep = () => {
    return (isNeedSubStyleCode() && isNeedStyleCode()) ?
      (currentStep === 3 && !isNoCodeMode()) : (
        isNeedStyleCode() ? (
          currentStep === 2
        ) : false
      );
  };

  const isStyleGridGenerateStep = () => {
    let ret = (isNeedSubStyleCode() && isNeedStyleCode()) ?
      (currentStep === 4) : (
        isNeedStyleCode() ? (
          currentStep === 3
        ) : false
      );

    ret = ret || ([2, 3].indexOf(currentStep) > -1 && isNoCodeMode());

    return ret;
  };

  const isStyleGridStep = () => {
    let ret = (isNeedSubStyleCode() && isNeedStyleCode()) ?
      (currentStep === 3 || currentStep === 4) : (
        isNeedStyleCode() ? (
          currentStep === 2 || currentStep === 3
        ) : false
      );

    ret = ret || ([2, 3].indexOf(currentStep) > -1 && !!styleOpt && styleOpt.GenerateStyleCode === 0 && styleOpt.GenerateSubStyleCode === 0);

    return ret;
  };

  const isSubStyleCodeStep = () => {
    return isNeedSubStyleCode() && currentStep === 1;
  };

  const isSubStyle = () => {
    return itemType === 'sub';
  };

  const makeStyleCodeList = (
    pats: string[],
    codePatDict: {[key: string]: string[]},
    patterns: string[],
    subPatterns: string[],
    codeList: StringKAnyVPair[],
  ) => {
    const len = codeList.length;
    let gc = 1;
  
    console.log('m->', pats, codePatDict, codeList);
    pats.forEach(k => {
      const cks = Array.isArray(codePatDict[k]) && codePatDict[k].length > 0 ? codePatDict[k] : ['?'];
      const ids = codePatDict[`${k}Id`] || [];
      const gn = len / (gc * cks.length); // group number
  
      if (isProduct() && k === 'ColorPatternCode' && subStyleCode) {
        if (Array.isArray(cks) && (cks.length === 0 || cks[0] === '?')) {
          cks[0] = splitColorPatternBySubStyleCode(subStyleCode);
        }
      }
  
      //console.log('cks', k, cks, gn)
      gc = gc * cks.length;
  
      for (let g = 0; g < gc; g++) {
        for (let gi = 0; gi < gn; gi++) {
          codeList[g * gn + gi][k] = cks[g % cks.length];
  
          if (typeof ids[g % cks.length] !== 'undefined') {
            codeList[g * gn + gi][`${k}Id`] = ids[g % cks.length];
          }
        }
      }
    });
  
    codeList.forEach(row => {
      const subCodes: string[] = [];
      const skuCodes: string[] = [];
  
      subPatterns.forEach(k => {
        subCodes.push(row[k] || '?');
      });
      patterns.forEach(k => {
        skuCodes.push(row[k] || '?');
      });
      row.sku = skuCodes.join('-');
      row.subStyle = subCodes.join('-');
      //row.subStyle = isProduct() && subStyleCode ? subStyleCode : subCodes.join('-');
    });
    //console.log('ooo->', styleOpt);
    if (styleOpt && styleOpt.GenerateRuleValue === 1) {
      codeList.forEach(row => {
        for (let k in row) {
          if (typeof row[k] === 'string') {
            //row[k] = row[k].replace(/\s|,/g, '');
            //row[k] = row[k].replace(/\s/g, '');
            row[k] = trimCodeLiteral(row[k]);
          }
        }
      });
    }
  };

  const makeStyleData = () => {
    const {
      lengthCodeList,
      sizeCodeList,
      widthCodeList,
    } = styleCodeDict;
    let {
      colorPatternList,
      // colorPatternDescList: colorPatternIdList,
      // lengthDescList: lengthIdList,
      // sizeDescList: sizeIdList,
      // widthDescList: widthIdList,
    } = styleCodeDict;
    const {
      ProductPatternElements = [],
      ProductPattern,
      // SubStyleCodePatternElements = [],
      SubStyleCodePattern,
    } = styleOpt || {};

    if ((!colorPatternList || colorPatternList.length === 0) && subStyleCode) {
      const colorPatternCode = splitColorPatternBySubStyleCode(subStyleCode);
      if (colorPatternCode) {
        colorPatternList = [colorPatternCode];
      }
    }

    // map key from Product Pattern Elements to map of chosen keys
    const patternKeys: Record<string, any[]> = {
      StyleCode: [styleCode],
      ColorPatternCode: colorPatternList,
      SizeCode: sizeCodeList,
      LengthCode: lengthCodeList,
      WidthCode: widthCodeList,
    };

    // a list of combinatinos of ProductPatternElements
    let combinations: any[][] = [];
    // create map for type to index in combinations item to
    const comboMap: any = {};
    const comboMapKeyToidx: any = {};

    (ProductPatternElements as string[]).forEach((patternKey, idx) => {
      comboMap[idx] = patternKey;
      comboMapKeyToidx[patternKey] = idx;
      if (patternKeys[patternKey]) {
        if (idx === 0) {
          // replace initial
          combinations.push(patternKeys[patternKey]);
        } else {
          // create another combo for every new pattern key
          combinations = combinations.flatMap((currentCombo) => {
            return patternKeys[patternKey].map((keys) => [...currentCombo, keys]);
          });
        }
      }
    });

    const styleGridData = combinations.map((combo) => {
      let sku: string = ProductPattern;
      let subStyle: string = SubStyleCodePattern;
      combo.forEach((code, idx) => {
        const key = comboMap[idx];
        if (key) {
          sku = sku.replaceAll(`{${(ProductPatternElements as string[])[idx]}}`, code);
          subStyle = subStyle.replaceAll(`{${(ProductPatternElements as string[])[idx]}}`, code);
        }
      });
      return {
        sku,
        subStyle,
        SizeCode: combo[comboMapKeyToidx.SizeCode],
        LengthCode: combo[comboMapKeyToidx.LengthCode],
        WidthCode: combo[comboMapKeyToidx.WidthCode],
        uniqueId: randomString(),
        ColorPatternCode: combo[comboMapKeyToidx.ColorPatternCode],
        // ColorPatternCodeId (backend don't need these id since these are frontend generated currently)
        // SizeCodeId
        // LengthCodeId
        // WidthCodeId
      };
    });

    setStyleData(styleGridData);
    return styleGridData;
  };

  const makeStyleData2 = () => { // eslint-disable-line @typescript-eslint/no-unused-vars
    const {
      colorPatternList,
      colorPatternDescList: colorPatternIdList,
      lengthCodeList,
      lengthDescList: lengthIdList,
      sizeCodeList,
      sizeDescList: sizeIdList,
      widthCodeList,
      widthDescList: widthIdList,
    } = styleCodeDict;
    const {
      ProductPatternElements: patterns = [],
      SubStyleCodePatternElements: subPatterns = [],
    } = styleOpt || {};
    const codeListDict: StringKAnyVPair = {
      'ColorPatternCode': colorPatternList,
      'ColorPatternCodeId': colorPatternIdList,
      'LengthCode': lengthCodeList,
      'LengthCodeId': lengthIdList,
      'SizeCode': sizeCodeList,
      'SizeCodeId': sizeIdList,
      'WidthCode': widthCodeList,
      'WidthCodeId': widthIdList,
    };

    if (patterns.length > 0) {
      let arrEles = patterns.filter((e: string) => e !== 'styleCode')
        .reduce((a: number, b: string) => {
          const codes = codeListDict[b];
          let codeLen = 0;

          if (codes && Array.isArray(codes)) {
            codeLen = codes.length;
          }

          return a === 0 ? codeLen : a * codeLen;
        }, 0);

      if (patterns.indexOf(AttrType.StyleCode) > -1) {
        if (arrEles === 0 && styleCode) arrEles = 1;
      }

      console.log('-- -->', arrEles);
      if (arrEles > 0) {
        const generateRow = () => {
          const co: StringKAnyVPair = { sku: '', subStyle: '', uniqueId: randomString() };

          patterns.forEach((e: string) => co[e] = e === 'StyleCode' ? styleCode : '');

          return co;
        };
        const codeList = Array(arrEles).fill(null)
            .map(e => generateRow());
        const pats: string[] = [ ...patterns ];
        const codePatDict: {[key: string]: string[]} = {};

        subPatterns.forEach((e: string) => {
          if (pats.indexOf(e) < 0) {
            console.log(`sub pattern ${e} not in product attrs`);
          }
        });
        pats.forEach(e => {
          const codeId = `${e}Id`;
          let codes: string[] = codeListDict[e];

          if (e === 'StyleCode') {
            codes = [ styleCode ];
          } else if (!Array.isArray(codes)) {
            codes = ['?'];
          }

          codePatDict[e] = codes;

          if (Array.isArray(codeListDict[codeId])) {
            codePatDict[codeId] = codeListDict[codeId];
          }
        });
        console.log('pt', patterns);
        console.log('cpl', codePatDict, pats);
        makeStyleCodeList(pats, codePatDict, patterns, subPatterns, codeList);
        console.log('cl', codeList, styleCodeDict);
        setStyleData(codeList);

        return codeList;
      }
    }

    console.log('styleOpt->', styleOpt);
  };

  const nextStep = async () => {
    if (currentStep === 0) {
      setStyleCode(styleCode.trim());

      if (styleOpt) {
        setCurrentStep(currentStep + 1);
      } else {
        const settings = await fetchProductSetting();

        if (settings) {
          setCurrentStep(currentStep + 1);
        }
      }

      if (isNeedSubStyleCode()) {
        if (!isProductPatternValue()) {
          setCodeSelectDone(true);
          console.log('need sub style with no pattern');
        }
      }
    } else {
      setCurrentStep(currentStep + 1);

      if (isSubStyleCodeStep()) {
        if (isProductPatternValue()) {
          setCodeSelectDone(isStyleCodesReady('ProductPatternElements'));
        } else {
          setCodeSelectDone(!!inputProductCode);
        }
      }
    }
  };

  const onChangeAddSetting = (setting: StringKAnyVPair) => {
    console.log('as', setting);
    setStyleOpt(setting);
    clearStyleState();
  };

  const onCodeSelectDone = (dict: StyleCodeDict) => {
    /*const done = dict.colorPatternList.length > 0
      && dict.colorPatternDescList.length > 0
      && dict.lengthCodeList.length > 0
      && dict.lengthDescList.length > 0
      && dict.widthCodeList.length > 0
      && dict.widthDescList.length > 0
      && dict.sizeCodeList.length > 0
      && dict.sizeDescList.length > 0;*/

    console.log('select done', dict, isSubStyleCodeStep());
    if (isSubStyleCodeStep()) {
      setCodeSelectDone(isProduct() ? !!subStyleCode : dict.colorPatternList.length > 0);
    } else {
      //setCodeSelectDone(done);
      setCodeSelectDone(isStyleCodesReady('ProductPatternElements', dict));
    }

    setStyleCodeDict(dict);
  };

  const onEditStyleCode = (evt: any) => {
    setStyleCode(evt.target.value);
  };

  const onEditStyleCodeDesc = (evt: any) => {
    setStyleCodeDesc(evt.target.value);
  };

  const onInputProduct = (pCode: string) => {
    const cl: StringKAnyVPair[] = [];

    setInputProductCode(pCode);
    setCodeSelectDone(!!pCode);

    if (pCode) {
      cl.push({
        sku: pCode,
      });
    }

    setStyleData(cl);
  };

  const onStyleClassChange = (styleClassInfo: any) => {
    const {
      value: val,
      ...classInfo
    } = styleClassInfo;
    //console.log('set style class', val);
    setStyleClass(val);
    // format class info
    if (classInfo) {
      classInfo.colors = classInfo.colors?.length ? (classInfo.colors as string).split(',') : [];
      classInfo.sizes = classInfo.sizes?.length ? (classInfo.sizes as string).split(',') : [];
      classInfo.widths = classInfo.widths?.length ? (classInfo.widths as string).split(',') : [];
      classInfo.lengths = classInfo.lengths?.length ? (classInfo.lengths as string).split(',') : [];

      setClassInfo(classInfo);
    }
  };

  const onStyleCodeInputBlur = (evt: any) => {
    const code = evt.target.value.trim();

    if (!isLoading && code !== lastCheckedStyleCode) {
      checkStyleCode(code);
      setLastCheckedStyleCode(code);
    }
  };

  const onStyleCodeInputKeyDown = (evt: any) => {
    if (evt.keyCode === 13) {
      const input = evt.target;
      const code = input.value.trim();

      //console.log('sc', styleCode);
      if (code && code !== lastCheckedStyleCode) {
        setIsStyleCodeCheckExist(undefined);
        checkStyleCode(code);
        setLastCheckedStyleCode(code);
        evt.preventDefault();

        setTimeout(() => {
          input.blur();
        }, 0);
      }
    }
  };

  const onSubStyleChange = (code: string) => {
    setSubStyleCode(code);

    if (isProduct()) {
      if (isStyleCodeStep()) {
        const {
          ProductPatternElements: ppe,
          SubStyleCodePatternElements: spe,
        } = styleOpt || {};

        if (Array.isArray(ppe) && Array.isArray(spe)) {
          if (ppe.indexOf('ColorPatternCode') > -1 || spe.indexOf('ColorPatternCode') > -1) {
            const cpCode = code.replace(`${styleCode}-`, '');

            if (cpCode) {
              const dict = { ...styleCodeDict, colorPatternList: [ cpCode ] };

              setStyleCodeDict(dict);
              setCodeSelectDone(isStyleCodesReady('ProductPatternElements', dict));
              //console.log('-+>>>', cpCode);
            }
          }
        }
      } else {
        setCodeSelectDone(!!code);
      }
    }
  };

  const openEndDialog = () => {
    setEndDialogVisible(true);
  };

  const previousStep = () => {
    if (currentStep === 3) {
      setGenerateBtnLocked(false);
    }

    setCurrentStep(currentStep - 1);

    if (isStyleCodeStep()) {
      setCodeSelectDone(isStyleCodesReady('SubStyleCodePatternElements'));
    }
  };

  const splitColorPatternBySubStyleCode = (ssCode: string) => {
    return ssCode.replace(`${styleCode}-`, '').trim();
  };

  const stepOneTitle = () => {
    let t = '';

    switch (itemType) {
      case 'product':
      case 'sub':
        t = 'Input';
        break;

      case 'style':
        t = 'Create';
        break;
    }

    return t;
  };

  const styleGridEditMode = () => {
    return (isNeedSubStyleCode() && isNeedStyleCode()) ?
      (currentStep === 3 ? 1 : 2) : (
        isNeedStyleCode() ? (
          currentStep === 2 ? 1 : 2
        ) : 2
      );
  };

  React.useEffect(() => {
    if (!inited) {
      fetchProductSetting();
      setInited(true);
    }
  }, [inited]);

  return (<>
    <ContentLayout>
      <Heading
        title={`New ${getStyleTypeTitle()}`}
      />
      <Spacer />
      <SiteContent flexGrow>
        <EditorWrapper>
          <Steps current={currentStep}>
            <Step title={`${stepOneTitle()} Style Info`} />
            {isNeedSubStyleCode() && <Step title="Input Sub-Style Info" />}
            <Step title="Select Attributes" />
            {!isOnlyInputCodeMode() && <Step title="Edit/Review" />}
            <Step title="Generate SKUs" />
          </Steps>
          <div className="editor-ctn">
            {currentStep === 0 && <div className="step-wrapper">
              <Row align="middle" justify="center" className="style-step-form-wrapper">
                <div className="style-info-form">
                  {itemType !== 'sub' && <>
                    <div className="style-info-form-row middle">
                      <FormLabel className="form-label">Add Setting</FormLabel>
                      <AddSettingSelector
                        className="form-input-grid"
                        current={styleOpt}
                        onChange={onChangeAddSetting}
                        options={styleSettingOptions}
                      />
                    </div>
                    <Spacer />
                  </>}
                  {isNeedStyleCode() && <>
                    <div className="style-info-form-row middle">
                      <FormLabel className="form-label">Style Code</FormLabel>
                      <Row className="form-input-grid">
                        <Input
                          allowClear
                          onBlur={onStyleCodeInputBlur}
                          onChange={onEditStyleCode}
                          onKeyDown={onStyleCodeInputKeyDown}
                          value={styleCode}
                        />
                      </Row>
                    </div>
                    <Spacer />
                    <div className="style-info-form-row middle">
                      <FormLabel className="form-label">Product Name</FormLabel>
                      <Row className="form-input-grid">
                        <TextArea
                          allowClear
                          disabled={isSubStyle() || isProduct()}
                          onChange={onEditStyleCodeDesc}
                          rows={1}
                          value={styleCodeDesc}
                        />
                      </Row>
                    </div>
                    <Spacer />
                  </>}
                  <div className="style-info-form-row middle">
                    <FormLabel className="form-label">Class</FormLabel>
                    <ClassSelector
                      className="form-input-grid"
                      disabled={isSubStyle() || (isProduct() && !isProductOnlyNoPattern())}
                      onChange={onStyleClassChange}
                      value={styleClass}
                      version={0}
                    />
                  </div>
                </div>
              </Row>
              <Row justify="end">
                <Button
                  disabled={!isFirstStepReady()}
                  type="primary"
                  onClick={nextStep}
                >
                  Next
                </Button>
              </Row>
            </div>}
            {isSubStyleCodeStep() && <div className="step-wrapper">
              <Row align="middle" justify="center" className="style-step-form-wrapper">
                <StyleCodeSelector
                  itemType={itemType}
                  onSelectDone={onCodeSelectDone}
                  onSelectSubStyle={onSubStyleChange}
                  productName={styleCodeDesc}
                  setSubStyleProducts={setExistProducts}
                  styleClass={styleClass}
                  classInfo={classInfo}
                  styleCode={styleCode}
                  styleCodeDict={styleCodeDict}
                  styleOption={styleOpt || {}}
                  subStyleCodes={existSubStyleCodes}
                  type="subStep"
                />
              </Row>
              <Row justify="space-between">
                <Button
                  onClick={previousStep}
                >
                  Previous
                </Button>
                <Button
                  disabled={!codeSelectDone}
                  type="primary"
                  onClick={forwardEditGrid}
                >
                  Next
                </Button>
              </Row>
            </div>}
            {isStyleCodeStep() && <div className="step-wrapper">
              <Row align="middle" justify="center" className="style-step-form-wrapper">
                <StyleCodeSelector
                  inputProduct={inputProductCode}
                  itemType={itemType}
                  onInputProduct={onInputProduct}
                  onSelectDone={onCodeSelectDone}
                  onSelectSubStyle={onSubStyleChange}
                  productName={styleCodeDesc}
                  products={existProducts}
                  classInfo={classInfo}
                  styleClass={styleClass}
                  styleCode={styleCode}
                  styleCodeDict={styleCodeDict}
                  styleOption={styleOpt || {}}
                  subStyleCode={subStyleCode}
                  subStyleCodes={existSubStyleCodes}
                  type="styleStep"
                />
              </Row>
              <Row justify="space-between">
                <Button
                  onClick={previousStep}
                >
                  Previous
                </Button>
                <Button
                  disabled={!codeSelectDone}
                  type="primary"
                  onClick={forwardEditGrid}
                >
                  Next
                </Button>
              </Row>
            </div>}
            {isStyleGridStep() && <div className="step-wrapper">
              <Row align="middle" justify="center" className="style-step-form-wrapper">
                <StyleCodeGrid
                  deleteStyleDataRow={deleteStyleDataRow}
                  //mode={currentStep === 2 ? 1 : 2}
                  mode={styleGridEditMode()}
                  styleData={styleData}
                  styleOption={styleOpt || {}}
                />
              </Row>
              <Row justify="space-between">
                <Button
                  onClick={previousStep}
                >
                  Previous
                </Button>
                {isStyleGridGenerateStep() && <Button disabled={generateBtnLocked} type="primary" onClick={generateStyleMaster}>
                  Generate System Master
                </Button>}
                {isStyleGridEditStep() && <Button
                  type="primary"
                  onClick={nextStep}
                >
                  Next
                </Button>}
              </Row>
            </div>}
          </div>
        </EditorWrapper>
      </SiteContent>
    </ContentLayout>
    <Modal
      centered
      footer={null}
      title={<SuccessfulTitle>Style [{styleCode}] was created successfully!</SuccessfulTitle>}
      maskClosable={false}
      onCancel={closeEndDialog}
      visible={endDialogVisible}
    >
      <Row align="middle" justify="center">
        <SuccessfulText>You can</SuccessfulText>
        &nbsp;
        <InfoButton onClick={editStyleContent}>
          Edit Style Content
        </InfoButton>
        &nbsp;
        <SuccessfulText>or</SuccessfulText>
        &nbsp;
        <Button type="primary" onClick={addNewStyle}>
          Add a New Style
        </Button>
      </Row>
    </Modal>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default StyleMasterEditor;

export const trimCodeLiteral = (code: string) => {
  return code.replace(/\s/g, '');
};
