import React from "react";

const Icon = ({ width = "16", height = "17", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <g clipPath="url(#a)">
        <path
          d="M8.171 7.827c-1.64-.426-2.167-.866-2.167-1.552 0-.788.73-1.336 1.95-1.336 1.286 0 1.763.614 1.806 1.516h1.596c-.05-1.242-.809-2.383-2.318-2.751V2.122H6.87v1.56c-1.401.303-2.528 1.213-2.528 2.607 0 1.668 1.38 2.5 3.395 2.983 1.805.433 2.166 1.069 2.166 1.74 0 .499-.353 1.293-1.95 1.293-1.487 0-2.072-.664-2.152-1.516H4.213c.087 1.581 1.271 2.47 2.658 2.766v1.567h2.167v-1.553c1.408-.267 2.527-1.083 2.527-2.564 0-2.05-1.754-2.751-3.394-3.178Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 .122h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default React.memo(Icon);
