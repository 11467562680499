import { useState, useEffect } from 'react';
import moment from 'moment';
import { message } from 'antd';
import authentication from 'react-azure-b2c';
import errorSound from '../assets/audios/error.mp3';
import { LOCAL_CACHE_TTL } from '../constants/config';
import store from '../redux/store';
import profileActions from '../redux/actions/profiles';
import { performLogout } from '../services/logout';
import { fetchProfileSettings } from '../services/channels';
// eslint-disable-next-line
import { clearCachedData } from '../screens/DetailProduct/helper';
import ValidUrl from './valid-url';
import reduxUtils from '../redux/utils';

let profileSettings = [];

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [delay, value],
  );

  return debouncedValue;
};

export const copyText = (elementRef) => {
  elementRef.current.select();
  document.execCommand('copy');
};

export const getCacheItem = (key) => {
  const item = window.localStorage.getItem(key);
  let ret = null;

  if (item) {
    try {
      const data = JSON.parse(item);
      const now = (new Date()).getTime();

      if (data && data.time && typeof data.time === 'number') {
        const interval = now - data.time;

        if (interval >= 0 && interval <= LOCAL_CACHE_TTL) {
          ret = data.value;
        }
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(`Get cache error: ${e}`);
    }
  }

  return ret;
};

export const setCacheItem = (key, data) => {
  const now = (new Date()).getTime();
  const item = {
    time: now,
    value: data,
  };

  window.localStorage.setItem(key, JSON.stringify(item));
};

export function getDateElement(element, date) {
  switch (element) {
    case 'month': return date.format('MMMM');
    case 'day': return date.date() > 9 ? date.date() : `${'0'}${date.date()}`;
    case 'year': return date.year();
    case 'weekDay': return date.format('dddd');
    case 'time': return date.format('hh:mm A');
    default: return date;
  }
}

export const formatDate = (date, type) => {
  if (!date) return '-';

  const gmtDateTime = moment.utc(date);
  switch (type) {
    case 'fullDate':
      return `${getDateElement('month', gmtDateTime)} ${getDateElement('day', gmtDateTime)}, ${getDateElement('year', gmtDateTime)}`;

    case 'dateTime':
      return gmtDateTime.format('MM/DD/YYYY - hh:mm A');

    case 'fullDateWeekDay':
      return `${getDateElement('weekDay', gmtDateTime)}, ${getDateElement('month', gmtDateTime)}, ${getDateElement('day', gmtDateTime)} ${getDateElement('year', gmtDateTime)}`;

    case 'time': return getDateElement('time', gmtDateTime);

    default: return gmtDateTime.format('MM/DD/YYYY');
  }
};

export const plainArray = ([head, ...tail]) => !head ? [] : plainArray(tail).concat(...head.packages);

/**
 * Play the Bell Sound.
 */
//export const playBell = (bell: BellSoundType) => {
export const playBell = (bell) => {
  const sound = bell === 'error' ? errorSound : `/audios/${bell}.mp3`;
  const audio = new Audio(sound);

  audio.play();
};

export const objectToArray = (obj) => Object.keys(obj).map(key => obj[key]);

export const randomString = (length = 16) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  // eslint-disable-next-line
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const reduceTimeline = (list, dateKey, content) => list.reduce((acum, current) => {
  const date = formatDate(current[dateKey], 'fullDateWeekDay');

  if (acum[date]) {
    acum[date].push({
      content: current[content],
      time: formatDate(current[dateKey], 'time'),
    });
  } else {
    // eslint-disable-next-line
    acum[date] = [{
      content: current[content],
      time: formatDate(current[dateKey], 'time'),
    }];
  }

  return acum;
}, {});

export const getProfiles = () => {
  const reducerProfile = JSON.parse(localStorage.getItem('persist:root'));
  return reducerProfile ? JSON.parse(reducerProfile.profiles).profiles : [];
};

export const getProfileIndex = () => {
  const reducerProfile = JSON.parse(localStorage.getItem('persist:root'));
  return reducerProfile ? JSON.parse(reducerProfile.profiles).selectedIndex : 0;
};

export const isDevEnv = () => {
  return ['local', 'development'].indexOf(process.env.NODE_ENV || '') > -1;
};

export const isEmail = (email, isRequired = false) => {
  if (email === '' && !isRequired) return true;
  // eslint-disable-next-line no-control-regex
  const pattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return pattern.exec(email) !== null;
};

export const isImageUrl = (url) => {
  if (typeof url !== 'string') return false;

  // eslint-disable-next-line
  return isValidHttpUrl(url) && (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi) != null);
};

export const isOtherFileUrl = (url) => {
  if (typeof url !== 'string') return false;

  // eslint-disable-next-line
  return isValidHttpUrl(url) && (url.match(/^http[^\?]*.(pdf|txt|xlsx)(\?(.*))?$/gmi) != null);
};

export const isVideoUrl = (url) => {
  if (typeof url !== 'string') return false;

  // eslint-disable-next-line
  return isValidHttpUrl(url) && (url.match(/^http[^\?]*.(mp4|avi)(\?(.*))?$/gmi) != null);
};

export const isZipCode = (zip, isRequired = false) => {
  if (zip === '' && !isRequired) return true;
  // eslint-disable-next-line no-control-regex
  const pattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return pattern.exec(zip) !== null;
};

export const isPhoneFax = (number, isRequired = false) => {
  if (number === '' && !isRequired) return true;
  // eslint-disable-next-line no-control-regex
  const pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return pattern.exec(number) !== null;
};


export const isValidHttpUrl = (urlString) => {
  return ValidUrl.isWebUri(urlString);
};

export const isWeb = (url, isRequired = false) => {
  if (url === '' && !isRequired) return true;
  // eslint-disable-next-line no-control-regex
  const pattern = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return pattern.exec(url) !== null;
};

export const leftTrim = (str) => str.replace(/^\s+/, '');

// eslint-disable-next-line
export let isLoggingOut = false;

export const logout = async () => {
  isLoggingOut = true;
  performLogout();
  authentication.signOut();
  clearCachedData();
  store.dispatch(profileActions.setIsProfileSelected(false));
};

export const compareStrings = (a, b, order) =>
  order === 'ascend' ?
    a.localeCompare(b) :
    b.localeCompare(a);

export const compareNumbers = (a, b, order) =>
  order === 'ascend' ?
    a - b :
    b - a;

export const isString = (v) => typeof v === 'string';

export const sortDataTable = (data = [], { field, order }) =>
  data.sort((a = {}, b = {}) =>
    isString(a[field]) ?
      compareStrings(a[field], b[field], order) :
      compareNumbers(a[field], b[field], order));

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Given any string, convert it to snake case
 *
 * @param {string} input
 */
export function toSnakeCase(input) {
  return input
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(w => w.toLowerCase())
    .join('_');
}

export function cartesian() {
  // eslint-disable-next-line prefer-rest-params
  const r = []; const arg = arguments[0].map(m => m); const max = arg.length - 1;
  function helper(arr, i) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0, l = arg[i].length; j < l; j++) {
      const a = arr.slice(0);
      a.push(arg[i][j]);
      if (i === max) {
        r.push(a);
      } else
        helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

/**
 * isjsonString
 */
export function isJsonString(data) {
  try {
    if (typeof JSON.parse(data) === 'object') {
      return true;
    }
    // eslint-disable-next-line
  } catch (error) { }
  return false;
}


/**
 * Get the profile value of a special profile setting.
 */
export const getProfileSettingValue = async (key, defaultValue) => {
  if (profileSettings.length === 0) {
    await getProfileSettings();
  }

  if (profileSettings.length > 0) {
    const ps = profileSettings.filter(e => e.SettingCode === key);

    if (ps.length > 0) {
      return ps[0].SettingValue || defaultValue;
    }
  }

  return defaultValue;
};

/**
 * Get profile settings.
 */
export const getProfileSettings = async () => {
  try {
    const settings = await fetchProfileSettings();

    if (Array.isArray(settings)) {
      profileSettings = settings;
    }
  } catch (e) {
    message.error(`Fetch profile setting error: ${e}`);
  }
};

/**
 *  Get profile value sync version.
 */
// eslint-disable-next-line
export const getProfileSettingValueSync = (settingCode = '', defaultValue = '') => {
  // eslint-disable-next-line
  if (profileSettings.length === 0 && typeof reduxUtils.getProfileSettings === 'function') {
    // eslint-disable-next-line
    profileSettings = reduxUtils.getProfileSettings();
  }

  if (profileSettings.length > 0) {
    const ps = profileSettings.filter(e => e.SettingCode === settingCode);

    if (ps.length > 0) {
      return ps[0].SettingValue || defaultValue;
    }
  }

  return defaultValue;
};

export const checkStringType = (v) => {
  if (`${v}`) {
    if (isValidHttpUrl(v)) {
      if (isImageUrl(v)) {
        return "imageUrl";
      }
      if (isVideoUrl(v)) {
        return "videoUrl";
      }
      if (isOtherFileUrl(v)) {
        return "fileUrl";
      }
      return "pageUrl";
    }
    return "string";
  } else {
    return "string";
  }
};

export const onSelectionChange = (
  props = {},
  sourceList = [],
  setSelected,
  setSelectedRow,
  id = '',
  isLocal = false,
  setSourceList = {},
) => {
  const { selected, data, unselected } = props;
  setSelected(selected);
  if (selected === true) {
    if (unselected) {
      const temp = sourceList.filter(
        (item) =>
          !Object.prototype.hasOwnProperty.call(unselected, `${item[id] === 0 ? 0 : item[id] || ''}`)
      );
      setSelectedRow(temp);
    } else {
      if (Array.isArray(data)) {
        setSelectedRow(data);
        if (isLocal) {
          typeof setSourceList === 'function' && setSourceList && setSourceList(data);
        }
      } else {
        setSelectedRow(sourceList);
      }
    }
  }
  if (selected && selected !== true) {
    setSelectedRow(Object.values(selected));
  }
  if (!selected) {
    if (unselected) {
      const temp = sourceList.filter(
        (item) =>
          !Object.prototype.hasOwnProperty.call(unselected, `${item[id] === 0 ? 0 : item[id] || ''}`)
      );
      setSelectedRow(temp);
    } else {
      setSelectedRow(Object.values(selected));
    }
  }
};

/**
 * Validate whether the http status code is a valid status.
 *
 * @param {Array<number>} codes - the valid status code list, do not need include 200, it will be included default.
 * @param {number} - the status code need to validated
 * @return {boolean}
 */
export const validateHttpStatus = (codes, status) => {
  const states = [200, ...codes];

  return states.indexOf(status) > -1;
};