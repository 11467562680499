import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
  notification,
} from 'antd';
import {
  ArrowLeftOutlined,
  CopyOutlined,
  FileTextOutlined,
  //MenuOutlined,
  //ReloadOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import theme from '../../../assets/styles/theme';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import { InfoButton } from '../../../components/common/styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { getProfilesState } from '../../../redux/utils';
import { fetchProfileSettings } from '../../../services/channels';
import Products, { aiGenerateText } from '../../../services/products';
//import { isDevEnv } from '../../../util';
import { EditorModal, TextEditorBody } from '../styledComponents';

type Props = {
  productId?: string;
  editable: boolean;
  onClose: Function;
  onSave?: Function;
  readonly?: boolean;
  textContent: string;
  title?: string;
  visible: boolean;
};

let profileSettings: StringKAnyVPair[] = [];

const TextEditor = (props: Props) => {
  const { useState } = React;
  const [spinning, setSpinning] = useState<boolean>(props.productId ? true : false);
  const [aiInputText, setAiInputText] = useState('Revise');
  const [aiTextTask, setAiTextTask] = useState('original');
  const [aiText, setAiText] = useState('');
  const [currentValue, setCurrentValue] = useState(props.textContent || '');
  const [editable, setEditable] = useState(props.editable);
  const [inited, setInited] = useState(false);
  const [isChatEnable, setIsChatEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastValue, setLastValue] = useState(props.textContent);
  const [productType, setProductType] = useState<number>(100);
  const [detailDialogTitleType, setDetailDialogTitleType] = useState('');
  const [overwrite, setOverwrite] = useState<boolean>(false)
  /*const [chatTextLengthList, setChatTextLengthList] = useState([
    {label: 'Short', value: 'short', selected: false},
    {label: 'Medium', value: 'medium', selected: true},
    {label: 'Long', value: 'long', selected: false},
  ]);*/
  const [chatToneList, setChatToneList] = useState([
    { label: 'Professional', value: 'professional', selected: true },
    { label: 'Casual', value: 'casual', selected: false },
    { label: 'Enthusiastic', value: 'enthusiastic', selected: false },
    { label: 'Informational', value: 'informational', selected: false },
    { label: 'Funny', value: 'funny', selected: false },
  ]);
  const aiInputTextRef = React.useRef<any>(null);
  //const isChatEnable = ['local', 'development'].indexOf(process.env.NODE_ENV as string) > -1;
  //const isChatEnable = isDevEnv();

  const chatSelectOptions = (
    list: StringKAnyVPair[],
    changeState: Function,
  ) => {
    const onChatOptionChange = (opt: StringKAnyVPair) => {
      list.forEach(item => {
        item.selected = item.value === opt.value;
      });

      changeState([...list]);
    };

    return list.map(t => (
      <Button
        onClick={() => onChatOptionChange(t)}
        key={t.value}
        type={t.selected ? 'primary' : 'default'}
      >
        {t.label}
      </Button>
    ));
  };

  /*const chatTextLengthOptions = () => {
    return chatSelectOptions(chatTextLengthList, setChatTextLengthList);
  };*/

  const chatToneOptions = () => {
    return chatSelectOptions(chatToneList, setChatToneList);
  };

  const copyAiText = () => {
    setCurrentValue(aiText);
  };

  const dialogTitle = () => {
    // const action = editable ? 'Edit' : 'View';
    // const title = props.title || 'Text';

    return <span style={{ color: theme['@primary-color'] }}>{`${detailDialogTitleType}`}</span>;
  };

  const getAIText = async () => {
    const ts = chatToneList.filter(e => e.selected);
    const tone = ts.length > 0 ? ts[0].value : '';
    console.log('->', aiInputText, aiTextTask, tone);
    const aiOpt = {
      completionPayload: {
        inputText: aiTextTask === 'draft' ? aiText : currentValue,
        //Task: aiTextTask,
        Task: aiInputText,
        Tone: tone,
        lengthMultiplier: '',
        //characterLength: 100,
      },
    };

    if (!aiInputText.trim()) {
      Modal.error({
        content: 'Please input the text about what to do',
        onOk() {
          aiInputTextRef?.current.focus();
        },
      });
      return;
    }

    setIsLoading(true);
    try {
      const { status, data } = await aiGenerateText(aiOpt);
      //console.log('ai writing', data);
      if (status && status === 200) {
        if (data.success && typeof data.completionPayload === 'object') {
          if (Array.isArray(data.completionPayload.resultList)) {
            //console.log('-->', data.completionPayload.resultList);
            const text = data.completionPayload.resultList[0] || '';
            setAiText(text.trim());
          }
        }
      }
    } catch (e) {
      notification.error({
        message: `Generate text error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    if (typeof props.onSave === 'function') {
      props.onSave(currentValue, overwrite);
      setLastValue(currentValue);
    }

    props.onClose();
  };

  const isAiFormComplete = () => {
    //console.log('-->', aiTextTask, currentValue, props.textContent);
    let ret = !!(aiTextTask === 'draft' ? aiText.trim() : currentValue.trim());

    if (ret) {
      ret = !!aiInputText.trim();
    }

    return ret;
  };

  const isContentUnchanged = () => {
    return !editable || lastValue === currentValue;
  };

  // eslint-disable-next-line
  const loadProfileSetting = async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'AllowChatGPT')[0];

    if (!ps) {
      try {
        setIsLoading(true);
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'AllowChatGPT')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (ps) {
      //console.log('ps', ps);
      const allowGpt = parseInt(ps.SettingValue) || 0;

      setIsChatEnable(!!allowGpt);
    }
  };

  const onAiInputDraftChange = (evt: any) => {
    setAiText(evt.target.value);
  };

  const onAiInputTextChange = (evt: any) => {
    setAiInputText(evt.target.value);
  };

  const onCloseDialog = () => {
    props.onClose();
  };

  const onReviseTypeChange = (evt: any) => {
    console.log('rr->', evt.target.value);
    setAiTextTask(evt.target.value);
  };

  const onSwitchEditable = () => {
    setEditable(!editable);
  };

  const onTextChange = (evt: any) => {
    setCurrentValue(evt.target.value);
  };

  const getProductType = async (productId: string) => {
    try {
      const { productType = '', productBasic = {} } = await Products.getStyleMaster2(productId);
      setProductType(productType);
      switch (productType) {
        case 1:
          setDetailDialogTitleType(`Product: ${productBasic.sku}`);
          break;

        case 2:
          setDetailDialogTitleType(`Style Master: ${productBasic.sku}`);
          break;

        case 3:
          setDetailDialogTitleType(`Sub Style: ${productBasic.sku}`);
          break;

        default:
          setDetailDialogTitleType(`${productBasic.sku}`);
          break;
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setSpinning(false);
    }
  }

  const onOverwriteChange = (e: any) => {
    setOverwrite(e);
  }

  const TextareaTitleRow = () => {
    return (
      <Row
        justify="space-between"
        align="middle"
        style={{ padding: '8px 0px', height: 48 }}
        wrap={false}
      >
        <Col span={8}><h4 style={{ marginBottom: 0 }}>{props.title || 'Text'}:</h4></Col>
        <Col>
          <Space split={<Divider type="vertical" />}>
            {
              props.productId && productType !== 1 && (
                <Space>
                  <span>Overwrite children's value</span>
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={overwrite}
                    onChange={onOverwriteChange}
                  />
                </Space>
              )
            }
            <CopyToClipboard
              text={currentValue || ''}
              onCopy={() => notification.success({ message: 'Attribute value has been copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION })}
            >
              <Button
                disabled={!(currentValue || '').trim()}
                icon={<CopyOutlined />}
              >
                Copy
              </Button>
            </CopyToClipboard>
          </Space>
        </Col>
      </Row>
    )
  }

  const TextareaFooterRow = () => {
    return (
      <Row align="middle" justify="space-between" style={{ padding: '12px 0px 0px' }}>
        {!props.readonly && <InfoButton
          onClick={onSwitchEditable}
          type="primary"
        >
          {editable ? 'Readonly' : 'Edit'}
        </InfoButton>}
        <Space>
          <Button onClick={onCloseDialog}>
            Cancel
          </Button>
          {!props.readonly && <Button
            disabled={isContentUnchanged()}
            type="primary"
            onClick={handleSave}
          >
            Save
          </Button>}
        </Space>
      </Row>
    )
  }

  useEffect(() => {
    if (!inited) {
      loadProfileSetting();
      if (props.productId) {
        getProductType(props.productId);
      }
      setInited(true);
    }
    // eslint-disable-next-line
  }, [inited, loadProfileSetting]);



  return (<>
    <EditorModal
      centered
      className="fullscreen-modal"
      footer={null}
      //okText="Save"
      onCancel={onCloseDialog}
      //onOk={onSaveImage}
      style={{ paddingBottom: 0 }}
      title={dialogTitle()}
      visible={props.visible}
      width={isChatEnable ? 1260 : 700}
    >
      <Spin spinning={spinning}>
        {!isChatEnable && <>
          <TextareaTitleRow />
          <Input.TextArea
            disabled={!editable}
            onChange={onTextChange}
            rows={16}
            value={currentValue}
          />
          <TextareaFooterRow />
        </>}
        {isChatEnable && (
          <TextEditorBody>
            <div className="gen-text-ctn-col">
              <TextareaTitleRow />
              <Input.TextArea
                disabled={!editable}
                onChange={onTextChange}
                style={{ height: 'calc(100% - 92px)' }}
                value={currentValue}
              />
              <TextareaFooterRow />
            </div>
            <Row align="middle" className="gen-text-central-col" justify="center">
              <Button type="primary" ghost disabled={!editable || !aiText} onClick={copyAiText}>
                <ArrowLeftOutlined />
                Copy to
              </Button>
            </Row>
            <div className="gen-text-ctn-col">
              <label className="label head">What to do</label>
              <Row>
                <div className="gen-text-section1">
                  <Input
                    ref={aiInputTextRef}
                    //disabled={!editable}
                    onChange={onAiInputTextChange}
                    value={aiInputText}
                  />
                </div>
                <div className="gen-text-section2">
                  <Radio.Group defaultValue={aiTextTask} onChange={onReviseTypeChange}>
                    <Radio value="original">Original</Radio>
                    <Radio value="draft">Draft</Radio>
                  </Radio.Group>
                </div>
              </Row>
              <div className="gen-text-section card">
                <div className="header-bar">
                  <SmileOutlined />
                  Tone
                </div>
                <div className="card-body">
                  {chatToneOptions()}
                </div>
              </div>
              {/*<div className="gen-text-section card">
              <div className="header-bar">
                <MenuOutlined />
                Length
              </div>
              <div className="card-body">
                {chatTextLengthOptions()}
              </div>
            </div>*/}
              <div className="gen-text-section">
                <InfoButton disabled={!isAiFormComplete()} style={{ width: 200 }} onClick={getAIText}>
                  Run
                </InfoButton>
              </div>
              <div className="gen-text-section card">
                <Row className="header-bar" justify="space-between">
                  <div>
                    <FileTextOutlined />
                    Draft
                  </div>
                  <div>
                    {aiText.trim() && (
                      <CopyToClipboard
                        text={aiText || ''}
                        onCopy={() => notification.success({ message: 'Draft has been copied to clipboard', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION, })}
                      >
                        <Tooltip title="Copy draft to clipboard">
                          <CopyOutlined
                            style={{ color: theme['@info-color'] }}
                          />
                        </Tooltip>
                      </CopyToClipboard>
                    )}
                    {!aiText.trim() && (
                      <CopyOutlined />
                    )}
                    {/*<ReloadOutlined />*/}
                  </div>
                </Row>
                <div className="card-body draft">
                  <Input.TextArea
                    //disabled={!editable}
                    onChange={onAiInputDraftChange}
                    value={aiText}
                  />
                </div>
              </div>
            </div>
          </TextEditorBody>
        )}
      </Spin>
    </EditorModal>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default TextEditor;
