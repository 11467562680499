import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Menu,
  Select,
  Space,
  notification,
  Input,
  Form,
  Card,
} from 'antd';
import {
  CaretDownOutlined,
  //DoubleLeftOutlined,
  //DoubleRightOutlined,
} from '@ant-design/icons';
import Loading from '../../../components/common/Loading';
import ModalDialog from '../../../components/common/ModalDialog';
import ScreenMask from '../../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { fetchProductCopywritingElements } from '../../../services/copywriting';
import { isDevEnv } from '../../../util';
import CopywritingSteps from '../CopywritingSteps';
import PullCopywritingSteps from '../PullCopywrtingSteps';
import AttrList from '../CopywritingSteps/AttrList';
import AttrGroupList from '../CopywritingSteps/AttrGroupList';
//import ImagePanel from '../ImagePanel';
import { getProductType } from '../helper';
import { CopywritingWrapper } from '../styledComponents';
import { HoverBgButton } from '../../../components/common/styledComponents';

type Props = {
  focused: boolean;
  isFullscreen?: boolean;
  productId: string;
  productType: number;
  state: StringKAnyVPair;
};

const Copywriting = (props: Props) => {
  const [attrList, setAttrList] = useState<StringKAnyVPair[]>([]);
  const [attrListVersion, setAttrListVersion] = useState(0);
  const [attrLoaded, setAttrLoaded] = useState(false);
  const [currentAttrList, setCurrentAttrList] = useState<StringKAnyVPair[]>([]);
  const [imagePanelExpanded, setImagePanelExpanded] = useState(false);
  const [inited, setInited] = useState(false);
  const [currentProductId, setProductId] = useState(props.productId);
  const [isLoading, setIsLoading] = useState(false);
  const [pushDialogVisible, setPushDialogVisible] = useState(false);
  const [pullDialogVisible, setPullDialogVisible] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  // const [groupList, setGroupList] = useState<string[]>([]);
  const [seletedTags, setSelectedTags] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  // const [filterGroup, setFilterGroup] = useState<any[]>([]);

  const [form] = Form.useForm();

  const attrListHeight = React.useMemo(() => {
    let hd = props.isFullscreen ? 180 : 320;

    if (typeof props.isFullscreen === 'undefined') hd = 240;

    return window.innerHeight - hd;
  }, [props]);

  const closePushDialog = () => {
    setPushDialogVisible(false);
  };

  const closePullDialog = () => {
    setPullDialogVisible(false);
  };

  const filterAttrList = useCallback(() => {
    let temp = [...attrList];
    if ( filterValue ){
      const key = filterValue.toLowerCase();
      temp = temp.filter(i=> i.elementName.toLowerCase().indexOf(key) > -1)
    }
    if (seletedTags.length > 0) {
      temp = temp.filter(i=> seletedTags.indexOf(i.tag) > -1)
    }
    // if (filterGroup.length > 0) {
    //   temp = temp.filter(i=> filterGroup.indexOf(i.contentSourceGroup) > -1)
    // }
    setCurrentAttrList(temp)
    setAttrListVersion(prev=> prev + 1);
  },[seletedTags, filterValue, attrList]);

  const getAttributePanelClassName = () => {
    const cls = ['form-ctn'];

    if (imagePanelExpanded) cls.push('shrink');

    return cls.join(' ');
  };

  // eslint-disable-next-line
  const getImagePanelClassName = () => {
    const cls = ['image-ctn'];

    if (imagePanelExpanded) cls.push('expand');

    return cls.join(' ');
  };

  const isCurrentAttrsNoValue = () => {
    return currentAttrList.every(e => !e.copywritingValue);
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    setIsLoading(true);

    try {
      const res = await fetchProductCopywritingElements(props.productId);

      console.log('e-->', res);
      if (res && typeof res === 'object') {
        const { data } = res;

        if (Array.isArray(data)) {
          const ts: string[] = [];
          // const gps: string[] = [];

          data.forEach(d => {
            let l: string[] = [];

            if (d.optionList.trim()) {
              try {
                l = d.optionList.trim().split('|');
              } catch(e) {}
            }

            d.optionList = l;
          });
          setAttrList(data);
          setCurrentAttrList(data);
          setAttrListVersion(attrListVersion + 1);
          data.forEach(attr => {
            if (attr.tag && ts.indexOf(attr.tag) < 0) ts.push(attr.tag);
            // if (attr.contentSourceGroup && gps.indexOf(attr.contentSourceGroup) < 0) gps.push(attr.contentSourceGroup)
          });
          setTags(ts);
          // setGroupList(gps);
        }
      }
    } catch(e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch content resources attributes error: ${e}`,
      });
    } finally {
      setAttrLoaded(true);
      setIsLoading(false);
    }
  };

  const onSelectTags = (ts: string[]) => {
    //console.log('sel ->', ts);
    setSelectedTags(ts);
  };

  // const onSelectGroups = (ts: string[]) => {
  //   //console.log('sel ->', ts);
  //   setFilterGroup(ts);
  // };

  const openPushDialog = () => {
    if (isCurrentAttrsNoValue()) {
      notification.info({
        message: 'Please edit at least one attribute',
        duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
      });
      return;
    } else {
      setPushDialogVisible(true);
    }
  };

  const openPullDialog = () => {
    setPullDialogVisible(true);
  };

  const moreDropdownMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => openPullDialog()}>
        Pull
      </Menu.Item>
    </Menu>
  );

  const pushDialogWidth = () => {
    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };

  const tagOptions = () => {
    return tags.map(t => (
      <Select.Option
        key={t}
        value={t}
      >
        {t}
      </Select.Option>
    ));
  };

  // const groupOptions = () => {
  //   return groupList.map(t => (
  //     <Select.Option
  //       key={t}
  //       value={t}
  //     >
  //       {t}
  //     </Select.Option>
  //   ));
  // };

  // eslint-disable-next-line
  const toggleImagePanel = () => {
    setImagePanelExpanded(!imagePanelExpanded);
  };

  React.useEffect(() => {
    if (props.focused && props.productId && (!inited || currentProductId !== props.productId)) {
      loadInitialData();
      setInited(true);
      setProductId(props.productId);
    }
  }, [currentProductId, inited, loadInitialData, props]);

  useEffect(()=>{
    filterAttrList();
  },[filterValue, seletedTags, filterAttrList])

  return (
    <>
      <CopywritingWrapper>
        <Col className="left-sider">
          <Space className="left-sider-bar" direction="vertical">
            <Form form={form}>
              <Card
                bodyStyle={{
                  padding: 8,
                }}
              >
                <Space direction="vertical">
                  <Form.Item noStyle name="Name">
                    <Input.Search
                      allowClear
                      name="Filter"
                      placeholder="Filter"
                      // onChange={onSearchTextChange}
                      onSearch={(value) => setFilterValue(value)}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="Tag">
                    <Select
                      mode="tags"
                      onChange={onSelectTags}
                      placeholder="Please Select Tags"
                      style={{ width: '100%' }}
                    >
                      {tagOptions()}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item noStyle name="Group">
                    <Select
                      mode="tags"
                      onChange={onSelectGroups}
                      placeholder="Please Select groups"
                      style={{ width: '100%' }}
                    >
                      {groupOptions()}
                    </Select>
                  </Form.Item> */}
                  <HoverBgButton
                    hovertype="danger"
                    onClick={() => {
                      form.resetFields();
                      setSelectedTags([]);
                      // setFilterGroup([]);
                      setFilterValue('');
                    }}
                  >
                    Clear Filters
                  </HoverBgButton>
                </Space>
              </Card>
            </Form>
            <Button
              disabled={currentAttrList.length === 0}
              onClick={openPushDialog}
              type="primary"
              style={{ width: '100%' }}
            >
              Push
            </Button>
            <Dropdown overlay={moreDropdownMenu} trigger={['click']}>
              <Button style={{ width: '100%' }}>
                More
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Col>
        <Col className={getAttributePanelClassName()} style={{ height: attrListHeight }}>
          {attrLoaded && attrList.length === 0 && (
            <Alert message="No attribute found" type="error" />
          )}
          {isDevEnv() || true ? (
            <AttrGroupList
              editable
              filted={currentAttrList.length !== attrList.length}
              list={currentAttrList}
              productId={props.productId}
              version={attrListVersion}
              state={props.state}
            />
          ) : (
            <AttrList
              editable
              list={currentAttrList}
              productId={props.productId}
              state={props.state}
              version={attrListVersion}
            />
          )}
        </Col>
        {/*<Col className={getImagePanelClassName()}>
        <span
          className="left-expand-icon"
          onClick={toggleImagePanel}
        >
          {imagePanelExpanded ?
            <DoubleRightOutlined /> :
            <DoubleLeftOutlined />
          }
        </span>
        <div
          className="image-panel-ctn"
          style={{display: imagePanelExpanded ? '': 'none'}}
        >
          <ImagePanel
            focused={imagePanelExpanded}
            productId={props.productId}
          />
        </div>
      </Col>*/}
      </CopywritingWrapper>
      {pushDialogVisible && (
        <ModalDialog
          bodyHeight={window.innerHeight - 200}
          closable={false}
          closeButton
          footer={null}
          fullscreen
          maskClosable={false}
          onClose={closePushDialog}
          title={`Push Content Resources (${getProductType(props.productType)})`}
          visible={pushDialogVisible}
          width={pushDialogWidth()}
        >
          <CopywritingSteps onCancel={closePushDialog} productId={props.productId} state={props.state} />
        </ModalDialog>
      )}
      {pullDialogVisible && (
        <ModalDialog
          bodyHeight={window.innerHeight - 200}
          closable={false}
          closeButton
          footer={null}
          fullscreen
          maskClosable={false}
          onClose={closePullDialog}
          title={`Pull Content Resources (${getProductType(props.productType)})`}
          visible={pullDialogVisible}
          width={pushDialogWidth()}
        >
          <PullCopywritingSteps onCancel={closePullDialog} productId={props.productId} />
        </ModalDialog>
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default React.memo(Copywriting);
