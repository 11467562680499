import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import Clock from '../../assets/icons/clock'

function IddleModal (props: any) {
	let [count, setCount] = useState(60);

	const useInterval = (callback: any, delay: any) => {
		const savedCallback = useRef();

		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		useEffect(() => {
			function tick() {
				// @ts-ignore
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => clearInterval(id);
			}
		}, [delay]);
	};
	useInterval(() => {
		if(count <= 1) {
			props.performLogout();
		} else {
			setCount(count - 1);
		}
	}, 1000);
	return (
			<div>
				<Row>
					<Col span={4}>
						<Clock width={64} height={64} />
					</Col>
					<Col span={20}>
						<p style={{fontSize: '23px'}}>For security, you will be logged out automatically in</p>
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{textAlign: 'center', fontSize: '25px'}}>
						<p>{count} Seconds</p>
					</Col>
				</Row>
			</div>
	);
};

export default IddleModal;