import { Badge, Form, Spin, Tabs } from 'antd';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Dashboard from '../../assets/icons/dashboard';
import Info from '../../assets/icons/info';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import { HeaderButton } from '../../components/common/styledComponents';
import ConfirmationModal from '../../components/ConfirmationModal';
import ContentLayout from '../../components/ContentLayout';
import BasicInfo from './Tabs/BasicInfo';
import ProductBundles from './Tabs/Bundle';
import ProductImages from './Tabs/Images';
import Inventory from './Tabs/Inventory';
import SiteContent from '../../components/SiteContent';
import { Permissions } from '../../constants/enums/permissions';
import Products from '../../services/products';

import {
  Actions,
  Dispatcher,
  initialState,
  ProductDetailScreenContext,
  ProductDetailState,
  reducer, saveChannelInv, saveProduct
  ,
} from './context';
import { TopHeaderButtonsWrapper, TitleWrapper, TitlePlaceholderWrapper, TitleInput } from './styledComponents';
import Attributes from './Tabs/Attributes';
import FormButtons from '../../components/common/FormButtons';
import notificationActions from '../../redux/actions/notifications';
// import Extended from './Tabs/Extended';
import ChannelInv from './Tabs/ChannelInv';
import StyleMasterScreen from './Tabs/StyleMaster';

const { TabPane } = Tabs;

const formTabsStructure: any = {
  'Basic': [
    'ASIN',
    'BoxHeight',
    'BoxLength',
    'BoxWidth',
    'Brand',
    'ClassificationNum',
    'Condition',
    'DimensionUnit',
    'EAN',
    'FNSku',
    'GrossWeight',
    'HarmonizedCode',
    'ISBN',
    'LongDescription',
    'MPN',
    'Manufacturer',
    'MultipackQuantity',
    'NetWeight',
    'ProductHeight',
    'ProductLength',
    'ProductTitle',
    'ProductType',
    'ProductWidth',
    'SKU',
    'ShortDescription',
    'Subtitle',
    'TaxProductCode',
    'UPC',
    'Warranty',
    'WeightUnit',
  ],
  'Pricing': [
    'Price',
    'Cost',
    'MSRP',
    'AvgCost',
    'MAPPrice',
  ],
};

const setPage = (dispatch: Dispatcher, productType: number, bundleType: number, productId?: string) => {
  const handler = async () => {
    dispatch({ type: Actions.SET_LOADING, params: true });
    let newProductType = productType;
    let newBundleType = bundleType;
    const classifications = await Products.getClassifications();
    const channels = await Products.getChannelControlFlags();
    const attributesChannels = await Products.getAccountEnabled();

    // some channels don't have this Channel Inventory enabled
    (await Products.fetchProductAssignedChannelInv(productId || '0')
      .then((channelsInv) => {
        dispatch({ type: Actions.SET_CHANNELSINV_DCS, params: channelsInv.distributionCenterList });
        const channelListRaw = channelsInv.channelList.map((c: any) => ({
          ...c,
          display: true,
          channelAccountList: c.channelAccountList.map((cal: any) => ({
            ...cal,
            display: true,
            distributionList: channelsInv.distributionCenterList.map((dl: any) => ({ ...dl, display: true })),
          })),
        }));

        dispatch({
          type: Actions.SET_CHANNELSINV, params: {
            channelListRaw,
            channelListProduct: channelsInv.channelList,
          },
        });
      })
      .catch(() => {
      }));


    const labels = await Products.getLabels({
      $count: true,
      $top: 0,
    });
    const dc = await Products.getDistributionCenters();
    dispatch({ type: Actions.SET_DC, params: dc });


    const inventoryTable = dc.map((distC: any) => ({
      DistributionCenterName: distC.DistributionCenterName,
      DistributionCenterId: distC.DistributionCenterID,
      DistributionCenterCode: distC.DistributionCenterCode,
      DistributionCenterType: distC.DistributionCenterType,
      AvailableQuantity: 0,
    }));

    const attributes = await Products.getAttributes();
    let groups1 = attributes.map((a: any) => a.Group1);
    let groups2 = attributes.map((a: any) => a.Group2);
    groups1 = groups1.filter((data: any, index: any) => groups1.indexOf(data) === index && data !== '');
    groups2 = groups2.filter((data: any, index: any) => groups2.indexOf(data) === index && data !== '');

    const visibilityMap = attributes.reduce((p: any, c: Entities.ProductAttribute) => {
      const r = { ...p };
      r[c?.AttributeId || 0] = true;
      return r;
    }, {});

    dispatch({ type: Actions.INIT_ATTRIBUTE_FILTER_BOOLS, params: visibilityMap });
    dispatch({
      type: Actions.SET_ATTRIBUTES,
      params: { attributes: attributes.filter((a: any) => a.AttributeType !== 3), groups1, groups2 },
    });
    dispatch({ type: Actions.SET_INVENTORY, params: inventoryTable });
    dispatch({ type: Actions.SET_ATTRIBUTESCHANNELS, params: attributesChannels });
    dispatch({ type: Actions.SET_INVENTORYFORM });
    if (productId) {
      const productData = await Products.fetchSingleProductById(productId);
      if (productData.ProductBasic.ProductType === 2 && productData.ProductBasic.BundleType === 0) {
        newProductType = productData.ProductBasic.ProductType;
        newBundleType = productData.ProductBasic.BundleType;
        const styleMasterProduct = await Products.getStyleMaster(productId);
        dispatch({ type: Actions.SET_PRODUCT_STYLEMASTER, params: styleMasterProduct });
      }

      if (productData.VaryByList) {
        const productsByAttribute = await Products.getProductsByAttributes({
          attributeIdArray: productData?.VaryByList.join(';') || [],
        });
        dispatch({ type: Actions.SET_PRODUCTS, params: productsByAttribute.ProductByAttributeList });
      }

      dispatch({ type: Actions.SET_PRODUCT_DATA, params: { ...productData, productId, dispatch } });
      dispatch({ type: Actions.SET_PRODUCTTYPE, params: productData.ProductBasic.ProductType });
      dispatch({ type: Actions.SET_BUNDLETYPE, params: productData.ProductBasic.BundleType });

      // Uncomment this when productExt tab is going to be enabled again
      // if this try section can not be executed, the code must continue with a default value inside catch
      // try {
      //   const productsExt = await Products.getProductExts(productData.ProductBasic.SKU);
      //   const { data: { inventory } } = productsExt;
      //   const isProductExist = inventory !== undefined;
      //   dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: productsExt || {}, isProductExist } });
      // } catch {
      //   dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: {}, isProductExist: false } });
      // }

      // Delete this line when productExt tab is going to be enabled again
      dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: {}, isProductExist: false } });

    } else {
      dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: null, isProductExist: false } });
      dispatch({ type: Actions.SET_EDITMODE, params: true });
      dispatch({ type: Actions.SET_PRODUCTTYPE, params: productType });
    }

    if (newProductType === 2 && newBundleType === 0) {
      const styleVariations = await Products.fetchStyleVariations();

      const stylesFormatterItemsOrder = [];
      if (styleVariations.data.styleVariation.useColorPatternCode) {
        stylesFormatterItemsOrder.push('colorCode', 'colorCodeSeparator');
      }
      if (styleVariations.data.styleVariation.useLengthCode) {
        stylesFormatterItemsOrder.push('lengthCode', 'lengthCodeSeparator');
      }
      if (styleVariations.data.styleVariation.useWidthCode) {
        stylesFormatterItemsOrder.push('widthCode', 'widthCodeSeparator');
      }
      if (styleVariations.data.styleVariation.useSizeCode) {
        stylesFormatterItemsOrder.push('sizeCode', 'sizeCodeSeparator');
      }
      dispatch({ type: Actions.SET_STYLE_FORMATTER_ITEMS_ORDER, params: stylesFormatterItemsOrder });
      styleVariations.data.styleVariation.colorCode = styleVariations.data.styleVariation.colorPatternCode;
      delete styleVariations.data.styleVariation.colorPatternCode;
      dispatch({ type: Actions.SET_STYLEVARIATIONS, params: styleVariations.data.styleVariation });
    }


    dispatch({ type: Actions.SET_LABELS, params: labels });
    dispatch({ type: Actions.SET_CLASSIFICATIONS, params: classifications });
    dispatch({ type: Actions.SET_CHANNELS, params: channels });
    dispatch({ type: Actions.SET_LOADING, params: false });
    return Promise.resolve();
  };

  handler()
    .catch(() => {

    });
};

const useProductDetailPageLoad = (dispatch: Dispatcher, productType: number, bundleType: number, productId?: string) => {
  useEffect(() => {
    setPage(dispatch, productType, bundleType, productId);
  }, [bundleType, dispatch, productId, productType]);
};

const onSave = async (state: ProductDetailState, dispatch: any, reduxDispatch: any, history: any) => {
  const actions = bindActionCreators(notificationActions, reduxDispatch);
  dispatch({ type: Actions.RESET_ATTRIBUTEFILTERS, params: state.basicInfoForm.getFieldValue('ClassificationNum') });
  dispatch({ type: Actions.SET_LOADING, params: true });
  saveProduct(dispatch, state)
    .then((response: any) => {
      actions.setNotification('success', 'Product saved');
      // Uncomment this when productExt is going to be enable again
      // saveProductExt(dispatch, state)
      //   .then()
      //   .catch(() => actions.setNotification('error', 'Extended Product Info was not updated'));
      saveChannelInv(dispatch, state, response.productId)
        .then()
        .catch((e) => actions.setNotification('error', e));

      state.basicInfoForm.resetFields();
      state.basicInfoForm?.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        styleMaster: {
          ...state.basicInfoForm.getFieldValue('styleMaster'),
          sizeCode: null,
          colorCode: null,
          lengthCode: null,
          widthCode: null,
          editReviewValues: [],
        },
      });
      dispatch({ type: Actions.SET_SHOWSTYLEMASTERSTEPS, params: false });
      dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
      dispatch({ type: Actions.RESET_ATTRIBUTEFILTERS });
      dispatch({ type: Actions.RESET_PRODUCT });
      dispatch({ type: Actions.SET_EDITMODE, params: false });
      dispatch({ type: Actions.SET_FORMUPDATED, params: false });
      dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs: ['Basic', 'Pricing'], show: false } });
      dispatch({ type: Actions.SET_LOADING, params: false });
      if (state.isCreating) {
        history.push(`/product-detail/${response.productId}`);
      } else {
        setPage(dispatch, state.productType, state.bundleType, state.productId);
      }
    })
    .catch(() => {
      actions.setNotification('error', 'There was an error');
      history.push('/list-products');
    });
};

const onFinishFailedForm = (values: any, dispatch: any, notifications: any, state: ProductDetailState) => {
  const tabNames = values.errorFields.map((ef: any) => Object.keys(formTabsStructure).find(key => formTabsStructure[key].includes(ef.name[0])));
  const errorTabs = tabNames.filter((data: any, index: any) => tabNames.indexOf(data) === index);
  const newTabsMap = new Map();
  errorTabs.forEach((item: string) => newTabsMap.set(item, true));
  if (!_.isEqual(state.tabErrorDots, newTabsMap)) {
    dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs, show:true } });
    notifications.setNotification('error', 'Please fill all mandatory fields');
  }
};

const Controls: React.FC<{ isCreating: boolean }> = ({ isCreating }) => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  const history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  if (!state || !dispatch) {
    return null;
  }

  const resetProduct = () => {
    state.basicInfoForm.resetFields();
    dispatch({ type: Actions.SET_SHOWSTYLEMASTERSTEPS, params: false });
    dispatch({ type: Actions.RESET_ATTRIBUTEFILTERS });
    dispatch({ type: Actions.RESET_PRODUCT });
    dispatch({ type: Actions.SET_EDITMODE, params: false });
    dispatch({ type: Actions.SET_FORMUPDATED, params: false });
    dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs: ['Basic', 'Pricing'], show: false } });
  };

  return (
    <>
      <ConfirmationModal
        handleCancel={() => setShowConfirm(false)}
        handleConfirm={resetProduct}
        visible={showConfirm}
        title="Are you sure you want to cancel?"
        confirmText="Yes"
        cancelText="No"
      />
      <FormButtons
        editingMode={state.editMode}
        permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
        onEdit={() => {
          dispatch({ type: Actions.SET_EDITMODE, params: true });
        }}
        onSave={state.basicInfoForm.submit}
        onCancel={() => {
          if (isCreating) {
            history.push('/list-products');
          } else if (state.isFormUpdated) {
            setShowConfirm(true);
          } else {
            resetProduct();
          }
        }}
        disableSave={state.editMode && !state.isFormUpdated}
      />
    </>
  );
};

const PageHeading: React.FC = () => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);

  if (!state || !dispatch) {
    return null;
  }

  const pageTitle = (
    <TitleWrapper>
      <TitlePlaceholderWrapper>Product -</TitlePlaceholderWrapper>
      <Form.Item
        name={state.product?.ProductBasic.ProductTitle === '' || state.product?.ProductBasic.ProductTitle === undefined ? 'SKU' : 'ProductTitle'}
        noStyle
      >
        <TitleInput tabIndex={-1} id="headerTitle" type="text" readOnly bordered={false} placeholder="New Product" />
      </Form.Item>
    </TitleWrapper>
  );

  return (
    <Heading
      title={pageTitle}
      actions={(
        <TopHeaderButtonsWrapper>
          <HeaderButton
            $hasPermission
            type="primary"
          >
            Details
            <Info width={22} height={22} pathColor="#fff" />
          </HeaderButton>
          <HeaderButton
            $hasPermission
          >
            Dashboard
            <Dashboard width={22} height={22} />
          </HeaderButton>
        </TopHeaderButtonsWrapper>
      )}
    />
  );
};

const ProductDetailScreen: React.FC<{ isCreating: boolean }> = ({ isCreating = false }) => {
  const { productId, productType, bundleType } = useParams<{ productId?: string, productType?: string, bundleType?: string }>();
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState({
    basicInfoForm: Form.useForm()[0],
    attributeFilterForm: Form.useForm()[0],
    attributesForm: Form.useForm()[0],
    channelInvForm: Form.useForm()[0],
    productExtForm: Form.useForm()[0],
    productType: Number(productType || 100),
    bundleType: Number(bundleType || 100),
    isCreating,
    showSteps: isCreating,
  }));
  const notifications = bindActionCreators(notificationActions, useDispatch());
  useProductDetailPageLoad(dispatch, Number(productType), Number(bundleType), productId);
  return (
    <ProductDetailScreenContext.Provider value={[state, dispatch]}>
      <ContentLayout>
        <Prompt
          when={state.isFormUpdated}
          message="You have unsaved changes, are you sure you want to exit?"
        />
        <Spin spinning={state.loading}>
          <Form.Provider onFormChange={() => {
            if (!state.isFormUpdated) {
              dispatch({ type: Actions.SET_FORMUPDATED, params: true });
            }
          }}
          >
            <Form
              form={state.basicInfoForm}
              labelCol={{
                sm: { span: 12 },
                lg: { span: 7 },
                xl: { span: 7 },
              }}
              wrapperCol={{
                sm: { span: 13 },
                lg: { span: 12 },
                xl: { span: 17 },
              }}
              initialValues={{ size: 'middle' }}
              onFinish={() => onSave(state, dispatch, reduxDispatch, history)}
              onFinishFailed={(values: any) => onFinishFailedForm(values, dispatch, notifications, state)}
            >
              <PageHeading />
              <Spacer />
              <SiteContent>
                <Tabs defaultActiveKey={state.activeTab} tabBarExtraContent={<Controls isCreating={isCreating} />}>
                  <TabPane
                    tab={(
                      <Badge dot={state.tabErrorDots.get('Basic')} offset={[5, 5]}>
                        Basic Info
                      </Badge>
                    )}
                    key="Basic"
                    forceRender
                  >
                    <BasicInfo
                      isCreating={isCreating}
                    />
                  </TabPane>
                  {
                    state.productType === 2 && state.bundleType === 0 && (
                      <TabPane
                        tab={(
                          <Badge dot={state.tabErrorDots.get('Variation')} offset={[5, 5]}>
                            Style Master
                          </Badge>
                        )}
                        key="Variation"
                        forceRender
                      >
                        <StyleMasterScreen isCreating={isCreating} />
                      </TabPane>
                    )
                  }
                  {
                    state.productType === 0 && state.bundleType === 1 && (
                      <TabPane
                        tab="Bundled"
                        key="Bundled"
                        forceRender
                      >
                        <ProductBundles />
                      </TabPane>
                    )
                  }
                  <TabPane
                    tab={(
                      <Badge dot={false} offset={[5, 5]}>
                        Images
                      </Badge>
                    )}
                    key="Images"
                    forceRender
                  >
                    <ProductImages />
                  </TabPane>
                  <TabPane
                    tab={(
                      <Badge dot={false} offset={[5, 5]}>
                        Inventory
                      </Badge>
                    )}
                    key="Inventory"
                    forceRender
                  >
                    <Inventory />
                  </TabPane>
                  <TabPane
                    tab={(
                      <Badge dot={false} offset={[5, 5]}>
                        Attributes
                      </Badge>
                    )}
                    key="Attributes"
                    forceRender
                  >
                    <Attributes />
                  </TabPane>
                  <TabPane
                    tab={(
                      <Badge dot={false} offset={[5, 5]}>
                        Channel Inv
                      </Badge>
                    )}
                    key="ChannelInv"
                    forceRender
                  >
                    <ChannelInv />
                  </TabPane>
                  {/* <TabPane */}
                  {/*  tab={( */}
                  {/*    <Badge dot={false} offset={[5, 5]}> */}
                  {/*      Extended */}
                  {/*    </Badge> */}
                  {/*  )} */}
                  {/*  key="Extended" */}
                  {/*  forceRender */}
                  {/* > */}
                  {/*  <Extended /> */}
                  {/* </TabPane> */}
                </Tabs>
              </SiteContent>
            </Form>
          </Form.Provider>
        </Spin>
      </ContentLayout>
    </ProductDetailScreenContext.Provider>
  );
};

export default ProductDetailScreen;
