import React, { ReactNode, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Badge, Dropdown, Layout, Menu, Popover, Button, Select, Typography } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import '../../assets/styles/layout.less';
// import DesignService from '../../assets/icons/design_service';
import AvatarSVG from '../../assets/icons/avatar';
import _profileActions from '../../redux/actions/profiles';
import _routesActions from '../../redux/actions/routes';
import _sidebar from '../../redux/actions/sidebar';
import { fetchUserPermissions } from '../../services/userManagement';
import { logout } from '../../util';
import Settings from '../../assets/icons/settings';
import Shipping from '../../assets/icons/shipping';
import linkERP from '../../util/linkERP';
import { clearCachedData } from '../../screens/DetailProduct/helper';
import VersionModal from '../common/VersionModal';

const { Option } = Select;
const { Header, Content } = Layout;

const HeaderContainer = styled(Header)`
  width: 100%;
  height: 55px;
  box-shadow: 0 -1px 8px 0 rgba(125, 140, 166, 0.08);
  border: solid 1px #d8dde6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 20px;
`;

// const VerticalDivider = styled.div`
//   position: relative;
//   display: inline-block;
//   height: 2em;
//   margin-right: 16px;
//   vertical-align: middle;
//   width: 3pt;
//   border-radius: 3pt;
//   background-color: #414141;
//   opacity: 85%;
//   font-size: 14px;
//   font-variant: tabular-nums;
//   line-height: 1.5715;
//   list-style: none;
//   font-feature-settings: 'tnum';
// `;

const HeaderActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ContentContainer = styled(Content)`
  overflow-y: auto;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
`;

const ProfilesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  & .ant-select-arrow{
    display: none;
  }
`;

const ProfileSelect = styled(Select)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  // line-height: 1.57;
  letter-spacing: normal;
  // color: #606060 !important;
`;

// const IconSpacer = styled.div`
//   margin-right: 16px;
//   display: flex;
// `;

const ButtonSpacer = styled(Button)`
  margin-right: 16px;
  display: flex;
  padding: 0px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
`;

const ActionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: black;
  font-size: 14px;
`;

const ButtonNoBorder = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover{
    color:#006dff;
    text-decoration: underline;
  }
`;

interface Props {
  children: ReactNode,
  profilesEntity: {
    profiles: any,
    selectedIndex: number
  },
  profileActions: any,
  routesActions: any,
  sidebarActions: any,
  isModal?: any,
  collapse: boolean,
}

const LayoutContainer = styled(Layout) <{ $isModal: boolean, $collapse: boolean }>`
  margin-left: ${(props) => props.$isModal || props.$collapse ? '80px' : '250px'};
  height: 100vh;
`;

const ProfileDropdownMenu = styled(Menu)`
    width: 300px;
    padding: 4px;
  & .ant-dropdown-menu-item:hover,
  & .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
  & .ant-dropdown-menu-title-content:not(:has(${ActionItemWrapper})),
  & .profile-menu-label {
    color: #606060;
    font-size: 13px;
    &:hover {
      color:#006dff;
      text-decoration: underline;
    }
  }
`;
// const languages = (
//   <Menu>
//     <Menu.Item key="USA" icon={<USAIcon style={{ marginRight: '5px' }} />}>
//       US - English
//     </Menu.Item>
//   </Menu>
// );

function ContentLayout(
  {
    children,
    profilesEntity,
    profileActions,
    routesActions,
    sidebarActions,
    isModal,
    collapse,
  }: Props,
) {
  const { Text } = Typography;
  const profile = useSelector((state: any) => state.profiles.profiles[0]);
  const [versionModalVisible, setVersionModalVisible] = useState<boolean>(false);

  // eslint-disable-next-line
  const profileMenuItem = (p: StringKAnyVPair, index: number) => {
    return (
      <Menu.Item onClick={() => handleChange(index)} key={p.ProfileNum}>
        {p.DisplayName}
      </Menu.Item>
    );
  };

  const profileSelector = React.useCallback(() => {
    const { selectedIndex, profiles } = profilesEntity;
    const fs = profiles[selectedIndex];
    let title = '';
    if (fs && fs.DisplayName) {
      title = fs.DisplayName;
    }

    if (profiles.length <= 1) return null;

    return (
      <Menu.SubMenu
        className="ant-typography-ellipsis"
        key="profileMenus"
        popupOffset={[-3, -3]}
        title={<Text className="profile-menu-label" ellipsis={{ tooltip: title }}>Switch Profile</Text>}
      >
        {profilesEntity.profiles.map((e: any, index: number) => profileMenuItem(e, index))}
      </Menu.SubMenu>
    );
  }, [Text, profileMenuItem, profilesEntity]);

  const userActions = (
    <ProfileDropdownMenu>
      <Menu.Item key="userEmail">
        {/* {profile.Email} */}
        <ActionItemWrapper>
        {profile.Email}
        <ButtonNoBorder type='button' onClick={logout}>Logout</ButtonNoBorder>
        </ActionItemWrapper>
      </Menu.Item>
      {profileSelector()}
      {/* <Menu.Item key="logout" onClick={logout}>
        Logout
      </Menu.Item> */}
      <Menu.Item key="about" onClick={() => setVersionModalVisible(true)}  >
        About
      </Menu.Item>
    </ProfileDropdownMenu>
  );

  const handleChange = async (index: any) => {
    try {
      profileActions.setSelectedIndex(index);
      routesActions.setCurrentKey([]);
      const permissions = await fetchUserPermissions(profilesEntity.profiles[0].Email);
      profileActions.setPermissions(permissions);
      clearCachedData();
    } catch (e) {
      profileActions.setPermissions([]);
    }
    const getUrl = window.location;
    window.location.href = `${getUrl.protocol}//${getUrl.host}/${getUrl.pathname.split('/')[1]}`;
  };

  return (
    <LayoutContainer $isModal={isModal} $collapse={collapse}>
      <HeaderContainer>
        {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          style: {
            marginRight: '15px',
          },
          onClick: () => sidebarActions.setCollapse(),
        })}
        <HeaderActionsWrapper>
          <ProfilesWrapper>
            {/* <DesignService
              height={32}
              width={32}
              color="#006dff"
              style={{
                borderRadius: '4px',
                border: '1px solid #d8dde6',
                padding: 5,
              }}
            /> */}
            <ProfileSelect
              onChange={handleChange}
              value={profilesEntity.selectedIndex}
              bordered={false}
            >
              {
                profilesEntity.profiles.map((p: any, index: number) => (
                  <Option key={p.ProfileNum} value={index}>{p.DisplayName}</Option>
                ))
              }
            </ProfileSelect>
          </ProfilesWrapper>
          <ActionsWrapper>
            <Popover content='Enterprise Resource Planning' title="ERP">
              <ButtonSpacer
                ghost
                onClick={(e) => {
                  e.preventDefault();
                  linkERP.open();
                }}
              >
                <Badge
                  count={0}
                  size="small"
                  style={{ backgroundColor: '#006dff' }}
                  offset={[0, 4]}
                >
                  <Settings
                    width={28}
                    height={28}
                    style={{
                      fill: '#414141',
                    }}
                  />
                </Badge>
              </ButtonSpacer>
            </Popover>
            <Popover content='Warehouse Management System' title="WMS">
              <ButtonSpacer
                ghost
                href={process.env.WMS_LINK}
                target="_blank"
              >
                <Shipping
                  width={28}
                  height={28}
                  style={{
                    fill: '#414141',
                  }}
                />
              </ButtonSpacer>
            </Popover>
            {/*<VerticalDivider />*/}
            {/*<IconSpacer>*/}
            {/*  <Badge*/}
            {/*    count={0}*/}
            {/*    size="small"*/}
            {/*    style={{ backgroundColor: '#006dff' }}*/}
            {/*    offset={[0, 4]}*/}
            {/*  >*/}
            {/*    <BubbleMessage*/}
            {/*      width={28}*/}
            {/*      height={28}*/}
            {/*      style={{*/}
            {/*        fill: '#414141',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Badge>*/}
            {/*</IconSpacer>*/}
            {/*<IconSpacer>*/}
            {/*  <Badge*/}
            {/*    count={0}*/}
            {/*    size="small"*/}
            {/*    style={{ backgroundColor: '#006dff' }}*/}
            {/*    offset={[-4, 4]}*/}
            {/*  >*/}
            {/*    <NotificationsIcon*/}
            {/*      width={28}*/}
            {/*      height={28}*/}
            {/*      style={{*/}
            {/*        fill: '#414141',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Badge>*/}
            {/*</IconSpacer>*/}
            {/*<Dropdown.Button*/}
            {/*  className="headerIconDropdown"*/}
            {/*  overlay={languages}*/}
            {/*  placement="bottomCenter"*/}
            {/*  icon={(*/}
            {/*    <USAIcon width={28} height={28} />*/}
            {/*  )}*/}
            {/*  style={{ border: 'none', marginRight: '15px' }}*/}
            {/*/>*/}
            <Dropdown.Button
              className="headerIconDropdown"
              overlay={userActions}
              placement="bottomCenter"
              icon={(
                // <Avatar src="https://cdn.onlinewebfonts.com/svg/img_569205.png" />
                <AvatarSVG />
              )}
            />
          </ActionsWrapper>
        </HeaderActionsWrapper>
      </HeaderContainer>
      <ContentContainer>
        {children}
      </ContentContainer>
      <VersionModal show={versionModalVisible} onClose={() => setVersionModalVisible(false)} />
    </LayoutContainer>
  );
}

ContentLayout.defaultProps = {
  isModal: false,
};

const mapStateToProps = (state: any) => ({
  profilesEntity: state.profiles,
  collapse: state.sidebar.collapse,
});

const mapDispatchToProps = (dispatch: any) => ({
  profileActions: bindActionCreators(_profileActions, dispatch),
  routesActions: bindActionCreators(_routesActions, dispatch),
  sidebarActions: bindActionCreators(_sidebar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentLayout);
