import React, { useCallback, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Upload,
  notification,
  Select,
  Space,
  Typography,
  Radio,
} from 'antd';
import { InboxOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import theme from '../../assets/styles/theme.js';
import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
import { FormLabel, ModalTitle } from '../../components/common/styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
  HTTP_STATUS_OK,
} from '../../constants/config';
import {
  uploadFile,
  downloadImageTemplate,
} from '../../services/imports';


import {
  downloadFile
} from '../../util/files';

const { Text } = Typography;

const DialogBodyWrapper = styled(Row)`
  & .file-section {
    margin-bottom: 12px;
  }

  & .form-ctn {
    width: 100%;
    height: 100%;
  }

  & .form-label.required::before {
    color: ${theme['@danger-color']};
    content: '* ';
  }
`;

const UploadOptionsArea = styled.div`
  padding: 8px;

  &.options-has-error {
    border: 1px solid #BC0000;
    border-radius: 4px;
    padding: 7px;
  }

  & .option-label {
    display: inline-block;
    font-weight: 500;
    width: 246px;
  }
`;


const FileUploadModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DownloadTemplateButton = styled(Button)`
  margin-right: 30px;
`;

interface DownloadProps {
  channelNum: number;
  fileImportLevel: number;
}

const TemplateDownloadButton: React.FC<DownloadProps> = (props: DownloadProps) => {
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  
  
  const onClick = useCallback(async () => {

    let template;
    try {
      setLoadingTemplate(true);
      const { data: fileBlob, status } = await downloadImageTemplate(props);

      if (HTTP_STATUS_OK === status) {
        // console.log('h->', headers);
        template = fileBlob;
      }
      
    } catch (e) {
      setLoadingTemplate(false);
    } finally {
      setLoadingTemplate(false);
    }

    if (!template) {
      notification.error({ message: 'No template information' });
      return;
    }
    
    downloadFile(
      'images-template.xlsx',
      template,
    );

  }, [props]);
  
  return (
    <DownloadTemplateButton onClick={onClick} loading={loadingTemplate} type="primary" ghost>
      Download Template
      <CloudDownloadOutlined />
    </DownloadTemplateButton>
  );
};

type Props = {
  close: Function;
  options: StringKAnyVPair;
};

const ImportModal = (props: Props) => {
  const {
    options: {
      ChannelNum: channelNum,
    },
  } = props;
  const { useState } = React;
  const [fileList, setFileList] =  useState<any[]>([]);
  const [importReason, setImportReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileImportLevel, setFileImportLevel] = useState(1);
  const [optionHasError, setOptionHasError] = useState(false);
  const [optionApplyChange, setOptionApplyChange] = useState<boolean>();
  const [optionOverwrite, setOptionOverwrite] = useState<boolean>();
  const [optionIgnore, setOptionIgnore] = useState<boolean>();

  const isAllOptionChoosed = React.useMemo(() => {
    return !(optionApplyChange === undefined
      || optionOverwrite === undefined
      || optionIgnore === undefined
    );
  }, [optionApplyChange, optionIgnore, optionOverwrite]);

  const onOptionApplyChange = (evt: any) => {
    setOptionApplyChange(evt.target.value);
    setOptionHasError(false);
  };

  const onOptionIgnoreChange = (evt: any) => {
    setOptionIgnore(evt.target.value);
    setOptionHasError(false);
  };

  const onOptionOverwriteChange = (evt: any) => {
    setOptionOverwrite(evt.target.value);
    setOptionHasError(false);
  };

  const dialogFoot = () => {
    return (<Row justify="end">
      <Space>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isContentReady()}
          onClick={handleUpload}
          type="primary"
        >
          Upload
        </Button>
      </Space>
    </Row>);
  };

  const handleUpload = async () => {
    if (!isAllOptionChoosed) {
      setOptionHasError(true);
      return;
    }
    const data = new FormData();

    data.append('FileOperation', '30011');
    data.append('Reason', importReason);
    data.append('File', fileList[0].originFileObj, fileList[0].name);

    for (let k in props.options) {
      data.append(k, props.options[k]);
    }

    setIsLoading(true);

    try {
      const res = await uploadFile(data, channelNum, optionOverwrite, optionIgnore, optionApplyChange) as StringKAnyVPair;

      if (res && typeof res === 'object' && res.msg) {
        notification.success({
          message: res.msg,
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });
        onCancel();
      } else {
        // eslint-disable-next-line
        throw 'Unexpected response from server';
      }
    } catch (e) {
      notification.error({
        message: `Upload file error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isContentReady = () => {
    return importReason.trim() && fileList.length > 0;
  };

  const onCancel = () => {
    props.close();
  };

  const onFilesChange = (evt: any) => {
    setFileList(evt.fileList.slice(-1));
  };

  const onReasonChange = (evt: any) => {
    setImportReason(evt.target.value);
  };

  return (<>
    <Modal
      centered
      style={{ minWidth: '75vw' }}
      destroyOnClose
      footer={dialogFoot()}
      onCancel={onCancel}
      title={(
        <FileUploadModalTitle>
          <ModalTitle>Import Images</ModalTitle>
          <Space>
            <Select
              onChange={setFileImportLevel}
              placeholder="Import Level"
              style={{ width: 150 }}
              value={fileImportLevel}
              options={[
                {
                  label: 'Style Master',
                  value: 1,
                },
                {
                  label: 'Sub Style',
                  value: 2,
                },
                {
                  label: 'Product',
                  value: 3,
                },
              ]}
            />
            <TemplateDownloadButton channelNum={channelNum} fileImportLevel={fileImportLevel} />
          </Space>
        </FileUploadModalTitle>
      )}
      visible
      width={700}
    >
      <DialogBodyWrapper>
        <Col className="form-ctn">
          <UploadOptionsArea
            className={optionHasError ? 'options-has-error' : ''}
          >
            <Row>
              <Space style={{ width: '100%' }}>
                <Text className="option-label">Apply change to lower level SKU(s):</Text>
                <Radio.Group
                  onChange={onOptionApplyChange}
                  value={optionApplyChange}
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Space>
            </Row>
            <Row>
              <Space style={{ width: '100%' }}>
                <Text className="option-label">If destination has value (Not empty):</Text>
                <Radio.Group
                  onChange={onOptionOverwriteChange}
                  value={optionOverwrite}
                >
                  <Radio value>Overwrite</Radio>
                  <Radio value={false}>Don't Overwrite</Radio>
                </Radio.Group>
              </Space>
            </Row>
            <Row>
              <Space style={{ width: '100%' }} align="start">
                <Text className="option-label">If source doesn't have value (empty):</Text>
                <Radio.Group
                  onChange={onOptionIgnoreChange}
                  value={optionIgnore}
                >
                  <Radio value={false}>Use empty value to update destination</Radio>
                  <Radio value>Ignore (don't update destination)</Radio>
                </Radio.Group>
              </Space>
            </Row>
          </UploadOptionsArea>
          {optionHasError && (
            <div className="ant-form-item-explain ant-form-item-explain-error">
              <div role="alert">All upload options required</div>
            </div>
          )}
          <FormLabel className="form-label required">SKU</FormLabel>
          <div className="file-section">
            <Upload.Dragger
              accept=".xls, .xlsx"
              beforeUpload={() => false}
              fileList={fileList}
              onChange={onFilesChange}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Upload.Dragger>
          </div>
          <FormLabel className="form-label required">Import Reason</FormLabel>
          <Input
            onChange={onReasonChange}
            placeholder="Write a brief description of why you're importing these files"
            value={importReason}
          />
        </Col>
      </DialogBodyWrapper>
    </Modal>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default ImportModal;
