import styled from 'styled-components';
import { Col } from 'antd';

import theme from '../../../assets/styles/theme.js';

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  &.image-tree-ctn {
    width: 36px;
  }
`;

export const SearchCol = styled(Col)`
  width: 100%;

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #D9D9D9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ': ';
  }

  & .loading-wrapper {
    display: 'inline-block';
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    width: 768px;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 260px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      width: 820px;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      width: 868px;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      width: 928px;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      width: 1028px;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      width: 1150px;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

export const TowerDialogBody = styled.div`
  background-color: #FFF;
  border-radius: ${theme['@border-radius-base']}; 
  height: calc(100vh - 200px);
  /*max-height: 800px;*/
  /*min-height: 460px;*/
  overflow-y: auto;
  padding: 12px;

  & .cell-right-icon:hover {
    cursor: pointer;
  }

  & .form-label {
    color: #555;
    font-size: 16px;
    font-weight: 550;
  }

  & .form-label:after {
    content: ':';
  }

  & .form-section {
    /*background-color: #F5F6F8;*/
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    /*padding: 8px;*/
    margin-bottom: 12px;
    box-shadow: rgba(0, 0, 0, .18) 0 3px 6px;
  }

  & .form-section:last-child {
    margin-bottom: 0;
  }

  & .grp-left-icon {
    cursor: pointer;
    margin-right: 6px;
  }

  & .mapping-cell-label {
    max-width: calc(100% - 32px);
  }

  & .section-title-bar {
    background-color: #D5E1EB;
    padding: 10px;
    width: 100%;
  }
  
  & .section-title-line {
    width: 100%;
  }
  
  & .section-title-line hr {
    background-color: #80CBC4;
    border: none;
    height: 1px;
    margin: 0;
  }

  & .section-title {
    color: #184A4E;
    font-size: 16px;
    font-weight: 600;
  }

  & .section-title-bar .section-title {
    color: #111;
  }

  & .top-toolbar {
    margin-bottom: 12px;
  }

  & .top-toolbar .search-box {
    width: 300px;
  }
`;
