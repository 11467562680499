import { Form, Input, Modal, Spin, Row, Col, message, InputNumber, Table, Space, Button, Popconfirm } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { fetchContentSourceType, addContentSourceType, sortContentSourceType, editContentSourceType, deleteContentSourceType } from '../../services/copywriting';
import { ColumnsType } from 'antd/lib/table';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { trimValues } from '../../util/validators';
import styled from 'styled-components';

interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  formValues: any;
  visible: boolean;
}

const HoverDangerButton = styled(Button)`
  &:hover {
    color: red;
  }
`;

function ModalForm({ formValues, visible, onClose, onSuccess }: ModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState<number>(-1);
  const isEditing = (record: any) => record.rowNum === editingKey;

  const columns = [
    {
      title: 'Group Name',
      dataIndex: 'enumName',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Action',
      render: (value: string, record: any, index: number) => {
        const editable = isEditing(record);
        if (index > 3) return (
          <Space>
            {
              !editable ? (
                <>
                  <Button
                    type="link"
                    disabled={editingKey !== -1}
                    onClick={() => edit(record)}
                  >
                    Edit
                  </Button>
                  <Popconfirm title={`Are you sure you want to delete this group ?`} onConfirm={() => deleteGroup(record)}>
                    <HoverDangerButton
                      type="link"
                    >
                      Delete
                    </HoverDangerButton>
                  </Popconfirm>
                </>
              ) : (
                <>
                  <Button
                    type="link"
                    onClick={() => save(record.rowNum)}
                  >
                    Save
                  </Button>
                  <HoverDangerButton
                    type="link"
                    onClick={() => cancel(record)}
                  >
                    Cancel
                  </HoverDangerButton>
                </>
              )
            }
          </Space>
        )
      }
    },
    {
      render: (value: string, row: any, index: number) => {
        if (index > 3) {
          return (
            <Space>
              <CaretUpOutlined
                onClick={() => {
                  reorderList(row, true)
                }}
              />
              <CaretDownOutlined
                onClick={() => {
                  reorderList(row, false)
                }}
              />
            </Space>
          )
        }
      }
    }
  ]

  const add = () => {
    form.setFieldsValue({ enumName: '', description: '' });
    setList([...list, {
      enumName: '',
      description: '',
      rowNum: 0,
    }])
    setEditingKey(0);
  }

  const edit = (record: any) => {
    form.setFieldsValue({ enumName: record.enumName, description: record.description });
    setEditingKey(record.rowNum);
  };

  const save = async (rowNum = 0) => {
    const formValues = trimValues(await form.validateFields());
    if (formValues.errorFields) return;
    try {
      const params = {
        EnumName: formValues.enumName,
        Description: formValues.description,
      }

      const { isSuccess, message: resMsg = '' } = rowNum === 0 ?
        await addContentSourceType(params)
        : await editContentSourceType(rowNum, params);

      if (isSuccess) {
        getGroupList();
        setEditingKey(-1);
      } else {
        message.error(resMsg || 'Save group failed!')
      }
    } catch (error) {
      message.error(`There was an error: ${error}`);
    }
  }

  const deleteGroup = async (record: any) => {
    try {
      const { isSuccess, message: resMsg = '' } = await deleteContentSourceType(record.rowNum);
      if (isSuccess) {
        message.success('Delete Successfully!')
        getGroupList();
      } else {
        message.error(resMsg || 'Delete group failed!')
      }
    } catch (error) {
      message.error(`There was an error: ${error}`);
    }
  }

  const cancel = (record: any) => {
    setEditingKey(-1);
    if (record.rowNum === 0) {
      const temp = [...list];
      temp.pop();
      setList(temp);
    }
  };

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const getGroupList = async () => {
    setProcessing(true);
    try {
      const { data = [], isSuccess, message: resMsg = '' } = await fetchContentSourceType();
      if (isSuccess) {
        setList(data);
      } else {
        message.error(resMsg || 'No results found');
      }
    } catch (error) {
      console.log('error', error);
    }
    setProcessing(false);
  };

  const reorderList = async (record: any, ascend: boolean) => {
    try {
      const { isSuccess, message: resMsg = '' } = await sortContentSourceType(record, ascend);
      if (isSuccess) {
        getGroupList();
      } else {
        message.error(resMsg || 'Reorder failed');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: ColumnsType;
    index: number;
    children: React.ReactNode;
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  useEffect(() => {
    getGroupList();
  }, []);

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={
        <Row justify="space-between" align="middle">
          <Col>{`Content Resources - Group`}</Col>
          <Col>
            <Button
              type="primary"
              onClick={add}
              disabled={editingKey !== -1}
            >
              <PlusOutlined />
              Add
            </Button>
          </Col>
        </Row>
      }
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={1000}
      footer={
        <Button
          onClick={() => {
            onSuccess();
            onClose();
          }}
        >
          <CloseOutlined />
          Close
        </Button>
      }
    >
      <Spin spinning={processing}>
        <Form form={form} component={false}>
          <Table
            size="middle"
            columns={mergedColumns}
            dataSource={list}
            pagination={{ size: "small", pageSize: 10 }}
            rowKey="enumValue"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </Spin>
    </Modal>
  );
}

export default ModalForm;
