import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  Space,
  Spin,
  Statistic,
  Card,
} from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import ChannelsSelect from '../components/common/channels/ChannelsSelect';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import { ListOrdersParams, useListOrdersQuery } from '../redux/api/orders';
import { getColorByIndex } from '../util/colors';
import { OrderStatus } from '../types/enums';
// import { appendUnits } from '../util/strings';
import { convertToUtcTime } from '../util/utcTime';
import { Chart } from 'react-google-charts';
import { DataGrid } from '../components/common/datagrid/DataGrid';
import { getChannelAccountStatisticList } from '../services/channels';

const { RangePicker } = DatePicker;

const FormWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`;

const ChartGroup = styled(Row)`
  margin-bottom: 2rem;
`;

const NoData = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #546279;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 2px 5px 1px rgba(51, 95, 226, 0.20);

  .ant-card-head {
    background-color: rgba(44, 105, 154, 0.2);
    height: 63px;
    font-size: 1.25rem;
    color: rgba(31, 31, 31, 0.85);
  }
`

interface FiltersForm extends ListOrdersParams {
  orderDate?: Moment[];
}

interface FiltersProps extends Omit<FormProps, 'onFinish'> {
  onFinish?: (data: ListOrdersParams) => void;
}

const Filters: React.FC<FiltersProps> = ({ onFinish, ...formProps }) => {
  const [form] = Form.useForm<FiltersForm>();
  const [dates, setDates] = useState<any>([moment(), moment()]);

  const disabledDate = (current: any) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open: any) => {
    if (open) {
      setDates([]);
      form.setFieldsValue({ orderDate: undefined });
    }
  };

  // const disableStartDate = (value: Moment) => {
  //   const { orderDate }: { orderDate: Moment[] } = form.getFieldsValue(['orderDate']);
  //   const [, endDate] = orderDate || [];

  //   if (!endDate) {
  //     return false;
  //   }

  //   const minDate = endDate.clone().subtract({ months: 1 });

  //   return value < minDate || value > endDate;
  // };

  // const disableEndDate = (value: Moment) => {
  //   const { orderDate }: { orderDate: Moment[] } = form.getFieldsValue(['orderDate']);
  //   const [startDate] = orderDate || [];

  //   if (!startDate) {
  //     return false;
  //   }

  //   const maxDate = startDate.clone().add({ months: 1 });
  //   return value < startDate || value > maxDate;
  // };

  const onTodayClicked = useCallback(() => {
    const today = moment();
    form.setFieldsValue({ orderDate: [today, today] });
  }, [form]);

  const onFormFinished = useCallback(
    (values: FiltersForm) => {
      const payload = {
        ...values,
      };

      // Transform moment dates to string and remove `orderDate` attribute
      if (values.orderDate) {
        payload.orderDateFrom =
          values.orderDate && values.orderDate[0] && values.orderDate[1]
            ? convertToUtcTime(values.orderDate[0]?.startOf('days').format())
            : undefined;
        payload.orderDateTo =
          values.orderDate && values.orderDate[0] && values.orderDate[1]
            ? convertToUtcTime(values.orderDate[1]?.endOf('days').format())
            : undefined;
        delete payload.orderDate;
      }

      // Emit values
      onFinish?.(payload);
    },
    [onFinish],
  );

  return (
    <FormWrapper>
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formProps}
        form={form}
        layout="inline"
        onFinish={onFormFinished}
      // initialValues={{
      //   orderDate: [moment(), moment()],
      //   $top: 200,
      // }}
      >
        <Form.Item label="Date">
          <Space>
            {/* <Form.Item
              name={['orderDate', 0]}
              rules={[{ required: true, message: 'You must select a starting date' }]}
              noStyle
            >
              <DatePicker placeholder="Start date" disabledDate={disableStartDate} />
            </Form.Item>
            <SwapRightOutlined />
            <Form.Item
              name={['orderDate', 1]}
              rules={[{ required: true, message: 'You must select an end date' }]}
              noStyle
            >
              <DatePicker placeholder="End date" disabledDate={disableEndDate} />
            </Form.Item> */}
            <Form.Item
              name="orderDate"
              initialValue={[moment(), moment()]}
              rules={[{ required: true, message: 'You must select date' }]}
              noStyle
            >
              <RangePicker
                // value={hackValue || value}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                onOpenChange={onOpenChange}
                format="MM/DD/YYYY"
              />
            </Form.Item>
            <Button onClick={onTodayClicked}>
              <CalendarOutlined />
              Today
            </Button>
          </Space>
        </Form.Item>
        <Form.Item label="Channel" name="channelNum">
          <ChannelsSelect style={{ minWidth: '200px' }} placeholder="Select Channel" allowClear />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">
            Apply
            {/* <PlusOutlined /> */}
          </Button>
        </Form.Item>
        {/* Hidden pagination fields */}
        <Form.Item name="$top" initialValue={20000} hidden noStyle>
          <Input disabled />
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

/**
 * Custom hook that takes the API data and transform it to fit the requirements for each chart.
 * @param data
 */
const useTransformData = (data?: {
  count: number;
  orders: Entities.SimpleOrder[];
}): [any, any, number, number, boolean] => {
  const [processing, setProcessing] = useState(false);
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [transformedData1, setTransformedData1] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [shipped, setShipped] = useState<number>(0);

  const processData = useCallback(
    (mounted: boolean, source?: { count: number; orders: Entities.SimpleOrder[] }) => {
      if (!source || !mounted) {
        return;
      }

      setProcessing(true);

      const value: any = {};
      const value1: any[] = [['Channels', 'Orders']];
      const value2: any[] = [['Channels', 'Orders', 'Shipped']];

      let orderCount = 0;
      let shippedCount = 0;
      source.orders.forEach((order) => {
        const { channelName, channelNum, orderStatus, totalOrderAmount } = order;
        const hasShipped =
          [OrderStatus.SHIPPED, OrderStatus.PARTIALLY_SHIPPED].indexOf(orderStatus) >= 0;
        const channelNumStr = channelNum.toString();

        if (!value[channelNumStr]) {
          value[channelNumStr] = {
            name: channelName,
            count: 0,
            shippedCount: 0,
            totalOrderAmount: 0,
          };
        }

        value[channelNumStr].count += 1;
        orderCount += 1;
        value[channelNumStr].totalOrderAmount += totalOrderAmount;

        if (hasShipped) {
          value[channelNumStr].shippedCount += 1;
          shippedCount += 1;
        }
      });

      if (!mounted) {
        return;
      }

      Object.keys(value).sort((a, b) => {
        const valueA = value[a];
        const valueB = value[b];
        const percentA = valueA.count / orderCount;
        const percentB = valueB.count / orderCount;
        return percentB - percentA;
      }).forEach((cNum) => {
        // eslint-disable-next-line
        const { name, count, shippedCount } = value[cNum];
        const percentStr = ((count / orderCount) * 100).toFixed(1);
        value1.push([`${name} - ${percentStr}%`, count]);
        value2.push([name, count, shippedCount]);
      });

      setProcessing(false);
      setTransformedData(value1);
      setTransformedData1(value2);
      setCount(orderCount);
      setShipped(shippedCount);
    },
    [],
  );

  useEffect(() => {
    let mounted = true;
    const destroy = () => {
      mounted = false;
    };

    if (data) {
      processData(mounted, data);
    }

    return destroy;
  }, [data, processData]);

  return [transformedData, transformedData1, count, shipped, processing];
};

type ChannelListingDataItem = {
  // channelNum: number;
  // channelAccount: string;
  // syncCatalog: number;
  // feedInventory: number;
  // total: number;
  // channelAccountName: string;
  // channelAccountNum: string;
  // hasCatalog: number;
  // hasInventory: number;
  // total: number;
  channelAccountNum: string;
  channelAccountName: string;
  inventoryByRule: number;
  inventoryAsZore: number;
  inventoryIsNo: number;
  catalogByRule: number;
  catalogAsZore: number;
  catalogIsNo: number;
  hasInventory: number;
  hasCatalog: number;
  total: number;
};

const Page: React.FC = () => {
  const [filters, setFilters] = useState<ListOrdersParams>({
    orderDateFrom: convertToUtcTime(moment().startOf('day').format()),
    orderDateTo: convertToUtcTime(moment().endOf('day').format()),
    // Currently, the max amount of items that can be retrieved
    $top: 20000,
  });
  const { data, isFetching } = useListOrdersQuery(filters);
  const [transformedData, transformedData1, count, shipped, processing] = useTransformData(data);
  const [loading, setLoading] = useState<boolean>(true);
  const [channelListingData, setChannelListingData] = useState<ChannelListingDataItem[]>([]);

  const channelListingStackingColumns = [
    {
      name: 'channelAccountName',
      header: 'Channel Account',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'total',
      header: 'Total',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'inventoryByRule',
      header: 'By Rule',
      group: 'feedInventory',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'inventoryAsZore',
      header: 'As Zero',
      group: 'feedInventory',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'inventoryIsNo',
      header: 'No',
      group: 'feedInventory',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'catalogByRule',
      header: 'Sync Catalog',
      group: 'syncCatalog',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'catalogAsZore',
      header: 'Prepare Catalog',
      group: 'syncCatalog',
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'catalogIsNo',
      header: 'No',
      group: 'syncCatalog',
      sortable: true,
      defaultFlex: 1,
    },
  ];

  const channelListingGroup = [
    { name: 'feedInventory', header: <div style={{ width: '100%', textAlign: 'center' }}>Feed Inventory</div> },
    { name: 'syncCatalog', header: <div style={{ width: '100%', textAlign: 'center' }}>Sync Catalog</div> },
  ];

  const getChannelListingData = async () => {
    setLoading(true);
    try {
      const res = await getChannelAccountStatisticList();
      if (Array.isArray(res)) {
        setChannelListingData(res);
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false);
    }
  };

  // const getERPWeeklyReport = async () => {
  //   const res = await fetchERPWeeklySOReports();
  //   console.log('res = ', res);
  // };

  useEffect(() => {
    getChannelListingData();
    // getERPWeeklyReport();
  }, []);

  return (
    <ContentLayout>
      <Heading title="Performance by Period" />
      <Filters onFinish={setFilters} />
      <Spacer />
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={6}>
          <Card size="small" style={{ borderTopColor: getColorByIndex(7, 2), borderTopWidth: 3 }}>
            <Statistic
              title="Total Orders"
              value={count}
              precision={0}
              valueStyle={{ color: getColorByIndex(7, 2) }}
            // prefix={<ArrowUpOutlined />}
            // suffix="%"
            />
          </Card>
        </Col>
        <Col span={2} />
        <Col span={6}>
          <Card size="small" style={{ borderTopColor: getColorByIndex(8, 2), borderTopWidth: 3 }}>
            <Statistic
              title="Total Shipped"
              value={shipped}
              precision={0}
              valueStyle={{ color: getColorByIndex(8, 2) }}
            // prefix={<ArrowUpOutlined />}
            // suffix="%"
            />
          </Card>
        </Col>
      </Row>
      <Spacer />
      <SiteContent flexGrow>
        <Spin spinning={isFetching || processing}>
          <ChartGroup justify="space-between" gutter={24}>
            <Col span={10}>
              {transformedData.length > 1 ? (
                <Chart
                  chartType="PieChart"
                  data={transformedData}
                  options={{
                    title: 'Orders by Channel',
                    titleTextStyle: { fontSize: 14 },
                    is3D: true,
                    legend: { position: 'left' },
                  }}
                  width="100%"
                  height="500px"
                />
              ) : (
                <NoData visible>No information available</NoData>
              )}
            </Col>
            <Col span={14}>
              {transformedData.length > 1 ? (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="500px"
                  data={transformedData1}
                  options={{
                    title: 'Orders and Shipments by Channel',
                    hAxis: {
                      title: 'Count',
                      minValue: 0,
                    },
                    vAxis: {
                      title: 'Channels',
                    },
                    bars: 'horizontal',
                    axes: {
                      y: {
                        0: { side: 'right' },
                      },
                    },
                    // legend: { position: "right" },
                  }}
                />
              ) : (
                <NoData visible>No information available</NoData>
              )}
            </Col>
          </ChartGroup>
        </Spin>
        {channelListingData.length > 0 && (
          <DashboardCard bordered={false} title="Channel Listing">
            <DataGrid
              loading={loading}
              dataSource={channelListingData}
              columns={channelListingStackingColumns}
              groups={channelListingGroup}
              // pagination={{ showSizeChanger: true }}
              // defaultLimit={100}
              // style={{ minHeight: channelListingData.length > 10 ? 520 : 40 * channelListingData.length + 120 }}
              style={{ minHeight: 300 }}
            />
          </DashboardCard>
        )}
      </SiteContent>
    </ContentLayout>
  );
};

export default Page;
