import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  Row,
  Space,
  Steps,
  notification,
  message,
  Drawer,
  AutoComplete,
  Typography,
} from 'antd';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, LOADING_ICON_SIZE1 } from '../../../constants/config';
import { CopywritingStepWrapper } from '../../DetailProduct/styledComponents';
import ConfirmMapping from './ConfirmMapping';
import FileSelector from './FileSelector';
import ReviewSelector from './Review';
import FinishSelector from './Finish';
import {
  uploadCopywriting,
  importCopywriting,
  fetchCopywritingelements,
} from '../../../services/copywriting';
import { RcFile } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';

const { Text } = Typography;
const fileFormats: { [key: string]: string } = {
  '5100': 'Content Resources',
  '5200': 'Shopify',
  '5300': 'Magento 2.X',
};

type Props = {
  onCancel?: Function;
};

const CopywritingSteps = (props: Props) => {
  const { Step } = Steps;
  const profile = useSelector((state: any) => state.profiles.profiles[0]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [operation, setOperation] = useState('5100');
  const [uploadResponse, setUploadResponse] = useState<any>();
  const [mappingList, setMappingList] = useState<any[]>([]);
  const [destinationNotEmptyOverwrite, setDestinationNotEmptyOverwrite] = useState(true);
  const [childrenNotEmptyOverwrit, setChildrenNotEmptyOverwrit] = useState(true);
  const [sourceEmptyIgnoreUpdate, setSourceEmptyIgnoreUpdate] = useState(true);
  const [copywritingList, setCopywritingList] = useState<any[]>([]);
  const [showChooseMapping, setShowChooseMapping] = useState(false);
  const [showSaveMapping, setShowSaveMapping] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [templateName, setTemplateName] = useState<string>();
  const [chooseMapping, setChooseMapping] = useState<any>();
  const [isSaveMapping, setIsSaveMapping] = useState(false);

  // destinationNotEmptyOverwrite: false
  // fileSize: 9596
  // jobGUID: "4a6b942c-70c7-4a25-bb59-4d8f8e54649f"
  // mappingList: [{headerName: "Title", copywritingElementNum: 0, ignore: false, removeHtmlTags: false,…},…]
  //   copywritingElementNum: 0
  // headerName: "Title"
  // ignore: false
  // removeHtmlTags: false
  // removeNewLine: false
  // trimText: false
  // masterAccountNum: 10001
  // operation: 5100
  // originalFileName: "Copywriting_Import_sample.xlsx"
  // otherInfo: null
  // profileNum: 10001
  // rowsCount: 0
  // sourceEmptyIgnoreUpdate: false
  // systemFileName: "Copywriting_Import_sample=4a6b942c-70c7-4a25-bb59-4d8f8e54649f=.xlsx"
  // userEmail: ""

  const LoadTemplates = useCallback(() => {
    if (templates.length > 0) return;
    //todo fetch templates
    setTemplates([
      { lable: 'test1', value: 'test1' },
      { lable: 'Test2', value: 'test2' },
      { lable: 'Joyboy', value: 'Joyboy' },
    ]);
  }, [setTemplates, templates]);

  const loadInitialData = useCallback(async () => {
    if (copywritingList.length > 0) return;
    setIsLoading(true);
    try {
      const res = await fetchCopywritingelements();
      if (res.isSuccess && Array.isArray(res.data)) {
        setCopywritingList(res.data);
      } else {
        message.error(res.message);
      }
    } catch (e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch data error: ${e}`,
      });
    } finally {
      setIsLoading(false);
    }
  }, [copywritingList]);

  const saveMapping = useCallback(async () => {
    try {
      setIsSaveMapping(true);
      //todo post save mappong
      setIsSaveMapping(false);
      setShowSaveMapping(false);
      message.success('Save successfully');
    } catch (error) {
      setIsSaveMapping(false);
    }
  }, [setIsSaveMapping]);

  const matchValue = useCallback(
    (data: any) => {
      console.log(data);
      console.log(copywritingList);
      const temp = copywritingList.filter(
        (i) => i.elementName.toLowerCase().trim() === data.headerName.toLowerCase().trim(),
      );
      console.log(temp);
      if (temp.length > 0) {
        return temp[0].copywritingElementNum;
      } else {
        return 0;
      }
    },
    [copywritingList],
  );

  const nextStep = async (action?: string) => {
    if (action) {
      if (action === 'upload') {
        setIsSubmiting(true);
        try {
          const data = new FormData();
          data.append('file', fileList[0]);
          const res = await uploadCopywriting({ operation, data });
          setIsSubmiting(false);
          if (res.isSuccess) {
            if (action === 'upload') {
              setUploadResponse(res.data);
              setMappingList(
                res.data.mappingList.map((i: any, index: number) => {
                  return {
                    ...i,
                    id: index,
                    copywritingElementNum: matchValue(i),
                  };
                }),
              );
            }
            setCurrentStep(currentStep + 1);
          }
        } catch (error) {
          setIsSubmiting(false);
        }
      } else {
        try {
          setIsSubmiting(true);
          const res1 = await importCopywriting({
            MasterAccountNum: uploadResponse.masterAccountNum,
            ProfileNum: uploadResponse.profileNum,
            Operation: Number(operation),
            OriginalFileName: uploadResponse.originalFileName,
            SystemFileName: uploadResponse.systemFileName,
            JobGUID: uploadResponse.jobGUID,
            UserEmail: uploadResponse.userEmail || profile.Email,
            FileSize: uploadResponse.fileSize,
            DestinationNotEmptyOverwrite: destinationNotEmptyOverwrite,
            ChildrenNotEmptyOverwrite: childrenNotEmptyOverwrit,
            SourceEmptyIgnoreUpdate: sourceEmptyIgnoreUpdate,
            MappingList: mappingList.map((i) => {
              return {
                ...i,
                ignore: i.copywritingElementNum === 0 ? true : false,
                id: undefined,
              };
            }),
            RowsCount: uploadResponse.rowsCount,
            OtherInfo: uploadResponse.otherInfo,
          });
          setIsSubmiting(false);
          if (res1.isSuccess) {
            setCurrentStep(currentStep + 1);
          } else {
            message.error(res1.message);
          }
        } catch (error) {
          setIsSubmiting(false);
        }
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onCancel = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (operation && fileList.length > 0 && currentStep === 0) {
      setCanNext(true);
    } else if (currentStep === 1) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [operation, fileList, currentStep]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  useEffect(() => {
    if (currentStep === 1) {
      LoadTemplates();
    }
  }, [currentStep, LoadTemplates]);

  return (
    <>
      <CopywritingStepWrapper>
        <div className="step-ctn bulk">
          <div className="step-wrapper">
            <div className="step-sider">
              <Steps current={currentStep} direction="vertical">
                <Step title="Select File" />
                <Step title="Confirm Mapping" />
                <Step title="Review" />
                <Step title="Finish" />
              </Steps>
            </div>
            <div className="step-board">
              {currentStep === 0 && (
                <FileSelector
                  maskScreen={setIsLoading}
                  operation={operation}
                  setOperation={setOperation}
                  fileList={fileList}
                  setFileList={setFileList}
                />
              )}
              {currentStep === 1 && (
                <ConfirmMapping
                  maskScreen={setIsLoading}
                  uploadResponse={uploadResponse}
                  destinationNotEmptyOverwrite={destinationNotEmptyOverwrite}
                  sourceEmptyIgnoreUpdate={sourceEmptyIgnoreUpdate}
                  mappingList={mappingList}
                  setMappingList={setMappingList}
                  setDestinationNotEmptyOverwrite={setDestinationNotEmptyOverwrite}
                  setSourceEmptyIgnoreUpdate={setSourceEmptyIgnoreUpdate}
                  copywritingList={copywritingList}
                  fileFormats={fileFormats}
                  onShowChooseMapping={() => setShowChooseMapping(true)}
                  chooseMapping={chooseMapping}
                  childrenNotEmptyOverwrit={childrenNotEmptyOverwrit}
                  setChildrenNotEmptyOverwrit={setChildrenNotEmptyOverwrit}
                />
              )}
              {currentStep === 2 && (
                <ReviewSelector
                  maskScreen={setIsLoading}
                  uploadResponse={uploadResponse}
                  childrenNotEmptyOverwrit={childrenNotEmptyOverwrit}
                  destinationNotEmptyOverwrite={destinationNotEmptyOverwrite}
                  sourceEmptyIgnoreUpdate={sourceEmptyIgnoreUpdate}
                  mappingList={mappingList}
                  copywritingList={copywritingList}
                  fileFormats={fileFormats}
                  chooseMapping={chooseMapping}
                  onShowSaveMapping={() => setShowSaveMapping(true)}
                />
              )}
              {currentStep === 3 && (
                <FinishSelector
                  maskScreen={setIsLoading}
                  uploadResponse={uploadResponse}
                  destinationNotEmptyOverwrite={destinationNotEmptyOverwrite}
                  childrenNotEmptyOverwrit={childrenNotEmptyOverwrit}
                  sourceEmptyIgnoreUpdate={sourceEmptyIgnoreUpdate}
                  mappingList={mappingList}
                  copywritingList={copywritingList}
                  fileFormats={fileFormats}
                  chooseMapping={chooseMapping}
                />
              )}
            </div>
          </div>
        </div>
        <Row align="middle" className="left-bottom-btn-block" justify="space-between">
          <Space>
            <Button
              onClick={previousStep}
              style={{ visibility: currentStep > 0 && currentStep < 3 ? 'unset' : 'hidden' }}
            >
              Back
            </Button>
            {currentStep === 0 && (
              <Button
                onClick={() => nextStep('upload')}
                disabled={!canNext}
                loading={isSubmiting}
                type="primary"
              >
                Upload
              </Button>
            )}
            {currentStep < 2 && currentStep > 0 && (
              <Button disabled={!canNext} onClick={() => nextStep()} type="primary">
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button onClick={() => nextStep('submit')} loading={isSubmiting} type="primary">
                Submit
              </Button>
            )}
            {currentStep === 3 && (
              <Button onClick={onCancel} type="primary">
                Close
              </Button>
            )}
          </Space>
          <Space>{currentStep < 3 && <Button onClick={onCancel}>Cancel</Button>}</Space>
        </Row>
        {uploadResponse && showChooseMapping && (
          <Drawer
            getContainer={false}
            //@ts-ignore
            nzNoAnimation={true} //no effect
            style={{ position: 'absolute', transitionDuration: '0s' }}
            title="Choose One Mapping Below"
            placement="right"
            onClose={() => setShowChooseMapping(false)}
            visible={true}
            footer={
              <Space align="end">
                <Button onClick={() => setShowChooseMapping(false)}>Cancel</Button>
              </Space>
            }
          >
            <Space direction="vertical" size="large">
              {templates.map((i: any) => (
                <Button
                  key={i.value}
                  type="link"
                  onClick={() => {
                    setChooseMapping(i.value);
                    setShowChooseMapping(false);
                  }}
                >
                  {i.value}
                </Button>
              ))}
            </Space>
          </Drawer>
        )}

        {uploadResponse && showSaveMapping && (
          <Drawer
            getContainer={false}
            //@ts-ignore
            nzNoAnimation={true} //no effect
            style={{ position: 'absolute' }}
            title="Save Mapping"
            placement="right"
            onClose={() => setShowSaveMapping(false)}
            visible={true}
            footer={
              <Space>
                <Button
                  loading={isSaveMapping}
                  disabled={!templateName}
                  onClick={saveMapping}
                  type="primary"
                >
                  Save
                </Button>
                <Button onClick={() => setShowSaveMapping(false)}>Cancel</Button>
              </Space>
            }
          >
            <Space direction="vertical" size="large">
              <Text strong>
                File Format: <Text>{fileFormats[uploadResponse.operation]}</Text>
              </Text>
              <Space direction="vertical" size="small">
                <Text strong>Template Name:</Text>
                <AutoComplete
                  style={{ width: 200 }}
                  options={templateName ? [] : templates}
                  value={templateName}
                  onChange={setTemplateName}
                />
                {templates.findIndex((i: any) => i.value === templateName) > -1 && (
                  <Text type="danger" style={{ fontSize: 12 }}>
                    The exisiting template will be replaced with current template.
                  </Text>
                )}
              </Space>
            </Space>
          </Drawer>
        )}
      </CopywritingStepWrapper>
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default CopywritingSteps;
