const colors = {
  blue: '#006dff',
  danger: '#C82333',
  green: '#02a800',
  info: '#138496',
  infoBtnHoverBg: '#1B5E20',
  warning: '#faad14',
  warningBg: '#FFFBE6',
  white: '#fff',
  red: '#bc0000',
  secondary: '#5A6268',
  secondaryBtnBg: '#FBB040',
  secondaryBtnActiveBg: '#F09205',
  secondaryBtnHoverBg: '#FCBD5F',
  black: '#000',
  grayBase: '#d8dde6',
};
  
module.exports = {
  '@primary-color': colors.blue,
  '@info-color': colors.info,
  '@info-btn-hover-bg': colors.infoBtnHoverBg,
  '@secondary-color': colors.secondary,
  '@secondary-btn-bg': colors.secondaryBtnBg,
  '@secondary-btn-active-bg': colors.secondaryBtnActiveBg,
  '@secondary-btn-hover-bg': colors.secondaryBtnHoverBg,
  '@success-color': colors.green,
  '@warning-color': colors.warning,
  '@warning-bg-color': colors.warningBg,
  '@processing-color': colors.blue,
  '@error-color': colors.red,
  '@highlight-color': colors.red,
  '@normal-color': colors.grayBase,
  '@white': colors.white,
  '@black': colors.black,
  '@danger-color': colors.danger,

  '@border-radius-base': '4px',
};
