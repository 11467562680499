import { ClearOutlined, CloudDownloadOutlined, CloudUploadOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
//import ReactDataGrid from '@inovua/reactdatagrid-community';
import { DataGrid } from '../components/common/datagrid/DataGrid2';
//import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
//import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
//import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuProps,
  Modal,
  ModalProps,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  SubMenuProps,
  Typography,
  notification,
} from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { /* useDispatch, */ useSelector } from 'react-redux';
import styled from 'styled-components';
import { MenuInfo } from 'rc-menu/es/interface';
import { QueryStatus } from '@reduxjs/toolkit/query';
import * as _ from 'lodash';
import Heading from '../components/common/Heading';
import LoadingIcon from '../components/common/LoadingIcon';
import Spacer from '../components/common/Spacer';
import { FormLabel, ModalTitle } from '../components/common/styledComponents';
// import TimedCallback from '../components/common/TimedCallback';
import message from '../components/common/message';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, HTTP_STATUS_OK } from '../constants/config';
import { Permissions, ViewLevel } from '../constants/enums/permissions';
import { downloadCustomerImportedFile, downloadImportedErrorFile } from '../services/products';
import { FileImportStatus, FileImportType, ProductReportType } from '../types/enums';
import { formatBytes, getProfileSettingValue } from '../util';
import { enumToObjects } from '../util/enums';
import { downloadFile, getFileNameFromHeader } from '../util/files';
import { useFileDownload } from '../util/hooks';
import { convertTimeByUtc, convertToUtcTime } from '../util/utcTime';
import { useUploadChannelFileMutation, useUploadFileMutation } from '../redux/api/files';
import { ListImportFilesParams, useListImportFilesQuery } from '../redux/api/imports';
import {
  downloadChannelInventoryTemplate,
  downloadCustomTemplateBlob,
  downloadLookupTemplate,
  downloadProductTemplateByProfileNum,
  downloadStyleLevelCustomizeTemplateByProfileNum,
  downloadTemplate,
  downloadTemplateAttributes,
  fetchImportsOfFile,
  downloadAliasTemplate,
  downloadSpecialTemplate,
} from '../services/imports';
import { ChannelAccount, useListMappedAccountsQuery } from '../redux/api/channels';
import AttributesCheckTable from '../components/FileImportCenter/AttributesCheckTable';
import CloseFilled from '../assets/icons/close_filled';
import CheckMarkFilled from '../assets/icons/checkmark_filled';
// import { attributeKeys } from '../constants/common';
import { useStyleMasterGroupNamesQuery } from '../redux/api/products';
import { hasHigherPermission } from '../util/permissions';
import moment from 'moment';
import ArrowTurnUp from '../assets/icons/arrowTurnUp';

const { Text } = Typography;

const ImportTypeMap = new Map([
  [FileImportType.INVENTORY, 'Inventory'],
  [1002, 'Updated Product'],
  [1001, 'Inventory'],
  [FileImportType.PRODUCT_CREATE_EDIT, 'Products'],
  [FileImportType.ORDERS, 'Order'],
  [FileImportType.TRACKING_NUMBER, 'Tracking Number'],
  [FileImportType.ATTRIBUTES_CREATE, 'Attributes'],
  [FileImportType.ATTRIBUTES_EDIT, 'Updated Attribute'],
  [FileImportType.LOOKUP, 'Channel Lookup'],
  [FileImportType.STYLE_MASTER_CREATE, 'Style Master Create'],
  [FileImportType.STYLE_MASTER_UPDATE, 'Style Master Update'],
  [FileImportType.STYLE_MASTER_ASSIGN, 'Style Master Build Relationship'],
  [FileImportType.STYLE_MASTER_GROUP, 'Style Master Commerce Central'],
]);

const ImportStatusMap = new Map([
  [1, 'Pending'],
  [2, 'Processing'],
  [3, 'Complete'],
  [4, 'Completed With Error'],
  [5, 'Failed Validation'],
  [6, 'Contact Support'],
  [7, 'Failed'],
]);

const DownloadTemplateButton = styled(Button)`
  margin-right: 30px;
`;

const SearchFormWrapper = styled.div`
  & .search-form-bottom-btn-row {
    margin-top: 12px;
  }

  & .search-form-label {
    margin-right: 8px;
  }

  & .search-user-input {
    width: calc(100% - 100px);
  }

  & .search-submit-date-picker {
    width: calc(100% - 180px);
  }
`;

const FileImportTypeLabels = new Map<FileImportType, string>([
  [FileImportType.INVENTORY, 'Channel Inventory'],
  [FileImportType.ORDERS, 'Orders'],
  [FileImportType.TRACKING_NUMBER, 'Tracking numbers'],
  [FileImportType.PRODUCT_CREATE_EDIT, 'Products'],
  [FileImportType.ATTRIBUTES_CREATE, 'Product Attribute'],
  [FileImportType.ATTRIBUTES_EDIT, 'Products Attribute Edit'],
  [FileImportType.LOOKUP, 'Channel Lookup'],
  [FileImportType.STYLE_MASTER_CREATE, 'Style Master Create'],
  [FileImportType.STYLE_MASTER_UPDATE, 'Style Master'],
  [FileImportType.STYLE_MASTER_ASSIGN, 'Style Master Build Relationship'],
  //[FileImportType.STYLE_MASTER_GROUP, 'Style Master Commerce Central'],
  [FileImportType.STYLE_MASTER_GROUP, 'Style Master Product'],
  [FileImportType.STYLE_MASTER_PRODUCT, 'Style Master Product'],
  [FileImportType.STYLE_MASTER_PRODUCT_BASIC, 'Style Master Product Basic'],
]);

const ProductTemplateMap = new Map<ProductReportType, string>([
  [ProductReportType.All, 'All'],
  [ProductReportType.Attributes, 'Basic Attributes'],
  [ProductReportType.Attributes, 'Custom Attributes'],
  [ProductReportType.DistributionCenters, 'Distribution Centers'],
  [ProductReportType.Classification, 'Classification'],
  [ProductReportType.MediaPlacements, 'Media Placements'],
  [ProductReportType.BundleComponents, 'Bundle Components'],
]);

const DownloadLink = styled.div`
  cursor: pointer;
  color: #016dff;
`;

const MenuDivider = styled.hr`
  border: 1px dashed #cecece;
  margin: 0 16px;
`;

const FileUploadModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const KeysCheckWrapper = styled.div``;
const KeyName = styled.p`
  color: #546279;
  font-weight: 400;
`;

const UploadOptionsArea = styled.div`
  padding: 8px;

  &.options-has-error {
    border: 1px solid #BC0000;
    border-radius: 4px;
    padding: 7px;
  }

  & .option-label {
    display: inline-block;
    font-weight: 500;
    width: 246px;
  }
`;

const FileDownloadLink: React.FC<{ data: Entities.ImportFileProfile; errorFile?: boolean }> = ({
  data,
  errorFile = false,
  children,
}) => {
  const file = useCallback(
    () =>
      errorFile
        ? downloadImportedErrorFile(data.ErrorFileName)
        : downloadCustomerImportedFile(data.SystemFileName),
    [data, errorFile],
  );
  const fileName = useMemo(() => (errorFile ? data.ErrorFileName : data.FileName), [
    errorFile,
    data,
  ]);
  const [download, loading] = useFileDownload(file, fileName);

  if (!data.FileName) {
    return null;
  }

  //console.log('dl ->', data, fileName, ':', data.FileName);
  return (
    <DownloadLink onClick={download}>
      <Spin indicator={<LoadingIcon size="small" />} spinning={loading}>{children}</Spin>
    </DownloadLink>
  );
};

// const attributeKeys = [
//   {
//     type: 'styleMaster',
//     name: 'STYLECODE',
//   },
//   {
//     type: 'styleMaster',
//     name: 'SUBSTYLECODE',
//   },
//   {
//     type: 'styleMaster',
//     name: 'SKU',
//   },
//   {
//     type: 'products',
//     name: 'SKU',
//   },
//   {
//     type: '30002:::', // temporary additon for menu adjustion
//     name: 'SKU',
//   },
// ];

const downloadFileTemplate = async (
  importType: FileImportType,
  reportType?: number,
  channelAccountNum?: number,
  channelNum?: number,
  groupName?: string,
  fileImportLevel?: number,
): Promise<[string | null, Blob | null] | [null, null]> => {
  if (!importType) {
    return [null, null];
  }

  let fileName: string | null = null;
  let templateResponse: Blob | null = null;

  switch (importType) {
    case FileImportType.ATTRIBUTES_CREATE:
    case FileImportType.ATTRIBUTES_EDIT:
      fileName = 'AttributeTemplate.xlsx';
      templateResponse = await downloadTemplateAttributes();
      break;
    case FileImportType.ORDERS:
    case FileImportType.TRACKING_NUMBER:
      message.info('No template available');
      break;
    case FileImportType.LOOKUP:
      fileName = `${_.camelCase(ImportTypeMap.get(importType))}Template.xlsx`;
      templateResponse = await downloadLookupTemplate();
      break;
    case FileImportType.INVENTORY:
      fileName = 'ChannelInventoryTemplate.xlsx';
      templateResponse = await downloadChannelInventoryTemplate();
      break;
    case FileImportType.PRODUCT_CREATE_EDIT:
      if (!reportType) {
        break;
      }
      fileName = `${_.camelCase(ProductTemplateMap.get(reportType))}Template.xlsx`;
      templateResponse = await downloadProductTemplateByProfileNum(reportType);
      break;
    case FileImportType.STYLE_MASTER_CREATE:
    case FileImportType.STYLE_MASTER_UPDATE:
    case FileImportType.STYLE_MASTER_ASSIGN:
    case FileImportType.STYLE_MASTER_GROUP:
      if (!reportType) {
        break;
      }
      fileName = 'StyleMasterTemplate.xlsx';
      try {
        // templateResponse = await downloadStyleLevelCustomizeTemplateByProfileNum(reportType, {
        const res = await downloadStyleLevelCustomizeTemplateByProfileNum(reportType, {
          channelAccountNum,
          channelNum,
          fileImportLevel,
          groupName,
        });
        // console.log('t->', res, fileName);
        const fields = res.headers['content-disposition'].split('=');
        fileName = fields.length > 0 ? fields[fields.length - 1] : fileName;
        templateResponse = res.data;
      } catch (e) {
        message.error(`Download template error: ${e}`);
      }
      break;
    default:
      fileName = `${_.camelCase(ImportTypeMap.get(importType))}Template.xlsx`;
      templateResponse = await downloadTemplate(importType);
      break;
  }

  if (fileName) {
    fileName = fileName.replace(/"/g, '');
  }

  return [fileName, templateResponse];
};

const TemplateDownloadButtonProductAlias: React.FC<any> = () => {
  const [loadingTemplate, setLoadingTemplate] = useState(false);


  const onClick = useCallback(async () => {

    let template;
    try {
      setLoadingTemplate(true);
      const { data: fileBlob, status } = await downloadAliasTemplate({
        fileImportLevel: 1,
      });

      if (HTTP_STATUS_OK === status) {
        // console.log('h->', headers);
        template = fileBlob;
      }

    } catch (e) {
      setLoadingTemplate(false);
    } finally {
      setLoadingTemplate(false);
    }

    if (!template) {
      notification.error({ message: 'No template information' });
      return;
    }

    downloadFile(
      'product-alias-template.xlsx',
      template,
    );

  }, []);

  return (
    <DownloadTemplateButton onClick={onClick} loading={loadingTemplate} type="primary" ghost>
      <CloudDownloadOutlined />
      Download Template
    </DownloadTemplateButton>
  );
};

const TemplateDownloadButton: React.FC<{
  importType: FileImportType | null;
  channelAccountNum?: number;
  channelNum?: number;
  fileImportLevel?: number;
}> = ({ importType, channelAccountNum, channelNum, fileImportLevel }) => {
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [groupName, setGroupName] = useState<string>('');
  const reportType = useRef<number | undefined>(undefined);
  const { data = [], isFetching } = useStyleMasterGroupNamesQuery({ channelNum });
  const onClick = useCallback(async () => {
    if (!importType) {
      return;
    }

    let template;
    let fileName;
    try {
      setLoadingTemplate(true);
      switch (importType) {
        case FileImportType.STYLE_MASTER_PRODUCT: {
          //[fileName, template] = await downloadCustomTemplateBlob({
          const { data: fileBlob, status, headers } = await downloadCustomTemplateBlob({
            reportType: 20004,
            includeChannelAttribute: false,
            fileImportLevel,
          });

          if (HTTP_STATUS_OK === status) {
            // console.log('h->', headers);
            template = fileBlob;
            fileName = getFileNameFromHeader(headers);
          }

          break;
        }
        case FileImportType.STYLE_MASTER_PRODUCT_BASIC: {
          //[fileName, template] = await downloadCustomTemplateBlob({
          const { data: fileBlob, status, headers } = await downloadCustomTemplateBlob({
            reportType: 30010,
            includeChannelAttribute: false,
            fileImportLevel,
            channelNum: 0,
            channelAccountNum: 0,
          });

          if (HTTP_STATUS_OK === status) {
            // console.log('h->', headers);
            template = fileBlob;
            fileName = getFileNameFromHeader(headers);
          }

          break;
        }

        default:
          [fileName, template] = await downloadFileTemplate(
            importType,
            reportType.current,
            channelAccountNum,
            channelNum,
            groupName,
            fileImportLevel,
          );
      }
    } catch (e) {
      // no-op
    } finally {
      setLoadingTemplate(false);
    }

    if (!template || template.type === 'application/json') {
      if (importType !== FileImportType.STYLE_MASTER_PRODUCT) {
        notification.error({ message: 'No template information' });
        return;
      }
    }
    // console.log('--->', fileName, template);
    downloadFile(
      // (fileName || 'template.xlsx').replace(/"/g, ''),
      fileName || 'template.xlsx',
      template,
    );

    /* const url = window.URL.createObjectURL(new Blob([template], { type: template.type }));
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', (fileName || 'Template.xlsx').replace('"', ''));
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 0); */
  }, [
    importType,
    reportType,
    setLoadingTemplate,
    groupName,
    channelAccountNum,
    channelNum,
    fileImportLevel,
  ]);

  const onItemClick = useCallback(
    (report: number) => {
      reportType.current = report;
      onClick().finally();
    },
    [reportType, onClick],
  );

  if (importType === FileImportType.PRODUCT_CREATE_EDIT) {
    const items: any = [];
    ProductTemplateMap.forEach((value, key) => {
      items.push(
        <Menu.Item key={value} onClick={() => onItemClick(key)}>
          {value}
        </Menu.Item>,
      );
    });

    return (
      <Dropdown overlay={<Menu>{items}</Menu>}>
        <DownloadTemplateButton loading={loadingTemplate} type="primary" ghost>
          Download Template
          <CloudDownloadOutlined />
        </DownloadTemplateButton>
      </Dropdown>
    );
  }

  if (
    importType === FileImportType.STYLE_MASTER_CREATE ||
    importType === FileImportType.STYLE_MASTER_UPDATE ||
    importType === FileImportType.STYLE_MASTER_ASSIGN ||
    importType === FileImportType.STYLE_MASTER_GROUP
  ) {
    return (
      <Row>
        {(importType === FileImportType.STYLE_MASTER_UPDATE ||
          importType === FileImportType.STYLE_MASTER_GROUP) && (
            <Col>
              <Select
                placeholder="Group Name (Optional)"
                loading={isFetching}
                onChange={(value: string) => {
                  setGroupName(value);
                }}
              >
                {data.map((m) => (
                  <Select.Option key={m} value={m}>
                    {m}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
        <Col>
          <DownloadTemplateButton
            onClick={() => onItemClick(importType)}
            loading={loadingTemplate}
            type="primary"
            ghost
          >
            Download Template
            <CloudDownloadOutlined />
          </DownloadTemplateButton>
        </Col>
      </Row>
    );
  }

  return (
    <DownloadTemplateButton onClick={onClick} loading={loadingTemplate} type="primary" ghost>
      Download Template
      <CloudDownloadOutlined />
    </DownloadTemplateButton>
  );
};

const SpecialTemplateDownloadButton: React.FC<{ name: string }> = ({ name }) => {

  const [loadingTemplate, setLoadingTemplate] = useState(false);


  const onClick = useCallback(async () => {

    let template;
    try {
      setLoadingTemplate(true);
      const { data: fileBlob, status } = await downloadSpecialTemplate(
        name,
        {
          fileImportLevel: 1,
        },
      );

      if (HTTP_STATUS_OK === status) {
        // console.log('h->', headers);
        template = fileBlob;
      }

    } catch (e) {
      setLoadingTemplate(false);
    } finally {
      setLoadingTemplate(false);
    }

    if (!template) {
      notification.error({ message: 'No template information' });
      return;
    }

    downloadFile(
      name,
      template,
    );

  }, [name]);

  return (
    <DownloadTemplateButton onClick={onClick} loading={loadingTemplate} type="primary" ghost>
      Download Template
      <CloudDownloadOutlined />
    </DownloadTemplateButton>
  );
};
interface AttributeMap {
  [key: string]: {
    AttributeName: string;
    MappedAttributeNum?: number;
    add: string;
  };
}

interface FileUploadForm {
  reason: string;
  fileCount: number;
  /**
   * A mapping where the key is the attribute name, and the value is the
   * payload sent to the BE.
   */
  attributeList?: AttributeMap;
  /**
   * Defines whether create all attributes contained within the `attributeList` map
   */
  createAll: boolean;
  ignoreAll: boolean;
}

interface ImportFileModalProps extends ModalProps {
  importType?: FileImportType;
  account?: ChannelAccount;
  rootKey?: string;
  onFileUploaded?: () => void;
}

const ImportFileModal: React.FC<ImportFileModalProps> = ({
  importType,
  account,
  onFileUploaded,
  rootKey,
  onCancel,
  ...modalProps
}) => {
  const DOWNLOAD_TYPE_STYLEMASTER = 1;
  const DOWNLOAD_TYPE_SUBSTYLE = 2;
  const DOWNLOAD_TYPE_PRODUCT = 3;
  const { Text } = Typography;
  const [
    uploadFile,
    { status: uploadStatus, isLoading: uploadIsLoading },
  ] = useUploadFileMutation();
  const [uploadChannelFile, { status, isLoading }] = useUploadChannelFileMutation();
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [form] = Form.useForm<FileUploadForm>();
  const [uploadButton, setUploadButton] = useState<boolean>(true);
  const [downloadType, setDownloadType] = useState(DOWNLOAD_TYPE_PRODUCT);
  //const [existentAttributeKeys, setExistentAttributeKeys] = useState<string[]>([]);
  const [optionApplyChange, setOptionApplyChange] = useState<boolean>();
  const [optionHasError, setOptionHasError] = useState(false);
  const [optionIgnore, setOptionIgnore] = useState<boolean>();
  const [optionOverwrite, setOptionOverwrite] = useState<boolean>();
  const optionCtnRef = useRef<any>(null);
  const [keyExist, setKeyExist] = useState<{
    product: boolean,
    substyleandstyle: boolean,
    style: boolean,
  }>({ product: false, substyleandstyle: false, style: false });

  const isAllOptionChoosed = React.useMemo(() => {
    return !(optionApplyChange === undefined
      || optionOverwrite === undefined
      || optionIgnore === undefined
    );
  }, [optionApplyChange, optionIgnore, optionOverwrite]);

  const isChannelAmazon = React.useMemo(() => {
    if (account) {
      const channelName = account.platform ? account.platform.channelName : account.ChannelName;
      return channelName.toLocaleLowerCase().match(/amazon/);
    }

    return false;
  }, [account]);

  const isChannelShopify = React.useMemo(() => {
    if (account) {
      const channelName = account.platform ? account.platform.channelName : account.ChannelName;
      return channelName.toLocaleLowerCase().match(/shopify/);
    }

    return false;
  }, [account]);

  const isProductsChannel = React.useMemo(() => {
    let ret = false;

    if (account) {
      const channelName = account.platform ? account.platform.channelName : account.ChannelName;

      ret = !!channelName && rootKey === 'products';
    }

    // console.log('ch p->', ret);
    return ret;
  }, [account, rootKey]);

  // eslint-disable-next-line
  const isStyleMasterChannel = React.useMemo(() => {
    let ret = false;

    if (account) {
      const channelName = account.platform ? account.platform.channelName : account.ChannelName;

      ret = !!channelName && rootKey === 'styleMaster';
    }

    // console.log('ch s->', ret);
    return ret;
  }, [account, rootKey]);

  const isUploadable = (attrs: string[]) => {
    let ret = false;

    const hasStyleCode = attrs.some((e) => {
      const header = e.toUpperCase();
      return header === 'STYLECODE';
    });
    const hasSubStyleCode = attrs.some((e) => {
      const header = e.toUpperCase();
      return header === 'SUBSTYLECODE' || header === 'SUB-STYLECODE';
    });
    const hasProductSku = attrs.some((e) => e.toUpperCase() === 'SKU');

    // only accept these combinations
    // sku 
    // style code
    // style code and sub style code
    //console.log('hs->', hasProductSku, attrs);
    ret = (hasProductSku && !hasStyleCode && !hasSubStyleCode)
      || (!hasProductSku && hasStyleCode && !hasSubStyleCode)
      || (!hasProductSku && hasStyleCode && hasSubStyleCode);

    setKeyExist({
      product: hasProductSku,
      substyleandstyle: hasSubStyleCode && hasStyleCode,
      style: hasStyleCode,
    });
    return ret;
  };

  const isChannel = useCallback((reg: RegExp) => {
    if (account) {
      const channelName = account.platform ? account.platform.channelName : account.ChannelName;
      return channelName.toLocaleLowerCase().match(reg);
    }

    return false;
  }, [account]);

  const onHeadersLoad = useCallback(
    (values: string[]) => {
      // if (isChannelAmazon) return;
      if (isProductsChannel) return;

      const attributeList: { [key: string]: any } = {};
      values.forEach((v) => {
        const nameFormatted = v.replace(/\s+/g, '');
        attributeList[nameFormatted] = {
          AttributeName: nameFormatted,
        };
      });
      const attributeListKeys = Object.keys(attributeList);
      setUploadButton(!isUploadable(attributeListKeys));
      // remove key words
      const validAttributeList = _.pick(attributeList, attributeListKeys.filter((key) => {
        const header = key.toUpperCase();
        const isStyleCode = header === 'STYLECODE';
        const isSubStyleCode = header === 'SUBSTYLECODE' || header === 'SUB-STYLECODE';
        const isProductSku = header === 'SKU';
        return !(isStyleCode || isSubStyleCode || isProductSku);
      }));
      form.setFieldsValue({ attributeList: validAttributeList });
    },
    [form, isProductsChannel],
  );

  const onAddAll = useCallback(
    (value: boolean) => {
      if (value) {
        const { attributeList = {} }: { attributeList: AttributeMap } = form.getFieldsValue([
          'attributeList',
        ]);

        Object.keys(attributeList).forEach((k) => {
          const attribute = attributeList[k];
          attribute.add = 'add';
        });
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ignoreAll: false,
        });
        form.setFieldsValue({ attributeList });
      }
    },
    [form],
  );

  const onIgnoreAll = useCallback(
    (value: boolean) => {
      if (value) {
        const { attributeList = {} }: { attributeList: AttributeMap } = form.getFieldsValue([
          'attributeList',
        ]);

        Object.keys(attributeList).forEach((k) => {
          const attribute = attributeList[k];
          attribute.add = 'ignore';
        });
        form.setFieldsValue({
          ...form.getFieldsValue(),
          createAll: false,
        });
        form.setFieldsValue({ attributeList });
      }
    },
    [form],
  );

  const onAddChanged = useCallback(
    (name: string, value: boolean) => {
      if (!value) {
        form.setFieldsValue({ createAll: false });
      } else {
        form.setFieldsValue({ ignoreAll: false });
      }
    },
    [form],
  );

  const onOptionApplyChange = (evt: any) => {
    setOptionApplyChange(evt.target.value);
    setOptionHasError(false);
  };

  const onOptionIgnoreChange = (evt: any) => {
    setOptionIgnore(evt.target.value);
    setOptionHasError(false);
  };

  const onOptionOverwriteChange = (evt: any) => {
    setOptionOverwrite(evt.target.value);
    setOptionHasError(false);
  };

  const onSubmit = useCallback(
    ({ reason, attributeList = {}, createAll = false }: FileUploadForm) => {
      if (!isAllOptionChoosed) {
        setOptionHasError(true);
        optionCtnRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }

      // Only one file is allowed per-upload, so check that there's a file and that it is valid
      if (files.length === 0 || !files[0].originFileObj) {
        return;
      }

      // Map the attributes to the APi-required shape
      const attributes: { AttributeName: string; MappedAttributeNum?: number }[] = Object.keys(
        attributeList,
      )
        .filter((k) => attributeList[k].add === 'add')
        .map((k) => {
          const { AttributeName, MappedAttributeNum } = attributeList[k];
          return {
            AttributeName,
            MappedAttributeNum,
          };
        });

      if (account) {
        // Account specific upload.
        if (importType === FileImportType.STYLE_MASTER_UPDATE) {
          uploadFile({
            reason,
            fileOperation: importType || 0,
            files: files.map((f) => f.originFileObj),
            attributeList: attributes,
            channelNum: account.ChannelNum,
            channelAccountNum: account.ChannelAccountNum,
            createAll,
            optionApplyChange,
            optionOverwrite,
            optionIgnore,
          });
        } else if (rootKey === 'product_alias') {
          uploadFile({
            reason,
            fileOperation: FileImportType.PRODUCT_ALIAS,
            files: files.map((f) => f.originFileObj),
            // attributeList: attributes,
            channelNum: account.ChannelNum,
            channelAccountNum: account.ChannelAccountNum,
            // createAll,
            optionApplyChange,
            optionOverwrite,
            optionIgnore,
          });
        } else {
          const channelName = account.platform ? account.platform.channelName : account.ChannelName;

          // Description on FE-686 says to use 20003 for Walmart-based operations.
          // But, as per usual, there's no easy way to tell other than checking if the channel name includes
          // walmart.
          let fileOperation = channelName.toLocaleLowerCase().match(/walmart/) ? 20003 : 20004;

          if (isChannelAmazon) fileOperation = 20006;
          if (isChannelShopify) fileOperation = 20005;
          if (isChannel(/nuorder/)) fileOperation = 20007;

          uploadChannelFile({
            reason,
            createAll,
            attributeList: attributes,
            channelNum: account.ChannelNum,
            channelAccountNum: account.ChannelAccountNum,
            file: files[0].originFileObj,
            fileOperation,
            optionApplyChange,
            optionOverwrite,
            optionIgnore,
          });
        }
      } else {
        // Regular CC file upload
        //console.log('upload oper', createAll, rootKey, importType);
        let createNew = false;
        let fileOperation = importType || 0;

        switch (importType) {
          case FileImportType.STYLE_MASTER_GROUP:
          case FileImportType.STYLE_MASTER_PRODUCT:
            fileOperation = 30002;

            if (importType === FileImportType.STYLE_MASTER_GROUP) createNew = true;

            break;

          case FileImportType.STYLE_MASTER_PRODUCT_BASIC:
            fileOperation = 30010;
            // eslint-disable-next-line no-param-reassign
            createAll = true;
            break;
          default:
            break;
        }

        uploadFile({
          reason,
          //fileOperation: importType || 0,
          files: files.map((f) => f.originFileObj),
          fileOperation,
          attributeList: attributes,
          createAll,
          createNew,
          optionApplyChange,
          optionOverwrite,
          optionIgnore,
        });
      }
    },
    [
      files,
      uploadChannelFile,
      account,
      uploadFile,
      isAllOptionChoosed,
      isChannel,
      isChannelAmazon,
      isChannelShopify,
      importType,
      optionApplyChange,
      optionIgnore,
      optionOverwrite,
      rootKey,
    ],
  );

  const onModalCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (status !== QueryStatus.fulfilled) {
        setFiles([]);
      }
      onCancel?.(e);
      resetUploadOptions();
    },
    [onCancel, status],
  );

  const onSelectDownloadType = (value: any) => {
    // console.log('sel->', value);
    if (typeof value === 'number') {
      setDownloadType(value);
    }
  };

  const downloadOptions = () => {
    const options = [
      { label: 'Style Master', value: DOWNLOAD_TYPE_STYLEMASTER },
      { label: 'Sub Style', value: DOWNLOAD_TYPE_SUBSTYLE },
      { label: 'Product', value: DOWNLOAD_TYPE_PRODUCT },
    ];

    return options.map((e) => (
      <Select.Option key={e.value} value={e.value}>
        {e.label}
      </Select.Option>
    ));
  };

  const resetUploadOptions = () => {
    setOptionApplyChange(undefined);
    setOptionIgnore(undefined);
    setOptionOverwrite(undefined);
  };

  useEffect(() => {
    if (status === QueryStatus.fulfilled || uploadStatus === QueryStatus.fulfilled) {
      form.resetFields();
      setFiles([]);
      onFileUploaded?.();
      resetUploadOptions();
    }
  }, [form, status, uploadStatus, onFileUploaded]);

  const title = useMemo(() => {
    if (account) {
      if (importType && importType === FileImportType.STYLE_MASTER_UPDATE) {
        return `Import ${FileImportTypeLabels.get(importType)} ${account.ChannelAccountName}`;
      }
      if (rootKey && rootKey === 'product_alias') {
        return 'Import Product Alias';
      }
      return `Import Product ${account.ChannelAccountName}`;
    }

    return `Import ${FileImportTypeLabels.get(importType || 0)}`;
  }, [account, importType, rootKey]);

  const onFilesChange = useCallback(
    (info: UploadChangeParam) => {
      form.resetFields();
      setFiles(info.fileList.slice(-1));
      form.setFieldsValue({ fileCount: 1 });
    },
    [form],
  );

  const hideAttributeColumn = useMemo(() => {
    switch (importType) {
      case FileImportType.STYLE_MASTER_PRODUCT_BASIC:
        return true;
      default:
        return false;
    }
  }, [importType]);

  useEffect(() => {
    form.resetFields();
  }, [account, form]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Modal
      centered
      style={{ minWidth: '75vw' }}
      {...modalProps}
      onCancel={onModalCancel}
      title={(
        <FileUploadModalTitle>
          <ModalTitle>{title}</ModalTitle>
          {(rootKey === 'product_alias') && (
            <Space>
              <TemplateDownloadButtonProductAlias />
            </Space>
          )}
          {(importType && rootKey !== 'product_alias') && (
            <Space>
              <Select
                onChange={onSelectDownloadType}
                placeholder="Download Option"
                style={{ width: 150 }}
                value={downloadType}
              >
                {downloadOptions()}
              </Select>
              <TemplateDownloadButton
                channelAccountNum={account?.ChannelAccountNum}
                channelNum={account?.ChannelNum}
                fileImportLevel={downloadType}
                importType={importType}
              />
            </Space>
          )}
          {
            (rootKey === 'products' && account?.ChannelAttributeSpecialTemplateName) && (
              <Space>
                <SpecialTemplateDownloadButton name={account.ChannelAttributeSpecialTemplateName} />
              </Space>
            )
          }
        </FileUploadModalTitle>
      )}
      footer={null}
      forceRender
    >
      <UploadOptionsArea
        className={optionHasError ? 'options-has-error' : ''}
        ref={optionCtnRef}
      >
        <Row>
          <Space style={{ width: '100%' }}>
            <Text className="option-label">Apply change to lower level SKU(s):</Text>
            <Radio.Group
              onChange={onOptionApplyChange}
              value={optionApplyChange}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Row>
          <Space style={{ width: '100%' }}>
            <Text className="option-label">If destination has value (Not empty):</Text>
            <Radio.Group
              onChange={onOptionOverwriteChange}
              value={optionOverwrite}
            >
              <Radio value>Overwrite</Radio>
              <Radio value={false}>Don't Overwrite</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Row>
          <Space style={{ width: '100%' }} align="start">
            <Text className="option-label">If source doesn't have value (empty):</Text>
            <Radio.Group
              onChange={onOptionIgnoreChange}
              value={optionIgnore}
            >
              <Radio value={false}>Use empty value to update destination</Radio>
              <Radio value>Ignore (don't update destination)</Radio>
            </Radio.Group>
          </Space>
        </Row>
      </UploadOptionsArea>
      {optionHasError && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">All upload options required</div>
        </div>
      )}
      {/* eslint-enable react/jsx-props-no-spreading */}
      <Form form={form} layout="vertical" labelCol={{ span: 24 }} onFinish={onSubmit}>
        <Form.Item
          label="File"
          name="fileCount"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Dragger
            fileList={files}
            onChange={onFilesChange}
            beforeUpload={() => false}
            accept=".xls, .xlsx"
            name="file"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag files to this area to upload</p>
          </Dragger>
        </Form.Item>
        <Form.Item
          label="Import Reason"
          name="reason"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder="Write a brief description of why you're importing these files" />
        </Form.Item>
        {(rootKey === '30002:::') && (
          <KeysCheckWrapper>
            <Row>
              <Col span={6}>
                <Row>
                  <Col span={24}>
                    <h3>Keys:</h3>
                  </Col>
                  {[{ name: 'Sku', exist: keyExist.product }, { name: 'Style Code & Sub-Style', exist: keyExist.substyleandstyle }, { name: 'Style Code', exist: keyExist.style }].map((m) => {
                    const Icon = m.exist ? CheckMarkFilled : CloseFilled;
                    const pathColor = m.exist ? '#18ff2a' : '#ff1818';
                    return (
                      <Col span={24} key={m.name}>
                        <Row>
                          <Col span={4}>
                            <Icon
                              pathColor={pathColor}
                              width={20}
                              height={20}
                              style={{ marginRight: '5px', marginTop: '4px' }}
                            />
                          </Col>
                          <Col span={20}>
                            <KeyName>{m.name}</KeyName>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </KeysCheckWrapper>
        )}
        {
          // The attributes check table only renders when uploading a products or style master file FOR AN SPECIFIC CHANNEL ACCOUNT
          (rootKey === '30002:::') &&
          !isProductsChannel && (
            <AttributesCheckTable
              hideAttributeColumn={hideAttributeColumn}
              file={files?.[0]}
              onAddAll={onAddAll}
              onIgnoreAll={onIgnoreAll}
              onLoad={onHeadersLoad}
              onAddChanged={onAddChanged}
              account={account}
              uploadType={rootKey}
              ignoreFilterHeader={(key) => {
                const header = key.toUpperCase().replace(/\s+/g, '');
                const isStyleCode = header === 'STYLECODE';
                const isSubStyleCode = header === 'SUBSTYLECODE' || header === 'SUB-STYLECODE';
                const isProductSku = header === 'SKU';
                return (isStyleCode || isSubStyleCode || isProductSku);
              }}
            />
          )
        }
        <Spacer />
        <Form.Item>
          <Row justify="center">
            <Col>
              {(importType === FileImportType.STYLE_MASTER_UPDATE ||
                importType === FileImportType.PRODUCT_CREATE_EDIT) &&
                account &&
                account.displayAnalyzeData ? (
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={uploadButton}
                  loading={isLoading || uploadIsLoading}
                >
                  <CloudUploadOutlined />
                  Upload
                </Button>
              ) : (
                <Button htmlType="submit" type="primary" loading={isLoading || uploadIsLoading}>
                  <CloudUploadOutlined />
                  Upload
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface ChannelAccountImportMenuProps extends SubMenuProps {
  accounts: ChannelAccount[];
  accountType: 'retail' | 'non-retail';
  importType?: number;
}

const ChannelAccountImportMenu: React.FC<ChannelAccountImportMenuProps> = ({
  accounts,
  accountType,
  importType,
  ...subMenuProps
}) => {
  const [channelNum, channelName] = useMemo(() => {
    if (accounts.length === 0) {
      return '';
    }

    const entry = accounts[0];
    if (entry.platform) {
      // TODO: double-check if this is correct
      return [entry.platform.channelNum, entry.platform.channelName];
    }

    return [entry.ChannelNum, entry.ChannelName];
  }, [accounts]);

  if (accounts.length === 0) {
    return null;
  }

  return (
    <Menu.SubMenu
      key={`${channelNum}-${channelName}-${accountType}-submenu`}
      popupOffset={[-3, -3]}
      title={channelName}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...subMenuProps}
    >
      {accounts.map((account) => {
        const menuItemKey = importType
          ? `${importType}:${accountType}:${channelNum}:${account.ChannelAccountNum}`
          : `:${accountType}:${channelNum}:${account.ChannelAccountNum}`;
        return <Menu.Item key={menuItemKey}>{account.ChannelAccountName}</Menu.Item>;
      })}
    </Menu.SubMenu>
  );
};

type ImportFileData = {
  /**
   * Information for account-specific imports
   *
   * If an account is selected, all the information required about the
   * account is provided.
   */
  account?: ChannelAccount;
  /**
   * Type value for CommerceCentral imports
   */
  fileImportType?: FileImportType;
  /**
   * If the selected option is a nested entry, this will contain the
   * root key, which dictates the type of import is being selected.
   *
   * Currently, this will only have two values:
   * - products
   * - undefined
   */
  rootKey?: string;
};

interface ImportFileButtonProps extends Omit<MenuProps, 'onClick'> {
  onClick?: (data: ImportFileData) => void;
  useCommon?: boolean;
}

const ImportFileButton: React.FC<ImportFileButtonProps> = ({ onClick, useCommon, ...menuProps }) => {
  const { data, isFetching } = useListMappedAccountsQuery();

  const channels = (importType?: number) => (
    <>
      {data &&
        Object.keys(data.nonRetail).map((channelNum: string) => (
          <ChannelAccountImportMenu
            key={channelNum}
            accounts={data.nonRetail[channelNum]}
            accountType="non-retail"
            importType={importType}
          />
        ))}
      {data &&
        Object.keys(data.retail).map((channelNum: string) => (
          <ChannelAccountImportMenu
            key={channelNum}
            accounts={data.retail[channelNum]}
            accountType="retail"
            importType={importType}
          />
        ))}
    </>
  );

  const channels1 = (importType?: number) => (
    <>
      {data &&
        Object.keys(data.specialNonRetail).map((channelNum: string) => (
          <ChannelAccountImportMenu
            key={channelNum}
            accounts={data.specialNonRetail[channelNum]}
            accountType="non-retail"
            importType={importType}
          />
        ))}
      {data &&
        Object.keys(data.specialRetail).map((channelNum: string) => (
          <ChannelAccountImportMenu
            key={channelNum}
            accounts={data.specialRetail[channelNum]}
            accountType="retail"
            importType={importType}
          />
        ))}
    </>
  );

  const isSpecialChannelExist = () => {
    return data && ((data.specialRetail && Object.keys(data.specialRetail).length > 0)
      || (data.specialNonRetail && Object.keys(data.specialNonRetail).length > 0));
  };

  const isDevEnv = () => {
    const devEnvs = ['development', 'local'];

    return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
  };

  const onMenuClick = useCallback(
    (info: MenuInfo) => {
      if (!onClick || !data) {
        console.log('no ->', info, onClick, data);
        return;
      }
      const rootKey = info.keyPath.length > 1 ? info.keyPath[info.keyPath.length - 1] : undefined;
      /**
       * Key for items is comprised by the following items:
       *  - FileImportType (if applies)
       *  - Retail / Non-retail
       *  - Channel number
       *  - Channel account number
       */
      const [fileImportType, accountType, channelNum, channelAccountNum] = info.key.split(':');

      console.log(info, fileImportType, accountType);
      if (
        fileImportType &&
        Number(fileImportType) !== FileImportType.STYLE_MASTER_UPDATE &&
        Number(fileImportType) !== FileImportType.PRODUCT_ALIAS
      ) {
        onClick({ fileImportType: Number(fileImportType), rootKey });
        return;
      }

      const findAccount = (a: ChannelAccount) => a.ChannelAccountNum === Number(channelAccountNum);
      const account = (accountType === 'retail' ? data.retail : data.nonRetail)[channelNum].find(
        findAccount,
      );

      // console.log('-->', account, rootKey);
      if (Number(fileImportType) === FileImportType.STYLE_MASTER_UPDATE) {
        onClick({ fileImportType: Number(fileImportType), account, rootKey });
        return;
      }
      if (Number(fileImportType) === FileImportType.PRODUCT_ALIAS) {
        onClick({ fileImportType: Number(fileImportType), account, rootKey });
        return;
      }

      onClick({ account, rootKey });
    },
    [onClick, data],
  );

  return (
    <Dropdown
      overlay={(
        <Menu
          selectable={false}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...menuProps}
          triggerSubMenuAction="click"
          onClick={onMenuClick}
        >
          {isSpecialChannelExist() && (<Menu.SubMenu key="products" popupOffset={[-3, -3]} title="Channels">
            {/* <Menu.Item key={`${FileImportType.PRODUCT_CREATE_EDIT}:::`}>Commerce Central</Menu.Item> */}
            {channels1()}
          </Menu.SubMenu>
          )}
          {/*<Menu.SubMenu key="styleMaster" title="Style Master">
            <Menu.SubMenu key={`${FileImportType.STYLE_MASTER_CREATE}:Sub::`} title="Create">
              <Menu.Item key={`${FileImportType.STYLE_MASTER_CREATE}:::`}>New Style Master</Menu.Item>
              <Menu.Item key={`${FileImportType.STYLE_MASTER_GROUP}:::`}>
                New Product(s)
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key={`${FileImportType.STYLE_MASTER_UPDATE}:::`} title="Update">
              <Menu.Item key={`${FileImportType.STYLE_MASTER_PRODUCT}:::`}>
                Product
              </Menu.Item>
              {channels(FileImportType.STYLE_MASTER_UPDATE)}
            </Menu.SubMenu>
            <Menu.Item key={`${FileImportType.STYLE_MASTER_ASSIGN}:::`}>
              Build Relationship
            </Menu.Item>
          </Menu.SubMenu>*/}
          <Menu.Item key={`${FileImportType.INVENTORY}:::`}>Channel Inventory</Menu.Item>
          {/*<Menu.Item key={`${FileImportType.ORDERS}:::`}>Orders</Menu.Item>
          <Menu.Item key={`${FileImportType.TRACKING_NUMBER}:::`}>Tracking Number</Menu.Item>*/}
          <MenuDivider />
          {/*<Menu.Item key={`${FileImportType.ATTRIBUTES_CREATE}:::`}>Attributes</Menu.Item>*/}
          <Menu.SubMenu key={`${FileImportType.STYLE_MASTER_UPDATE}:::`} popupOffset={[-3, -3]} title="Attributes">
            <Menu.Item key={`${FileImportType.STYLE_MASTER_PRODUCT_BASIC}:::`}>
              Basic
            </Menu.Item>
            {useCommon && <Menu.Item key={`${FileImportType.STYLE_MASTER_PRODUCT}:::`}>
              Common
            </Menu.Item>}
            {channels(FileImportType.STYLE_MASTER_UPDATE)}
          </Menu.SubMenu>
          <Menu.SubMenu key="product_alias" popupOffset={[-3, -3]} title="Product Alias">
            {channels()}
          </Menu.SubMenu>
          {isDevEnv() && (<Menu.Item key={`${FileImportType.LOOKUP}:::`}>Channel Lookup</Menu.Item>)}
        </Menu>
      )}
      placement="bottomLeft"
    >
      <Button type="primary" loading={isFetching}>
        <CloudUploadOutlined />
        Import
      </Button>
    </Dropdown>
  );
};

const ImportFile: React.FC<{ onFileUploaded?: () => void, useCommon: boolean }> = ({ onFileUploaded, useCommon }) => {
  const [visible, setVisible] = useState(false);
  const [account, setAccount] = useState<ChannelAccount | undefined>();
  const [rootKey, setRootKey] = useState<string | undefined>();
  const [importType, setImportType] = useState<FileImportType | undefined>();

  const clear = useCallback(() => {
    setImportType(undefined);
    setAccount(undefined);
    setRootKey(undefined);
    setVisible(false);
  }, []);

  const onSuccess = useCallback(() => {
    onFileUploaded?.();
    clear();
  }, [onFileUploaded, clear]);

  const onItemSelected = useCallback((data: ImportFileData) => {
    console.log('ff-->', data);
    setImportType(data?.fileImportType);
    setAccount(data?.account);
    setRootKey(data?.rootKey);
    setVisible(true);
  }, []);

  return (
    <>
      <ImportFileButton onClick={onItemSelected} useCommon={useCommon} />
      <ImportFileModal
        importType={importType}
        account={account}
        rootKey={rootKey}
        visible={visible}
        onFileUploaded={onSuccess}
        onCancel={clear}
      />
    </>
  );
};

const initialData = {
  count: 0,
  data: [],
};

// eslint-disable-next-line
const initialFilterValue = [
  { name: 'RequestedTime', operator: 'inrange', type: 'date', value: '' },
  { name: 'ImportStatus', operator: 'eq', type: 'select', value: '' },
  { name: 'RequesterEmail', operator: 'contains', type: 'string', value: '' },
];

const initialDataLoadParams: ListImportFilesParams = {
  $count: true,
  $skip: 0,
  $top: 10,
  requestedDateFrom: convertToUtcTime(moment().add(-7, 'days').startOf('day').format()),
  requestedDateTo: convertToUtcTime(moment().endOf('day').format())
};

const columns = [
  {
    header: 'Type',
    name: 'Type',
    defaultFlex: 3,
    minwidth: 120,
    // render: ({ value }: { value: number }) => ImportTypeMap.get(value),
  },
  {
    header: 'Reason',
    name: 'Reason',
    defaultFlex: 3,
  },
  {
    dateFormat: 'YYYY-MM-DD',
    defaultFlex: 2,
    //filterEditor: DateFilter,
    header: 'Submitted Time',
    minWidth: 180,
    name: 'RequestedTime',
    // render: ({ value }: { value: string }) => formatDate(value, 'dateTime'),
    // render: ({ value }: { value: string }) => moment(value).format('MM/DD/YYYY - hh:mm A'),
    render: ({ value }: { value: string }) => convertTimeByUtc(value, '', '', 'MM/DD/YYYY - hh:mm A')
  },
  {
    header: 'File Name',
    defaultFlex: 3,
    name: 'FileName',
    minWidth: 300,
    render({ data, value }: { data: Entities.ImportFileProfile; value: string }) {
      //const errorFile = data.ImportStatus === FileImportStatus.COMPLETED_WITH_ERROR;
      //const fileName = (errorFile && data.ErrorFileName) || value;
      const fileName = value;

      //console.log('vd', data, value);
      //return <FileDownloadLink data={data} errorFile={errorFile}>{fileName}</FileDownloadLink>;
      return <FileDownloadLink data={data}>{fileName}</FileDownloadLink>;
    },
  },
  {
    header: 'File Size',
    minWidth: 100,
    name: 'FileSize',
    defaultFlex: 1,
    render: ({ value }: { value: string }) => formatBytes(value),
  },
  {
    header: 'User',
    minWidth: 180,
    name: 'RequesterEmail',
    defaultFlex: 2,
    //filterDelay: 500,
  },
  {
    header: 'Status',
    minWidth: 130,
    name: 'ImportStatus',
    defaultFlex: 2,
    render({ value, data }: { value: number; data: Entities.ImportFileProfile }) {
      const text = ImportStatusMap.get(value);

      if (
        value !== FileImportStatus.COMPLETED_WITH_ERROR &&
        value !== FileImportStatus.FAILED_VALIDATION &&
        value !== FileImportStatus.FAILED
      ) {
        return text;
      }

      return (
        <FileDownloadLink data={data} errorFile>
          {text}
        </FileDownloadLink>
      );
    },
    //filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Any',
      dataSource: enumToObjects({ choices: FileImportStatus }),
    },
  },
];

const Page: React.FC = () => {
  //const [filterValue, setFilterValue] = useState<TypeFilterValue>(initialFilterValue);
  const [init, setInit] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<ListImportFilesParams>(initialDataLoadParams);
  const [fetchedPermissions, setFetchedPermissions] = useState(false);
  const [importUser, setImportUser] = useState<string>();
  const [importUserKey, setImportUserKey] = useState(0);
  const [importStatus, setImportStatus] = useState<number>();
  const [importStatusKey, setImportStatusKey] = useState(0);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [gridData, setGridData] = useState<StringKAnyVPair[]>([]);
  const [submittedDate, setSubmittedDate] = useState<any>([
    moment().add(-7, 'days').startOf('day'),
    moment().endOf('day'),
  ]);
  const [submittedDateKey, setSubmittedDateKey] = useState(0);
  const [useCommon, setUseCommon] = useState(false)
  const {
    data: items = initialData,
    isFetching: loading,
    refetch: reloadData,
  } = useListImportFilesQuery(filterParams);
  //const dataSource = useCallback(async () => items, [items]);
  const profiles = useSelector((s: any) => s.profiles);

  const statusOptions = [1, 2, 3, 4, 5, 6, 7].map(e => ({
    label: ImportStatusMap.get(e),
    value: e,
  }));

  const clearDataSource = () => {
    for (let i = items.data.length - 1; i <= 0; i--) {
      items.data.splice(i, 1);
    }
    setGridData([]);
  };

  const clearSearchForm = () => {
    setImportStatus(undefined);
    setImportStatusKey(importStatusKey + 1);
    setImportUser(undefined);
    setImportUserKey(importUserKey + 1);
    setSubmittedDate([
      moment().add(-7, 'days').startOf('day'),
      moment().endOf('day'),
    ]);
    setSubmittedDateKey(submittedDateKey + 1);
  };

  const loadNextLevelNodes = async (data: any) => {
    const { node } = data;
    let ret: any = null;

    if (node.nodes) return;
    //console.log('load -->', data);
    if (node.FileId) {
      // setIsLoading(true);

      try {
        const nodes = await fetchImportsOfFile(node.FileId);
        const gd = gridData.length > 0 ? gridData : JSON.parse(JSON.stringify(items.data));

        console.log('nodes ->', node, nodes);
        if (Array.isArray(nodes)) {
          //if (nodes.length > 1) nodes.splice(0, 1);
          ret = nodes.map((e: StringKAnyVPair) => ({
            ...node,
            __nodeProps: undefined, // !important
            ErrorFileName: e.errorFileName,
            FileId: e.fileId,
            FileName: e.fileName,
            //FileName: e.errorFileName || e.fileName,
            FileSize: e.fileSize,
            HasSubFile: 0,
            ImportStatus: e.importStatus,
            RequestedTime: e.requestedTime,
            RequesterEmail: e.requesterEmail,
            SystemFileName: e.fileName,
            TreeId: e.fileId,
            //TreeId: `${node.FileId}/${e.fileId}`,
            nodes: undefined,
          }));
          //console.log('items:', items, node);
          node.nodes = ret;
          //gd.forEach((e: StringKAnyVPair, i: number) => {
          for (let i = 0; i < gd.length; i++) {
            if (gd[i].FileId === node.FileId) {
              gd[i] = { ...node };
              break;
            }
          }
          //});
          //console.log('gd ->', gd);
          setGridData([...gd]);
        }
      } catch (e) {
        notification.error({
          message: `Fetch sub files error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        // setIsLoading(false);
      }
    }

    return ret;
  };

  const dataSource = useCallback(async () => {
    const isDataUnchanged = () => {
      if (gridData.length > 0 && items.data.length > 0) {
        const itemData = items.data as StringKAnyVPair[];

        return !loading && gridData[0].FileId === itemData[0].FileId;
      }

      return false;
    };

    //const data = items.data;
    //const data = gridData.length > 0 ? gridData : JSON.parse(JSON.stringify(items.data));
    // let data = isDataUnchanged() ? gridData : JSON.parse(JSON.stringify(items.data));
    let data = [];
    if (init) {
      data = isDataUnchanged() ? gridData : JSON.parse(JSON.stringify(items.data));
    }


    data.forEach((e: StringKAnyVPair) => {
      if (!e.TreeId) {
        e.TreeId = e.FileId;
      }

      if (e.nodes === undefined) {
        if (e.HasSubFile) {
          e.nodes = null;
        }
      }
    });

    //console.log('dd ->', data, items.count);
    if (loading) {
      data = [];

      if (gridData.length > 0) {
        setGridData([]);
        //clearDataSource();
      }
    } else {
      if (data.length > 0 && gridData.length === 0) {
        setGridData([...data]);
      }
    }

    //return {count: data.length, data};
    return { count: items.count, data };
  }, [init, items, gridData, loading]);

  const onFileUploaded = useCallback(() => {
    reloadData();
  }, [reloadData]);

  // const onCountDownFinished = useCallback(() => {
  //   reloadData();
  // }, [reloadData]);

  const onImportStatusChange = (val: any) => {
    console.log('st', val);
    setImportStatus(val);
  };

  const onImportUserChange = (evt: any) => {
    //console.log('-->', evt.target.value.trim());
    setImportUser(evt.target.value.trim());
  };

  // eslint-disable-next-line
  const onNodeCollapse = (args: any) => {
    const { data, id } = args;

    if (data) {
      console.log(id, data);
      return true;
    }
  };

  const onSkipChange = ($skip: number) => {
    clearDataSource();
    setFilterParams({ ...filterParams, $skip });
  };

  const onLimitChange = ($top: number) => {
    clearDataSource();
    setFilterParams({ ...filterParams, $top });
  };

  /*
  const onFilterValueChange = useCallback(
    (value: TypeFilterValue) => {
      // Update local copy
      setFilterValue(value);

      // Update filter params
      const { $top, $skip } = filterParams;
      const requestedTimeFilter = value?.find((f) => f.name === 'RequestedTime');
      const params = {
        $count: true,
        $top,
        $skip,
        importStatus: value?.find((f) => f.name === 'ImportStatus')?.value,
        requester: value?.find((filter) => filter.name === 'RequesterEmail')?.value,
        requestedDateFrom: requestedTimeFilter?.value?.start,
        requestedDateTo: requestedTimeFilter?.value?.end,
      };
      setFilterParams(params);
    },
    [filterParams],
  );*/

  const onSearchImportedFiles = async () => {
    setInit(true);
    const { $top, $skip } = filterParams;
    const params = {
      $count: true,
      $top,
      $skip,
      //importStatus: value?.find((f) => f.name === 'ImportStatus')?.value,
      importStatus: typeof importStatus === 'undefined' ? undefined : `${importStatus}`,
      //requester: value?.find((filter) => filter.name === 'RequesterEmail')?.value,
      requester: importUser || undefined,
      requestedDateFrom: submittedDate ?
        convertToUtcTime(submittedDate[0].startOf('day').format()) : undefined,
      requestedDateTo: submittedDate ?
        convertToUtcTime(submittedDate[1].endOf('day').format()) : undefined,
      timeStamp: new Date().getTime()
    };
    setFilterParams(params);
  };

  const onSubmitDateChange = (dates: any) => {
    setSubmittedDate(dates);

    if (dates) {
      console.log(convertToUtcTime(dates[0].startOf('day').format()));
      console.log(convertToUtcTime(dates[1].endOf('day').format()));
    }
  };

  const getUseCommon = useCallback(async () => {
    try {
      const settingValue = await getProfileSettingValue('UseCommonAttributes', '0')
      setUseCommon(settingValue !== '0')
    } catch (error) {

    }
  }, [])

  useEffect(() => {
    if (profiles.permissions) {
      setFetchedPermissions(true);
    }
  }, [profiles]);

  useEffect(() => {
    getUseCommon()
  }, [getUseCommon])

  const SearchTips = (params: { left: number }) => {
    const { left } = params;
    return (
      <div
        style={{ position: 'absolute', left, top: 35, width: 620, backgroundColor: 'transparent', textAlign: 'center' }}
      >
        <div style={{ width: 35, height: 35, padding: 5, marginLeft: 330 }}>
          <ArrowTurnUp />
        </div>
        <Row>
          <Text style={{ fontSize: 24, color: '#5C677D' }}>
            Input your search criteria and click "Search" button
          </Text>
        </Row>
      </div>
    )
  }

  // console.log('pp->', profiles);
  /* profiles.permissions.forEach((e: StringKAnyVPair) => {
    if (e.PermissionName.indexOf('Export') > -1) {
      console.log('ee->', e.PermissionName, e.PermissionNum, e.PermissionLevel, e);
    }
  }); */

  return (
    <ContentLayout>
      {fetchedPermissions ? (
        <Heading
          title="Import Center"
          actions={
            hasHigherPermission(profiles.permissions, Permissions.MANAGE_IMPORT, ViewLevel.VIEW) ? (
              <ImportFile onFileUploaded={onFileUploaded} useCommon={useCommon} />
            ) : null
          }
        />
      ) : (
        <Heading title="Import Center" />
      )}
      <Spacer />
      <SiteContent flexGrow={init}>
        <SearchFormWrapper>
          <Row>
            <Col span={10}>
              <Row align="middle">
                <FormLabel className="search-form-label">Submitted Date</FormLabel>
                <DatePicker.RangePicker
                  className="search-submit-date-picker"
                  key={`submit-date-range${submittedDateKey}`}
                  onChange={onSubmitDateChange}
                  value={submittedDate}
                  allowClear={false}
                />
              </Row>
            </Col>
            <Col span={7}>
              <Row align="middle">
                <FormLabel className="search-form-label">User</FormLabel>
                <Input
                  allowClear
                  className="search-user-input"
                  key={`user-input${importUserKey}`}
                  onChange={onImportUserChange}
                />
              </Row>
            </Col>
            <Col span={7}>
              <Row align="middle">
                <FormLabel className="search-form-label">Status</FormLabel>
                <Select
                  allowClear
                  className="search-user-input"
                  key={`status-sel${importStatusKey}`}
                  onChange={onImportStatusChange}
                  options={statusOptions}
                />
              </Row>
            </Col>
          </Row>
          <Row className="search-form-bottom-btn-row" justify="center">
            <Space>
              <Button
                onClick={onSearchImportedFiles}
                disabled={loading}
                type="primary"
                style={{ position: 'relative', width: 95 }}
              >
                <SearchOutlined />
                Search
                {!init && <SearchTips left={-295} />}
              </Button>
              <Button
                onClick={clearSearchForm}
                disabled={loading}
              >
                <ClearOutlined />
                Clear
              </Button>
            </Space>
          </Row>
        </SearchFormWrapper>
        {/* <TimedCallback onCountDownFinished={onCountDownFinished} /> */}
        {
          init && (
            <DataGrid
              //idProperty="FileName"
              idProperty="TreeId"
              style={{ minHeight: '50vh', marginTop: 15 }}
              columns={columns}
              dataSource={dataSource}
              //filterValue={filterValue}
              skip={filterParams.$skip}
              limit={filterParams.$top}
              loadNode={loadNextLevelNodes}
              //onFilterValueChange={onFilterValueChange}
              //onExpandedNodesChange={onNodeCollapse}
              onSkipChange={onSkipChange}
              onLimitChange={onLimitChange}
              loading={loading || isLoading}
              pagination
              //enableFiltering
              showColumnMenuLockOptions={false}
              treeColumn="FileName"
            />
          )
        }
      </SiteContent>
    </ContentLayout>
  );
};

export default Page;
