import _ from "lodash";
import { listProfileChannelAccounts } from "../../services/channels";

let channelList: Entities.ProfileChannelAccount[] | undefined = undefined;

/**
 * 
 * @param list list of type objects
 * @param keys list of variables inside objects 
 * @param strictKeysEnabled if true only group if all keys are defined - otherwise ignore
 * @returns returns an object whose values are lists of original list items with same values for each key
 */
export const groupByKeys = (list: any[], keys: string[], strictKeysEnabled = false) => {
  const result = list.reduce<Record<string, any[]>>((reducer, item) => {
    // if strictKeysEnabled & every key is not defined, then don't group 
    if (strictKeysEnabled && !keys.every((key: string) => item[key])) return reducer;
    const reduceKey = keys.map((key: string) => (item[key])).join('_').toLowerCase(); // use lowercase key to concatenate (case insensitive grouping important in case like skus)
    if (!reducer[reduceKey]) reducer[reduceKey] = [];
    reducer[reduceKey].push(item);
    return reducer;
  }, Object.create(null));
  return result;
};

const getChannelList = async () => {
  try {
    const list = await listProfileChannelAccounts();
    channelList = list || [];
    return channelList;
  } catch (error) { }
}

export const getChannelInfo = async (channelNum: number, channelAccountNum: number) => {
  let list = channelList;
  if (!list) list = await getChannelList();

  if (list) {
    const channelName = list.find(item => item.ChannelNum === channelNum)?.ChannelName || '';
    const channelAccountName = list.find(item => item.ChannelAccountNum === channelAccountNum)?.ChannelAccountName || '';
    return { channelName, channelAccountName };
  }
  return { channelName: '', channelAccountName: '' };
}

/**
* Check value or object is empty or zero or no data.
* @param {any} obj - object or array or value
* @return {bool} return true if is empty
*/
export const isEmpty = (obj: any): boolean => {
  if (!obj) return true;
  const t = typeof obj;
  if (t === 'string') {
    return obj.length <= 0;
  }
  if (t === 'number') {
    return (isNaN(obj) || obj === 0);
  }
  return _.isEmpty(obj);
};

export const isChartEmpty = (chartData: any[]) => {
  if (!chartData) return true;

  let flag = true;
  chartData.forEach((item: any[], index: number) => {
    if (index > 0) {
      item.forEach((itm: any, idx: number) => {
        if (idx > 0 && itm > 0) flag = false;
      })
    }
  })
  return flag;
}

/**
* Check if target is string
* @param {any} target - target
* @return {bool} is string
*/
export const isString = (target: any): target is string => {
  return !isNil(target) && _.isString(target);
};

/**
* Check if target is null or undefined
* @param {any} target - object
* @return {bool} is function
*/
export const isNil = (target: any): boolean => {
  return (target == null || target === undefined);
};