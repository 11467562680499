import React from 'react';
import { Button, Col, Row, Select } from 'antd';
import { ClearOutlined, SaveOutlined } from '@ant-design/icons';
import { EditorModal } from '../styledComponents';

type Props = {
  onClose: Function;
  onSave?: Function;
  options?: StringKAnyVPair[];
  title?: string;
  value?: any;
  visible: boolean;
};

const SimpleSelectorModal = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState<any>(props.value === undefined ? undefined : props.value);

  const dialogTitle = () => {
    return props.title || '';
  };

  const getOptions = (opts: StringKAnyVPair[]) => {
    const ret: any[] = [];

    opts.forEach(opt => {
      if ('value' in opt) {
        ret.push({
          label: opt.label || opt.value,
          value: opt.value,
        });
      }
    });

    return ret;
  };

  const onChange = (val: any) => {
    console.log('ch->', val);
    setCurrentValue(val === undefined ? '' : val);
  };

  const onClear = () => {
    onChange('');
  };

  const onCloseDialog = () => {
    props.onClose();
  };

  const saveData = () => {
    if (typeof props.onSave === 'function') {
      props.onSave(currentValue);
    }

    onCloseDialog();
  };

  return (<>
    <EditorModal
      centered
      className="fullscreen-modal"
      footer={null}
      //okText="Save"
      onCancel={onCloseDialog}
      //onOk={onSaveImage}
      maskClosable={false}
      style={{ paddingBottom: 0 }}
      title={dialogTitle()}
      visible={props.visible}
      width={400}
    >
      <Col className="modal-body-wrapper2">
        <Select
          allowClear
          showSearch
          // placement="topRight"
          dropdownMatchSelectWidth={false}
          filterOption={(inputValue, option) => {
            const search = inputValue.trim().toLowerCase();
            let label = '';

            if (option && 'object' == typeof option) {
              if ('string' === typeof option.label) {
                label = option.label.toLowerCase();
              }
            }

            return !!label && label.indexOf(search) === 0;
          }}
          onChange={onChange}
          options={getOptions(props.options || [])}
          style={{width: '100%'}}
          value={currentValue}
        />
        <Row justify="space-between" style={{marginTop: 18}}>
          <Button
            disabled={currentValue === undefined ? !props.value : !currentValue}
            onClick={onClear}
          >
            <ClearOutlined />
            Clear Value
          </Button>
          <Button
            disabled={currentValue === props.value}
            onClick={saveData}
            type="primary"
          >
            <SaveOutlined />
            Save
          </Button>
        </Row>
      </Col>
    </EditorModal>
  </>);
};

export default SimpleSelectorModal;
