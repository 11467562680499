import React from "react";

const Icon = ({ width = "16", height = "17", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M2.667 4.789v6.666c0 1.473 2.388 2.667 5.333 2.667 2.945 0 5.333-1.194 5.333-2.667V4.79m-10.666 0c0 1.47 2.388 2.665 5.333 2.665 2.945 0 5.333-1.194 5.333-2.666m-10.666 0c0-1.473 2.388-2.667 5.333-2.667 2.945 0 5.333 1.194 5.333 2.667m0 3.333c0 1.473-2.388 2.667-5.333 2.667-2.946 0-5.333-1.194-5.333-2.667"
        stroke="#D9D9D9"
      />
    </svg>
  );
};
export default React.memo(Icon);
