import React from "react";

const Icon = ({ width = "16", height = "17", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M14 4.789 8 2.122 2 4.789m12 0L8 7.455m6-2.666v6.666l-6 2.667m0-6.667L2 4.79m6 2.666v6.667M2 4.789v6.666l6 2.667"
        stroke="#D9D9D9"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default React.memo(Icon);
