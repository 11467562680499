import React from 'react';
import { Col, Divider, Modal, Row, Typography } from 'antd';
import { APP_VERSION } from '../../constants/config';

const { Title } = Typography;

interface VersionModalProps {
  show: boolean;
  onClose: Function;
}

export default function VersionModal(props: VersionModalProps): JSX.Element {
  const { show, onClose } = props as any;

  return (
    <Modal
      centered
      visible={show}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
    >
      <div style={{
        padding: 8,
        backgroundColor: '#FFF',
        borderRadius: 4,
      }}>
        <Row wrap={false}>
          <Col>
            <div style={{ padding: 20 }} >
              <img className="logo" alt="Company Logo" src="/Digitbridge-LOGO-100X100.png" />
            </div>
          </Col>
          <Col>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col>
            <div style={{ padding: 20 }} >
              <Title level={2}>DigitBridge</Title>
              <Title level={5}>{`Version ${APP_VERSION}`}</Title>
            </div>
          </Col>
        </Row>
      </div>
    </Modal >
  )
}
