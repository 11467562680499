import { Form, Modal, Spin, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Permissions } from "../../constants/enums/permissions";
import FormButtons from "../../components/common/FormButtons";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/es/upload";
import { uploadContentSource } from "../../services/copywriting";

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  visible: boolean;
}

function ModalForm({ visible, onClose, onSuccess }: ModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();

  const handleBeforeSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (files.length === 0 || !files[0].originFileObj) {
      return;
    }
    try {
      setProcessing(true);
      const res = await uploadContentSource(files[0].originFileObj);
      if (res.isSuccess) {
        onSuccess();
      } else {
        message.error(res.message || res.Message);
      }
      form.resetFields();
    } catch (error) {
      // TODO: something
      message.error(
        (error as any).response.data.message ||
          (error as any).response.data.Message
      );
    } finally {
      setProcessing(false);
    }
  };

  const onFilesChange = useCallback(
    (info: UploadChangeParam) => {
      form.resetFields();
      setFiles(info.fileList.slice(-1));
      form.setFieldsValue({
        fileCount: info.fileList.length === 0 ? undefined : 1,
      });
    },
    [form]
  );

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={<TitleHeader>Import Content Resource</TitleHeader>}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={500}
      footer={
        <FormButtons
          permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
          onSave={form.submit}
          onCancel={onClose}
          editingMode
          style={{ justifyContent: "flex-end" }}
        />
      }
    >
      <Spin spinning={processing}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          initialValues={{ size: "middle" }}
          onFinish={handleBeforeSubmit}
        >
          <Form.Item
            name="fileCount"
            rules={[{ required: true, message: "file is required" }]}
          >
            <Dragger
              fileList={files}
              onChange={onFilesChange}
              beforeUpload={() => false}
              accept=".xls, .xlsx"
              name="file"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag files to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ModalForm;
