import React from 'react';
import {
  Button,
  Row,
  Space,
  Typography,
  notification,
} from 'antd';
import styled from 'styled-components';
import {
  CloseOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import { DataGrid } from '../../../components/common/datagrid/DataGrid2';
import Loading from '../../../components/common/Loading';
import ModalDialog from '../../../components/common/ModalDialog';
import ScreenMask from '../../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  //DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import {
  fetchControlTowerMappingBySubGroupNum,
  saveControlTowerChannelDataMapping,
} from '../../../services/controlTower';
import SelectAtrributeComponent from './SelectAttributeModal';

type Props = {
  group: StringKAnyVPair;
  onClose: Function;
  visible: boolean;
};

const DialogBody = styled.div`
  height: 500px;
  width: 100%;

  & .mapping-cell-label {
    cursor: pointer;
  }

  & .mapping-cell-label {
    text-align: left;
    width: calc(100% - 18px);
  }
`;

const ChannelDataFieldMappingDialog = (props: Props) => {
  const { useState } = React;
  const [attrSelectorVisible, setAttrSelectorVisible] = useState(false);
  const [displayGrid, setDisplayGrid] = useState(false);
  const [editCellIdx, setEditCellIdx] = useState(0);
  const [editDict, setEditDict] = useState<StringKAnyVPair>({});
  const [editRow, setEditRow] = useState<StringKAnyVPair>({});
  const [gridColumns, setGridColumns] = useState<StringKAnyVPair[]>([]);
  const [gridData, setGridData] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bodyRef = React.useRef<any>(null);

  const closeAttrSelector = () => {
    setAttrSelectorVisible(false);
  };

  // eslint-disable-next-line
  const loadMapping = async () => {
    setIsLoading(true);

    try {
      const res = await fetchControlTowerMappingBySubGroupNum(props.group.rowNum);

      console.log('ttt', res);
      if (res && typeof res === 'object') {
        const { datalist, headers } = res;
        const data: StringKAnyVPair[] = [];

        if (Array.isArray(datalist)) {
          datalist.forEach((ds: StringKAnyVPair[]) => {
            const row: StringKAnyVPair = {};
            const rowNums: number[] = [];

            ds.filter(d => {
              const ret = rowNums.indexOf(d.rowNum) < 0;

              if (ret) rowNums.push(d.rowNum);

              return ret;
            }).forEach((d, i) => {
              row[`data${i}`] = d.displayName;
            });
            row.data = ds;
            data.push(row);
          });
          setGridData(data);
        }

        if (Array.isArray(headers)) {
          setGridColumns(headers.map((h: StringKAnyVPair, i: number) => {
            return {
              defaultLocked: h.rowNum === 0,
              name: `data${i}`,
              header: h.displayName,
              //defaultFlex: 1,
              //locked: true,
              minWidth: 160,
              render(row: any) {
                const { data } = row;

                return renderMappingCell(data, i);
              },
              showColumnMenuTool: false,
              sortable: i === 0,
              textAlign: 'center' as 'center',
            };
          }));
          setDisplayGrid(true);
        }
      }
    } catch(e) {
      notification.error({
        message: `Fetch mapping error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    props.onClose();
  };

  const onEditCellMapping = (
    data: StringKAnyVPair,
    idx: number,
  ) => {
    console.log('edit', data, idx);
    setEditCellIdx(idx);
    setEditRow(data);
    openAttrSelector()
  };

  const onFullscreen = (full: boolean) => {
    const body = bodyRef.current;

    if (body) {
      const ctn = body.parentNode.parentNode;
      const height = window.innerHeight;

      //console.log('full', full, ctn, height);
      setTimeout(() => {
        if (full) {
          ctn.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 16}px`;
          ctn.style.height = `${height - 120}px`;
          body.style.height = '100%';
          resetColumnMinWidth(220);
          setTimeout(() => {
            ctn.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
          }, 100);
        } else {
          ctn.style.height = '';
          body.style.height = '';
          resetColumnMinWidth(160);
        }
      }, 0);
    }
  };

  const onMapCellAttr = (data: StringKAnyVPair) => {
    console.log('map', data, editRow);
    const rd = editRow.data[editCellIdx];

    if (editCellIdx > 0 && rd) {
      editRow[`data${editCellIdx}`] = data.attributeName;
      editDict[data.attributeNum] = {
        opr: 'update',
        //subGroupRowNum: props.group.rowNum,
        subGroupRowNum: rd.rowNum,
        source: data.groupName,
        element: {
          attributeId: data.attributeNum,
          //attributeId: rd.rowNum,
          originalName: data.attributeName,
        },
      };
      console.log('ed-->', editDict);
      setEditDict({...editDict});
      setGridData([...gridData]);
    }

    closeAttrSelector();
  };

  const openAttrSelector = () => {
    setAttrSelectorVisible(true);
  };

  const renderFooter = () => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={onCancel}>
            <CloseOutlined />
            Close
          </Button>
          <Button
            disabled={Object.keys(editDict).length === 0}
            onClick={saveMapping}
            type="primary"
          >
            <SaveOutlined />
            Save
          </Button>
        </Space>
      </Row>
    );
  };

  const renderMappingCell = (
    data: StringKAnyVPair,
    idx: number,
  ) => {
    const Text = Typography.Text;
    const text = data[`data${idx}`];

    return idx === 0 ? text : (
      <Row align="middle" justify="space-between">
        <Text className="mapping-cell-label" ellipsis={{tooltip: text}}>
          {text}
        </Text>
        <EditOutlined
          className="mapping-cell-editor"
          onClick={() => onEditCellMapping(data, idx)}
        />
      </Row>
    );
  };

  const saveMapping = async () => {
    const md: StringKAnyVPair[] = [];

    for (let k in editDict) {
      md.push(editDict[k]);
    }

    console.log('mmm', md);
    setIsLoading(true);
    try {
      const res = await saveControlTowerChannelDataMapping(props.group.rowNum, md);

      console.log('res', res);
      setEditDict({});
    } catch(e) {
      notification.error({
        message: `Saved channel data mapping error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetColumnMinWidth = (width: number) => {
    gridColumns.forEach(c => c.minWidth = width);
    setGridColumns([...gridColumns]);
  };

  React.useEffect(() => {
    if (!inited) {
      loadMapping();
      setInited(true);
    }
  }, [inited, loadMapping]);

  return (<>
    <ModalDialog
      centered
      closable={false}
      closeButton
      destroyOnClose
      footer={renderFooter()}
      fullscreen
      maskClosable={false}
      onCancel={onCancel}
      onClose={onCancel}
      onFullscreen={onFullscreen}
      title="Channel Data Fields Mapping"
      visible={props.visible}
      width={700}
    >
      <DialogBody ref={bodyRef}>
        {displayGrid && <DataGrid
          //columns={columns}
          columns={gridColumns}
          //dataSource={dataSource}
          dataSource={gridData}
          idProperty="key"
          pagination={false}
          style={{height: '100%'}}
        />}
      </DialogBody>
      {attrSelectorVisible && (
        <SelectAtrributeComponent
          editIndex={editCellIdx}
          onHide={closeAttrSelector}
          onRefresh={onMapCellAttr}
          row={editRow.data}
          visible={attrSelectorVisible}
        />
      )}
    </ModalDialog>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default ChannelDataFieldMappingDialog;
