import { Row, Button, Modal, Typography, Space, Menu, Dropdown, Col, message } from 'antd';
import React, { useState, useCallback, } from 'react';
import styled from 'styled-components';
import { ProductProfileMap, ProductSearchProps } from '../../components/common/ProductSearch';
import ProductSearchAddRemove from '../../components/common/ProductSearchAddRemove';
import { pushToChannelAccount, pushToChannelAccountAll } from '../../services/channels';
import { ChannelAccountEventType } from '../../types/enums';
import { isEmpty } from 'lodash';
import { useGetChannelAccountProductsQuery } from '../../redux/api/products';


const { Text, Title } = Typography;

const productsSearchProps: ProductSearchProps = {
  title: 'Product Search',
  allowMultiple: true,
  tableStyle: { height: '500px' },
  buttonText: 'Add products',
};
const ProductSearchWrapper = styled.div`
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 12px;
`;

const ProductsActionButton: React.FC<{ settings: ChannelAccount }> = ({ settings }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isStyleMaster, setIsStyleMaster] = useState<boolean>(false);
  const [selected, setSelected] = useState<ProductProfileMap>({});
  const [available, setAvailable] = useState<Entities.ProductProfile[]>([]);
  const [processing, setProcessing] = useState(false);

  const openPushProductsModal = useCallback((styleMasterSelection: boolean) => {
    setModalVisible(true);
    setIsStyleMaster(styleMasterSelection);
  }, []);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => openPushProductsModal(false)}>Single Products</Menu.Item>
      <Menu.Item onClick={() => openPushProductsModal(true)}>Style Master products</Menu.Item>
    </Menu>
  );

  const onChangeProductsSelected = useCallback((changeSelected: Entities.ProductProfile[], changeAvailable: Entities.ProductProfile[]) => {
    const selectedFormatted = changeSelected.reduce((p: any, item: Entities.ProductProfile) => {
      const r = { ...p };
      r[item?.ProductId] = item;
      return r;
    }, {});
    setSelected(selectedFormatted);
    setAvailable(changeAvailable);
  }, []);

  const onApply = useCallback(() => {
    if (!settings) {
      return;
    }
    /**
     * As per Van comments:
     * - event is CATALOG_UPDATE
     * - Push empty body to process ALL products
     *  - Otherwise, send an array of Product's SKUs
     */
    const skus = (Object.keys(selected).length === available.length ? [] : Object.keys(selected)).map((k) => selected[k].SKU);

    setModalVisible(false);
    setProcessing(true);
    pushToChannelAccount(ChannelAccountEventType.CATALOG_UPDATE, settings.ChannelAccountNum, settings.ChannelNum, skus).finally(() => {
      setProcessing(false);
      message.success('Publish successfully');
    });
  }, [settings, selected, available.length]);

  const onCancel = useCallback(() => {
    setModalVisible(false);
    setSelected({});
    setAvailable([]);
  }, []);

  if (!settings) {
    return null;
  }

  return (
    <>
      <Dropdown overlay={menu} placement="bottomCenter" arrow>
        <Button loading={processing}>
          Publish Advanced
        </Button>
      </Dropdown>
      <Modal
        visible={modalVisible}
        width={window.innerWidth}
        okText="Publish Products"
        closable={false}
        footer={null}
        className="fullscreen-modal"
        centered
        style={{ paddingBottom: 0 }}
        destroyOnClose
      >
        <ProductSearchWrapper>
          <Row>
            <Col offset={21} span={3}>
              <Button style={{ marginRight: '5px' }} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={isEmpty(selected)}
                onClick={() => {
                  onApply();
                }}
              >
                Publish Products
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ProductSearchAddRemove
                onChangeSelected={onChangeProductsSelected}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...productsSearchProps}
                styleMaster={isStyleMaster}
                query={useGetChannelAccountProductsQuery}
                channelAccountNum={settings.ChannelAccountNum}
                channelNum={settings.ChannelNum}
              />
            </Col>
          </Row>
        </ProductSearchWrapper>
      </Modal>
    </>
  );
};

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const CardSpace = styled(Space)`
  width: 450px;
`;

const SecondaryText = styled(Text)`
  font-size: 16px;
`;

const ResultDialog: React.FC<{
  account: ChannelAccount;
  selectedRows: any[];
  channelControlFlagNum: number;
  onClose: () => void;
}> = ({ account, onClose, selectedRows, channelControlFlagNum }) => {
  const [selectedLoading, setSelectedLoading] = useState(false)
  const [allLoading, setAllLoading] = useState(false);

  const postPush = useCallback(async () => {
    try {
      setSelectedLoading(true);
      const skus = selectedRows.map((i: any) => i.SKU)
      await pushToChannelAccount(ChannelAccountEventType.CATALOG_UPDATE, account.ChannelAccountNum, account.ChannelNum, skus);
      message.success('Publish successfully');
      setSelectedLoading(false)
    } catch (error) {
      setSelectedLoading(false)
    }
  }, [selectedRows, account])

  const postPushAll = useCallback(async () => {
    try {
      if (channelControlFlagNum >= 0) {
        setAllLoading(true);
        await pushToChannelAccountAll(ChannelAccountEventType.CATALOG_UPDATE, account.ChannelAccountNum, account.ChannelNum, channelControlFlagNum);
        message.success('Publish successfully');
        setAllLoading(false)
      } else {
        message.warning('invalid mapping templates')
      }

    } catch (error) {
      setAllLoading(false)
    }
  }, [account, channelControlFlagNum])
  return (
    <Modal
      visible
      width={500}
      closable={false}
      title={
        <Row>
          <Title level={4}>Publish Products</Title>
        </Row>
      }
      onCancel={() => onClose()}
      footer={false}
    >
      <CardSpace direction="vertical">
        <SecondaryText strong>
          Channel Account: <Text>{account.ChannelAccountName}</Text>
        </SecondaryText>
        <Row justify='space-around' style={{ marginTop: 16, marginBottom: 16 }}>
          <Button loading={selectedLoading} disabled={selectedRows.length === 0} onClick={postPush}>Publish Selected</Button>
          <Button loading={allLoading} onClick={postPushAll}>Publish All</Button>
          <ProductsActionButton settings={account} />
        </Row>
        <Row justify="end">
          <Button type="primary" onClick={() => onClose()}>
            Close
          </Button>
        </Row>
      </CardSpace>
    </Modal>
  );
};

export default ResultDialog;
