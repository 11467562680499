import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  message,
  notification,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
// import CSVLink, { CSVColumns } from '../../../components/common/CSVLink';
import { DataGrid } from "../../../components/common/datagrid/DataGrid3";
import Heading from "../../../components/common/Heading";
import SearchBar, { SearchField } from "../../../components/common/SearchBar";
import Spacer from "../../../components/common/Spacer";
import ContentLayout from "../../../components/ContentLayout";
import SiteContent from "../../../components/SiteContent";
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../../constants/config';
import ExprotModal from "./ExportModal";
import AddEditModal from "./AddEditModal";
import SelectAttributeModal from "./SelectAttributeModal";
import {
  fetchControlTowerGroups,
  fetchControlTowers,
  deleteControlTower2,
} from "../../../services/controlTower";

type ControlTowerElement = {
  rowNum: number;
  originalName: string;
  displayName: string;
  dataType: number;
  attributeId: string;
  source: string;
  editable: boolean;
  displaySequence: number;
  rows: number;
  columns: number;
  note: string;
  groupId: number;
};

const ElementDataTypeEnum: { [key: string]: string } = {
  "1": "string",
  "2": "Integer",
  "3": "Decimal",
  "4": "DataTime",
  "5": "ImageURL",
  "6": "Price",
  "7": "ImageUrlList",
  "8": "VideoURL",
  "9": "Virtual",
  "10": "Metafield",
  "11": "PageUrl",
};

const AddButton = styled(Button)<{ $hasPermission: boolean }>`
  display: ${(props) => (props.$hasPermission ? "initial" : "none")};
  min-height: 40px;
`;

//   const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
//     if (permissions && permissions.size === 0) return false;
//     return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
//   };

const searchFields: Array<SearchField<ControlTowerElement> | string> = [
  "originalName",
  "displayName",
  "source",
];

const PageContent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [sourceData, setSourceData] = useState<ControlTowerElement[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [groupDict, setGroupDict] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const [
    selectAttributeModalVisible,
    setSelectAttributeModalVisible,
  ] = useState(false);

  const getSourceData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchControlTowers({});
      setLoading(false);
      if (res) {
        setSourceData(res);
        setFilterData(res);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const loadGroups = async () => {
    try {
      //setLoadingGroup(true);
      const res = await fetchControlTowerGroups();
      //setLoadingGroup(false);
      if (Array.isArray(res)) {
        const dict: StringKAnyVPair = {};
        res.forEach((e: StringKAnyVPair) => {
          if (!dict[e.rowNum]) {
            dict[e.rowNum] = e.controlTowerGroupName;
          }
        });
        console.log('res', dict);
        setGroupDict(dict);
      }
    } catch(e) {
      notification.error({
        message: `Loaded groups error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
    }
  };

  const deleteField = useCallback(
    async (data: ControlTowerElement) => {
      try {
        const res = await deleteControlTower2(data.rowNum);

        if (res) {
          message.success("delete successfully");
          getSourceData();
        }
      } catch (error) {}
    },
    [getSourceData]
  );

  const columns = [
    {
      header: "Original Name",
      name: "originalName",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Display Name",
      name: "displayName",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Data Type",
      name: "dataType",
      defaultFlex: 1,
      editable: false,
      render({ data }: any) {
        return ElementDataTypeEnum[data.dataType];
      },
    },
    {
      header: "Source",
      name: "source",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Group Name",
      //name: "groupId",
      defaultFlex: 1,
      editable: false,
      render(row: any) {
        const { data } = row;

        return groupDict[data.groupId] || '';
      },
    },
    {
      header: "Editable",
      name: "editable",
      defaultFlex: 1,
      editable: false,
      render({ data }: any) {
        return data.editable ? "Enable" : "Disable";
      },
    },
    {
      header: "Sequence",
      name: "displaySequence",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Rows",
      name: "rows",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Columns",
      name: "columns",
      defaultFlex: 1,
      editable: false,
    },
    {
      name: "rowNum",
      header: "Action",
      defaultFlex: 3,
      minWidth: 200,
      maxWidth: 200,
      sortable: false,
      editable: false,
      render({ data }: any) {
        return (
          <Row justify="end" gutter={14}>
            <Col>
              <Button
                size="small"
                onClick={() => {
                  setCurrent(data);
                  setAddEditDialogVisible(true);
                }}
              >
                <EditOutlined style={{ color: "#006dff" }} />
                Edit
              </Button>
            </Col>
            <Col>
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: "Do you want to delete this field?",
                    icon: <ExclamationCircleOutlined />,
                    content: `Original Name: ${data.originalName}`,
                    onOk: async () => {
                      deleteField(data);
                    },
                    onCancel() {},
                  });
                }}
              >
                <DeleteOutlined style={{ color: "#c13939" }} />
                Delete
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (!inited) {
      loadGroups();
      setInited(true);
    }

    getSourceData();
  }, [getSourceData, inited]);

  return (
    <ContentLayout>
      <Heading
        title="Control Tower Fields"
        actions={
          <Space>
            <AddButton
              type="primary"
              $hasPermission={true}
              onClick={() => {
                setSelectAttributeModalVisible(true);
              }}
            >
              <PlusCircleOutlined />
              Add
            </AddButton>
          </Space>
        }
      />
      <Spacer />
      <SiteContent flexGrow>
        <Spin spinning={loading} wrapperClassName="ant-spin-flex">
          <Row justify="space-between">
            <Col xs={20} md={12}>
              <SearchBar
                reference="rowNum"
                data={sourceData}
                onResult={setFilterData}
                fields={searchFields}
                disabled={false}
              />
            </Col>
            <Button
              type="primary"
              disabled={sourceData.length === 0}
              onClick={() => setExportModalVisible(true)}
            >
              Export
            </Button>
          </Row>
          <Spacer height={14} />
          <DataGrid
            idProperty="rowNum"
            rowHeight={35}
            columns={columns}
            dataSource={filterData}
            loading={loading}
            pagination
            otherButtons={["Manage Attributes"]}
          />
        </Spin>
      </SiteContent>
      {exportModalVisible && (
        <ExprotModal
          fileName="Control-tower-fields"
          data={sourceData}
          selectedRows={filterData}
          columns={columns}
          filterArr={["Action"]}
          onClose={() => setExportModalVisible(false)}
        />
      )}
      {addEditDialogVisible && (
        <AddEditModal
          mode={current?.rowNum ? "Edit" : "New"}
          field={current}
          onClose={() => setAddEditDialogVisible(false)}
          onRefresh={() => {
            setAddEditDialogVisible(false);
            getSourceData();
          }}
          visible
        />
      )}
      {selectAttributeModalVisible && (
        <SelectAttributeModal
          visible
          onHide={() => {
            setSelectAttributeModalVisible(false);
          }}
          onRefresh={(data) => {
            setCurrent({
              originalName: data.attributeName,
              source: data.groupName,
              attributeId: data.attributeNum,
              dataType: data.attributeDataType,
            });
            setSelectAttributeModalVisible(false);
            setAddEditDialogVisible(true);
          }}
        />
      )}
    </ContentLayout>
  );
};

export default PageContent;
