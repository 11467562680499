import React from 'react';
import { Switch } from 'antd';

type Props = {
  setOverwrite: Function;
  overwrite: boolean;
};

const OverwriteSwitch = (props: Props) => {
  const { setOverwrite, overwrite } = props;

  const onChange = (chk: boolean) => {
    //dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: chk });
    setOverwrite(chk);
  };

  return (<>
    <span style={{marginRight: 4}}>Overwrite children's value</span>
    <Switch
      checkedChildren="On"
      //defaultChecked={state.overwriteChildren}
      onChange={onChange}
      unCheckedChildren="Off"
      checked={overwrite}
    />
  </>);
};

export default OverwriteSwitch;
