import React from 'react';
import { Col, Image, Input, Modal, Row } from 'antd';
import { patchProductImage } from  '../../services/products'
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';

type Props = {
  attributeName: string;
  imageUrl: string;
  productId: string;
  imageAttributeNum: number;
  onClose: () => void;
  onSuccess: (url:string) => void;
};

const PreviewImageDialog = (props: Props) => {
  const { attributeName, imageUrl, onClose, onSuccess,productId,imageAttributeNum } = props;
  const [editImageUrl, setEditImageUrl] = React.useState(imageUrl);
  const [editAble, setEditAble] = React.useState<boolean>(imageUrl ? false : true);
  const [loading, setLoading] = React.useState<boolean>(false)

  const onSaveImage = async() => {
    if (!editAble) {
      setEditAble(true);
      return;
    }
    try {
      setLoading(true)
      const json = JSON.stringify([{imageAttributeNum,imageURL:editImageUrl}])
      await patchProductImage(productId,json)
      setLoading(false)
      onSuccess(editImageUrl);
    } catch (error) {
      setLoading(false)

    }
  };

  return (
    <Modal
      centered
      className="fullscreen-modal"
      confirmLoading={loading}
      okText={editAble ? 'Save' : 'Edit'}
      onCancel={onClose}
      onOk={onSaveImage}
      style={{ paddingBottom: 0 }}
      title={imageUrl ? `View ${attributeName}` : `Edit ${attributeName}`}
      visible
      width={600}
    >
      <Row>
        <Col span={12} >
          {editImageUrl && (
            <Row align="middle" justify="center" style={{ border: '1px solid #D9D9D9', height: '100%', overflowY: 'auto' }}>
              <Image fallback={ImagePlaceholder} src={editImageUrl}/>
            </Row>
          )}
        </Col>
        <Col span={12} style={{ padding: '0 6px' }}>
          <Input.TextArea onChange={(e) => setEditImageUrl(e.target.value)} placeholder="Please Input Image Url At Here" rows={8} value={editImageUrl} />
        </Col>
      </Row>
    </Modal>
  );
};

export default PreviewImageDialog;
