import Icon from '@ant-design/icons';
import React from 'react';
import { BaseIcon } from './base';

const TextSMSIcon: React.FC<BaseIcon> = ({
  style = {},
  height = 16,
  width = 17,
  viewBox = '0 0 16 17',
  pathColor = 'black',
}) => (
  <Icon
    component={() => (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} viewBox={viewBox} width={width}>
        <g clipPath="url(#clip0_3_218)">
          <path d="M5.99998 9.28866C4.43998 9.28866 1.33331 10.0687 1.33331 11.622V12.7887H10.6666V11.622C10.6666 10.0687 7.55998 9.28866 5.99998 9.28866ZM2.89331 11.4553C3.45331 11.0687 4.80665 10.622 5.99998 10.622C7.19331 10.622 8.54665 11.0687 9.10665 11.4553H2.89331ZM5.99998 8.12199C7.28665 8.12199 8.33331 7.07532 8.33331 5.78866C8.33331 4.50199 7.28665 3.45532 5.99998 3.45532C4.71331 3.45532 3.66665 4.50199 3.66665 5.78866C3.66665 7.07532 4.71331 8.12199 5.99998 8.12199ZM5.99998 4.78866C6.55331 4.78866 6.99998 5.23532 6.99998 5.78866C6.99998 6.34199 6.55331 6.78866 5.99998 6.78866C5.44665 6.78866 4.99998 6.34199 4.99998 5.78866C4.99998 5.23532 5.44665 4.78866 5.99998 4.78866ZM10.6933 9.32866C11.4666 9.88866 12 10.6353 12 11.622V12.7887H14.6666V11.622C14.6666 10.2753 12.3333 9.50866 10.6933 9.32866ZM9.99998 8.12199C11.2866 8.12199 12.3333 7.07532 12.3333 5.78866C12.3333 4.50199 11.2866 3.45532 9.99998 3.45532C9.63998 3.45532 9.30665 3.54199 8.99998 3.68866C9.41998 4.28199 9.66665 5.00866 9.66665 5.78866C9.66665 6.56866 9.41998 7.29532 8.99998 7.88866C9.30665 8.03532 9.63998 8.12199 9.99998 8.12199Z" fill="#D9D9D9" />
        </g>
        <defs>
          <clipPath id="clip0_3_218">
            <rect width="16" height="16" fill="white" transform="translate(0 0.121948)" />
          </clipPath>
        </defs>
      </svg>
    )}
  />
);

export default TextSMSIcon;
