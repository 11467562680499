import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  SearchOutlined,
  CloudDownloadOutlined,
  LinkOutlined,
  DownOutlined,
} from "@ant-design/icons";
// import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import {
  Button,
  Col,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
  Card,
  Dropdown,
  Menu,
  notification,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ContentLayout from "../../components/ContentLayout";
import Heading from "../../components/common/Heading";
import Loading from "../../components/common/Loading";
import ScreenMask from "../../components/common/ScreenMask";
import SiteContent from "../../components/SiteContent";
import Spacer from "../../components/common/Spacer";
import { DataGrid } from "../../components/common/datagrid/DataGrid2";
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from "../../components/common/datagrid/Filter";
import {
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_LIST,
} from "../../constants/config";
import Products, {
  fetchSimpleProductGroup,
  fetchSimpleProductList,
  fetchSimpleProductPartialData,
  // fetchProductCopywrting,
  fetchProductCopywrting3,
} from "../../services/products";
import {
  fetchContentSourceType,
  saveCopywritingSingleElement,
  fetchCopywritingelements,
} from "../../services/copywriting";
//import ClassificationSelector from '../ListProducts/ClassificationSelector';
import GroupDetail from "../ListProducts/GroupDetail";
import LabelsSelector from "../ListProducts/LabelsSelector";
// import SortSelector from '../ListProducts/SortSelector';
import ChannelControlFlagSelector from "../ListProducts/ChannelControlFlagSelector";
import ExportModal from "./ExportModal";
import BlukModal from "./BlukModal";
import BlukPullModal from "./BlukPullModal";
import BulkPushSteps from "./BulkPushSteps";
import ModalDialog from "../../components/common/ModalDialog";
import ProductsDrawer from "./ViewSelectedDrawer";
import ImportModal from "./ImportModal";
import ProductDetailDialog from "../ListProducts/ProductDetailDialog";
import {
  getOperationStatusDesc,
  getOperationStatusOptions,
} from "../DetailProduct/BasicAttrEditor";
import { getProfileSettingValue, checkStringType } from "../../util";
import SetValueCell from "../Channel/setValueCell";
import EditImageDialog from "../DetailProduct/EditImageDialog";
import SimpleSelectorModal from "../DetailProduct/BasicAttrEditor/SimpleSelectorModal";
import TextEditor from "../DetailProduct/BasicAttrEditor/TextEditor";
import SearchFilterPanel from "./SearchFilterPanel";
import { StyleInputWrapper } from "../../components/common/styledComponents";
import SearchTips from "../../components/common/SearchTips";

const CancelToken = axios.CancelToken;
let cancel: any = undefined;

type SKULinkProps = {
  product: Entities.ProductProfile;
  target?: string;
};

const SKULink = (props: SKULinkProps) => {
  const { product, target = "_self" } = props;
  const pids = product.ProductId.split("/");
  const productId = pids[pids.length - 1] || "-";

  return (
    <Link target={target} to={`/product-detail/${productId}`}>
      <LinkOutlined />
      &nbsp;
      {product.SKU}
    </Link>
  );
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const SectionTitle = styled.h2`
//   font-weight: bold;
//   margin-bottom: 0;
// `;

const SearchCol = styled(Col)`
  width: 100%;

  & .ant-input:read-only {
    background-color: #dcdcdc;
  }

  & .ant-input-affix-wrapper-readonly {
    background-color: #dcdcdc;
  }

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #d9d9d9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ": ";
  }

  & .loading-wrapper {
    display: "inline-block";
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    width: 768px;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 260px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      width: 820px;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      width: 868px;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      width: 928px;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      width: 1028px;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      width: 1150px;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

// const ProductDetailDialogBodyWrapper = styled.div`
//   /*max-height: calc(100vh - 180px);*/
//   height: calc(100vh - 260px);
//   overflow-y: auto;

//   &.fullscreen-mode {
//     height: calc(100vh - 168px);
//   }
// `;

// const ExportBar = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: end;
//   align-items: center;
//   height: 100%;
// `;

export const isCodeListType = (typ: number) => {
  const cs = [
    CODE_OPTION_SKU_LIST,
    CODE_OPTION_COLOR_LIST,
    CODE_OPTION_STYLE_LIST,
    CODE_OPTION_UPC_LIST,
  ];

  return cs.indexOf(typ) > -1;
};

export const columns = [
  {
    name: "mediaURL",
    header: "Image",
    defaultFlex: 1,
    defaultLocked: true,
    minWidth: 100,
    maxWidth: 100,
    render({ value, data }: { value: string; data: Entities.ProductProfile }) {
      const src = value || "https://via.placeholder.com/300";
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer
          key={data.ProductId}
          className={isTreeNode ? "image-tree-ctn" : ""}
        >
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: "VariationParentSKU",
    header: "Style Code",
    defaultFlex: 1,
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === "object") {
        return data.styleCode || data.VariationParentSKU;
      }

      return "";
    },
  },
  {
    //name: 'ProductId',
    name: "subStyleCode",
    header: "Sub-Style Code",
    defaultFlex: 1,
    minWidth: 180,
    render({ data }: { data: any }) {
      if (data && typeof data === "object") {
        return data.subStyleCode || "";
      }

      return "";
    },
  },
  {
    name: "SKU",
    header: "SKU",
    defaultFlex: 1,
    minWidth: 150,
    // sort: (a: any, b: any) => false,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  {
    name: "ProductStatus",
    header: "Status",
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;

      return getOperationStatusDesc(data.ProductStatus, true);
    },
  },
];

const PageContent: React.FC = () => {
  const { Option } = Select;
  const CODE_OPTION_STYLE_EQUALS = 1;
  const CODE_OPTION_STYLE_CONTAINS = 2;
  const CODE_OPTION_SKU_EQUALS = 3;
  const CODE_OPTION_SKU_CONTAINS = 4;
  const CODE_OPTION_COLOR_EQUALS = 5;
  const CODE_OPTION_COLOR_CONTAINS = 6;
  const CODE_OPTION_UPC_EQUALS = 7;
  const CODE_OPTION_SKU_LIST = 8;
  const CODE_OPTION_STYLE_LIST = 9;
  const CODE_OPTION_COLOR_LIST = 10;
  const CODE_OPTION_UPC_LIST = 11;
  const DEFAULT_GRID_LIMIT = 20;
  const GROUP_OPTION_COLOR = 1;
  const GROUP_OPTION_STYLE = 2;
  const GROUP_OPTION_NO_GROUP = 3;
  const GROUP_OPTION_PRODUCT = 4;
  const OPERATION_CONTAIN = 2;
  const OPERATION_EQUALS = 1;
  const OPERATION_LIST = 3;

  // const [extLoading, setExtLoading] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [stateColumns, setStateColumns] = useState<any>(columns);
  const [detailVisible, setDetailVisible] = useState(false);
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [editSelectorDialogVisible, setEditSelectorDialogVisible] = useState(
    false
  );
  const [eleAttrs, setEleAttrs] = useState<StringKAnyVPair[]>([]);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [gridRef, setGridRef] = useState<any>(null);
  const [initDataReady, setInitDataReady] = useState(false);
  const [inited, setInited] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  //const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchCFs, setSearchCFs] = useState<any[]>([]);
  const [searchCode, setSearchCode] = useState("");
  const [searchCodeType, setSearchCodeType] = useState(
    CODE_OPTION_STYLE_CONTAINS
  );
  // eslint-disable-next-line
  const [searchMore, setSearchMore] = useState(false);
  const [searchMultiCode, setSearchMultiCode] = useState("");
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [searchGroup, setSearchGroup] = useState(GROUP_OPTION_PRODUCT);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchBrand, setSearchBrand] = useState<string>("");
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  const [searchSorter, setSearchSorter] = useState<string>("SKU ASC");
  const [searchStatus, setSearchStatus] = useState<number[]>([0]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchGroups, setSearchGroups] = useState<number[]>([]);
  const [searchGroupsStr, setSearchGroupsStr] = useState<string[]>([]);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  // const [searchTagsStr, setSearchTagsStr] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  // const [isMapped, setIsMapped] = useState<boolean>(false);
  // const [templateLoading, setTemplateLoading] = useState<boolean>(true);
  const [selected, setSelected] = React.useState<any>({});
  // const [templateInfo, setTemplateInfo] = React.useState<any>();
  const [blukModalVisible, setBlukModalVisible] = useState<boolean>(false);
  const [blukData, setblukData] = useState<any>();
  const [blukType, setBlukType] = useState<string>("Pull"); // Pull or Push
  const [pullDialogVisible, setPullDialogVisible] = useState(false);
  const [pushDialogVisible, setPushDialogVisible] = useState(false);
  const [productsDrawerVisible, setProductsDrawerVisible] = useState(false);
  const [importDialogVisible, setImportDialogVisible] = useState(false);
  const codeInputRef = React.useRef<any>(null);
  const codeListInputRef = React.useRef<any>(null);
  const [groupList, setGroupList] = useState<any[]>([]);
  const searchMoreStyle = useMemo(() => {
    return {
      display: searchMore ? "" : "none",
    };
  }, [searchMore]);
  const [tempParams, setTempParams] = useState<any>();
  const [productListStyle, setProductListStyle] = useState<string>();
  const [currentValue, setCurrentValue] = React.useState<any>();
  const [valueModalVisible, setValueModalVisible] = React.useState(false);

  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [labelVersion, setLabelVersion] = useState(0);
  // const [groupVersion, setGroupVersion] = useState(0);
  // const [tagVersion, setTagVersion] = useState(0);
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const filterHiddenTimer = React.useRef<any>(0);
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>(
    {}
  );
  const filterState = React.useRef<any>(null);
  const [ccfList, setCcfList] = useState<StringKAnyVPair[]>([]);
  const [labelList, setLabelList] = useState<StringKAnyVPair[]>([]);
  const [searchTagVersion, setSearchTagVersion] = useState(0);
  const [searchGroupsVersion, setSearchGroupsVersion] = useState(0);
  const [dataInited, setDataInited] = useState(false);

  const fetchLabels = async () => {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (
        res &&
        typeof res === "object" &&
        Array.isArray(res.LabelList) &&
        res.LabelList.length > 0
      ) {
        setLabelList(res.LabelList);
      }
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  // const clearGroup = () => {
  //   setSearchGroups([]);
  //   setGroupVersion(groupVersion + 1);
  // };

  // const clearTag = () => {
  //   setSearchTags([]);
  //   setTagVersion(tagVersion + 1);
  // };

  const clearSearchBrand = () => {
    setSearchBrand("");
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle("");
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      //console.log('enter', filterPanelVisible);
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };
  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: new Date().getTime(),
    };
    setFilterPanelVisible(true);
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const onSearchbarFocus = () => {
    const ctn = getCodeInputWrapperDom();

    if (ctn) {
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      setFilterPanelOutline({
        x: rect.x,
        y: rect.y + rect.height,
        width: rect.width,
      });

      if (!filterPanelVisible) {
        showSearchFilterPanel();
      }
    }
  };

  const fetchChannelControlFlags = async () => {
    try {
      const res = await Products.getChannelControlFlags();

      //console.log('s', res);
      if (Array.isArray(res)) setCcfList(res);
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const showSearchConditionTags = (showFilter: boolean) => {
    const ccfLabels: string[] = ccfList
      .filter((c) => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map((c) => c.CHNLCtrlFlag);
    const labels: string[] = labelList
      .filter((l) => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map((l) => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter((e) => searchStatus.indexOf(e.code) > -1)
      .map((e) => e.description);

    return showFilter ? (
      <Space>
        {searchTitle.trim() && (
          <Tag closable onClose={clearSearchTitle}>
            <span className="field-label">Title</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag closable onClose={() => setSearchStatus([])}>
            <span className="field-label">Stauts</span>
            {statusLabels.join(", ")}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(", ")}
          </Tag>
        )}
        {searchBrand.trim() && (
          <Tag closable onClose={clearSearchBrand}>
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Labels</span>
            {labels.join(", ")}
          </Tag>
        )}
        {searchGroupsStr.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchGroups([]);
              setSearchGroupsStr([]);
              setSearchGroupsVersion(searchGroupsVersion + 1);
            }}
          >
            <span className="field-label">Group</span>
            {searchGroupsStr.join(", ")}
          </Tag>
        )}
        {searchTags.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchTags([]);
              setSearchTagVersion(searchTagVersion + 1);
              // setSearchTagsStr('')
            }}
          >
            <span className="field-label">Tag</span>
            {searchTags.join(", ")}
          </Tag>
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
      </Space>
    ) : (
      <Space></Space>
    );
  };

  // const GroupSelector = () => {
  //   return (
  //     <>
  //     <FormLabel>Group</FormLabel>
  //     <Select
  //         className="status-selector"
  //         mode="multiple"
  //         dropdownMatchSelectWidth={false}
  //         style={{ width: '100%' }}
  //         onChange={(value, option) =>onSelectGroups(value, option)}
  //         options={groupList.map((group) => ({
  //           label: group.enumName,
  //           value: group.enumValue,
  //         }))}
  //         value={searchGroups}
  //       />
  //   </>
  //   );
  // };

  // const TagInput = () => {
  //   return (
  //     <>
  //     <FormLabel>Tag</FormLabel>
  //     <Input.Search
  //         key="searchTags"
  //         placeholder="Please input and separate with commas"
  //         style={{ width: '100%' }}
  //         defaultValue={searchTagsStr}
  //         enterButton="Ok"
  //         // onPressEnter={(e)=> setSearchTagsStr(e.target.value ||'')}
  //         onBlur={(e)=>  setSearchTagsStr(e.target.value ||'')}
  //         onSearch={(value)=> setSearchTagsStr(value ||'')}
  //       />
  //   </>
  //   );
  // };

  const getAttrOptions = (attr: any) => {
    let ret: StringKAnyVPair[] = [];

    if (attr && typeof attr === "object") {
      if (typeof attr.optionList === "string" && attr.optionList.trim()) {
        attr.optionList
          .split("|")
          .map((e: string) => e.trim())
          //.filter((e: string) => e)
          .map((e: string) => ({ value: e }))
          .forEach((e: StringKAnyVPair) => ret.push(e));
      }
    }

    return ret;
  };

  const getProductListStyle = useCallback(async () => {
    const value = await getProfileSettingValue("ProductListStyle", "0");
    setProductListStyle(value);
  }, []);

  const getGroupList = async () => {
    try {
      const {
        data = [],
        isSuccess,
        message: resMsg = "",
      } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        message.error(resMsg || "No group list found");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!initDataReady) {
      loadCopywritingElements();
      setInitDataReady(true);
    }

    getProductListStyle();
  }, [getProductListStyle, initDataReady]);

  useEffect(() => {
    getGroupList();
  }, []);

  const closeImageEditor = () => {
    setEditImageDialogVisible(false);
  };

  const closePullDialog = () => {
    setPullDialogVisible(false);
  };

  const closePushDialog = () => {
    setPushDialogVisible(false);
  };

  const closeSimpleSelectorEditor = () => {
    setEditSelectorDialogVisible(false);
  };

  const closeImportDialog = () => {
    setImportDialogVisible(false);
  };

  const pushDialogWidth = () => {
    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };

  const searchCodeTypeSelector = () => (
    <Select
      defaultValue={searchCodeType}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230 }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Code Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Code Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style Code List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Sub-Style Code Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>
        Sub-Style Code Contains
      </Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Sub-Style List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );
  const filterValue = [
    { name: "SKU", operator: "contains", type: GRID_FILTER13, value: "" },
    {
      name: "ProductTitle",
      operator: "contains",
      type: GRID_FILTER11,
      value: "",
    },
    { name: "UPC", operator: "eq", type: GRID_FILTER12, value: "" },
    { name: "QtyTotal", operator: "gte", type: GRID_FILTER21, value: null },
  ];
  const gridFilterTypes = Object.assign(
    {
      styleCodeFilter: {
        type: "string",
        emptyValue: "",
        operators: [
          { name: "SKU Contains", fn: () => true },
          { name: "SKU Equals", fn: () => true },
        ],
      },
    },
    filterTypes
  );

  const dataSource = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredData,
      count: totalCount,
    };
  };
  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
  };

  // eslint-disable-next-line
  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    const { skip, top } = options;
    setSelected({});

    setIsFetching(true);

    try {
      // console.log("options ->", getSearchOptions());
      const params = getSearchOptions();
      const { ProductList, ProductTotalCount } = await fetchSimpleProductList(
        typeof skip === "number" && skip >= 0 ? skip : pageSkip,
        typeof top === "number" && top >= 0 ? top : pageTop,
        params
      );
      if (!dataInited) {
        setDataInited(true);
      }
      if (Array.isArray(ProductList)) {
        if (isSearchGroupMode(searchCodeType)) {
          ProductList.forEach((e) => {
            if (["Product", "Bundle"].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }

        setFilteredData(ProductList);
        setTotalCount(ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        setSearchTreeMode(isSearchGroupMode(searchCodeType));

        if (ProductList.length > 0) {
          setTimeout(async () => {
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
          }, 0);
          await fetchProductExtData(ProductList);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
      setTempParams(params);
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      message.error(`Fetch products error: ${e}`);
      console.log("Fetch products error:", e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProductExtData = async (products: StringKAnyVPair[]) => {
    try {
      if (cancel !== undefined) {
        cancel();
      }
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });

      const params = {
        products: ids,
        groups: searchGroups,
        tags: searchTags,
      };

      // setExtLoading(true);
      const data = await fetchProductCopywrting3(
        // ids,
        params,
        new CancelToken(function executor(c) {
          cancel = c;
        })
      );
      if (data.length > 0) {
        setStateColumns([
          ...columns,
          ...data[0].mappedPreviewers.map((i: any, index: number) => {
            return {
              name: `ext${index}`,
              header: i.previewDisplayName,
              defaultFlex: 1,
              minWidth: 150,
              render: (value: any) => {
                const { data, rowIndex } = value;
                return (
                  <SetValueCell
                    value={data[`ext${index}`]}
                    valueType={checkStringType(data[`ext${index}`])}
                    editable={true}
                    onButtonClick={() => {
                      const attr = getDataAttrById(i.attributeNum);
                      const opts = getAttrOptions(attr);

                      setCurrentValue({
                        data: data[`ext${index}`],
                        options: opts,
                        previewValue: data[`ext${index}`],
                        previewDisplayName: i.previewDisplayName,
                        previewID: i.previewID,
                        productId: data.ProductId,
                        name: `ext${index}`,
                        attributeNum: i.attributeNum,
                        rowIndex: rowIndex,
                        editable: true,
                        sku: data.SKU,
                      });

                      if (attr) {
                        if (opts.length > 0) {
                          setEditSelectorDialogVisible(true);
                        } else {
                          switch (attr.elementDataType) {
                            case ATTRIBUTE_DATA_TYPE_IMAGEURL:
                              setEditImageDialogVisible(true);
                              break;

                            default:
                              setValueModalVisible(true);
                          }
                        }
                      } else {
                        setValueModalVisible(true);
                      }
                    }}
                  />
                );
              },
            };
          }),
        ]);
        data.map((item: any) => {
          if (pDict[item.productId]) {
            item.mappedPreviewers.map((i: any, index: number) => {
              pDict[item.productId][`ext${index}`] = i.previewValue;
              return true;
            });
          }
          return true;
        });
        // setFilteredData(Object.values(pDict));
      }
      // setExtLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("request canceled..");
      }
    }
  };

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    // setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData(ids);

      if (data && Array.isArray(data)) {
        // console.log('d->', data, products);
        data.forEach((e) => {
          if (pDict[e.productId]) {
            const obj = { ...e };

            delete obj.productId;
            // pDict[e.productId] = obj;
            // console.log('e->', obj);
            for (let k in obj) {
              pDict[e.productId][k] = obj[k];
            }
          }
        });
        //console.log('p-->', products);
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      message.error(`Fetch partial error: ${e}`);
      console.error("Fetch partial error:", e);
    } finally {
      // setIsFetching(false);
    }
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode
          .split("\n")
          .map((e) => e.trim())
          .filter((e) => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(),
          collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    ret.push({
      filterName: "ProductStatus",
      collectionFilterValues: searchStatus,
      op: 2,
    });

    return ret;
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        return "ColorPatternCode";

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return "SKU";

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return "StyleCode";

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return "UPC";
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };
  
  const getSearchGrouper = (/*filters: any[]*/) => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = "";

    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = "ColorPatternCode";
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = "Product";
        break;

      case GROUP_OPTION_STYLE:
        groupName = "StyleCode";
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
  };

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [];

    //if (searchCode) {
    if (
      searchCodeType !== CODE_OPTION_SKU_LIST &&
      searchCodeType !== CODE_OPTION_COLOR_LIST &&
      searchCodeType !== CODE_OPTION_STYLE_LIST &&
      searchCodeType !== CODE_OPTION_UPC_LIST
    ) {
      filters.push({
        filterName: getFilterNameBySearchCodeType(),
        filterValue: searchCode,
        op: getOperateCodeBySearchCodeType(),
      });
    }
    //}

    if (searchTitle) {
      filters.push({
        filterName: "ProductTitle",
        filterValue: searchTitle,
        op: OPERATION_CONTAIN,
      });
    }

    if (searchCCFs.length > 0) {
      filters.push({
        filterName: "ChannelControlFlag",
        filterValue: searchCCFs.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (searchCFs.length > 0) {
      filters.push({
        filterName: "Classification",
        filterValue: searchCFs.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (searchLabels.length > 0) {
      filters.push({
        filterName: "Labels",
        filterValue: searchLabels.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (searchBrand) {
      filters.push({
        filterName: "Brand",
        filterValue: searchBrand,
        op: OPERATION_CONTAIN,
      });
    }

    if ((searchTags || []).length > 0) {
      filters.push({
        filterName: "Tag",
        filterValue: searchTags.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if ((searchGroups || []).length > 0) {
      filters.push({
        filterName: "ContentSourceGroup",
        filterValue: searchGroups.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
    };
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(" ");

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  const saveBasic = async (newValue: string, data: any, overwrite = false) => {
    if (!data || !data.productId || !data.previewID || !data.attributeNum) {
      message.warning(`Data error`);
      return;
    }
    if (newValue === data.previewValue) {
      setCurrentValue(undefined);
      setEditImageDialogVisible(false);
      setValueModalVisible(false);
      return;
    }
    try {
      const productBasic: StringKAnyVPair = {};
      productBasic[data.previewID] = newValue;
      setIsLoading(true);
      await saveCopywritingSingleElement(
        data.productId,
        data.sku,
        data.attributeNum,
        {
          CopywritingElementNum: data.attributeNum,
          CopywritingValue: newValue,
          overwrite,
        }
      );
      // this code must be put before setCurrentValue
      setEditImageDialogVisible(false);
      setCurrentValue(undefined);
      setValueModalVisible(false);
      message.success(`Saved ${data.previewDisplayName} successfully`);
      const temp = [...filteredData];
      temp[data.rowIndex][data.name] = newValue; // eslint-disable-line no-use-before-define
      setFilteredData(temp);
    } catch (error) {
      notification.error({
        message: `Save data error: ${error}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchProducts = () => {
    setInited(true);
    fetchProductList();
    fetchChannelControlFlags();
    fetchLabels();
  };

  const isColorCode = (code: number) => {
    return (
      [
        CODE_OPTION_COLOR_CONTAINS,
        CODE_OPTION_COLOR_EQUALS,
        CODE_OPTION_COLOR_LIST,
      ].indexOf(code) > -1
    );
  };

  const isCommonGroupOption = (code: number) => {
    return isColorCode(code) || isStyleCode(code);
  };

  const isSearchGroupMode = (code: number) => {
    return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
  };

  // const isSKUCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_SKU_CONTAINS,
  //       CODE_OPTION_SKU_EQUALS,
  //       CODE_OPTION_SKU_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  const isStyleCode = (code: number) => {
    return (
      [
        CODE_OPTION_STYLE_CONTAINS,
        CODE_OPTION_STYLE_EQUALS,
        CODE_OPTION_STYLE_LIST,
      ].indexOf(code) > -1
    );
  };

  const openProductDetailDialog = (
    productId: string,
    product: StringKAnyVPair
  ) => {
    setDetailVisible(true);
    // console.log("pid->", productId);
    setCurrentProduct(product);
  };

  const gridColumns = (isGroupMode = false) => {
    const colDef = [...stateColumns];
    const tempColumns: any[] = [];

    for (let i = 0; i < colDef.length; i++) {
      if (productListStyle === "2") {
        if (["Style Code", "Sub-Style Code"].includes(colDef[i].header)) {
          continue;
        }
      }
      if (colDef[i].header === "Image") {
        colDef[i].render = (p: any) => {
          const { value, data } = p;
          const src = value || "https://via.placeholder.com/300";
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);

          return (
            <ImageContainer
              key={data.ProductId}
              className={isTreeNode ? "image-tree-ctn" : ""}
            >
              <Image width={28} height={28} src={src} />
            </ImageContainer>
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      } else if (colDef[i].header === "SKU") {
        colDef[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split("/");
          const productId = pids[pids.length - 1] || "-";

          return (
            <Button
              type="link"
              onClick={() => openProductDetailDialog(`${productId}`, data)}
            >
              {data.SKU}
            </Button>
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      }
      tempColumns.push(colDef[i]);
    }
    return tempColumns;
  };

  const fetchChildrenPartialData = async (
    node: StringKAnyVPair,
    ProductList: Entities.ProductProfile[]
  ) => {
    await fetchProductParticalData(ProductList);
    await fetchProductExtData(ProductList);
    node.nodes = ProductList;
    //console.log('fd', filteredData);
    updateGridRow(node as Entities.ProductProfile);
  };

  const getDataAttrById = (id: number) => {
    const fs = eleAttrs.filter((e) => e.copywritingElementNum === id);
    if (fs.length > 0) {
      return fs[0];
    }
  };

  const loadCopywritingElements = async () => {
    try {
      const { data } = await fetchCopywritingelements();

      if (data && Array.isArray(data)) {
        setEleAttrs(data);
      }
    } catch (e) {
      notification.error({
        message: `Load attribute data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const loadNextLevelProducts = async (data: any) => {
    //console.log('--->', data);
    const { node } = data;
    let ret: any = null;

    //setIsFetching(true);
    try {
      const { ProductList } = await fetchSimpleProductGroup(node.ProductId);

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        // if (ProductList.length > 0) {
        //   await fetchProductParticalData(ProductList);
        //   await fetchProductExtData(ProductList);

        // }
        if (ProductList.length > 0) {
          await fetchChildrenPartialData(node, ProductList);
        }

        ret = ProductList;
      }
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const onFilterValueChange = (val: any) => {
    // console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    // console.log('limit -->', limit);
    fetchProductList({ top: limit });
    setPageTop(limit);
    gridRef?.current.deselectAll();
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    //console.log('v-->', values);
    setSearchCCFs(values);
  };

  // eslint-disable-next-line
  const onSelectClassifications = (values: any[]) => {
    //console.log('v-->', values);
    setSearchCFs(values);
  };

  const onSelectLabels = (values: any[]) => {
    //console.log('v-->', values);
    setSearchLabels(values);
  };

  // const onSelectSortType = (value: any) => {
  //   // console.log('-->', value);
  //   setSearchSorter(value);
  // };

  const onSearchCodeChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  const onSelectGroups = (groups: number[], option: any) => {
    setSearchGroups(groups);
    setSearchGroupsStr(option ? option.map((i: any) => i.label) : []);
  };

  const onSelectTags = (value: string) => {
    let str: string = value
      .trim()
      .replace(/[\r\n]/g, ",")
      .replace(/,+/g, ",")
      .replace("/,$/gi", "");
    const tags = str.split(",");
    setSearchTags(tags.filter((item) => item));
  };

  const onSearchTitleChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchTitle(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchTitle(value), 0);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      /*case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
        //case CODE_OPTION_STYLE_EQUALS:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;*/
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (
      value === CODE_OPTION_SKU_LIST ||
      value === CODE_OPTION_COLOR_LIST ||
      value === CODE_OPTION_STYLE_LIST ||
      value === CODE_OPTION_UPC_LIST
    ) {
      setTimeout(() => {
        setSearchMultiCode("");
        codeListInputRef?.current?.focus();
        //codeListInputRef?.current?.select();
      }, 0);
    } else {
      setTimeout(() => {
        codeInputRef?.current?.select();
        codeInputRef?.current?.focus();
      }, 0);
    }
  };

  const onSelectionChange = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected(selected);
    if (selected === true && !unselected) {
      setSelectedRows(data as any);
    } else {
      if (!unselected) {
        setSelectedRows(Object.values(selected as any));
      } else {
        setSelectedRows((prev) => {
          return prev.filter((item) => item.ProductId !== (data as any).id);
        });
      }
    }
  }, []);

  const onSkipChange = (skip: number) => {
    // console.log('skip -->', skip);
    fetchProductList({ skip });
    setPageSkip(skip);
    gridRef?.current.deselectAll();
  };

  const onSortChange = async (info: any) => {
    // console.log('-->', info);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    //console.log('p ->', data, param);
    return <GroupDetail row={data} />;
  };

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }

    if (found) {
      setFilteredData([...filteredData]);
    }
  };

  return (
    <>
      <ContentLayout>
        <Heading
          title="Content Resources"
          actions={
            <Space>
              <Dropdown
                disabled={searchGroup === 3}
                overlay={
                  <Menu
                    onClick={(e) => {
                      setBlukType(e.key);
                      setBlukModalVisible(true);
                    }}
                  >
                    <Menu.Item key="Push">Push</Menu.Item>
                    <Menu.Item key="Pull">Pull</Menu.Item>
                  </Menu>
                }
              >
                <Button>
                  <Space>
                    Bulk
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <Button onClick={() => setImportDialogVisible(true)}>
                Import
              </Button>

              <Link to="/pimSettings-products-content-resources">
                <Button>Manage</Button>
              </Link>
            </Space>
          }
        />
        <Spacer />
        <SiteContent flexGrow noPadding transparent>
          <Row className="content-section" justify="space-between">
            <SearchCol>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row className="search-element-area">
                  <Row>
                    {productListStyle !== "2" && (
                      <Row align="middle" className="display-category-field">
                        <span
                          className="field-label"
                          style={{ paddingRight: 8 }}
                        >
                          Display
                        </span>
                        <Radio.Group
                          onChange={onGroupModeChange}
                          //style={{ width: 430 }}
                          value={searchGroup}
                        >
                          <Radio
                            // disabled={!isStyleCode(searchCodeType)}
                            value={GROUP_OPTION_STYLE}
                          >
                            Style Master
                          </Radio>
                          {productListStyle !== "1" && (
                            <Radio
                              // disabled={!isCommonGroupOption(searchCodeType)}
                              value={GROUP_OPTION_COLOR}
                            >
                              Sub-Style
                            </Radio>
                          )}
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_PRODUCT}
                          >
                            Product
                          </Radio>
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_NO_GROUP}
                          >
                            All
                          </Radio>
                        </Radio.Group>
                      </Row>
                    )}
                    {isCodeListType(searchCodeType) && (
                      <StyleInputWrapper>
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={
                            filterPanelVisible ? "code-selector-active" : ""
                          }
                          //disabled
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          readOnly
                          ref={codeInputRef}
                          value={searchMultiCode.replace(/\n/g, ", ")}
                        />
                      </StyleInputWrapper>
                    )}
                    {!isCodeListType(searchCodeType) && (
                      <StyleInputWrapper>
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={
                            filterPanelVisible ? "code-selector-active" : ""
                          }
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          ref={codeInputRef}
                        />
                      </StyleInputWrapper>
                    )}
                  </Row>
                  {filteredData.length > 0 && (
                    <div className="action-btn-wrap">
                      <Button
                        disabled={isFetching}
                        onClick={() => setExportModalVisible(true)}
                      >
                        <CloudDownloadOutlined />
                        Export
                      </Button>
                    </div>
                  )}
                </Row>
                <Space style={searchMoreStyle}>
                  <div className="title-wrapper">
                    <span className="field-label">Title:</span>
                    <Input
                      allowClear
                      onChange={onSearchTitleChange}
                      style={{ width: 200 }}
                    />
                  </div>
                  <div className="channel-ctrl-flag-wrapper">
                    <span className="field-label">Sales Channel:</span>
                    <ChannelControlFlagSelector
                      onChange={onSelectChannelControlFlags}
                      style={{ width: 290 }}
                    />
                  </div>
                </Space>
                <Space style={searchMoreStyle}>
                  <div className="labels-wrapper">
                    <span className="field-label">Labels:</span>
                    <LabelsSelector
                      onChange={onSelectLabels}
                      style={{ width: 200 }}
                    />
                  </div>
                  <div className="labels-wrapper">
                    <span className="field-label">Brand:</span>
                    <Input
                      onChange={(e) => setSearchBrand(e.target.value)}
                      style={{ width: 200 }}
                    />
                  </div>
                </Space>
                <Row
                  align="middle"
                  className="search-btn-row"
                  justify="space-between"
                  style={{ marginTop: searchMore ? 0 : -6 }}
                >
                  {searchBegun && showSearchConditionTags(dataInited)}
                </Row>
              </Space>
            </SearchCol>
            {/* <Col>
                <Row align="top" style={{ height: "100%" }}>
                  {filteredData.length > 0 && (
                    <div className="action-btn-wrap">
                      <Button
                        disabled={isFetching}
                        onClick={() => setExportModalVisible(true)}
                      >
                        <CloudDownloadOutlined />
                        Export
                      </Button>
                    </div>
                  )}
                </Row>
              </Col> */}
          </Row>
          {inited ? (
            <Spacer height={14} />
          ) : (
            SearchTips(680, 300, "Input your search criteria", 130)
          )}
          {inited && (
            <Card
              size="small"
              style={{ flexGrow: 1 }}
              bodyStyle={{ height: "100%" }}
            >
              <DataGrid
                style={{ height: "100%" }}
                idProperty="ProductId"
                rowHeight={35}
                columns={gridColumns(searchTreeMode)}
                dataSource={dataSource}
                defaultFilterValue={filterValue}
                defaultLimit={DEFAULT_GRID_LIMIT}
                enableFiltering={false}
                filterTypes={gridFilterTypes}
                limit={pageTop}
                loadNode={loadNextLevelProducts}
                loading={isFetching}
                onFilterValueChange={onFilterValueChange}
                onLimitChange={onLimitChange}
                onReady={setGridRef}
                onSelectionChange={onSelectionChange}
                onSkipChange={onSkipChange}
                onSortInfoChange={onSortChange}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                checkboxOnlyRowSelect
                renderRowDetails={renderRowDetails}
                rowExpandColumn={searchGroupMode}
                rowExpandHeight={300}
                // selected={selectedRows}
                skip={pageSkip}
                sortable={false}
                treeColumn={searchTreeMode ? "mediaURL" : undefined}
                checkboxColumn
                selected={selected}
              />
            </Card>
          )}
        </SiteContent>
        <SearchFilterPanel
          channelFlagVersion={channelFlagVersion}
          clearChannelFlags={clearChannelFlags}
          clearLabels={clearLabels}
          clearSearchBrand={clearSearchBrand}
          clearSearchTitle={clearSearchTitle}
          handleSearch={handleSearchProducts}
          hidePanel={hideSearchFilterPanel}
          labelVersion={labelVersion}
          onMouseEnter={onMouseEnterFilter}
          onMouseLeave={onMouseLeaveFilter}
          searchBrandVersion={searchBrandVersion}
          searchCodeType={searchCodeType}
          searchMultiCode={searchMultiCode}
          searchTitleVersion={searchTitleVersion}
          searchTagVersion={searchTagVersion}
          searchGroupsVersion={searchGroupsVersion}
          setBrand={setSearchBrand}
          setLabels={setSearchLabels}
          setMultiCode={setSearchMultiCode}
          setSalesChannel={setSearchCCFs}
          setSortType={setSearchSorter}
          setStatus={setSearchStatus}
          setTitle={setSearchTitle}
          showPanel={showSearchFilterPanel}
          status={searchStatus}
          statusOptions={getOperationStatusOptions().map((e) => ({
            label: e.description,
            value: e.code,
          }))}
          x={filterPanelOutline.x || 0}
          y={filterPanelOutline.y || 0}
          visible={filterPanelVisible}
          width={filterPanelOutline.width || 0}
          // ExtraFilter={[<GroupSelector />,<TagInput />]}
          onGroupChange={(value: any, option: any) =>
            onSelectGroups(value, option)
          }
          groupList={groupList}
          onTagChange={(value: string) => onSelectTags(value || "")}
          clearExtraFilter={() => {
            setSearchGroups([]);
            setSearchGroupsStr([]);
            setSearchTags([]);
            setSearchTagVersion(searchTagVersion + 1);
          }}
        />

        {detailVisible && (
          <ProductDetailDialog
            onClose={closeProductDetailDialog}
            product={currentProduct}
            visible={detailVisible}
          />
        )}

        {exportModalVisible && (
          <ExportModal
            fileName="content-resources-setting"
            getSearchOptions={getSearchOptions}
            filterArr={
              searchGroup === 2
                ? ["Image", "Sub-Style Code", "SKU", "Status"]
                : searchGroup === 1
                ? ["Image", "SKU", "Status"]
                : searchGroup === 4
                ? ["Image", "Style Code", "Sub-Style Code", "Status"]
                : ["Image", "Status"]
            }
            onClose={() => setExportModalVisible(false)}
            data={filteredData.filter((i) => i.ProductId.indexOf("/") < 0)}
            selectedRows={selectedRows.filter(
              (i) => i.ProductId.indexOf("/") < 0
            )}
            columns={gridColumns(searchTreeMode)}
          />
        )}
        {blukModalVisible && (
          <BlukModal
            blukType={blukType}
            onClose={() => setBlukModalVisible(false)}
            // queryParams={getSearchOptions().queryFilters}
            searchParams={tempParams}
            needpushData={(data: any) => {
              setblukData(data);
              setBlukModalVisible(false);
              if (blukType === "Pull") {
                setPullDialogVisible(true);
              } else {
                //todo show bulk push Modal
                setPushDialogVisible(true);
              }
            }}
            data={filteredData.filter((i) => i.ProductId.indexOf("/") < 0)}
            selectedRows={selectedRows.filter(
              (i) => i.ProductId.indexOf("/") < 0
            )}
          />
        )}
        {pullDialogVisible && (
          <ModalDialog
            bodyHeight={window.innerHeight - 200}
            closable={false}
            closeButton
            footer={null}
            fullscreen
            maskClosable={false}
            onClose={closePullDialog}
            title={`Bulk Pull Content Resources`}
            visible={pullDialogVisible}
            width={pushDialogWidth()}
          >
            <BlukPullModal
              onCancel={closePullDialog}
              // groupName={gridDataGroup}
              blukData={blukData}
              onShowProducts={() => setProductsDrawerVisible(true)}
            />
          </ModalDialog>
        )}
        {pushDialogVisible && (
          <ModalDialog
            bodyHeight={window.innerHeight - 200}
            closable={false}
            closeButton
            footer={null}
            fullscreen
            maskClosable={false}
            onClose={closePushDialog}
            title={`Bulk Push Content Resources`}
            visible={pushDialogVisible}
            width={pushDialogWidth()}
          >
            <BulkPushSteps onCancel={closePushDialog} bulkData={blukData} />
          </ModalDialog>
        )}
        {productsDrawerVisible && (
          <ProductsDrawer
            selectedRows={blukData.products}
            onClose={() => setProductsDrawerVisible(false)}
          />
        )}
        {importDialogVisible && (
          <ModalDialog
            bodyHeight={window.innerHeight - 200}
            closable={false}
            closeButton
            footer={null}
            fullscreen
            maskClosable={false}
            onClose={closeImportDialog}
            title={`Import Content Resources`}
            visible={importDialogVisible}
            width={pushDialogWidth()}
          >
            <ImportModal onCancel={closeImportDialog} />
          </ModalDialog>
        )}
        {valueModalVisible && currentValue && (
          <TextEditor
            productId={currentValue.productId}
            editable={true}
            onClose={() => setValueModalVisible(false)}
            onSave={(newValue: string, overwrite: boolean) => {
              saveBasic(newValue, currentValue, overwrite);
            }}
            readonly={false}
            textContent={currentValue.previewValue}
            title={`${currentValue.previewDisplayName}`}
            visible={true}
          />
        )}
      </ContentLayout>
      {editSelectorDialogVisible && (
        <SimpleSelectorModal
          onClose={closeSimpleSelectorEditor}
          onSave={(newValue: string, overwrite: boolean) => {
            saveBasic(newValue, currentValue, overwrite);
          }}
          options={currentValue.options || []}
          title={`${currentValue.previewDisplayName}`}
          value={currentValue.previewValue}
          visible={editSelectorDialogVisible}
        />
      )}
      {editImageDialogVisible && currentValue && (
        <EditImageDialog
          imageUrl={currentValue.previewValue}
          onClose={closeImageEditor}
          //onSave={saveAttributeByEditor}
          onSave={(newValue: string, overwrite: boolean) => {
            saveBasic(newValue, currentValue, overwrite);
          }}
          title={`${currentValue.previewDisplayName}`}
          visible={editImageDialogVisible}
        />
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default PageContent;
