import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Spin, Row, Space, Typography, Button, Transfer, Tag, message } from 'antd';
import React, { useState, useEffect, useCallback, MutableRefObject } from 'react';
import {
  fetchAvailableAttributesPlus,
  // fetchAvailableAttributesPlus,
  //   fetchAttributesByAttributeSet,
  //   saveAttributeSet,
} from '../../services/products';
import { fetchCopywritingAttribute, saveCopywritingAttribute } from '../../services/copywriting';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
// import { getProfileSettingValue } from '../../util'

const { Text } = Typography;

type Attribute = {
  attributeDataType: number;
  attributeName: string;
  attributeNum: number;
  attributeType: number;
  displaySeq: number;
  groupName: string;
  itemType: number;
};

interface TableTransferProps {
  leftColumns: any[];
  rightColumns: any[];
  dataSource: any;
  targetKeys: any;
  showSearch: any;
  onChange: any;
  filterOption: any;
  rowKey: any;
}

const leftFilterValue = [
  { name: 'attributeName', operator: 'startsWith', type: 'string', value: '' },
  { name: 'groupName', operator: 'startsWith', type: 'string', value: '' },
];

const rightFilterValue = [
  { name: 'itemType', operator: 'eq', type: 'select', value: null },
  { name: 'attributeName', operator: 'startsWith', type: 'string', value: '' },
  { name: 'groupName', operator: 'startsWith', type: 'string', value: '' },
];

const TableTransferChild = ({
  direction,
  filteredItems,
  onItemSelectAll,
  // onItemSelect,
  selectedKeys: listSelectedKeys,
  disabled: listDisabled,
  leftColumns,
  rightColumns,
}: any) => {
  const columns = direction === 'left' ? leftColumns : rightColumns;
  const filterValue = direction === 'left' ? leftFilterValue : rightFilterValue;
  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);

  const onSelectionChange = (gridData: any) => {
    const {
      selected,
    } = gridData;
    // const [item] = data;
    // const selectedKeys = Object.keys(selected).map((objectKey) => selected[objectKey].key);
    const selectedKeys = Object.keys(selected).map((objectKey) => Number(objectKey));


    const newSelect = selectedKeys.filter((key: any) => !listSelectedKeys.includes(key));
    const unSelect = listSelectedKeys.filter((key: any) => !selectedKeys.includes(key));

    if (newSelect.length) onItemSelectAll(newSelect, true); 
    if (unSelect.length) onItemSelectAll(unSelect, false);
  };

  const getSelection = () => {
      let obj: any = {};
      listSelectedKeys.forEach((key: any) => {
        obj[key] = true;
      });
      return obj;
  };

  useEffect(() => {
    // when filteredItems length changes then clear activeIndex to avoid bug
    if (!gridRef) return;
    gridRef.current?.doSetLastActiveIndex(-1);
    gridRef.current?.setActiveIndex(-1);
    if (gridRef.current?.lastLockedEndIndex) gridRef.current.lastLockedEndIndex = -1;
    if (gridRef.current?.computedActiveIndex) gridRef.current.computedActiveIndex = -1;
    if (gridRef.current?.activeRowRef) gridRef.current.activeRowRef.current = null;
    gridRef.current?.reload();
  }, [gridRef, filteredItems.length]);

  return (
    <ReactDataGrid
      // style={style}
      dataSource={filteredItems}
      columns={columns}
      checkboxColumn={!listDisabled}
      defaultFilterValue={filterValue}
      onSelectionChange={onSelectionChange}
      selected={getSelection()}
      idProperty='key'
      style={{ 
        height: '100%',
      }}
      onReady={setGridRef}
      virtualized
      activeIndex={undefined} 
    />
  );
}
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => {

  return (
    <Transfer className='transfer-class-height' style={{ height: '100%', width:'100%' }} {...restProps}>
      {(props: any) => <TableTransferChild {...props} leftColumns={leftColumns} rightColumns={rightColumns} />}
    </Transfer>
  );
};

interface ManageAttributeProps {
  handleCancel: () => void;
  current: any;
}

function ManageMapping({ current, handleCancel }: ManageAttributeProps) {
  const [loading, setLoaidng] = useState<boolean>(false);
  const [loading1, setLoaidng1] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [targetKeys, setTargetKeys] = useState<number[]>([]);
  const [data, setData] = useState<Attribute[]>([]);

  const getData = useCallback(async () => {
    try {
      setLoaidng(true);
      const res = await fetchAvailableAttributesPlus(1);
      // const allowCommon = await getProfileSettingValue('UseCommonAttributes', '0');
      setLoaidng(false);
      if (res) {
        // if(allowCommon === '0') {
        //   setData(res.filter((i:any) => i.groupName !== 'Common Attributes'));
        // }else {
        //   setData(res);
        // }
        setData(res);
      }
    } catch (error) {
      setLoaidng(false);
    }
  }, []);

  const getSelected = useCallback(async () => {
    try {
      setLoaidng1(true);
      const res = await fetchCopywritingAttribute(current.copywritingElementNum);
      setLoaidng1(false);
      if (res.isSuccess) {
        setTargetKeys(res.data.map((i: any) => i.attributeNum));
      } else {
        message.error(res.message || res.Message);
      }
    } catch (error) {
      message.error((error as any).response.data.message || (error as any).response.data.Message);
      setLoaidng1(false);
    }
  }, [current]);

  const handleSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      const params = targetKeys.map((i, index: number) => {
        return {
          AttributeNum: i,
          DisplaySeq: index + 1,
        };
      });
      const res = await saveCopywritingAttribute(current.copywritingElementNum, params);
      if (res.isSuccess) {
        message.success('saved successfully');
        handleCancel();
      } else {
        message.error(res.message || res.Message);
      }
    } catch (error) {
      message.error((error as any).response.data.message || (error as any).response.data.Message);
      setSaveLoading(false);
    }
  }, [handleCancel, current, targetKeys]);

  const onMoveUp = useCallback(
    (item: number, e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const temp = [...targetKeys];
      const index = temp.indexOf(item);
      if (index > 0) {
        const upperKey = temp[index - 1];
        temp[index - 1] = item;
        temp[index] = upperKey;
        setTargetKeys([...temp]);
      }
    },
    [targetKeys],
  );

  const onMoveDown = useCallback(
    (item, e) => {
      e.stopPropagation();
      e.preventDefault();
      const temp = [...targetKeys];
      const index = temp.indexOf(item);
      if (index < temp.length - 1) {
        const nextKey = temp[index + 1];
        temp[index + 1] = item;
        temp[index] = nextKey;
        setTargetKeys([...temp]);
      }
    },
    [targetKeys],
  );

  const leftTableColumns = [
    {
      name: 'attributeName',
      header: 'Attribute Name',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'groupName',
      header: 'Channel',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
  ];
  const rightTableColumns = [
    {
      name: 'itemType',
      header: 'Type',
      width: 70,
      enableColumnFilterContextMenu: false,
      render: ({ value }: any) => {
        return (value === 1 ? <Tag color="#2db7f5">SYS</Tag> : <Tag color="#87d068">ATT</Tag>);
      },
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: [
          {
            id: 1,
            label: 'SYS',
          },
          {
            id: 2,
            label: 'ATT',
          },
        ],
      },
    },
    {
      name: 'attributeName',
      header: 'Attribute Name',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'groupName',
      header: 'Channel',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'attributeNum',
      header: '',
      width: 70,
      enableColumnFilterContextMenu: false,
      render: (attributeNum: number) => (
        <span>
          <ArrowUpOutlined onClick={(e) => onMoveUp(attributeNum, e)} />
          <ArrowDownOutlined
            type="arrow-down"
            onClick={(e) => onMoveDown(attributeNum, e)}
            style={{ paddingLeft: 6, paddingRight: 4 }}
          />
        </span>
      ),
      defaultFlex: 1,
    },
  ];

  useEffect(() => {
    getData();
    getSelected();
  }, [getData, getSelected]);

  return (
    <Spin spinning={loading || loading1} wrapperClassName='ant-spin-flex-height'>
      <Row justify="space-between">
        <Text strong style={{ fontSize: 18 }}>{`Name: ${current.elementName}`}</Text>
        <Space>
          <Button type="primary" loading={saveLoading} onClick={() => handleSave()}>
            Save
          </Button>
          <Button onClick={() => handleCancel()}>Cancel</Button>
        </Space>
      </Row>
      <Row style={{ marginTop: 20, flexGrow: 1 }}>
        <TableTransfer
          dataSource={data}
          targetKeys={targetKeys}
          showSearch
          onChange={setTargetKeys}
          filterOption={(inputValue: string, item: any) =>
            item.attributeName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
            item.groupName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          rowKey={(record: any) => record.attributeNum}
        />
      </Row>
    </Spin>
  );
}

export default ManageMapping;
