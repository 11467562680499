import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ContentLayout from '../../components/ContentLayout';
import Heading from '../../components/common/Heading';
import { Button, Col, Collapse, DatePicker, Drawer, Row, Space, Spin } from 'antd';
import { CloseOutlined, ControlOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { FormLabel } from '../../components/common/styledComponents';
import DashboardRow from './DashboardRow';
import { fetchERPDigitBridgeLogSummary } from '../../services/channels';
import { convertToUtcTime } from '../../util/utcTime';
import { summaryData } from './function';
import { getChannelInfo } from './util';

const { Panel } = Collapse;

const Page: React.FC = () => {
  const defaultFilterDate = moment();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<any>(defaultFilterDate);
  const [summary, setSummary] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [summaryHour, setSummaryHour] = useState<any[]>([]);
  const [hourChartData, setHourChartData] = useState<any[]>([]);

  const getDataSource = async () => {
    setSpinning(true);
    try {
      const res = await fetchERPDigitBridgeLogSummary(convertToUtcTime(filterDate, '', '', 'YYYY-MM-DDTHH:mm:ss')) || [];

      const formatRes = [];
      for (let i = 0; i < res.length; i++) {
        const data = res[i];
        let { channelName, channelAccountName } = await getChannelInfo(data.channelNum, data.channelAccountNum);
        if (data.channelAccountNum > 0) {
          formatRes.push({
            ...data,
            channelAccountName,
            channelName
          })
        }
      }

      const [summary, chartData, summaryHour, hourChartData] = await summaryData(formatRes);
      setSummary(summary);
      setChartData(chartData);
      setSummaryHour(summaryHour);
      setHourChartData(hourChartData);
    } catch (error) {
      console.log(error);
    } finally {
      setSpinning(false);
    }
  }

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line
  }, [])

  return (
    <ContentLayout>
      <Heading
        title="Operation Dashboard"
        actions={
          <Button icon={<ControlOutlined />} onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}>Filter</Button>
        }
      />
      <Drawer
        visible={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        closable={false}
        width={'30%'}
        title={
          <Row align="middle" justify="space-between" wrap={false}>
            <Col>
              <Space>
                <CloseOutlined onClick={() => setFilterDrawerOpen(false)} />
                <span>Filter</span>
              </Space>
            </Col>
            <Col>
              <Space>
                <Button icon={<DeleteOutlined />} onClick={() => setFilterDate(defaultFilterDate)}>Clear</Button>
                <Button icon={<SearchOutlined />} onClick={getDataSource} type="primary">Apply</Button>
              </Space>
            </Col>
          </Row>
        }
      >
        <FormLabel>Date</FormLabel>
        <DatePicker
          allowClear={false}
          value={filterDate}
          onChange={e => setFilterDate(e)}
          style={{ width: '100%' }}
        />
      </Drawer>

      <Spin spinning={spinning}>
        <DashboardRow title="Total Day Summary" dataSource={summary[0]} chartData={chartData[0]} />
        {summary.filter((item: any, filterIndex: number) => filterIndex > 0).map((items: any, index: number) => {
          return (
            <Collapse key={`collapse-${index}`} accordion ghost>
              <Panel
                key={`collapse-panel-${index}`}
                showArrow={false}
                header={
                  <DashboardRow
                    key={`dashboardRow-${index}`}
                    title={`${items.channelAccountName} ${items.dateFrom}`}
                    dataSource={items}
                    chartData={chartData[index + 1]}
                  />
                }
              >
                <div style={{ marginLeft: 20 }}>
                  {summaryHour.length >= 1 && summaryHour[index] && summaryHour[index].map((hourData: any, indexChart: number) => {
                    return (
                      <DashboardRow
                        key={`dashboardRow-${index}-hour-${indexChart}`}
                        title={`${hourData.hour}:00 ${hourData.channelName} ${hourData.channelAccountName} ${hourData.dateFrom}`}
                        dataSource={hourData}
                        chartData={((hourChartData || []).length > 0 && hourChartData[index] && hourChartData[index][indexChart]) ? hourChartData[index][indexChart] : []}
                        size="small"
                      />
                    )
                  })}
                </div>
              </Panel>
            </Collapse>
          )
        })}
      </Spin>
    </ContentLayout>
  );
};

export default Page;
