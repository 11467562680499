import React from 'react';
import { useHistory} from 'react-router-dom';
import {
  Button,
  Row,
  Space,
  Steps,
  notification,
} from 'antd';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import { InfoButton } from '../../../components/common/styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { saveBulkPushCopywritingelements } from '../../../services/copywriting';
//import Products from '../../../services/products';
import { CopywritingStepWrapper } from '../../DetailProduct/styledComponents';
import DestinationSelector from './DestinationSelector';
import FieldSelector from './FieldSelector';

type Props = {
  onCancel?: Function;
  bulkData: StringKAnyVPair;
};

const BulkPushSteps = (props: Props) => {
  const { useState } = React;
  const { Step } = Steps;
  //const { productId } = props;
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [overwriteDest, setOverwriteDest] = useState(false);
  const [useChildLevel, setUseChildLevel] = useState(false);
  //const [productBasicInfo, setProductBasicInfo] = useState<StringKAnyVPair>({});
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<StringKAnyVPair[]>([]);

  const allType = () => {
    const all = [0, ''];

    if (props.bulkData.all) {
      switch (props.bulkData.all) {
        case 1:
          all[0] = 1;
          all[1] = 'Product';
          break;
        case 2:
          all[0] = 2;
          all[1] = 'StyleMaster';
          break;
        case 3:
          all[0] = 3;
          all[1] = 'SubStyle';
          break;
      }
    }

    return all;
  };

  const closeDialog = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const isNextButtonEnabled = () => {
    let ret = false;

    if (currentStep === 0) {
      ret = selectedAttributes.length > 0;
    } else if (currentStep === 1) {
      ret = selectedDestinations.length > 0;
    }

    return ret;
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const onCancel = () => {
    // maybe need to prompt
    closeDialog();
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const pushCopywriting = async () => {
    const list: StringKAnyVPair[] = [];
    //console.log('push', selectedDestinations);
    selectedDestinations.forEach(g => {
      g.dests.forEach((e: StringKAnyVPair) => {
        if (e.selected) list.push(e);
      });
    });
    console.log('push ->', list, props);
    if (list.length > 0) {
      const items = list.map(e => ({
        AttributeNum: e.attributeNum,
        CopywritingElementNum: e.copywritingElementNum,
      }));
      const prds = props.bulkData.products.map((e: StringKAnyVPair) => ({
        CentralProductNum: e.CentralProductNum,
        Sku: e.SKU,
        Style: e.styleCode,
        //SubStyle: e.colorPatternCode,
        SubStyle: e.subStyleCode,
        Type: e.Type,
      }));
      setIsLoading(true);
      try {
        const pd = {
          IgnoreSource: true,
          Items: items,
          Overwrite: overwriteDest,
          IncludeChild: useChildLevel,
          ProductFilter: productFilter(),
          Products: prds,
          TypeNum: allType()[0],
          //Type: allType()[1],
        };
        console.log('pd ->', pd);
        await saveBulkPushCopywritingelements(pd);
        setCurrentStep(currentStep + 1);
      } catch(e) {
        notification.error({
          message: `Saved destination error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      notification.error({
        message: 'Please select at least one destination',
        duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
      });
    }
  };

  const productFilter = () => {
    //const defaultParmas = {queryFilters: [], queryGrouper: {groupName: 'Style'}};
    const defaultParmas = {};
    let ret = props.bulkData.searchParams || defaultParmas;

    switch (props.bulkData.all) {
      case 1:
        ret = {
          queryFilters: [
            {
              'filterName': 'Product',
              'filterValue': '',
              'op': 1,
            }
          ],
          queryGrouper: {
            groupName: 'Product'
          }
        };

        break;

      case 2:
        ret = {
          queryFilters: [
            {
              'filterName': 'StyleCode',
              'filterValue': '',
              'op': 1,
            }
          ],
          queryGrouper: {
            groupName: 'StyleCode'
          }
        };

        break;

      case 3:
        ret = {
          queryFilters: [
            {
              'filterName': 'ColorPatternCode',
              'filterValue': '',
              'op': 1,
            }
          ],
          queryGrouper: {
            groupName: 'ColorPatternCode'
          }
        };
        break;
    }

    return ret;
  };

  React.useEffect(() => {
    if (!inited) {
      //loadInitialData();
      setInited(true);
      console.log('props ->', props);
    }
  }, [inited, props]);

  return (<>
    <CopywritingStepWrapper>
      <div className="step-ctn bulk">
        <div className="step-wrapper">
          <div className="step-sider">
            <Steps current={currentStep} direction="vertical">
              <Step title="Select Fields" />
              <Step title="Select Destination" />
              <Step title="Review" />
              <Step title="Finish" />
            </Steps>
          </div>
          <div className="step-board">
            <FieldSelector
              focused={currentStep === 0}
              maskScreen={setIsLoading}
              //productId={props.productId}
              setSelectedAttributes={setSelectedAttributes}
            />
            <DestinationSelector
              focused={currentStep === 1}
              maskScreen={setIsLoading}
              mode="destination"
              overwrite={overwriteDest}
              productId={''}
              selectedAttributes={selectedAttributes}
              setOverwrite={setOverwriteDest}
              setSelectedDestinations={setSelectedDestinations}
              useChildLevel={useChildLevel}
              setUseChildLevel={setUseChildLevel}
            />
            <DestinationSelector
              focused={currentStep === 2}
              maskScreen={setIsLoading}
              mode="review"
              overwrite={overwriteDest}
              productId={''}
              selectedAttributes={selectedAttributes}
              selectedDestinations={selectedDestinations}
              useChildLevel={useChildLevel}
              //setSelectedDestinations={setSelectedDestinations}
            />
            <DestinationSelector
              focused={currentStep === 3}
              maskScreen={setIsLoading}
              mode="finish"
              productId={''}
              selectedAttributes={selectedAttributes}
              selectedDestinations={selectedDestinations}
              overwrite={overwriteDest}
              useChildLevel={useChildLevel}
              //setSelectedDestinations={setSelectedDestinations}
            />
          </div>
        </div>
      </div>
      {/*<Row align="middle" justify={currentStep === 0 ? 'end' : 'space-between'}>*/}
      <Row align="middle" className="left-bottom-btn-block" justify="space-between">
        <Space>
          <Button
            onClick={previousStep}
            style={{visibility: currentStep > 0 && currentStep < 3 ? 'unset' : 'hidden'}}
          >
            Back
          </Button>
          {currentStep < 2 && <Button
            disabled={!isNextButtonEnabled()}
            onClick={nextStep}
            type="primary"
          >
            Next
          </Button>}
          {currentStep === 2 && <Button
            onClick={pushCopywriting}
            type="primary"
          >
            Submit
          </Button>}
          {currentStep === 3 && <InfoButton
            onClick={() => history.push('/file-import')}
            type="primary"
          >
            Go to "Import Center" to check out the result
          </InfoButton>}
          {currentStep === 3 && <Button
            onClick={closeDialog}
            type="primary"
          >
            Close
          </Button>}
        </Space>
        <Space>
          {currentStep < 3 && <Button onClick={onCancel}>
            Cancel
          </Button>}
        </Space>
      </Row>
    </CopywritingStepWrapper>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default BulkPushSteps;
