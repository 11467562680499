import React from 'react';
import { Button, Select, notification } from 'antd';
import { CodeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../constants/config';
import { fetchCopywritingelements } from '../../services/copywriting';

const PatternToolbar = styled.div`
  & .ant-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 4px 8px;
  }

  & .ant-select {
    width: calc(100% - 32px);
  }

  & .ant-select-selector {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: none !important;
  }
`;

type Props = {
  onInsertPattern: Function;
};

const CWEList: StringKAnyVPair[] = [];

const PatternAttrSelector = (props: Props) => {
  const { useState } = React;
  const [attrOptions, setAttrOptions] = useState<any[]>([]);
  const [currentPattern, setCurrentPattern] = useState<string>();
  const [inited, setInited] = useState(false);
  const [isLoadingPatternAttr, setIsLoadingPatternAttr] = useState(false);

  /*const attrOptions = [
    { label: 'testPatternVar1', value: 'testPatternVar1' },
    { label: 'testPatternVar2', value: 'testPatternVar2' },
    { label: 'testPatternVar3', value: 'testPatternVar3' },
    { label: 'testPatternVar4', value: 'testPatternVar4' },
    { label: 'testPatternVar5', value: 'testPatternVar5' },
  ];*/

  // eslint-disable-next-line
  const loadInitData = async () => {
    try {
      await loadPatternMeta();
    } catch(e) {
      notification.error({
        message: `Fetch data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const onInsertPattern = () => {
    if (currentPattern) {
      props.onInsertPattern(currentPattern);
    }
  };

  const onPatternChange = (p: string) => {
    setCurrentPattern(p);
  };

  const loadPatternMeta = async () => {
    if (CWEList.length === 0) {
      setIsLoadingPatternAttr(true);

      const res = await fetchCopywritingelements();
      const { data } = res;

      if (Array.isArray(data)) {
        console.log('d', data);
        data.forEach(e => {
          const opt = {
            label: e.elementName,
            value: e.elementName,
          };

          CWEList.push(opt);
        });
        setAttrOptions([ ...CWEList ]);
      }

      setIsLoadingPatternAttr(false);
    } else {
      setAttrOptions([ ...CWEList ]);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      loadInitData();
      setInited(true);
    }
  }, [inited, loadInitData]);

  return (<>
    <PatternToolbar>
      <Select
        loading={isLoadingPatternAttr}
        options={attrOptions}
        onChange={onPatternChange}
        showSearch={true}
      />
      <Button
        disabled={!currentPattern}
        onClick={onInsertPattern}
      >
        <CodeOutlined />
      </Button>
    </PatternToolbar>
  </>);
};

export default PatternAttrSelector;
