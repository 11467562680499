import React from 'react';
import { Switch } from 'antd';

import { Actions } from './context';

type Props = {
  dispatch: Function;
  state: StringKAnyVPair;
};

const OverwriteSwitch = (props: Props) => {
  const { dispatch, state } = props;

  const onChange = (chk: boolean) => {
    dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: chk });
  };

  return (<>
    <span style={{marginRight: 4}}>Overwrite children's value</span>
    <Switch
      checkedChildren="On"
      //defaultChecked={state.overwriteChildren}
      onChange={onChange}
      unCheckedChildren="Off"
      checked={state.overwriteChildren}
    />
  </>);
};

export default OverwriteSwitch;
