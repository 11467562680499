import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { bindActionCreators } from 'redux';
import { FormInstance } from 'antd/es/form';
import { HeaderButton } from '../common/styledComponents';
import { WrapperForm, Wrapper } from './styledComponents';
import Edit from '../../assets/icons/edit';
import Delete from '../../assets/icons/delete';
import Save from '../../assets/icons/save';
import Cancel from '../../assets/icons/cancel';
import { ViewLevel } from '../../constants/enums/permissions';
import { ProductDetailScreenContext } from '../../screens/ProductDetail/context';
import notificationActions from '../../redux/actions/notifications';

interface EditControlsProps {
  editMode: boolean;
  cancel: any;
  edit: any;
  deleteFunction: any;
  isFormUpdated: boolean;
  permissionNumber: number;
  onFinishForm: any;
  form: FormInstance;
  onFinishFailedForm: any;
  context: any,
}

const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
  if (permissions && permissions.size === 0) return false;
  return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
};

const EditControls = ({ context = ProductDetailScreenContext, edit, deleteFunction, isFormUpdated = false, editMode = false, cancel, permissionNumber, onFinishForm, form, onFinishFailedForm }: EditControlsProps) => {
  const [, dispatch] = useContext(context);
  const profiles = useSelector((state: any) => state.profiles);
  const notifications = bindActionCreators(notificationActions, useDispatch());
  return (
    editMode ? (
      <WrapperForm
        form={form}
        onFinish={onFinishForm}
        onFinishFailed={(values: any) => onFinishFailedForm(values, dispatch, notifications)}
      >
        <Form.Item>
          <HeaderButton
            disabled={!isFormUpdated}
            $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
            type="primary"
            htmlType="submit"
          >
            Save
            <Save width={22} height={22} pathColor="#fff" />
          </HeaderButton>
        </Form.Item>
        <Form.Item>
          <HeaderButton
            onClick={cancel}
            $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
          >
            Cancel
            <Cancel width={22} height={22} pathColor="#000" />
          </HeaderButton>
        </Form.Item>
      </WrapperForm>
    ) : (
      <Wrapper>
        <HeaderButton
          onClick={edit}
          $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
          type="primary"
        >
          Edit
          <Edit width={22} height={22} pathColor="#fff" />
        </HeaderButton>
        <HeaderButton
          onClick={cancel}
          $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
        >
          Cancel
          <Cancel width={22} height={22} pathColor="#000" />
        </HeaderButton>
        <HeaderButton
          onClick={deleteFunction}
          $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
          className="hoverButtonRed"
        >
          Delete
          <Delete classNameIcon="hoverIconWhite" width={22} height={22} />
        </HeaderButton>
      </Wrapper>
    )
  );
};

export default EditControls;
