import React from "react";

const Icon = ({ width = "16", height = "17", fill = "#D9D9D9", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M2.947 13.841a.667.667 0 0 1-.492-.217.659.659 0 0 1-.175-.51l.163-1.795L9.99 3.776l2.358 2.357-7.544 7.542-1.796.164a.634.634 0 0 1-.06.002Zm9.87-8.18L10.46 3.306l1.414-1.414a.667.667 0 0 1 .943 0l1.414 1.414a.667.667 0 0 1 0 .943L12.818 5.66Z"
        fill={fill}
      />
    </svg>
  );
};
export default React.memo(Icon);
