import { Form, Input, Modal, Spin, Row, Col, Select, message, InputNumber, Button } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Permissions } from '../../constants/enums/permissions';
import FormButtons from '../../components/common/FormButtons';
import { getContentResource, editCopywritingElement, addCopywritingElement, fetchContentSourceType } from '../../services/copywriting';
import { trimValues } from '../../util/validators';
import { PlusOutlined } from '@ant-design/icons';
import OptionListDialog from './OptionListDialog';
import PatternAttrSelector from './PatternAttrSelector';

const { TextArea } = Input;

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  formValues: any;
  visible: boolean;
}

function ModalForm({ formValues, visible, onClose, onSuccess }: ModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [groupLoading, setGroupLoading] = useState<boolean>(false);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [dataType, setDataType] = useState<any>();
  const [selOptions, setSelOptions] = useState<any[]>([]);
  const [optionList, setOptionList] = useState<any[]>([]);
  const [optionListDialogVisible, setOptionListDialogVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const patternInputRef = React.useRef<any>(null);

  const getOptionList = async () => {
    setProcessing(true);
    try {
      const { data, isSuccess, message: msg } = await getContentResource(formValues.copywritingElementNum);
      if (isSuccess && data) {
        const { copywritingElementOptionMasterList = [], copywritingElementOptionList = [] } = data;
        setSelOptions(copywritingElementOptionList);
        setOptionList(copywritingElementOptionMasterList);
      } else {
        message.error(msg);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    if (formValues) {
      setDataType(formValues.elementDataType);
      getOptionList();
    }
    // eslint-disable-next-line
  }, [formValues]);

  const handleBeforeSubmit = async () => {
    // const params = await form.validateFields();
    const params = trimValues(await form.validateFields());

    //console.log('p->', params, form.getFieldValue('Pattern'));
    if (params.errorFields) return;
    try {
      setProcessing(true);
      const res = formValues
        ? await editCopywritingElement(formValues.copywritingElementNum, {
          ElementName: params.ElementName,
          ElementType: formValues.elementType,
          ElementDataType: params.ElementDataType,
          Tag: params.Tag,
          Pattern: params.Pattern,
          // DefaultValue: params.DefaultValue,
          // OptionList: formValues.optionList || '',
          OptionList: selOptions.map(opt => opt.optionValue).join('|'),
          Note: params.Note,
          DisplaySeq: params.DisplaySeq,
          ContentSourceGroup: params.ContentSourceGroup,
        })
        : await addCopywritingElement({
          // CopywritingElementNum: 0,
          ElementName: params.ElementName,
          ElementType: params.ElementType,
          ElementDataType: params.ElementDataType,
          Tag: params.Tag,
          Pattern: params.Pattern || '',
          // DefaultValue: params.DefaultValue,
          // OptionList: '',
          OptionList: selOptions.map(opt => opt.optionValue).join('|'),
          Note: params.Note,
          DisplaySeq: params.DisplaySeq,
          Relationships: [],
          ContentSourceGroup: params.ContentSourceGroup,
        });
      if (res.isSuccess) {
        message.success(formValues ? 'Save successfully' : 'Add successfully');
        onSuccess();
      } else {
        message.error(res.message || res.Message);
      }
      form.resetFields();
    } catch (error) {
      // TODO: something
      message.error((error as any).response.data.message || (error as any).response.data.Message);
    } finally {
      setProcessing(false);
    }
  };

  const getGroupList = async () => {
    setGroupLoading(true);
    try {
      const { data = [], isSuccess, message: resMsg = '' } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        message.error(resMsg || 'No results found');
      }
    } catch (error) {
      console.log('error', error);
    }
    setGroupLoading(false);
  };

  const onInsertPattern = (patText: string) => {
    if (patternInputRef.current) {
      const el = patternInputRef.current.resizableTextArea.textArea;

      if (el) {
        const [start, end] = [el.selectionStart, el.selectionEnd];
        let patStr = patText.indexOf(' ') > -1 ? `"${patText}"` : patText;
        const patExp = `{{${patStr}}}`;

        el.setRangeText(patExp, start, end, 'select');
        //console.log('el val->', el.value);
        //console.log('el form->', form.getFieldsValue());
        form.setFieldsValue({
          'Pattern': el.value,
        });
        //form.setFieldValue('Pattern', el.value);
      }
    }
  };

  const onPatternTextareaBlur = () => {
  };

  const onPatternTextareaChange = (evt: any) => {
  };

  const onPatternTextareaFocus = () => {
  };

  const OptionListSelector = () => {
    return (
      <Row wrap={false} style={{ width: '88%' }} gutter={10}>
        <Col span={16}>
          <Select
            mode="tags"
            disabled
            options={optionList.map(opt => {
              return { label: opt.optionValue, value: opt.optionValue };
            })}
            value={selOptions.map(opt => {
              return opt.optionValue;
            })}
          />
        </Col>
        <Col span={8}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setOptionListDialogVisible(true)}
          >
            Add Options
          </Button>
        </Col>
      </Row>
    )
  };

  const patternString = (pat: any) => {
    let ret = '';

    if (typeof pat === 'string') {
      ret = pat;

      if (pat && typeof pat === 'string') {
        try {
          const p = JSON.parse(pat);

          if (p && typeof p === 'object') {
            ret = p.Pattern || ret;
          }
        } catch(e) {}
      }
    }

    return ret;
  };

  useEffect(() => {
    getGroupList();
  }, [])

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={<TitleHeader>{formValues ? `Edit Content Resources` : `Add Content Resources`}</TitleHeader>}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={1000}
      footer={
        <FormButtons
          permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
          onSave={form.submit}
          onCancel={onClose}
          editingMode
          style={{ justifyContent: 'flex-end' }}
        />
      }
    >
      <Spin spinning={processing}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ size: 'middle' }}
          onFinish={handleBeforeSubmit}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="ElementName"
                initialValue={formValues?.elementName}
                rules={[{ required: true, message: 'Name is required.' }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tag"
                name="Tag"
                initialValue={formValues?.tag}
              // rules={[{ required: true, message: 'Tag is required.' }]}
              >
                <Input placeholder="Tag" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Data Type"
                name="ElementDataType"
                initialValue={formValues?.elementDataType}
              >
                <Select onChange={(value) => setDataType(value)}>
                  <Select.Option value={1}>String</Select.Option>
                  <Select.Option value={2}>Integer</Select.Option>
                  <Select.Option value={3}>Decimal</Select.Option>
                  <Select.Option value={4}>DataTime</Select.Option>
                  <Select.Option value={5}>ImageURL</Select.Option>
                  <Select.Option value={6}>Price</Select.Option>
                  <Select.Option value={7}>ImageUrlList</Select.Option>
                  <Select.Option value={8}>VideoURL</Select.Option>
                  <Select.Option value={9}>Virtual</Select.Option>
                  <Select.Option value={10}>Metafield</Select.Option>
                  <Select.Option value={11}>PageUrl</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Group"
                name="ContentSourceGroup"
                initialValue={formValues?.contentSourceGroup}
              >
                {/*<Select onChange={(value) => setDataType(value)} loading={groupLoading}>*/}
                <Select loading={groupLoading}>
                  {
                    groupList.map((group: any, index) => <Select.Option value={group.enumValue} key={index}>{group.enumName}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sequence" name="DisplaySeq" initialValue={formValues?.displaySeq}>
                <InputNumber style={{ width: 317 }} />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Option List">
                <Row justify="space-between">
                  <Form.Item noStyle name="options" initialValue={formValues?.options}>
                    <Input style={{ width: 'calc(100% - 140px)' }} />
                  </Form.Item>
                  <Button type="primary" style={{ width: 130 }}>
                    Add Option <PlusOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="DefaultValue"
                label="Default Value"
                initialValue={formValues?.defaultValue}
              >
                <Input />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item name="optionList" label="Option List">
                {/* <Select
                  mode="tags"
                  disabled
                  options={[]}
                  suffixIcon={
                    <Button icon={<PlusOutlined/>}>Add Options</Button>
                  }
                /> */}
                <OptionListSelector />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Note" label="Note" initialValue={formValues?.note}>
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            {dataType === 9 && (
              <Col span={12}>
                <Row style={{marginBottom: 2}}>
                  <Col span="6"></Col>
                  <Col span="16">
                    <PatternAttrSelector
                      onInsertPattern={onInsertPattern}
                    />
                  </Col>
                </Row>
                <Form.Item name="Pattern" label="Pattern" initialValue={patternString(formValues?.pattern)}>
                  <TextArea rows={6}
                    onBlur={onPatternTextareaBlur}
                    onChange={onPatternTextareaChange}
                    onFocus={onPatternTextareaFocus}
                    ref={patternInputRef}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Spin>
      {
        optionListDialogVisible && (
          <OptionListDialog
            data={formValues}
            visible={optionListDialogVisible}
            onVisibleChange={(e: boolean) => setOptionListDialogVisible(e)}
            optionList={optionList}
            selOptions={selOptions}
            optionListChange={(opts: any[]) => setOptionList(opts)}
            selOptionsChange={(opts: any[]) => setSelOptions(opts)}
          />
        )
      }
    </Modal>
  );
}

export default ModalForm;
