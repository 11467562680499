import React from 'react';
import Icon from '@ant-design/icons';
import { BaseIcon } from './base';

const Dashboard: React.FC<BaseIcon> = ({
  style = {},
  height = 16,
  width = 17,
  viewBox = '0 0 16 17',
  pathColor = 'black',
}) => (
  <Icon component={() => (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} viewBox={viewBox} width={width}>
      <path d="M7.28397 2.1444H2.02081L2.0442 8.12195H7.28397V2.1444Z" stroke={pathColor} strokeLinejoin="round" fillOpacity={0} />
      <path d="M14.0208 2.1444L9.52958 2.12195V5.87195H14.0208V2.1444Z" stroke={pathColor} strokeLinejoin="round" fillOpacity={0} />
      <path d="M14.0208 8.12195H9.52958V14.1219H14.0208V8.12195Z" stroke={pathColor} strokeLinejoin="round" fillOpacity={0} />
      <path d="M7.28397 10.3719H2.0442V14.1219H7.28397V10.3719Z" stroke={pathColor} strokeLinejoin="round" fillOpacity={0} />
    </svg>
  )}
  />
);

export default Dashboard;