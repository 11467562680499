import authentication from 'react-azure-b2c';

export const config = {
	instance: 'https://login.microsoftonline.com/tfp/',
	tenant: process.env.TENANT,
	signInPolicy: process.env.SIGNIN_POLICY,
	clientId: process.env.CLIENT_ID,
	cacheLocation: 'localStorage',
	scopes: [process.env.SCOPE_URL],
	redirectUri: process.env.REDIRECT_URI
};
export default authentication;
