import { PlusOutlined } from '@ant-design/icons';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Button, Col, Modal, Row, Space, notification, Select } from 'antd';
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
// import { Link } from 'react-router-dom';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid } from '../../components/common/datagrid/DataGrid';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import { HoverBgButton } from '../../components/common/styledComponents';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import ScreenMask from '../../components/common/ScreenMask';
import Loading from '../../components/common/Loading';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, LOADING_ICON_SIZE1 } from '../../constants/config';
import { fetchChannels } from '../../services/channels';
import { deleteAttributesById, fetchAttributes } from '../../services/products';
import { ProductAttributeDataType, ProductAttributeType } from '../../types/enums';
import { enumNameToLabel } from '../../util/strings';
import ImportDialog from './ImportDialog';
import { Actions, Dispatcher, initialState, PageContext, reducer } from './context';
import { loadAttributesChannels, loadProfileAccountAttributesChannels } from '../DetailProduct/helper';
import AddEditDilaog from '../AttributeDetail'


const ChannelName: React.FC<{ value: number }> = ({ value }) => {
  const [state] = useContext(PageContext);
  if (!state) {
    return null;
  }

  const channelName = state.channels.get(value)?.channelName || `Common ${value ? `(${value})` : ''}`;

  return <span>{channelName}</span>;
};

const defaultSearchFields: Array<SearchField<Entities.ProductAttribute> | string> = [
  'AttributeName',
  {
    fieldName: 'AttributeType',
    attributes: {
      extractor(attribute): string {
        return enumNameToLabel(enumNameToLabel(ProductAttributeType[attribute.AttributeType]));
      },
    },
  },
  {
    fieldName: 'AttributeDataType',
    attributes: {
      extractor(attribute): string {
        return enumNameToLabel(ProductAttributeDataType[attribute.AttributeDataType]);
      },
    },
  },
  'DefaultValue',
  {
    fieldName: 'Classifications',
    attributes: {
      extractor(attribute): string {
        return attribute.Classifications?.length?.toString() || '0';
      },
    },
  },
];

const baseCSVColumns: CSVColumns = [
  { key: 'AttributeName', header: 'Attribute' },
  {
    key: 'AttributeDataType',
    header: 'Data Type',
    transform: (v: any) => enumNameToLabel(ProductAttributeDataType[v]),
  },
  { key: 'AttributeChannelNum', header: 'Channel', },
  { key: 'Group1', header: 'Collection' },
  { key: 'Group2', header: 'Group' },
  { key: 'AttributeType', header: 'Attribute Type', transform: (v: any) => enumNameToLabel(ProductAttributeType[v]) },
  { key: 'DefaultValue', header: 'Default Value' },
  { key: 'OptionList', header: 'Option List' },
  /*{
    key: 'AttributeClassificationProfiles',
    header: 'Classification Count',
    transform: (v?: Entities.AttributeClassificationProfile[]) => v?.filter(ac => ac.Selected || false).length || 0,
  },*/
];

const PageContent: React.FC = () => {
  const [state, dispatch] = useContext(PageContext);
  const { channels } = state || {};
  const [gridRef, setGridRef] = useState<any>(null);
  const [importDialogVisible, setImportDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localData, setLocalData] = useState<Entities.ProductAttribute[]>([]);
  const [filteredData, setFilteredData] = useState<Entities.ProductAttribute[]>([]);
  const [selectedAttrs, setSelectedAttrs] = useState<string[]>([]);
  const [channelList, setChannelList] = useState<any[]>([]);
  const [channelValue, setChannelValue] = useState<any>([]);

  const columns: TypeColumn[] = [
    {
      name: 'AttributeName',
      header: 'Attribute',
      defaultFlex: 2,
      minWidth: 220,
      render({ value, data }: { value: string, data: Entities.ProductAttribute }) {
        return (
          <Button type="link" onClick={()=>{
            dispatch&&dispatch({ type: Actions.SET_CURRENT, params: data });
            dispatch&&dispatch({ type: Actions.SET_ADD_EDIT_DIALOG_VISIBLE, params: true });
          }}>
            {value}
          </Button>
        );
      },
    },
    {
      name: 'AttributeDataType',
      header: 'Data Type',
      defaultFlex: 1,
      minWidth: 120,
      render({ value }) {
        const enumValue = ProductAttributeDataType[value];
        return <span>{enumNameToLabel(enumValue)}</span>;
      },
    },
    {
      name: 'AttributeChannelNum',
      header: 'Channel',
      defaultFlex: 1,
      minWidth: 180,
      render({ value }) {
        return <ChannelName value={value} />;
      },
    },
    {
      name: 'AttributeId',
      header: 'AttributeId',
      defaultFlex: 1,
      defaultVisible: false,
    },
    {
      name: 'Group1',
      header: 'Collection',
      defaultFlex: 1,
      minWidth: 120,
    },
    {
      name: 'Group2',
      header: 'Group',
      defaultFlex: 1,
    },
    {
      name: 'DefaultValue',
      header: 'Default Value',
      defaultFlex: 1,
      minWidth: 140,
    },
    {
      name: 'AttributeType',
      header: 'Attribute Type',
      defaultFlex: 1,
      minWidth: 150,
      render({ value }) {
        const enumValue = ProductAttributeType[value];
        return <span>{enumNameToLabel(enumValue)}</span>;
      },
    },
    /*{
      name: 'Classifications',
      header: 'Classification Count',
      defaultFlex: 2,
      render({ data }: { data: Entities.ProductAttribute }) {
        const value = data.Classifications?.length || 0;
        return <span>{value}</span>;
      },
    },*/
    {
      name: 'OptionList',
      header: 'Option List',
    },
    {
      name: 'ProductCount',
      header: 'Product Count',
    },
  ];

  const loadSysAttributesChannels = async () => {
    const ac = await loadProfileAccountAttributesChannels();

    if (Array.isArray(ac)) {
      setChannelList([
        {
          channelNum: 0,
          channelName: 'Common',
        },
        ...ac,
      ]);
    }
  };

  const channelOptions = useMemo(() => {
    return channelList.map(e => (
      {
        value: e.channelNum,
        label: e.channelName,
      }
    ));
  }, [channelList]);

  // scroll to bottom or top of select list
  const selectScrollTo = (bottom = true) => {
    const [element] = document.getElementsByName('channelSelectList');
    if (!element) return;
    element.scrollTo(0, bottom ? element.scrollHeight : 0);
  };

  useEffect(() => {
    // filter data based on channelDropdownFilter
    if (channelValue.length && state?.data) {
      // scroll to bottom of select
      selectScrollTo();
      const channelNums = channelValue.map((item: any) => item.value);
      const filtered = state.data.filter((item) => {
        if (channelNums.includes(item.AttributeChannelNum)) return true;
        return false;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(state?.data || []);
    }
  }, [channelValue, state?.data]);

  const searchFields = useMemo((): Array<SearchField<Entities.ProductAttribute> | string> => [
    ...defaultSearchFields,
    {
      fieldName: 'AttributeChannelNum',
      attributes: {
        extractor(attribute): string {
          return channels?.get(attribute.AttributeChannelNum)?.channelName || 'Common';
        },
      },
    },
  ], [channels]);

  const deleteSelectedRows = async () => {
    setIsLoading(true);
    console.log('del:', selectedAttrs);
    try {
      const res = await deleteAttributesById(selectedAttrs);

      console.log('dr:', res);
      gridRef?.current.deselectAll();
      reloadData();
    } catch (e) {
      notification.error({
        message: `Deleted Attributes ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseImportDialog = () => {
    setImportDialogVisible(false);
  };

  const onFileImported = () => {
    reloadData();
  };

  const onSelectRow = (checked: any) => {
    const { selected, unselected, data } = checked;
    let list: string[] = [...selectedAttrs];

    if (selected) {
      if (typeof selected === 'object' && Object.keys(selected).length >= 0) {
        if (list.length > Object.keys(selected).length) {
          if (Array.isArray(data)) {
            list = [];

            data.forEach((e) => {
              list.push(e.AttributeId);
            });
          } else {
            const i = list.indexOf(data.AttributeId);

            if (i > -1) list.splice(i, 1);
          }
        } else {
          if (typeof data === 'object') {
            list.push(data.AttributeId);
          }
        }
      } else if (data && typeof data === 'object') {
        if (Array.isArray(data)) {
          list = [];

          data.forEach((e) => {
            list.push(e.AttributeId);
          });
        } else if (selected === true) {
          if (data && data.AttributeId) {
            if (list.indexOf(data.AttributeId) === -1) {
              list.push(data.AttributeId);
            }
          }
        }
      }

      if (unselected && typeof unselected === 'object') {
        for (let i = list.length - 1; i >= 0; i--) {
          if (list[i] === data.AttributeId) {
            if (unselected[data.AttributeId]) {
              list.splice(i, 1);
            }
          }
        }
      }
    }

    console.log('list', list, checked);
    setSelectedAttrs(list);
  };

  const readyDeleteSelectedRow = () => {
    Modal.confirm({
      title: 'Confirm Deleting',
      content: 'Are you sure to delete selected rows?',
      onOk: deleteSelectedRows,
    });
  };

  const reloadData = async () => {
    if (dispatch) {
      dispatch({ type: Actions.FETCH_DATA, params: false });

      try {
        const attributes = (await fetchAttributes() as Entities.ProductAttribute[]).filter(a => [ProductAttributeType.RESERVED, ProductAttributeType.MEDIA].indexOf(a.AttributeType) < 0);
        const channels = new Map((await loadAttributesChannels() as Entities.ChannelProfile[]).map(p => [p.channelNum, p]));

        dispatch({ type: Actions.DATA_FETCHED, params: { attributes, channels } });
      } finally {
        dispatch({ type: Actions.SET_LOADING, params: false });
      }
    }
  };

  const csvColumns: CSVColumns = useMemo(() => {
    /*[
    ...baseCSVColumns,
    {
      key: 'AttributeChannelNum',
      header: 'Channel',
      transform: (v: any) => channels?.get(v)?.channelName || 'Common',
    },
  ]*/
    const cols = [...baseCSVColumns];
    const col = cols[2] as StringKAnyVPair;

    col.transform = (v: any) => channels?.get(v)?.channelName || 'Common';

    return cols;
  }, [channels]);

  useEffect(() => {
    loadSysAttributesChannels();
  }, []);

  if (!state || !dispatch) {
    return null;
  }

  return (
    <>
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <Row>
            <SearchBar<any>
              id="product_attribute_searchbar"
              reference="AttributeId"
              data={filteredData}
              onResult={setLocalData}
              fields={searchFields}
              disabled={state.searchDisabled}
              enableCache
              style={{
                width: '440px',
              }}
            />
            <Row>
              <span
                style={{
                  padding: '5px',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                Channel
              </span>
              <Select
                {...{
                  name: 'channelSelectList',
                  filterOption: (input: string, option: any) => {
                    return ((option.label || option.value) as string).toLowerCase().startsWith(input.toLowerCase());
                  },
                }}
                labelInValue
                allowClear
                style={{
                  width: '220px',
                  maxHeight: '60px',
                  overflow: 'auto',
                }}
                mode="multiple"
                placeholder="Channel"
                onChange={setChannelValue}
                onBlur={() => selectScrollTo(false)}
                disabled={state.searchDisabled}
                // showSearch
                value={channelValue}
                options={channelOptions}
              />
            </Row>
          </Row>
        </Col>
        <Col>
          <Space>
            <HoverBgButton
              disabled={selectedAttrs.length === 0}
              hovertype="danger"
              onClick={readyDeleteSelectedRow}
            >
              Delete
            </HoverBgButton>
            <HoverBgButton
              hovertype="info"
              onClick={() => setImportDialogVisible(true)}
            >
              Import
            </HoverBgButton>
            <CSVLink
              id="product_attribute_export_button"
              filename="attributes.csv"
              data={localData}
              disabled={state.searchDisabled}
              columns={csvColumns}
            />
          </Space>
        </Col>
      </Row>
      <Spacer height={14} />
      <DataGrid<Entities.ProductAttribute>
        style={{ minHeight: 'calv(100vh - 300px)' }}
        checkboxColumn
        checkboxOnlyRowSelect
        columns={columns}
        dataSource={localData}
        idProperty="AttributeNum"
        loading={state.loading}
        onEdit={(r) => {
          dispatch({ type: Actions.EDIT_ENTITY, params: r.AttributeNum });
        }}
        onReady={setGridRef}
        onSelectionChange={onSelectRow}
        inlineEdit={false}
        pagination
      />
      {importDialogVisible && (<ImportDialog
        onClose={onCloseImportDialog}
        onFileImported={onFileImported}
        visible={importDialogVisible}
      />)}
      {isLoading && <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>}

      {
        state.addEditDialogVisible &&
        <AddEditDilaog
          isCreating={!state.current}
          attributeNum={state.current? state.current.AttributeNum : undefined}
          visible={true}
          onClose={()=>{
            dispatch&&dispatch({ type: Actions.SET_ADD_EDIT_DIALOG_VISIBLE, params: false });
          }}
          onSuccess={()=>{
            dispatch&&dispatch({ type: Actions.SET_ADD_EDIT_DIALOG_VISIBLE, params: false });
            reloadData()
          }}
        />
      }
    </>
  );
};

const useFetchData = (shouldFetch: boolean, dispatch: Dispatcher) => {
  useEffect(() => {
    if (!shouldFetch) {
      return;
    }

    dispatch({ type: Actions.FETCH_DATA, params: false });

    const requests = async () => {
      const attributes = (await fetchAttributes() as Entities.ProductAttribute[]).filter(a => [ProductAttributeType.RESERVED, ProductAttributeType.MEDIA].indexOf(a.AttributeType) < 0);
      const channels = new Map((await fetchChannels() as Entities.ChannelProfile[]).map(p => [p.channelNum, p]));

      dispatch({ type: Actions.DATA_FETCHED, params: { attributes, channels } });
    };

    requests()
      .catch(() => {
        dispatch({ type: Actions.SET_LOADING, params: false });
      });
  }, [shouldFetch, dispatch]);
};

const Page: React.FC = () => {
  const context = useReducer(reducer, initialState());
  const [state, dispatch] = context;

  useFetchData(state.fetchData, dispatch);

  return (
    <PageContext.Provider value={context}>
      <ContentLayout>
        <Heading
          id="product_attribute_title"
          title="Product Attributes"
          actions={(
            // <Link to="/new-attribute">
              <Button
                id="attribute_add_button"
                type="primary"
                onClick={()=>{
                  dispatch&&dispatch({ type: Actions.SET_CURRENT, params: undefined });
                  dispatch&&dispatch({ type: Actions.SET_ADD_EDIT_DIALOG_VISIBLE, params: true });
                }}
              >
                <PlusOutlined />
                Add
              </Button>
            // </Link>
          )}
        />
        <Spacer />
        <SiteContent flexGrow>
          <PageContent />
        </SiteContent>
      </ContentLayout>
    </PageContext.Provider>
  );
};

export default Page;
