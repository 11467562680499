import React from 'react';
import { Button, Checkbox, Row, Space, Table, Radio, Typography } from 'antd';

const { Text } = Typography;

type Props = {
  maskScreen: Function;
  selectedSourceName: string;
  sourceList: any[];
  setCanNext: (value: boolean) => void;
  onSelected: (selectedRow: any[]) => void;
  destinationNotEmptyOverwrite: boolean;
  sourceEmptyIgnoreUpdate: boolean;
  setDestinationNotEmptyOverwrite: (data: boolean) => void;
  setSourceEmptyIgnoreUpdate: (data: boolean) => void;
  useChildLevel?: boolean;
  setUseChildLevel?: (data: boolean) => void;
};

const AttributeSelector = (props: Props) => {
  const { useState } = React;
  const {
    sourceList,
    onSelected,
    setCanNext,
    destinationNotEmptyOverwrite,
    sourceEmptyIgnoreUpdate,
    setDestinationNotEmptyOverwrite,
    setSourceEmptyIgnoreUpdate,
  } = props;
  const [productBasicList, setProductBasicList] = useState<StringKAnyVPair[]>(
    sourceList.map((e) => ({
      ...e,
      selected: false,
    })),
  );
  const allBasicItemSelected = React.useMemo(() => {
    return productBasicList.every((e) => e.selected);
  }, [productBasicList]);

  const noItemSelected = React.useMemo(() => {
    return productBasicList.every((e) => !e.selected);
  }, [productBasicList]);

  const onSelectBasicItem = (evt: any) => {
    console.log('sel->', evt.target.value, evt.target.checked);
    for (let i = 0; i < productBasicList.length; i++) {
      const item = productBasicList[i];

      if (item.attributeNum === evt.target.value) {
        item.selected = evt.target.checked;
        break;
      }
    }

    setProductBasicList([...productBasicList]);
    onSelected(productBasicList.filter((i) => i.selected));
  };

  const productBasicColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Attribute',
      render(text: string, data: any, index: number) {
        return (
          <Checkbox checked={data.selected} onChange={onSelectBasicItem} value={data.attributeNum}>
            {text}
          </Checkbox>
        );
      },
    },
    {
      dataIndex: 'elementName',
      title: 'Content Resources Field',
    },
  ];

  const onChildLevelChange = (evt: any) => {
    if (typeof props.setUseChildLevel === 'function') {
      props.setUseChildLevel(evt.target.checked);
    }
  };

  const unSelectAllBasicItems = () => {
    productBasicList.forEach((e) => (e.selected = false));
    setProductBasicList([...productBasicList]);
    onSelected([...productBasicList]);
  };

  const selectAllBasicItems = () => {
    productBasicList.forEach((e) => (e.selected = true));
    setProductBasicList([...productBasicList]);
    onSelected([...productBasicList]);
  };

  React.useEffect(() => {
    // onSelected(productBasicList.filter(i => i.selected))
    if (productBasicList.findIndex((i) => i.selected) > -1) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [productBasicList, onSelected, setCanNext]);

  return (
    <>
      <div className="dest-step-wrapper">
        <Row>
          <Space style={{ width: '100%' }}>
            <Text strong>{`If destination has value (Not empty):`}</Text>
            <Radio.Group
              onChange={(e) => setDestinationNotEmptyOverwrite(e.target.value)}
              value={destinationNotEmptyOverwrite}
            >
              <Radio value={true}>Overwrite</Radio>
              <Radio value={false}>Don't Overwrite</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Row>
          <Space style={{ width: '100%' }} align="start">
            <Text strong>{`If source doesn't have value (empty):`}</Text>
            <Radio.Group
              onChange={(e) => setSourceEmptyIgnoreUpdate(e.target.value)}
              value={sourceEmptyIgnoreUpdate}
            >
              <Radio value={false}>Use empty value to update destination</Radio>
              <Radio value>{`Ignore (don't update destination)`}</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Row>
        <Space style={{ width: '100%' }} align="start">
            <Text strong>Pull value at child level:</Text>
            <Checkbox checked={props.useChildLevel} onChange={onChildLevelChange}/>
          </Space>
        </Row>
        <div className="section">
          <Row align="middle" className="section-bar">
            <Space>
              <span className="section-title">{props.selectedSourceName}</span>
              <Button
                className="primary-text-btn"
                disabled={allBasicItemSelected}
                onClick={selectAllBasicItems}
              >
                Select All
              </Button>
              <Button
                className="primary-text-btn"
                disabled={noItemSelected}
                onClick={unSelectAllBasicItems}
              >
                Unselect All
              </Button>
              <Button 
                className="primary-text-btn"
              >
                Select No-Value Attributes
              </Button>
            </Space>
          </Row>
          <Table
            columns={productBasicColumns}
            dataSource={productBasicList}
            pagination={false}
            rowKey="attributeNum"
          />
        </div>
      </div>
    </>
  );
};

export default AttributeSelector;
