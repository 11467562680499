import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Button, Col, Form, Modal, Row, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ConfirmationModal from '../../components/ConfirmationModal';
import ContentLayout from '../../components/ContentLayout';
import ModalForm from '../../components/Labels/ModalForm';
import SiteContent from '../../components/SiteContent';
import { Permissions, ViewLevel } from '../../constants/enums/permissions';
import notificationActions from '../../redux/actions/notifications';
import { addLabel, deleteLabel, fetchLabels } from '../../services/products';
import {
  getReducerInitialState,
  productLabelsReducer,
  ProductLabelsScreenContext,
  saveLabel,
  StateActions,
} from './context';
import FormElement, { Type } from '../../components/common/FormElement';

const AddButton = styled(Button)<{ $hasPermission: boolean }>`
  display: ${props => props.$hasPermission ? 'initial' : 'none'};
  min-height: 40px;
`;

const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
  if (permissions && permissions.size === 0) return false;
  return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
};

const searchFields: Array<SearchField<Entities.ILabel> | string> = [
  'ProductLabelName', 'ProductLabelDesc', 'CreatedBy', 'ProductCount',
];

const EditModal: React.FC<{ show: boolean; closeModal: () => void; isSaving: boolean; onSave: () => void }> = ({ show, closeModal, isSaving, onSave }) => (
  <Modal
    visible={show}
    onCancel={closeModal}
    title="Edit Label"
    closable
    okText="Save"
    footer={(
      <>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={onSave}
          type="primary"
          loading={isSaving}
        >
          Save
        </Button>
      </>
    )}
  >
    <FormElement
      formItemProperties={{
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 19,
        },
        name: ['edit', 'ProductLabelName'],
        label: 'Label Name',
        rules: [
          { required: true, message: 'Field required.' },
        ],
      }}
      inputProperties={{
        placeholder: 'Name',
        name: 'ProductLabelName',
      }}
    />
    <FormElement
      formItemProperties={{
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 19,
        },
        name: ['edit', 'ProductLabelDesc'],
        label: 'Description',
        rules: [
          { required: true, message: 'Field required.' },
        ],
      }}
      inputProperties={{
        placeholder: 'Description',
        name: 'ProductLabelDesc',
      }}
      inputType={Type.TEXTAREA}
    />
  </Modal>
);


const refreshData = (dispatch:  React.Dispatch<Utils.DispatchAction<StateActions>>, state: any) => {
  if (!dispatch) return;

  dispatch({ type: StateActions.SET_LOADING_DATA, params: true });

  const paginationParams = {
    $top: state.pagination.pageSize,
    $skip: state.pagination.current <= 1 ? 0 : (state.pagination.pageSize * (state.pagination.current - 1)) + 1,
  };
  const params = {
    $count: true,
    ...paginationParams,
  };

  fetchLabels(params)
    .then((res) => dispatch({ type: StateActions.SET_DATA, params: res.LabelList }))
    .finally(() => dispatch({ type: StateActions.SET_LOADING_DATA, params: false }));
};

const PageContent: React.FC = () => {
  const [state, dispatch] = useContext(ProductLabelsScreenContext);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<Entities.ILabel>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { form } = state || {};
  const reduxActions = bindActionCreators(notificationActions, useDispatch());
  const profiles = useSelector((reduxState: any) => reduxState.profiles);
  const hasPermission = hasPermissions(
    profiles.permissions,
    Permissions.MANAGE_PRODUCT_ELEMENTS,
    ViewLevel.VIEW,
  );

  const onSearchResult = useCallback(
    (newData) => {
      if (!dispatch) {
        return;
      }
      dispatch({ type: StateActions.SET_FILTERED_DATA, params: newData });
    },
    [dispatch],
  );



  if (!state || !dispatch) {
    return null;
  }

  const onSave = () => {
    if (!selectedRowData || !state || !form) return;
    setIsSaving(true);
    const payload = {
      ...form.getFieldValue('edit'),
    };

    saveLabel(dispatch, payload, selectedRowData.ProductLabelId)
      .then(() => reduxActions.setNotification('success', 'Label updated'))
      .catch(() => reduxActions.setNotification('error', 'There was an error'))
      .finally(() => {
        form.resetFields();
        setIsSaving(false);
        setShowEditModal(false);
        refreshData(dispatch, state);
      });
  };

  const onDelete = (data: any) => dispatch({ type: StateActions.SET_LABEL_TO_DELETE, params: data });

  const openModal = (data:  Entities.ILabel) => {
    if (!form) return;
    form.setFieldsValue({
      edit: {
        ProductLabelName: data.ProductLabelName,
        ProductLabelDesc: data.ProductLabelDesc,
      },
    });
    setSelectedRowData(data);
    setShowEditModal(true);
  };

  const closeModal = () => {
    if (!form) return;
    setShowEditModal(false);
    form.resetFields();
  };

  const columns = [
    {
      header: 'Name',
      name: 'ProductLabelName',
      defaultFlex: 3,
    },
    {
      header: 'Description',
      name: 'ProductLabelDesc',
      defaultFlex: 4,
    },
    {
      header: 'Created By',
      name: 'CreatedBy',
      defaultFlex: 3,
    },
    {
      header: 'Product Count',
      name: 'ProductCount',
      defaultFlex: 2,
    },
    {
      name: '',
      defaultFlex: 3,
      render({ data }: any) {
        return hasPermission && (
          <>
            <Button
              size="small"
              disabled={false}
              onClick={() => {openModal(data);}}
            >
              <EditOutlined style={{ color: '#006dff' }} />
              Edit
            </Button>
            <Button
              size="small"
              disabled={false}
              onClick={() => {onDelete(data);}}
            >
              <DeleteOutlined style={{ color: '#c13939' }} />
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const csvColumns: CSVColumns = columns.map(c => ({
    key: c.name,
    header: c.header,
  }));

  return (
    <Spin spinning={state.isSaving} wrapperClassName="ant-spin-flex">
      <EditModal
        show={showEditModal}
        onSave={onSave}
        closeModal={closeModal}
        isSaving={isSaving}
      />
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <SearchBar
            reference="ProductLabelId"
            data={state.data}
            onResult={onSearchResult}
            fields={searchFields}
            disabled={state.searchDisabled}
          />
        </Col>
        <Col>
          <CSVLink
            filename="labels.csv"
            data={state.filteredData}
            disabled={state.searchDisabled}
            columns={csvColumns}
          />
        </Col>
      </Row>
      <Spacer height={14} />
      <ReactDataGrid
        idProperty="ProductLabelId"
        //id='reactDataGridLabel'
        rowHeight={35}
        columns={columns}
        dataSource={state.filteredData}
        loading={state.loadingData}
        pagination
      />
    </Spin>
  );
};

interface ProductLabelsProps {
  isModal: boolean;
}

const ProductLabels: React.FC<ProductLabelsProps> = ({ isModal }: ProductLabelsProps) => {
  const profiles = useSelector((state: any) => state.profiles);
  const reduxActions = bindActionCreators(notificationActions, useDispatch());
  const reducer = useReducer(productLabelsReducer, getReducerInitialState(Form.useForm()[0]));
  const [state, dispatch] = reducer;


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {refreshData(dispatch, state);}, []);

  return (
    <ProductLabelsScreenContext.Provider value={reducer}>
      <ContentLayout isModal={isModal}>
        <ConfirmationModal
          visible={state.confirmationModalVisible}
          title={`Are you sure you want to delete ${state.selectedLabel.ProductLabelName}`}
          handleCancel={() => dispatch({ type: StateActions.SET_CONFIRMATION_MODAL_VISIBLE, params: false })}
          handleConfirm={async () => {
            dispatch({ type: StateActions.SET_IS_SAVING, params: true });
            try {
              await deleteLabel(state.selectedLabel.ProductLabelId);
              reduxActions.setNotification('success', 'Label deleted');
              // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
              dispatch({ type: StateActions.SET_IS_SAVING, params: false });
            }
            refreshData(dispatch, state);
          }}
        />

        <ModalForm
          visible={state.addModalVisible}
          formValues={state.formValues}
          handleChange={(e: any) => {
            // This will cause lots of re-renders
            dispatch({
              type: StateActions.SET_FORM_VALUES,
              params: { ...state.formValues, [e.target.name]: e.target.value },
            });
          }}
          handleCancel={() => {
            dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: false });
          }}
          submit={async () => {
            await addLabel({
              ProductLabelDesc: state.formValues.ProductLabelDesc,
              ProductLabelName: state.formValues.ProductLabelName,
            });
            dispatch({ type: StateActions.RESET_FORM });
            reduxActions.setNotification('success', 'Label added');
            dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: false });
            refreshData(dispatch, state);
          }}
        />

        <Heading
          title="Labels"
          id='labelHeading'
          actions={(
            <AddButton
              id="labelButton"
              type="primary"
              $hasPermission={hasPermissions(profiles.permissions, Permissions.MANAGE_PRODUCT_ELEMENTS, ViewLevel.VIEW)}
              onClick={() => {
                dispatch({ type: StateActions.RESET_FORM });
                dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: true });
              }}
            >
              <PlusCircleOutlined />
              Add Label
            </AddButton>
          )}
        />
        <Spacer />
        <SiteContent flexGrow>
          <Form form={state.form} component={false}>
            <PageContent />
          </Form>
        </SiteContent>
      </ContentLayout>
    </ProductLabelsScreenContext.Provider>
  );
};

export default ProductLabels;
