import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../assets/styles/settings.less';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { fetchChannels, listProfileChannelAccounts } from '../services/channels';
import { useExecutePromise } from '../util/hooks';

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const SectionTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`;

const SectionHeading = styled(Row)`
  margin-bottom: 0.4rem;
`;

const IntegrationsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap
`;

const IntegrationWrapper = styled.div`
  border: solid 1px #ededed;
  flex: 0 0 18%;
  border-radius:  10px;
  margin: 10px;
  padding: 10px;
`;

const Section: React.FC<{ title: string }> = ({ title,  children }) => (
  <IntegrationWrapper>
    <SectionHeading align="top" gutter={12}>
      <Col>
        <SectionTitle>
          {title}
        </SectionTitle>
      </Col>
    </SectionHeading>
    <>
      {children}
    </>
  </IntegrationWrapper>
);

const AddIntegrationLink: React.FC = () => (
  <Link to="/integrations/add">
    <Button type="primary">
      <PlusOutlined />
      Add New Channel
    </Button>
  </Link>
);


const ChannelIntegration: React.FC<{ accounts: ChannelAccount[] }> = (
  {
    accounts,
  },
) => {
  const channelName = useMemo(() => {
    const entry = accounts[0];

    if (entry.platform) {
      return entry.platform.platformName;
    }

    return entry.ChannelName;
  }, [accounts]);

  return (
    <Section title={channelName}>
      <div>
        {
            accounts.map(p => (
              <Link
                key={p.ChannelAccountNum}
                to={`/integrations/${p.ChannelNum}-${p.PlatformNum}/details/${p.ChannelAccountNum}`}
              >
                <div>
                  {p.ChannelAccountName}
                </div>
              </Link>
            ))
          }
      </div>
    </Section>
  );
};

type AccountMap = {
  /**
   * Retail accounts grouped by PlatformNum
   */
  retail: { [key: number]: ChannelAccount[] },
  /**
   * Non-retail accounts grouped by ChannelNum
   */
  nonRetail: { [key: number]: ChannelAccount[] },
};

const loadMappedAccounts = async () => {
  const [accounts, platforms] = await Promise.all([listProfileChannelAccounts(), fetchChannels()]);

  const platformsMap: { [key: number]: Entities.ChannelProfile } = {};
  const mappedAccounts: AccountMap = {
    retail: {},
    nonRetail: {},
  };

  // Generate channels map for easier access.
  platforms.forEach(c => {
    platformsMap[c.channelNum] = c;
  });

  accounts.forEach(a => {
    if (a.PlatformNum === 0) {
      if (!mappedAccounts.nonRetail[a.ChannelNum]) {
        mappedAccounts.nonRetail[a.ChannelNum] = [];
      }

      mappedAccounts.nonRetail[a.ChannelNum].push(a);
      return;
    }

    const entry = {
      ...a,
      platform: platformsMap[a.ChannelNum],
    };

    if (!mappedAccounts.retail[a.PlatformNum]) {
      mappedAccounts.retail[a.PlatformNum] = [];
    }

    mappedAccounts.retail[a.PlatformNum].push(entry);
  });

  return mappedAccounts;
};

const ChannelIntegrations: React.FC = () => {
  const [data, loading] = useExecutePromise(loadMappedAccounts, { retail: [], nonRetail: [] });

  return (
    <ContentLayout>
      <Heading title="Channel Integrations" actions={<AddIntegrationLink />} />
      <Spacer />
      <SiteContent style={{ padding: '24px' }}>
        <Row justify="space-between">
          <Col span={24}>
            <Spin spinning={loading}>
              <IntegrationsWrapper>
                {Object.keys(data.nonRetail).map(channelNum => (
                  <ChannelIntegration
                    key={channelNum}
                    accounts={data.nonRetail[Number(channelNum)]}
                  />
                ))}
                {Object.keys(data.retail).map(channelNum => (
                  <ChannelIntegration
                    key={channelNum}
                    accounts={data.retail[Number(channelNum)]}
                  />
                ))}
              </IntegrationsWrapper>
            </Spin>
            <Spacer height={24} />
          </Col>
        </Row>
      </SiteContent>
    </ContentLayout>
  );
};

export default ChannelIntegrations;
