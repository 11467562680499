import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Counter from './counter';

const ButtonRefresh = styled(Button)`
  margin-left: 15px;
 `;

function BannerToken () {
    let [count, setCount] = useState(60 * 55);

    const useInterval = (callback: any, delay: any) => {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            function tick() {
                // @ts-ignore
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };
    useInterval(() => {
        if(count > 0) {
            setCount(count - 1);
        }
    }, 1000);
    return (
        count <= 1 ?
            <Alert showIcon message={
                (
                    <div style={{display: 'flex'}}>
                        <p>Session expiring in <Counter/> please click refresh to continue</p>
                        <ButtonRefresh
                            type="primary"
                            icon={<SyncOutlined/>}
                            onClick={() => {
                                window.location.reload()
                            }}
                            size="small"
                        >
                            Refresh
                        </ButtonRefresh>
                    </div>
                )
            } type="warning"/> : null
    );
}

export default BannerToken;