import React from 'react';
import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { FormLabel } from '../../components/common/styledComponents';
import MaskOverlay from '../../components/common/MaskOverlay';
/*import {
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_LIST,
} from '../../constants/config';*/
import LabelsSelector from '../ListProducts/LabelsSelector';
import ChannelControlFlagSelector from '../ListProducts/ChannelControlFlagSelector';
import BrandSelector from '../ListProducts/BrandSelector';
import SortSelector from '../ListProducts/SortSelector';
import { isCodeListType } from './';

type Props = {
  channelFlagVersion: number;
  clearChannelFlags: Function;
  clearLabels: Function;
  clearSearchBrand: Function;
  clearSearchTitle: Function;
  handleSearch: Function;
  hidePanel: Function;
  labelVersion: number;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  searchBrandVersion: number;
  searchCodeType: number;
  searchMultiCode: string;
  searchTitleVersion: number;
  setBrand: Function;
  setLabels: Function;
  setMultiCode: Function;
  setSalesChannel: Function;
  setSortType: Function;
  setStatus: Function;
  setTitle: Function;
  showPanel: Function;
  status: number[];
  statusOptions: StringKAnyVPair[];
  x: number;
  y: number;
  visible: boolean;
  width: number;
  ExtraFilter?: JSX.Element[];
  clearExtraFilter?: Function;
  groupList: any[];
  onGroupChange: Function;
  onTagChange: Function;
  searchTagVersion: number;
  searchGroupsVersion: number;
};

const PanelWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: absolute;

  & textarea.ant-input {
    height: 120px;
  }

  & .ant-divider {
    margin: 8px;
  }

  & .ant-select {
    width: 100%;
  }

  & .bottom-toolbar {
    margin: 16px 8px 0;
  }

  & .filter-body {
    margin: 12px;
  }

  & .filter-form-cell {
    padding: 8px;
    width: 50%;
  }

  & .form-text-input {
    width: 100%;
  }

  & .textarea-wrapper {
    padding: 8px;
  }
`;

const SearchFilterPanel = (props: Props) => {
  const clearFilter = () => {
    props.clearChannelFlags();
    props.clearLabels();
    props.clearSearchBrand();
    props.clearSearchTitle();
    props.setMultiCode("");
    props.setStatus([]);
    if (typeof props.clearExtraFilter === "function") props.clearExtraFilter();
  };

  const handleSearch = () => {
    setTimeout(() => {
      props.handleSearch();
    }, 0);
    props.hidePanel();
  };

  const onPanelMouseDown = () => {
    props.showPanel();
  };

  // eslint-disable-next-line
  const onSearchBrandChange = (brands: string[]) => {
    //console.log('v', brands);
    props.setBrand(brands.join(','));
  };

  // eslint-disable-next-line
  const onSearchBrandChange2 = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchBrand(value);
      props.setBrand(value);
    }, 0);
  };

  const onSearchCodeListChange = (evt: any) => {
    const value = evt.target.value as string;
    const str = value.replace(/\t/g, "\n");

    //setSearchMultiCode(str);
    props.setMultiCode(str);
  };

  const onSearchCodeListKeyDown = (evt: any) => {
    if (evt.keyCode === 9) {
      if (props.searchMultiCode.trim()) {
        props.setMultiCode(props.searchMultiCode.trim() + "\n");
      }

      evt.preventDefault();
    }

    evt.stopPropagation();
  };

  const onSearchStatusChange = (st: number[]) => {
    //setSearchStatus(st);
    props.setStatus(st);
  };

  const onSearchTitleChange = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchTitle(value);
      props.setTitle(value);
    }, 0);
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    //setSearchCCFs(values);
    props.setSalesChannel(values);
  };

  const onSelectLabels = (values: any[]) => {
    //setSearchLabels(values);
    props.setLabels(values);
  };

  const onSelectSortType = (value: any) => {
    //setSearchSorter(value);
    props.setSortType(value);
  };

  return (
    <>
      <PanelWrapper
        onMouseDown={onPanelMouseDown}
        onMouseEnter={props.onMouseEnter as any}
        onMouseLeave={props.onMouseLeave as any}
        style={{
          left: props.x,
          top: props.y,
          width: props.width,
          display: props.visible ? "" : "none",
          zIndex: 1,
        }}
      >
        <Col className="filter-body">
          {isCodeListType(props.searchCodeType) && (
            <div className="textarea-wrapper">
              <Input.TextArea
                allowClear
                onChange={onSearchCodeListChange}
                onKeyDown={onSearchCodeListKeyDown}
                value={props.searchMultiCode}
              />
            </div>
          )}
          <Row justify="space-between">
            <div className="filter-form-cell">
              <FormLabel>Title</FormLabel>
              <Input
                allowClear
                className="form-text-input"
                key={`title-${props.searchTitleVersion}`}
                onChange={onSearchTitleChange}
                //value={searchTitle}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Status</FormLabel>
              <Select
                mode="multiple"
                onChange={onSearchStatusChange}
                options={props.statusOptions as any[]}
                value={props.status}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Sales Channel</FormLabel>
              <ChannelControlFlagSelector
                channelFlagVersion={props.channelFlagVersion}
                onChange={onSelectChannelControlFlags}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Brand</FormLabel>
              {/*<Input
                allowClear
                className="form-text-input"
                key={`brand-${props.searchBrandVersion}`}
                onChange={onSearchBrandChange2}
              />*/}
              <BrandSelector
                key={`brand-${props.searchBrandVersion}`}
                version={props.searchBrandVersion}
                onBrandChange={onSearchBrandChange}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Labels</FormLabel>
              <LabelsSelector
                labelVersion={props.labelVersion}
                onChange={onSelectLabels}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Tag</FormLabel>
              <Input
                key={`Tag-${props.searchTagVersion}`}
                placeholder="Please input and separate with commas"
                style={{ width: "100%" }}
                onChange={(e) => props.onTagChange(e.target.value || "")}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>View Grid By</FormLabel>
              <Select
                key={`Group-${props.searchGroupsVersion}`}
                className="status-selector"
                mode="multiple"
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
                onChange={(value, option) => props.onGroupChange(value, option)}
                options={props.groupList.map((group) => ({
                  label: group.enumName,
                  value: group.enumValue,
                }))}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Sort By</FormLabel>
              <SortSelector onChange={onSelectSortType} />
            </div>
            {(props.ExtraFilter || []).map((item, index) => (
              <div className="filter-form-cell" key={`extra-item-${index}`}>
                {item}
              </div>
            ))}
          </Row>
          <Divider />
          <Row className="bottom-toolbar" justify="end">
            {/*<Button onClick={() => props.hidePanel()}>
            <CloseOutlined />
            Close
          </Button>*/}
            <Space>
              <Button onClick={clearFilter} type="text">
                Clear Filter
              </Button>
              <Button onClick={handleSearch} type="primary">
                <SearchOutlined />
                Search
              </Button>
            </Space>
          </Row>
        </Col>
      </PanelWrapper>
      <MaskOverlay
        height={`calc(100vh - ${props.y}px)`}
        onClick={props.hidePanel}
        x={0}
        y={props.y}
        visible={props.visible}
      />
    </>
  );
};

export default SearchFilterPanel;
