import React, { useCallback, useEffect, useState } from 'react';
import { CheckCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Row, Button, Space, Modal, Switch, Col } from 'antd';
import { useExportChannelAccountFileMutation, useExportChannelAccountFilterMutation } from '../../redux/api/exports';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { downloadXlsxFromCsv } from '../../util/files';

// use to hide based on env

const ExportSearchButton: React.FC<{
  mappingType: number;
  disabled: boolean;
  onCallback?: () => void;
  getSearchOptions?: any; // get search options fn
  exportAs?: string;
}> = ({ mappingType, onCallback, disabled, getSearchOptions, exportAs }) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [queueFileExport, { data: operationResponse }] = useExportChannelAccountFilterMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback();
  }, [onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      if(typeof(operationResponse) === 'object'){
        handleSuccess();
      }
    }
  }, [handleSuccess, exportLoading, operationResponse]);


  const payload = {
    fileOperation: 20004,
    exportFile: {
      channelNum: 0,
      channelAccountNum: 0,
      mappingType,
      reason: 'Content Resources',
      isForAllSku: false,
      isFileOperation: true,
      operationSetNum: 0,
      TemplateName: '',
      exportAs,
    },
    exportQuery: getSearchOptions?.call(null) || {},
  };
  

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(payload);
      }}
    >
      Export Search Result
      <CloudDownloadOutlined />
    </Button>
  );
};

type ExportProps = {
  selectedRows: Array<any>;
  data: Array<any>;
  columns: Array<StringKAnyVPair>;
  fileName: string;
  filterArr: string[];
  onClose: () => void;
  getSearchOptions: any;
};
export default function ExportCSV(props: ExportProps): JSX.Element {
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const { columns, data, selectedRows, onClose, fileName, filterArr, getSearchOptions } = props;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [queueFileExport, { data: operationResponse }] = useExportChannelAccountFileMutation();
  const [exportCsv, setExportCsv] = useState(false);
  const history = useHistory();

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onClose();
  }, [onClose]);
  /**
   * handle download event
   */
  const handleDownload = useCallback(
    (downloadData: Array<any>, filterArr: string[], fileName: string) => {
      const headers = columns
        .map(
          (item) =>
            item.header.replace('\n', '').replace('\r', '') ||
            item.title.replace('\n', '').replace('\r', ''),
        )
        .filter((i) => filterArr.indexOf(i) < 0);
      const lines = [] as any;
      lines.push(headers.map((headerItem) => {
        let formattedHeader = headerItem;
        if (typeof headerItem === 'string') {
          if (headerItem.includes(',')) {
            if (headerItem.includes('"')) {
              formattedHeader = headerItem.replace(/"/g, '""');
            }
            formattedHeader = `"${headerItem}"`;
          }
        } else {
          formattedHeader = '';
        }
        return formattedHeader;
      }).join(','));
      downloadData.forEach((row) => {
        const items = [] as any;
        selectedColumns.forEach((dataIndex: string) => {
          let value: string;
          value = row[dataIndex];
          if (dataIndex === 'VariationParentSKU') value = row.styleCode || row.VariationParentSKU;
          if (dataIndex === 'ProductId') value = row.colorPatternCode || '';
          if (typeof value === 'string') {
            // replace \n with \\n,
            value = value.replaceAll('\n', '\\n');
            if (value.includes(',')) {
              if (value.includes('"')) {
                value = value.replaceAll(/"/g, '""');
              }
              value = `"${value}"`;
            }
          } else {
            value = '';
          }
          items.push(value);
        });
        lines.push(items.join(','));
      });
      const csvString = lines.join('\n');
      const fileNameString = `${fileName}_${moment().format('YYYYMMDD')}`;
      if (!exportCsv) {
        // export as xlsx
        downloadXlsxFromCsv(csvString, fileNameString);
        return;
      }
      const blob = new Blob([csvString], { type: 'application/csv' });
      const name = `${fileNameString}.csv`;
      if ('download' in document.createElement('a')) {
        // No IE Download
        const elink = document.createElement('a');
        elink.download = name;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // free url
        document.body.removeChild(elink);
      } else {
        // IE10+ download
        //navigator.msSaveBlob(blob, name);
      }
    },
    [columns, exportCsv, selectedColumns],
  );

  useEffect(() => {
    const checkedList: Array<string> = [];
    columns.forEach((item) => {
      if (
        filterArr.indexOf(
          item.header.replace('\n', '').replace('\r', '') ||
            item.title.replace('\n', '').replace('\r', ''),
        ) < 0
      ) {
        checkedList.push(item.dataIndex || item.name);
      }
    });
    setSelectedColumns(checkedList);
  }, [columns, filterArr]);

  useEffect(() => {
    if (operationResponse) {
      setExportLoading(false);
      if(typeof(operationResponse) === 'object'){
        Modal.confirm({
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          content: `Content Resources is scheduled to export. It may take some time.`,
          okText: 'Close',
          okType: 'primary',
          cancelText: 'Check Out Export Result',
          onCancel: () => {
            history.push('/file-export');
          },
        });
      }
    }
  }, [operationResponse, history]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={handleHide}>Close</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Export Content Resources"
      centered
      visible
      width={700}
      destroyOnClose
      onCancel={handleHide}
      footer={<Footer />}
    >
      <Col style={{ padding: '5px' }}>
        <h5>Export As</h5>
        <Switch
          checked={exportCsv}
          onChange={setExportCsv}
          checkedChildren='CSV'
          unCheckedChildren='XLSX'
          style={{
            backgroundColor: '#006dff',
          }}
        />
      </Col>
      <Row justify="start" style={{ marginTop: 10, gap: 15 }}>
      <Button
          type="primary"
          onClick={() => handleDownload(selectedRows, filterArr, fileName)}
          disabled={selectedRows.length === 0}
        >
          Export Selected
        </Button>
        <Button type="primary" onClick={() => handleDownload(data, filterArr, fileName)}>
          Export Current Page
        </Button>
        <ExportSearchButton
          disabled={false}
          mappingType={8}
          getSearchOptions={getSearchOptions}
          onCallback={() =>
            Modal.confirm({
              icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
              content: `Data is scheduled to export. It may take some time.`,
              okText: 'Close',
              okType: 'primary',
              cancelText: 'Check Out Export Result',
              onCancel: () => {
                history.push('/file-export');
              },
            })
          }
          exportAs={exportCsv ? 'csv' : 'xlsx'}
        />
        <Button
          type="primary"
          loading={exportLoading}
          onClick={() => {
            setExportLoading(true);
            queueFileExport({
              fileOperation: 20004,
              // channelAcctJsonFileId: `${templateInfo?.productMappingNum}`,
              channelNum: 0,
              channelAccountNum: 0,
              mappingType: 8,
              reason: 'Content Resources export',
              isForAllSku: true,
              isFileOperation: true,
              operationSetNum: 0,
              exportAs: exportCsv ? 'csv' : 'xlsx',
            });
          }}
        >
          Export All
        </Button>
      </Row>
    </Modal>
  );
}
