import React from "react";

const Icon = ({ width = "16", height = "17", fill = "#D9D9D9", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M8.667 9.065a.667.667 0 0 0 .471.195h.39c.177 0 .347-.07.472-.195l3.138-3.138 1.529 1.528v-4h-4l1.528 1.529-2.862 2.862-2-2a.667.667 0 0 0-.471-.195h-.39A.667.667 0 0 0 6 5.846l-4.471 4.471.942.943 4.196-4.195 2 2Z"
        fill={fill}
      />
    </svg>
  );
};
export default React.memo(Icon);
