import React from "react";

const Icon = ({ width = "16", height = "17", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      {...other}
    >
      <path
        d="M12.111 6.344V4.9c0-.798-.647-1.445-1.444-1.445H3.444C2.647 3.455 2 4.102 2 4.9v4.333c0 .798.647 1.444 1.444 1.444H4.89m1.444 2.89h7.223c.797 0 1.444-.647 1.444-1.445V7.789c0-.798-.647-1.445-1.444-1.445H6.333c-.797 0-1.444.647-1.444 1.445v4.333c0 .798.647 1.444 1.444 1.444Zm5.056-3.612a1.444 1.444 0 1 1-2.889 0 1.444 1.444 0 0 1 2.889 0Z"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default React.memo(Icon);
