import React, { useEffect, useState } from 'react';
import { Card, Tabs, Form, Typography, Result, Row, Col, Button, message } from 'antd';
import { FormLabel } from '../../components/common/styledComponents';
import ContentLayout from '../../components/ContentLayout';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import SiteContent from '../../components/SiteContent';
import { fetchProfileSettingdefs, fetchProfileSettings, saveProfileSettings } from '../../services/channels';
import moment from 'moment';
import { SaveOutlined } from '@ant-design/icons';
import { isJsonString } from '../../util';
import uiTypeRender from '../../util/uiTypeRender';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [settings, setSettings] = useState<any[]>([]);
  const [userSetting, setUserSetting] = useState<{ [key: string]: any }>({});
  const [activeTab, setActiveTab] = useState<string>();
  const [form] = Form.useForm();


  const getSettings = async () => {
    try {
      setLoading(true);
      const res = await fetchProfileSettingdefs();
      setSettings(res);
      if (!activeTab && res.length > 0) {
        setActiveTab(res[0].Category);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserSettings = async () => {
    try {
      setLoading1(true);
      const res = await fetchProfileSettings();
      const temp: { [key: string]: any } = {};
      res.map((item: any) => {
        temp[item.SettingCode] = item.SettingValue;
        return true;
      });
      setUserSetting({ ...temp, isReady: true });
      setLoading1(false);
    } catch (error) {
      setLoading1(false);
    }
  };

  const saveSettings = async () => {
    setSaveLoading(true);
    try {
      const params = await form.validateFields();
      if (params.errorFields) return;
      const postParams = [];
      for (let i in params) {
        const tmp = i.split('_$_$_')
        if (tmp.length === 2) {
          postParams.push({
            SettingCode: tmp[1],
            SettingValue: params[i],
            MasterAccountNum: " ",
            ProfileNum: " ",
          });
        }
      }
      const res = await saveProfileSettings(postParams);
      if (res) {
        message.success({ content: 'Saved Successfully' });
        // getUserSettings();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.log('error = ', error);
      // if (error && error.errorFields && error.errorFields.length > 0 && error.errorFields[0].name && error.errorFields[0].name.length > 0) {
      //   const t = error.errorFields[0].name[0].split('_$_$_')
      //   if (t.length === 2) {
      //     setActiveTab(t[0])
      //   }
      //   if (error && error.errorFields && error.errorFields.length > 0 && error.errorFields[0].errors && error.errorFields[0].errors.length > 0)
      //     message.info(error.errorFields[0].errors[0])
      // };
    }

    setSaveLoading(false);
  };

  /* eslint-disable */
  useEffect(() => {
    getSettings();
    getUserSettings();
  }, []);
  /* eslint-enable */
  return (
    <ContentLayout>
      <Heading
        title="Profile Settings"
        actions={(
          <Row gutter={12}>
            <Col>
              <Button
                type="primary"
                loading={saveLoading}
                disabled={settings.length === 0}
                onClick={() => saveSettings()}
              >
                <SaveOutlined />
                Save Settings
              </Button>
            </Col>
          </Row>
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Form
          form={form}
          labelCol={{
            style: { width: 300, display: 'flex', justifyContent: 'start' },
          }}
          wrapperCol={{
            style: { width: 'calc(100% - 300px)' },
          }}
        >
          <Card
            style={{ width: '100%', marginTop: 16, minHeight: 500 }}
            loading={loading || loading1}
            bodyStyle={{ padding: 8 }}
          >
            {settings.length > 0 ? (
              userSetting &&
              userSetting.isReady &&
              activeTab && (
                <Tabs
                  activeKey={activeTab}
                  size="middle"
                  type="card"
                  onChange={(value) => setActiveTab(value)}
                >
                  {(settings).map((i: any, idx1: number) => (
                    <TabPane tab={i.Category} key={i.Category}>
                      {(i.Scopes || []).map((m: any, idx2: number) => (
                        <Card
                          key={`${m.Scope}${idx2}`}
                          size="small"
                          bordered={false}
                          style={{ marginBottom: 10 }}
                          title={<Title level={5}>{m.Scope}</Title>}
                        >
                          {m.ScopeItems.map((f: any, index: number) => {
                            return !f.IsVisibleByCustomer ? null : (
                              <div key={`${f.SettingCode}${index}`}>
                                <Form.Item
                                  name={`${i.Category}_$_$_${f.SettingCode}`}
                                  initialValue={
                                    f.ProfileSettingDefUI.UIType === 3 ||
                                      f.ProfileSettingDefUI.UIType === 11
                                      ? moment(userSetting[f.SettingCode])
                                      : f.ProfileSettingDefUI.UIType === 7
                                        ? isJsonString(userSetting[f.SettingCode])
                                          ? JSON.parse(userSetting[f.SettingCode])
                                          : []
                                        : f.ProfileSettingDefUI.UIType === 12
                                          ? undefined
                                          : userSetting[f.SettingCode]
                                  }
                                  label={
                                    <FormLabel>{f.SettingName}</FormLabel>
                                  }
                                  rules={[
                                    {
                                      required:
                                        f.ProfileSettingDefUI.UIType === 12 &&
                                          userSetting[f.SettingCode]
                                          ? false
                                          : true,
                                      message: `${f.SettingName} is required`,
                                    },
                                  ]}
                                >
                                  {uiTypeRender(
                                    f.ProfileSettingDefUI,
                                    f.CanBeModifiedByCustomer
                                  )}
                                </Form.Item>
                                {f.Note && (
                                  <Text
                                    style={{ marginLeft: 20 }}
                                    type="secondary"
                                  >
                                    {f.Note}
                                  </Text>
                                )}
                              </div>
                            );
                          })}
                        </Card>
                      ))}
                    </TabPane>
                  ))}
                </Tabs>
              )
            ) : (
              <Result
                status="error"
                title="Failed to extract configuration data."
                subTitle="Please contact the system administrator."
              ></Result>
            )}
          </Card>
        </Form>
      </SiteContent>
    </ContentLayout>
  );
};
