import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, message } from "antd";
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { DataGrid } from "../../../components/common/datagrid/DataGrid";
import { ProductAttributeDataType, ProductAttributeType } from "../../../types/enums";
import { enumNameToLabel } from "../../../util/strings";
import { fetchAttributesPost } from "../../../services/products";
import { trimValues } from "../../../util/validators";
import { loadAttributesChannels, loadProfileAccountAttributesChannels } from "../../DetailProduct/helper";

const FormItem = styled(Form.Item)`
  margin-bottom: 12px;

  .ant-form-item-label{
    text-align: right;
  }

  .ant-form-item-label > label {
    font-size: 14px;
  }
`
type AttributeFilterModalProps = {
  attrModalVisible: boolean;
  onHide: Function;
  onAttributeSelect: (attribute: any) => void;
}

const AttributeFilterModal = (props: AttributeFilterModalProps) => {
  const { attrModalVisible, onHide, onAttributeSelect } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [channels, setChannels] = useState<Map<number, Entities.ChannelProfile>>();
  const [attributeList, setAttributeList] = useState<any[]>([]);
  const [channelList, setChannelList] = useState<any[]>([]);

  const [form] = Form.useForm();

  const ChannelName: React.FC<{ value: number }> = ({ value }) => {
    if (!channels) return null;
    const channelName = channels.get(value)?.channelName || `Common ${value ? `(${value})` : ''}`;

    return <span>{channelName}</span>;
  };

  const columns: TypeColumn[] = [
    {
      name: 'AttributeName',
      header: 'Attribute',
      defaultFlex: 2,
      minWidth: 220,
      // render({ value, data }: { value: string, data: Entities.ProductAttribute }) {
      //   return (
      //     <Link to={`/attribute-detail/${data.AttributeNum}`}>
      //       {value}
      //     </Link>
      //   );
      // },
    },
    {
      name: 'AttributeDataType',
      header: 'Data Type',
      defaultFlex: 1,
      minWidth: 100,
      render({ value }) {
        const enumValue = ProductAttributeDataType[value];
        return <span>{enumNameToLabel(enumValue)}</span>;
      },
    },
    {
      name: 'AttributeChannelNum',
      header: 'Channel',
      defaultFlex: 1,
      minWidth: 160,
      render({ value }) {
        return <ChannelName value={value} />;
      },
    },
    {
      name: 'AttributeId',
      header: 'AttributeId',
      defaultFlex: 1,
      defaultVisible: false,
    },
    {
      name: 'Group1',
      header: 'Collection',
      defaultFlex: 1,
      minWidth: 120,
    },
    {
      name: 'Group2',
      header: 'Group',
      defaultFlex: 1,
      minWidth: 120,
    },
    {
      name: 'DefaultValue',
      header: 'Default Value',
      defaultFlex: 1,
      minWidth: 140,
    },
    {
      name: 'AttributeType',
      header: 'Attribute Type',
      defaultFlex: 1,
      minWidth: 150,
      render({ value }) {
        const enumValue = ProductAttributeType[value];
        return <span>{enumNameToLabel(enumValue)}</span>;
      },
    },
    {
      name: 'OptionList',
      header: 'Option List',
    },
    {
      name: 'ProductCount',
      header: 'Product Count',
    },
    {
      header: 'Action',
      name: 'action',
      // defaultLocked: 'end',
      render: (row: any) => {
        const { data } = row;
        return (
          <Button
            type="primary"
            onClick={() => onAttributeSelect(data)}
          >
            Select
          </Button>
        )
      }
    }
  ];

  const handleSelectAttribute = (row: any) => {
    onAttributeSelect(row.data);
    onHide();
  }

  const handleSearchAttributes = async () => {
    setLoading(true);
    try {
      const params = trimValues(await form.getFieldsValue());
      const attributes = (await fetchAttributesPost({
        ...params,
        ChannelPricingRelated: params.ChannelPricingRelated ? params.ChannelPricingRelated ? 1 : 0 : undefined
      }) as Entities.ProductAttribute[]).filter(a => [ProductAttributeType.RESERVED, ProductAttributeType.MEDIA].indexOf(a.AttributeType) < 0);
      // const attributes = (await fetchAttributes() as Entities.ProductAttribute[]).filter(a => [ProductAttributeType.RESERVED, ProductAttributeType.MEDIA].indexOf(a.AttributeType) < 0);
      if (Array.isArray(attributes)) {
        setAttributeList(attributes);
      }
    } catch (error) {
      message.error(`Fetch attributes failed! ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const getChannels = async () => {
    setLoading(true);
    setAttributeList([]);
    try {
      const channels = new Map((await loadAttributesChannels() as Entities.ChannelProfile[]).map(p => [p.channelNum, p]));
      if (channels) {
        setChannels(channels);
        handleSearchAttributes();
      }
    } catch (error) {
      message.error(`Fetch channel list failed! ${error}`);
    }
  }

  const loadSysAttributesChannels = async () => {
    const ac = await loadProfileAccountAttributesChannels();

    if (Array.isArray(ac)) {
      setChannelList([
        {
          channelNum: 0,
          channelName: 'Common',
        },
        ...ac,
      ]);
    }
  };

  const channelOptions = useMemo(() => {
    return channelList.map(e => (
      {
        value: e.channelNum,
        label: e.channelName,
      }
    ));
  }, [channelList]);

  useEffect(() => {
    loadSysAttributesChannels();
    getChannels();
    // eslint-disable-next-line
  }, [])

  return (
    <Modal
      title="Filter Attributes"
      visible={attrModalVisible}
      destroyOnClose={false}
      maskClosable={false}
      width={window.innerHeight - 50}
      onCancel={() => onHide()}
      footer={null}
    >
      <Form
        labelCol={{ xs: 24, md: 8 }}
        labelAlign="right"
        colon
        size="middle"
        form={form}
      >
        <Row>
          <Col span={12}>
            <FormItem name="AttributeName" label="Attribute Name">
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="ChannelNum" label="Channel">
              <Select mode="multiple" options={channelOptions} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="Collection" label="Collection">
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="Group" label="Group">
              <Input />
            </FormItem>
          </Col>
        </Row>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <FormItem name="ChannelPricingRelated" noStyle valuePropName="checked">
              <Checkbox>Is Channel Pricing Related</Checkbox>
            </FormItem>
            <Button
              loading={loading}
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleSearchAttributes}
            >
              Search
            </Button>
          </Space>
        </div>
      </Form>
      <Divider />
      <DataGrid
        idProperty="AttributeId"
        loading={loading}
        dataSource={attributeList}
        columns={columns}
        pagination="local"
        defaultLimit={10}
        style={{ height: 500 }}
        enableSelection
        onSelectionChange={handleSelectAttribute}
      // onrow
      />
    </Modal>
  )
}

export default AttributeFilterModal;
