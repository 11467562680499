import React, { useMemo } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  notification,
} from 'antd';
import styled from 'styled-components';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../../constants/config';
import { getAllAttributes } from '../../../services/copywriting';
import { getProfileSettingValue } from '../../../util'

type Props = {
  focused: boolean;
  maskScreen: Function;
  mode: 'destination' | 'finish' | 'review';
  overwrite?: boolean;
  productId: string;
  selectedAttributes: number[];
  selectedDestinations?: StringKAnyVPair[];
  setOverwrite?: Function;
  setSelectedDestinations?: Function;
  useChildLevel?: boolean;
  setUseChildLevel?: Function;
};

const ViewCol = styled(Col)`
  & .title-bar {
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
  }

  & .value-area {
    border: 1px solid #D9D9D9;
    padding: 12px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
`;

const DestinationSelector = (props: Props) => {
  const { useState } = React;
  //const [productBasicList, setProductBasicList] = useState<StringKAnyVPair[]>([]);
  const [currentAttributes, setCurrentAttributes] = useState<number[]>(props.selectedAttributes);
  const [productGroups, setProductGroups] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [viewDestination, setViewDestination] = useState<StringKAnyVPair>({});
  const [viewDialogVisible, setViewDialogVisible] = useState(false);
  const destinationWrapperRef = React.useRef<any>(null);
  const overwriteOptions = [
    {label: 'Overwrite', value: true,},
    {label: "Don't Overwrite", value: false,},
  ];
  const style = {
    display: props.focused ? '' : 'none',
  };

  const closeViewDialog = () => {
    setViewDialogVisible(false);
    setViewDestination({});
  };

  const onSelectDestinationItem = (evt: any) => {
    console.log('sel->', evt.target.value, evt.target.checked);
    //for (let i = 0; i < productBasicList.length; i++) {
    loop1:
    for (let i = 0; i < productGroups.length; i++) {
      for (let j = 0; j < productGroups[i].dests.length; j++) {
        //const item = productBasicList[i];
        const item = productGroups[i].dests[j];

        if (item.attributeNum === evt.target.value) {
          item.selected = evt.target.checked;
          setGroupAllItemSelectedStatus(productGroups[i]);
          break loop1;
        }
      }
    }

    //setProductBasicList([...productBasicList]);
    tryToSelectDestinations(productGroups);
    setProductGroups([...productGroups]);
  };

  // eslint-disable-next-line
  const openViewDialog = (dest: StringKAnyVPair) => {
    setViewDialogVisible(true);
    setViewDestination(dest);
  };

  const destinationGridColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Name',
      width: '50%',
      render(text: string, data: any, index: number) {
        //console.log('r->', text, data, index);
        return (
          props.mode === 'destination' ? <Checkbox
            checked={data.selected}
            onChange={onSelectDestinationItem}
            value={data.attributeNum}
          >
            {text}
          </Checkbox> : text
        );
      },
    },
    {
      dataIndex: 'elementName',
      title: 'Content Resources Field',
      width: '50%',
    },
  ];

  const destinationColumns = () => {
    const ret = [...destinationGridColumns];

    /*if (props.mode === 'finish') {
      ret.splice(4, 1);
      ret.splice(1, 1);
    }*/

    return ret;
  };

  const destinationList = (dests: StringKAnyVPair[]) => {
    let list = [...dests];

    if (props.mode === 'review') {
      list = dests.filter(e => e.selected);
    }

    return list;
  };

  const isAllItemSelected = useMemo(() => {
    return productGroups.every(g => g.allSelected);
  }, [productGroups]);

  const isNoItemSelected = useMemo(() => {
    return productGroups.every(g => g.dests && g.dests.every((e: any) => e.selected === false));
  }, [productGroups]);

  // eslint-disable-next-line
  const isAllNoValueItemSelected = () => {
    return productGroups.every(g => isGroupAllNoValueItemsSelected(g));
  };

  const isGroupAllNoValueItemsSelected = (g: StringKAnyVPair) => {
    const items = g.dests.filter((e: StringKAnyVPair) => !e.existingAttributeValue);
    let ret = true;

    if (items.length > 0) {
      ret = items.every((e: StringKAnyVPair) => e.selected);
    }

    return ret;
  };

  const isGroupVisible = (g: StringKAnyVPair) => {
    let ret = false;

    if (props.mode === 'destination') {
      ret = true;
    } else {
      ret = g.dests.some((e: StringKAnyVPair) => e.selected);
    }

    return ret;
  };

  const isSelectedAttribute = (attr: StringKAnyVPair, attrs = currentAttributes) => {
    //console.log(props.selectedAttributes, attr.copywritingElementNum);
    //console.log(attrs, '<--');
    //return props.selectedAttributes.indexOf(attr.copywritingElementNum) > -1;
    return attrs.indexOf(attr.copywritingElementNum) > -1;
    //return true;
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    props.maskScreen(true);

    try {
      //const res = await fetchProductDestinationAttributes(props.productId);
      const { data } = await getAllAttributes();
      const allowCommon = await getProfileSettingValue('UseCommonAttributes', '0'); // isuse common atrribute


      console.log('res->', data);
      if (data && Array.isArray(data)) {
        const tempData = allowCommon === '0' ? data.filter((i: any)=> i.groupName !== 'Common Attributes') : [...data];

        const gd: StringKAnyVPair = {};
        const gs: string[] = [];

        tempData.forEach(e => {
          const gn = e.groupName;

          // match attribute here
          if (!isSelectedAttribute(e, props.selectedAttributes)) return;

          if (gs.indexOf(gn) < 0) gs.push(gn);

          if (!gd[gn]) gd[gn] = {
            allSelected: false,
            dests: [],
            groupName: gn,
          };

          gd[gn].dests.push({
            ...e,
            selected: false,
          });
        });
        console.log('gs', gs, gd);
        const pgs = gs.map(gn => gd[gn]);

        tryToSelectDestinations(pgs);
        //setProductGroups(gs.map(gn => gd[gn]));
        setProductGroups(pgs);
      }
    } catch(e) {
      notification.error({
        message: `Fetch Content Resources elements error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      props.maskScreen(false);
    }
  };

  const onOverwriteChange = (evt: any) => {
    if (typeof props.setOverwrite === 'function') {
      props.setOverwrite(evt.target.value);
    }
  };

  const onChildLevelChange = (evt: any) => {
    if (typeof props.setUseChildLevel === 'function') {
      props.setUseChildLevel(evt.target.checked);
    }
  };

  const unSelectAllItems = () => {
    productGroups.forEach(g => {
      g.dests.forEach((e: StringKAnyVPair) => e.selected = false);
      g.allSelected = false;
    });

    if (typeof props.setSelectedDestinations === 'function') {
      props.setSelectedDestinations([]);
    }
    setProductGroups([...productGroups]);
  };

  const selectAllItems = () => {
    productGroups.forEach(g => {
      g.dests.forEach((e: StringKAnyVPair) => e.selected = true);
      setGroupAllItemSelectedStatus(g);
    });

    tryToSelectDestinations(productGroups);
    setProductGroups([...productGroups]);
  };

  const selectGroupAllItems = (g: StringKAnyVPair) => {
    g.dests.forEach((e: StringKAnyVPair) => e.selected = true);
    setGroupAllItemSelectedStatus(g);
    tryToSelectDestinations(productGroups);
    setProductGroups([...productGroups]);
  };

  const unSelectGroupAllItems = (g: StringKAnyVPair) => {
    g.dests.forEach((e: StringKAnyVPair) => e.selected = false);
    g.allSelected = false;
    tryToSelectDestinations(productGroups);
    setProductGroups([...productGroups]);
  };

  const setGroupAllItemSelectedStatus = (g: StringKAnyVPair) => {
    g.allSelected = g.dests.every((e: StringKAnyVPair) => e.selected);
  };

  const tryToSelectDestinations = (pgs: StringKAnyVPair[]) => {
    if (typeof props.setSelectedDestinations === 'function') {
      const selected = pgs.some(g => g.dests.some((e: StringKAnyVPair) => e.selected));

      props.setSelectedDestinations(selected ? [...pgs] : []);
    }
  };

  const viewDialogWidth = () => {
    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };

  React.useEffect(() => {
    if (props.focused) {
      if (!inited) {
        if (props.mode === 'destination') {
          loadInitialData();
        }

        setInited(true);
      }

      if (props.mode === 'destination') {
        if (JSON.stringify(currentAttributes) !== JSON.stringify(props.selectedAttributes)) {
          setCurrentAttributes(props.selectedAttributes);
          loadInitialData();
        }
      }

      if (['finish', 'review'].indexOf(props.mode) > -1) {
        if (props.selectedDestinations) {
          setProductGroups(props.selectedDestinations);
        }
      }
    }
  }, [
    currentAttributes,
    inited,
    loadInitialData,
    props,
  ]);

  return (<>
    <div className="dest-step-wrapper" ref={destinationWrapperRef} style={style}>
      {props.mode === 'review' && <Alert
        message="The value of the attributes will be replaced with the value from Content Resources"
        type="error"
      />}
      {props.mode === 'finish' && <Alert
        message="The value of following fields is schedule to be updated with the value from Content Resources"
        type="success"
      />}
      <Space className={props.mode !== 'destination' ? 'space-line' : ''}>
        <span
          className={`title-item-label ${props.mode === 'destination' ? 'danger-color' : ''}`}
        >
          If destination has value
        </span>
        <Radio.Group
          disabled={props.mode !== 'destination'}
          onChange={onOverwriteChange}
          options={overwriteOptions}
          value={!!props.overwrite}
        />
      </Space>
      <Row>
        <Space className={props.mode !== 'destination' ? 'space-line' : ''}>
          <span
            className={`title-item-label ${props.mode === 'destination' ? 'danger-color' : ''}`}
          >
            Push value at child level
          </span>
          <Checkbox disabled={props.mode !== 'destination'} checked={props.useChildLevel} onChange={onChildLevelChange}/>
        </Space>
      </Row>
      {props.mode === 'destination' && productGroups.length > 0 && <Space className="space-row">
        <Button
          disabled={isAllItemSelected}
          onClick={selectAllItems}
        >
          Select All
        </Button>
        <Button
          disabled={isNoItemSelected}
          onClick={unSelectAllItems}
        >
          Unselect All
        </Button>
      </Space>}
      {productGroups.map(g => {
        if (isGroupVisible(g)) {
          const noneSelected = g?.dests && g.dests.every((item: any) => !item.selected);
          return (
            <div className="section" key={g.groupName}>
              <Row align="middle" className="section-bar">
                <Space>
                  <span className="section-title">{g.groupName}</span>
                  {props.mode === 'destination' && (
                    <>
                      <Button
                        className="primary-text-btn"
                        disabled={g.allSelected}
                        onClick={() => selectGroupAllItems(g)}
                        // type="text"
                      >
                        Select All
                      </Button>
                      <Button
                        className="primary-text-btn"
                        disabled={noneSelected}
                        onClick={() => unSelectGroupAllItems(g)}
                        // type="text"
                      >
                        Unselect All
                      </Button>
                    </>
                  )}
                </Space>
              </Row>
              <Table
                columns={destinationColumns()}
                dataSource={destinationList(g.dests)}
                pagination={false}
                rowKey='attributeNum'
              />
            </div>  
          );
        }
        return null;
      })}
    </div>
    {viewDialogVisible && (
      <Modal
        centered
        footer={
          <Row justify="end">
            <Button onClick={closeViewDialog}>
              Cancel
            </Button>
          </Row>
        }
        onCancel={closeViewDialog}
        title={`View ${viewDestination.attributeName}`}
        visible={viewDialogVisible}
        width={viewDialogWidth()}
      >
        <Row
          style={{
            height: destinationWrapperRef?.current.offsetHeight,
          }}
        >
          <ViewCol span={12} style={{height: '100%'}}>
            <div className="title-bar">Existing Value</div>
            <div className="value-area">
              {viewDestination.existingAttributeValue}
            </div>
          </ViewCol>
          <ViewCol span={12} style={{height: '100%'}}>
            <div className="title-bar">Content Resources Value</div>
            <div className="value-area">
              {viewDestination.copywritingValue}
            </div>
          </ViewCol>
        </Row>
      </Modal>
    )}
  </>);
};

export default DestinationSelector;
