import { message, Spin, Button, Modal, Typography, Row, Form, Input, Select, Checkbox, Space } from 'antd';
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import { ProductDetailScreenContext } from '../context';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchProductAlias, deleteProductAlias, createProductAlias, syncProductAlias } from '../../../services/products';
import { listProfileChannelAccounts } from '../../../services/channels';
import { DataGrid } from '../../../components/common/datagrid/DataGrid2';
import { convertTimeByUtc } from '../../../util/utcTime';

const { Text, Title } = Typography;

type TextAlign = 'end' | 'left' | 'center' | 'right' | 'start' | undefined;

type Props = {
  focused: boolean;
  productId: string;
  loadChannelInv: Function;
};
type AliasType = {
  rowNum: number;
  channelNum: number;
  channelAccountNum: number;
  channelName: string;
  channelAccountName: string;
  centralProductNum: number;
  alias: string;
};

type CreateModalProps = {
  productId: string;
  onClose: () => void;
  onReload: () => void;
};

const CreateModal = (props: CreateModalProps) => {
  const { productId, onClose, onReload } = props;
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();

  const fetchAccounts = useCallback(async () => {
    try {
      setLoading(true);
      const res = await listProfileChannelAccounts();
      setLoading(false);
      if (res) {
        setAccounts(res);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const addAlias = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      const json = JSON.stringify([
        {
          channelAccountNum: params.channelAccountNum,
          alias: params.alias,
          aliasStatus: params.aliasStatus ? 1 : 0,
        },
      ]);
      setSaveLoading(true);
      await createProductAlias(productId, json);
      setSaveLoading(false);
      message.success('Added alias successfully');
      onReload();
    } catch (error) {
      setSaveLoading(false);
    }
  }, [productId, onReload, form]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <Modal visible width={700} footer={false} maskClosable={false} closable={!saveLoading} onCancel={() => onClose()}>
      <Spin spinning={loading}>
        <Row justify="center">
          <Title level={4}>Add Product Alias</Title>
        </Row>
        <Form form={form} layout="inline" style={{ marginTop: 20 }}>
          <Form.Item name="channelAccountNum" label={<Text strong>Channel Account:</Text>} initialValue={0} rules={[{ required: true, message: 'Please select channel account!' }]}>
            <Select dropdownMatchSelectWidth={false} showSearch optionFilterProp="label" style={{ width: 200 }}>
              <Select.Option key={0} value={0} label="Any Channel">
                Any Channel
              </Select.Option>
              {accounts.map((item) => (
                <Select.Option key={item.ChannelAccountNum} value={item.ChannelAccountNum} label={item.ChannelAccountName}>
                  {item.ChannelAccountName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="alias" label={<Text strong>Alias:</Text>} rules={[{ required: true, message: 'Please input Alias!' }]}>
            <Input />
          </Form.Item>
          <Form.Item style={{ marginLeft: 10, marginTop: 20 }} name="aliasStatus" valuePropName="checked">
            <Checkbox>Send this alias as SKU to the Channel.</Checkbox>
          </Form.Item>
        </Form>
        <Row style={{ marginTop: 20 }} justify="center">
          <Button loading={saveLoading} type="primary" onClick={() => addAlias()}>
            Save
          </Button>
        </Row>
        <Row justify="end">
          <Button disabled={saveLoading} onClick={() => onClose()}>
            Close
          </Button>
        </Row>
      </Spin>
    </Modal>
  );
};

const Alias = (props: Props) => {
  const { productId, focused } = props;
  // const [state] = useContext(ProductDetailScreenContext);
  // const { channelListRaw = [] } = state || {};
  const [inited, setInited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aliasList, setAliasList] = useState<AliasType[]>([]);
  const [currentProductId, setProductId] = useState(productId);
  // const [filterList, setFilterList] = useState<AliasType[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  // const [channelAccounts, setChannelAccounts] = useState<any[]>([])
  // const [selectedKey, setSelectedKey] = useState<string>('0');

  const columns = [
    { name: 'channelAccountName', header: 'Channel Account', defaultFlex: 1, showColumnMenuTool: false },
    { name: 'alias', header: 'Alias', defaultFlex: 1, showColumnMenuTool: false },
    {
      name: 'aliasStatus',
      header: 'Send as SKU',
      defaultFlex: 1,
      showColumnMenuTool: false,
      headerAlign: 'center' as TextAlign,
      textAlign: 'center' as TextAlign,
      minWidth: 150,
      maxWidth: 150,
      render({ data }: { data: any }) {
        return data.aliasStatus === 1 ? 'YES' : 'NO';
      },
    },
    {
      name: 'enterDate',
      header: 'Latest Updated On',
      defaultFlex: 1,
      showColumnMenuTool: false,
      render({ data }: { data: any }) {
        return data.enterDate === '1900-01-01T00:00:00Z' ? '' : convertTimeByUtc(data.enterDate);
      },
    },

    {
      name: '',
      header: 'Action',
      showColumnMenuTool: false,
      minWidth: 150,
      maxWidth: 150,
      headerAlign: 'center' as TextAlign,
      textAlign: 'center' as TextAlign,
      defaultFlex: 1,
      render({ data }: { data: any }) {
        return (
          <Button key="list-loadmore-edit" onClick={() => delAlias(data)}>
            Delete <DeleteOutlined style={{ color: '#ff4d4f' }} />
          </Button>
        );
      },
    },
  ];

  const loadAliasList = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchProductAlias(productId);
      setLoading(false);
      // const channelAccounts = [...new Set(data.map((i:AliasType)=> {return JSON.stringify({
      //   channelAccountNum: i.channelAccountNum
      // })}))]
      // setChannelAccounts(channelAccounts)
      setAliasList(data);
      // setFilterList(data);
    } catch (error) {
      setLoading(false);
    }
  }, [productId]);

  const delAlias = useCallback(
    async (alias: AliasType) => {
      Modal.confirm({
        title: 'Are you sure delete this alias?',
        icon: <ExclamationCircleOutlined />,
        content: alias.alias,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          try {
            const res = await deleteProductAlias(alias.rowNum);
            if (res) {
              message.success(`deleted [${alias.alias}] successfully`);
              loadAliasList();
            }
          } catch (error) { }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [loadAliasList]
  );

  const syncAlias = useCallback(async () => {
    Modal.confirm({
      title: 'Are you sure sync alias?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          const res = await syncProductAlias()
          if (res) {
            message.success(`Sync alias successfully`);
            loadAliasList();
          }
        } catch (error) { }
      },
      onCancel() {
      },
    });
  }, [loadAliasList])

  // const onSelect = (selectedKeys: React.Key[], info: any) => {
  //   setSelectedKey(selectedKeys.length > 0 ? `${selectedKeys[0]}` : '0');
  // };

  useEffect(() => {
    if (productId && focused && (!inited || productId !== currentProductId)) {
      loadAliasList();
      setInited(true);
      setProductId(productId);
    }
  }, [inited, loadAliasList, focused, productId, currentProductId]);

  // useEffect(() => {
  //   if (selectedKey === '0') {
  //     setFilterList([...aliasList]);
  //   } else {
  //     const temp = selectedKey.split('-');
  //     if (temp[0] === '1') {
  //       setFilterList([...aliasList.filter((i) => `${i.channelNum}` === temp[1])]);
  //     }
  //     if (temp[0] === '2') {
  //       setFilterList([...aliasList.filter((i) => `${i.channelAccountNum}` === temp[1])]);
  //     }
  //   }
  // }, [selectedKey, aliasList]);

  return (
    <>
      <Spin spinning={loading}>
        {aliasList.length === 0 ? (
          <Row justify="center" style={{ marginTop: 20 }}>
            <Text strong>
              No Alias was Created yet.{' '}
              <Button type="primary" onClick={() => setModalVisible(true)}>
                Click Here
              </Button>{' '}
              to add one.
            </Text>
          </Row>
        ) : (
          <>
            <Row justify="end" align="middle" style={{ marginBottom: 4 }}>
              {/* <Text>{`Alias Count: ${filterList.length}`}</Text> */}
              <Space>
                <Button onClick={syncAlias}>
                  Sync Alias
                </Button>
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Add New Alias <PlusOutlined />
                </Button>
              </Space>
            </Row>
            <DataGrid style={{ marginLeft: 16, marginRight: 16, minHeight: 400 }} idProperty="rowNum" rowHeight={35} columns={columns} dataSource={aliasList} pagination="local" />
          </>
        )}
      </Spin>
      {modalVisible && (
        <CreateModal
          productId={productId}
          onClose={() => setModalVisible(false)}
          onReload={() => {
            setModalVisible(false);
            loadAliasList();
          }}
        />
      )}
    </>
  );
};

const MemoAlias = React.memo(Alias);

export default MemoAlias;
