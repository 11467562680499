import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Header from '../../components/ChannelIntegration/header';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import IntegrationsActions from '../../redux/actions/integrations';
import { getChannelAccountProfile } from '../../services/channels';
import { usePageSetup } from './ChannelIntegrationDetails';
import { FormsContext } from './details/context';
import ProductMapping from './mappings/products';


const useLoadChannelAccountProfile = (channelAccountNum?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!channelAccountNum) {
      return;
    }

    const actions = bindActionCreators(IntegrationsActions, dispatch);

    getChannelAccountProfile(Number(channelAccountNum))
      .then((data) => {
        actions.setChannelAccount(data);
      });
  }, [channelAccountNum, dispatch]);
};

const ChannelIntegrationMapping: React.FC = () => {
  const { channelNum, platformNum, channelAccountNum } = useParams<{ channelNum: string, channelAccountNum?: string, platformNum?: string }>();
  const [forms] = usePageSetup(channelNum, platformNum, channelAccountNum);
  useLoadChannelAccountProfile(channelAccountNum);

  return (
    <FormsContext.Provider value={forms}>
      <ContentLayout>
        <Header />
        <Spacer />
        <SiteContent>
          <ProductMapping channelNum={channelNum} channelAccountNum={channelAccountNum} />
        </SiteContent>
      </ContentLayout>
    </FormsContext.Provider>
  );
};

export default ChannelIntegrationMapping;
