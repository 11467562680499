import React, { useCallback } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { Form, Select, Upload, message } from 'antd';

const validExcelMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

type Props = {
  maskScreen: Function;
  fileList: any[];
  setFileList: (data: any[]) => void;
  operation: string;
  setOperation: (data: string) => void;
};

const SourceSelector = (props: Props) => {
  const { fileList, setFileList, operation, setOperation } = props;

  const [form] = Form.useForm();

  const beforeUpload = useCallback(
    (file: RcFile) => {
      form.setFieldsValue({ fileSelected: false });

      if (validExcelMimeTypes.indexOf(file.type) < 0) {
        message.error(`${file.name} is not a spreadsheet file`).then();
      } else {
        form.setFieldsValue({ fileSelected: true });
        setFileList([file]);
      }

      return false;
    },
    [form, setFileList],
  );

  return (
    <>
      <div className="dest-step-wrapper">
        <Form form={form} style={{ marginTop: 16, width: 400 }}>
          <Form.Item label="File Format:">
            <Select style={{ width: 250 }} value={operation} onChange={setOperation}>
              <Select.Option value="5100">Content Resources</Select.Option>
              {/* <Select.Option value="5200">Shopify</Select.Option>
              <Select.Option value="5300">Magento 2.X</Select.Option> */}
            </Select>
          </Form.Item>
          <Form.Item
            name="fileSelected"
            rules={[{ required: true, message: 'Please add a file.' }]}
          >
            <Upload.Dragger
              fileList={fileList}
              name="template"
              multiple={false}
              beforeUpload={beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag a file to this area to upload</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SourceSelector;
