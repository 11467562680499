import CopyToClipboard from 'react-copy-to-clipboard';
//import { message } from 'antd';
import React from 'react';
import { OptionsType } from 'rc-select/es/interface';
import FileCopy from '../../assets/icons/file_copy';
import { Wrapper } from './styledComponents';
import FormElement, { Type } from '../../components/common/FormElement';
import message from '../../components/common/message';
import { filterOption } from '../../util/strings';

export enum ProductTypes {
  STANDARD = 0,
  VARIATIONS = 1,
  BUNDLE = 2,
}

export const basicInfoFields = (
  editMode: boolean,
  productType: number,
  bundleType: number,
  isCreating?: boolean,
  sku?: string,
  labels?: Array<Entities.ILabel>,
  classificationOnChange?: Function,
  channels?: Array<object>,
  classifications?: Array<Entities.ProductClassification>,
) => {
  const classificationsOptions = classifications ? [
    {
      label: 'No Classification',
      value: 0,
    },
    ...classifications.map((c: any) => ({
      label: c.ClassificationName,
      value: c.ClassificationNum,
    })),
  ] : [];

  return ({
    data1: [
      {
        formItemProperties: {
          label: (
            <Wrapper>
              SKU
              <CopyToClipboard text={sku || ''} onCopy={() => message.success('SKU Copied to clipboard')}>
                <span style={{ cursor: 'pointer' }}><FileCopy width={24} height={24} style={{ marginLeft: '15px', paddingTop: '4px' }} /></span>
              </CopyToClipboard>
            </Wrapper>
          ),
          rules: [
            { required: true, message: 'Field required.' },
          ],
          name: 'SKU',
        },
        inputProperties: {
          name: 'SKU',
          maxLength: 50,
          disabled: !editMode || !isCreating,
        },
        toolTip: 'Your own unique ID of a Product (required)',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Style Code',
          rules: [
            { required: productType === 2 && bundleType === 0, message: 'Field required.' },
          ],
          name: ['styleMaster', 'styleCode'],
        },
        inputProperties: {
          name: 'styleCode',
          maxLength: 25,
          disabled: !editMode || !isCreating,
        },
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Title',
          name: 'ProductTitle',
        },
        inputProperties: {
          name: 'ProductTitle',
          maxLength: 120,
          disabled: !editMode,
        },
        toolTip: 'Product Title. 120 character limit',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Subtitle',
          name: 'Subtitle',
        },
        inputProperties: {
          name: 'Subtitle',
          maxLength: 100,
          disabled: !editMode,
        },
        toolTip: 'Inventory product subtitle. 100 character limit',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Short Description',
          name: 'ShortDescription',
        },
        inputProperties: {
          name: 'ShortDescription',
          maxLength: 1000,
          disabled: !editMode,
        },
        toolTip: 'Short description of the product (HTML-supported). 1,000 character limit.',
        inputType: Type.TEXTAREA,
      },
      {
        formItemProperties: {
          label: 'Description',
          name: 'LongDescription',
        },
        inputProperties: {
          name: 'LongDescription',
          maxLength: 32000,
          disabled: !editMode,
        },
        toolTip: 'Description of the product (HTML-supported). 32,000 character limit.',
        inputType: Type.TEXTAREA,
      },
      {
        formItemProperties: {
          label: 'Labels',
          name: 'labels',
        },
        inputProperties: {
          placeholder: 'Select Labels',
          mode: 'tags',
          style: { width: '100%' },
          disabled: !editMode,
          optionFilterProp: 'title',
          options: labels?.map((c: any) => ({
            label: c.ProductLabelName,
            value: c.ProductLabelId,
            title: c.ProductLabelName,
          })) || [],
        },
        inputType: Type.SELECT,
      },
      {
        formItemProperties: {
          label: 'Channel Flags',
          name: 'channels',
        },
        inputProperties: {
          placeholder: 'Select Channels',
          mode: 'multiple',
          style: { width: '100%' },
          value: 0,
          disabled: !editMode,
          filterOption,
          options: channels?.map((c: any) => ({
            label: c.CHNLCtrlFlag,
            value: c.ProductCHNLCtrlFlagId,
          })) || [],
        },
        inputType: Type.SELECT,
      },
      {
        formItemProperties: {
          label: 'Condition',
          name: 'Condition',
        },
        inputProperties: {
          placeholder: 'Select a condition',
          style: { width: '100%' },
          value: 0,
          disabled: !editMode,
          options: [
            {
              label: 'New',
              value: 0,
            },
            {
              label: 'Used',
              value: 1,
            },
            {
              label: 'Refurbished',
              value: 2,
            },
            {
              label: 'Reconditioned',
              value: 4,
            },
            {
              label: 'Like new',
              value: 8,
            },
          ],
        },
        inputType: Type.SELECT,
      },
      {
        formItemProperties: {
          label: 'Classification',
          name: 'ClassificationNum',
        },
        inputProperties: {
          placeholder: 'Select a classification',
          style: { width: '100%' },
          onChange: classificationOnChange,
          value: 0,
          disabled: !editMode,
          options: classificationsOptions,
        },
        inputType: Type.SELECT,
      },
      {
        formItemProperties: {
          label: 'Brand',
          name: 'Brand',
        },
        inputProperties: {
          name: 'Brand',
          maxLength: 150,
          disabled: !editMode,
        },
        toolTip: 'Kind or make of the product. 150 character limit.',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Manufacturer',
          name: 'Manufacturer',
        },
        inputProperties: {
          name: 'Manufacturer',
          maxLength: 255,
          disabled: !editMode,
        },
        toolTip: 'Name of the company that manufactures the product.',
        inputType: Type.INPUT,
      },
    ],
    data2: [
      {
        formItemProperties: {
          label: 'Retail price',
          name: 'Price',
          initialValue: 0,
        },
        inputProperties: {
          prefix: '$',
          name: 'Price',
          min: 0,
          disabled: !editMode,
          style: { width: '100%' },
        },
        inputType: Type.NUMBER,
      },
      {
        formItemProperties: {
          label: 'MSRP',
          name: 'MSRP',
        },
        inputProperties: {
          prefix: '$',
          name: 'MSRP',
          min: 0,
          disabled: !editMode,
          style: { width: '100%' },
        },
        inputType: Type.NUMBER,
        toolTip: 'The manufacturer\'s suggested retail price',
      },
    ],
    data3: [
      {
        formItemProperties: {
          label: 'Sales Cost',
          name: 'Cost',
        },
        inputProperties: {
          prefix: '$',
          name: 'Cost',
          min: 0,
          disabled: !editMode,
          style: { width: '100%' },
        },
        inputType: Type.NUMBER,
      },
      {
        formItemProperties: {
          label: 'Average Cost',
          name: 'AvgCost',
        },
        inputProperties: {
          prefix: '$',
          name: 'AvgCost',
          min: 0,
          disabled: !editMode,
          style: { width: '100%' },
        },
        inputType: Type.NUMBER,
      },
      {
        formItemProperties: {
          label: 'MAP',
          name: 'MAPPrice',
        },
        inputProperties: {
          prefix: '$',
          name: 'MAPPrice',
          min: 0,
          disabled: !editMode,
          style: { width: '100%' },
        },
        inputType: Type.NUMBER,
        toolTip: 'In its simplest form, minimum advertised pricing (MAP) is the lowest price a retailer can advertise the product for sale.',
      },
    ],
    data4: [
      {
        formItemProperties: {
          label: 'UPC',
          name: 'UPC',
        },
        inputProperties: {
          name: 'UPC',
          maxLength: 20,
          disabled: !editMode,
        },
        toolTip: 'Universal Product Code',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'FNSku',
          name: 'FNSku',
        },
        inputProperties: {
          name: 'FNSku',
          maxLength: 10,
          disabled: !editMode,
        },
        toolTip: 'Fulfillment Network Stock Keeping Unit (FNSKU) is an Amazon-unique term to describe the barcodes that help the eCommerce guru identify and track products and connect them to you, the seller. This product-identifying code is similar to a UPC and it’s used to help Amazon label your product in their fulfillment centers.',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'ISBN',
          name: 'ISBN',
        },
        inputProperties: {
          name: 'ISBN',
          maxLength: 20,
          disabled: !editMode,
        },
        toolTip: 'International Standard Book Number',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'EAN',
          name: 'EAN',
        },
        inputProperties: {
          name: 'EAN',
          maxLength: 20,
          disabled: !editMode,
        },
        toolTip: 'European Article Number',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'MPN',
          name: 'MPN',
        },
        inputProperties: {
          name: 'MPN',
          maxLength: 50,
          disabled: !editMode,
        },
        toolTip: 'Manufacturer Part Number',
        inputType: Type.INPUT,
      },
    ],
    data5: [
      {
        formItemProperties: {
          label: 'ASIN',
          name: 'ASIN',
        },
        inputProperties: {
          name: 'ASIN',
          maxLength: 10,
          disabled: !editMode,
        },
        toolTip: 'Amazon Standard Identification Number',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Tax Product Code',
          name: 'TaxProductCode',
        },
        inputProperties: {
          name: 'TaxProductCode',
          maxLength: 25,
          disabled: !editMode,
        },
        toolTip: 'Tax product code for this product (for reseller use; NOT sales tax). 25 character limit.',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Harmonized Code',
          name: 'HarmonizedCode',
        },
        inputProperties: {
          name: 'HarmonizedCode',
          maxLength: 12,
          disabled: !editMode,
        },
        toolTip: 'Internationally standard code for customs tariffs.',
        inputType: Type.INPUT,
      },
      {
        formItemProperties: {
          label: 'Warranty',
          name: 'Warranty',
        },
        inputProperties: {
          name: 'Warranty',
          maxLength: 255,
          disabled: !editMode,
        },
        toolTip: 'Guarantee that the product meets certain criteria. 255 character limit.',
        inputType: Type.INPUT,
      },
    ],
    data6: [
      {
        formItemProperties: {
          label: 'Length (inches)',
          name:'ProductLength',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'ProductLength',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Length of the product',
      },
      {
        formItemProperties: {
          label: 'Width (inches)',
          name:'ProductWidth',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'ProductWidth',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Width of this product.',
      },
      {
        formItemProperties: {
          label: 'Height (inches)',
          name:'ProductHeight',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'ProductHeight',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Height of this product.',
      },
      {
        formItemProperties: {
          label: 'Box Length (inches)',
          name:'BoxLength',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'BoxLength',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Length of this product (as prepared for shipping).',
      },
      {
        formItemProperties: {
          label: 'Box Width (inches)',
          name:'BoxWidth',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'BoxWidth',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Width of this product (as prepared for shipping).',
      },
      {
        formItemProperties: {
          label: 'Box Height (inches)',
          name:'BoxHeight',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'BoxHeight',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Height of this product (as prepared for shipping).',
      },
    ],
    data7: [
      {
        formItemProperties: {
          label: 'Weight (lb)',
          name:'NetWeight',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'NetWeight',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Weight of this item. (do not include abbreviations such as lbs or oz)',
      },
      {
        formItemProperties: {
          label: 'Gross Weight (lb)',
          name:'GrossWeight',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'GrossWeight',
          min: 0.1,
          style: { width: '100%' },
          disabled: !editMode,
        },
      },
      {
        formItemProperties: {
          label: 'Multipack Quantity',
          name:'MultipackQuantity',
        },
        inputType: Type.NUMBER,
        inputProperties: {
          name: 'MultipackQuantity',
          min: 0,
          step: 1,
          onKeyDown: (event: any) => { if (event.key === '.'){event.preventDefault();} },
          style: { width: '100%' },
          disabled: !editMode,
        },
        toolTip: 'Multipack Quantity',
      },
    ],
  });

};


export const RetailPriceInput = ({ index, value, record }:({ index: number, value: number, record: any })) => (
  <>
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'retailPrice'],
        initialValue: value,
      }}
      inputType={Type.NUMBER}
      inputProperties={{
        prefix: '$',
        min: 0,
        style: { width: '100%' },
      }}
    />
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'erpCode', 'sizeCode'],
        initialValue: record.sizeCode,
        noStyle: true,
      }}
      inputProperties={{
        type: 'hidden',
      }}
    />
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'erpCode', 'colorCode'],
        initialValue: record.colorCode,
        noStyle: true,
      }}
      inputProperties={{
        type: 'hidden',
      }}
    />
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'erpCode', 'lengthCode'],
        initialValue: record.lengthCode,
        noStyle: true,
      }}
      inputProperties={{
        type: 'hidden',
      }}
    />
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'erpCode', 'widthCode'],
        initialValue: record.widthCode,
        noStyle: true,
      }}
      inputProperties={{
        type: 'hidden',
      }}
    />
  </>
);

export const SKUInput = ({ index, value }: ({ index: number, value: string })) => (
  <>
    {value}
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'styleSKU'],
        initialValue: value,
        noStyle: true,
      }}
      inputProperties={{
        type: 'hidden',
      }}
    />
  </>
);

export const AttributeDropDown = ({ index, attributeId, options, attributeListIndex }: ({ index: number, attributeId: string, options: OptionsType, attributeListIndex: number })) => (
  <>
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'attributeList', attributeListIndex, 'attributeId'],
        initialValue: attributeId,
        noStyle: true,
      }}
      inputProperties={{
        type: 'hidden',
      }}
    />
    <FormElement
      formItemProperties={{
        name: ['styleMaster', 'editReviewValues', index, 'attributeList', attributeListIndex, 'value'],
        style: { width: '100%' },
        initialValue: options[0].value,
      }}
      inputProperties={{
        options,
      }}
      inputType={Type.SELECT}
    />
  </>
);
