import React from 'react';
//import { Button } from 'antd';
import { DataGrid } from '../../components/common/datagrid/DataGrid';
//import Spacer from '../../components/common/Spacer';
import message from '../../components/common/message';
import { HoverBgButton } from '../../components/common/styledComponents';

const EDIT_MODE = 1;
//const MAKE_MODE = 2;

type Props = {
  deleteStyleDataRow: Function;
  mode: number;
  styleData: StringKAnyVPair[];
  styleOption: StringKAnyVPair;
};

const columnSrc = [
  {
    index: 'index',
    header: 'Index',
    render: ({ rowIndex }: any) => {
      return rowIndex + 1;
    },
    width: 100,
  },
  {
    name: 'sku',
    header: 'SKU',
    headerAlign: 'center',
    defaultFlex: 1,
    minWidth: 260,
    width: 350,
  },
  {
    name: 'subStyle',
    //header: 'Color Pattern',
    header: 'Sub-Style',
    headerAlign: 'center',
    defaultFlex: 1,
    minWidth: 130,
    width: 160,
  },
  {
    name: 'SizeCode',
    header: 'Size',
    headerAlign: 'center',
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
  {
    name: 'LengthCode',
    header: 'Length',
    headerAlign: 'center',
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
  {
    name: 'WidthCode',
    header: 'Width',
    headerAlign: 'center',
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
];

const StyleCodeGrid = (props: Props) => {
  // eslint-disable-next-line
  const deleteStyleRow = (row: any) => {
    const { data } = row;

    if (data && typeof data === 'object') {
      console.log('d->', data);
      props.deleteStyleDataRow(data);
      message.info(`[${data.sku}] was deleted successfully`);
    }
  };

  const deleteStyleRowById = (uid: string) => {
    const rows = props.styleData.filter(e => e.uniqueId === uid);

    if (rows.length === 1) {
      const data = rows[0];
      console.log('d->', data);
      props.deleteStyleDataRow(data);
      message.info(`[${data.sku}] was deleted successfully`);
    }
  };

  const gridColumns = () => {
    const {
      GenerateSubStyleCode,
      ProductPatternElements: patterns = [],
      //SubStyleCodePatternElements: subPatterns = [],
    } = props.styleOption || {};
    const cols: any[] = [ ...columnSrc ];
    const optPatterns = ['SizeCode', 'LengthCode', 'WidthCode'];

    //console.log('---p--->', props);
    for (let i = cols.length - 1; i >= 0; i--) {
      const k = cols[i].name;

      if (optPatterns.indexOf(k) > -1 && patterns.indexOf(k) < 0) {
        cols.splice(i, 1);
      } else if (GenerateSubStyleCode === 0) {
        if (k === 'subStyle') {
          cols.splice(i, 1);
        }
      }
    }

    if (isProductPatternNeeded() && props.mode === EDIT_MODE) {
      cols.push({
        name: 'uniqueId',
        header: 'Action',
        textAlign: 'center',
        width: 100,
        render(row: any) {
          const { data } = row;

          return (
            <HoverBgButton hovertype="danger" onClick={() => deleteStyleRowById(data.uniqueId)}>
              Remove
            </HoverBgButton>
          );
        },
      });
    }

    return cols;
  };

  const isProductPatternNeeded = () => {
    return props.styleOption.ProductPatternValue > 0;
  };

  console.log('sd ->', props.styleData);

  return (<>
    <div className="style-info-form grid-ctn">
      <DataGrid
        columns={gridColumns()}
        dataSource={props.styleData}
        idProperty="sku"
        sortable={false}
      />
    </div>
  </>);
};

export default StyleCodeGrid;
